import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../Common/backBtn";
import InputRadioField from "../../../Common/inputRadioField";
import InputTextField from "../../../Common/inputTextField";
import SucessModal from "../../../modal/SucessModal";

const OnlineDoctorSplecialisationForm = ({ edit = false }) => {

  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const { id } = useParams();

  useEffect(() => {
    edit && getMenuData();
  }, [edit])

  const getMenuData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}doctor-specialisation/get/${id}`).then(response => {
      setValue('online_doctor_specialization', response.data.online_doctor_specialization);
      setValue('active', response.data.active);
    }).catch(err => {
      console.error(err);
      alert("Some error");
    })
  }

  const onSubmit = (fields) => {
    if (edit) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}doctor-specialisation/update/${id}`,
          { ...fields, online_doctor_specialization: fields?.online_doctor_specialization.trim() }
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}doctor-specialisation/create`,
          { ...fields, online_doctor_specialization: fields?.online_doctor_specialization.trim() }
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    }
  };

  return (
    <>
      <BackBtn
        navigatePath="/online-doctor-specialisation"
      />
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title">
            <Breadcrumbs
              breadcrumbText="Doctor"
              breadcrumbLink=""
              nestedText="Online Doctor Specialization"
              nestedLink="/online-doctor-specialisation"
              nestedText2={`${edit ? "Edit" : ""} Online Doctor Specilization`}
              nestedLink2=""
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="career-jobpost-form">
            <div className="addnew-form1-autor-detail mt-3 d-flex">
              <div className="col-md-6">
                <InputTextField
                  labelName="Online Doctor Specialization "
                  name="online_doctor_specialization"
                  register={register}
                  errors={errors}
                  errorText={"Please provide a Online Doctor Specialization"}
                />
              </div>
              <div className="col-md-6">
                <InputRadioField
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                />
              </div>
            </div>
            <div className="addnew-form1-autor-detail mt-3 d-flex">
            </div>
            <div className="car-dep-form-button mt-5 mb-5">
              <button type="submit" style={{ marginTop: "" }}>
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      {success && (
        <SucessModal
          page={"/online-doctor-specialisation"}
          name="Doctor Specialisation"
          post={edit ? 'updated' : 'posted'}
        />
      )}
    </>
  );
};

export default OnlineDoctorSplecialisationForm;
