import React from "react";
import success from "../../assets/images/animation_300_lb53ccn4 (1).gif";
import "./SucessModel.scss";

const NewSuccessModal = ({ page, name, post }) => {
    return (
        <div
          onClick={() => {
            window.location.href = page;
          }}
        >
          <div className="modal-backdrop fade show success-model"></div>
          <div
            style={{ display: "block" }}
            className="modal fade show modal-sm"
            id="exampleModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog ">
              <div className=" modal-content ">
                <div><img className="w-100 " src={success} alt="" /></div>
                <p className="text-center pb-3">The {name} in now active and the login credentials & login page link in shared with the chosen number & email</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

export default NewSuccessModal