import JoditEditor from "jodit-react";
import { Fragment, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbEditCircle } from "react-icons/tb";

const FAQContent = ({
	setFaqs,
	faqs = [],
}) => {

	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');

	const handleSave = (question, answer) => {
		if (!question || !answer) {
			alert('Please provide both question and answer.');
			return;
		}
		const newFaq = { question, answer };
		setFaqs([...faqs, newFaq]);
		setQuestion('');
		setAnswer('');
	};

	const handleEdit = (index) => {
		// Retrieve the FAQ to edit
		const faqToEdit = faqs[index];

		// Set the question and answer fields with the values of the selected FAQ
		setQuestion(faqToEdit.question);
		setAnswer(faqToEdit.answer);

		// Remove the FAQ being edited from the list
		const updatedFaqs = [...faqs];
		updatedFaqs.splice(index, 1);
		setFaqs(updatedFaqs);
	};

	const handleDelete = (index) => {
		const updatedFaqs = [...faqs];
		updatedFaqs.splice(index, 1);
		setFaqs(updatedFaqs);
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mt-4">
				<div><b>{question ? 'Edit FAQ' : 'Create a FAQ'}</b></div>
				<div>
					<button className="btn btn-success createFaqBtn" type="button" onClick={() => handleSave(question, answer)}>
						Save
					</button>
				</div>
			</div>
			<div className="mt-3">
				<b>Question</b>
				<div className="mt-4 mb-4">
					<input
						type="text"
						className="w-100 questionWrapper border-0"
						value={question}
						onChange={(e) => setQuestion(e.target.value)}
					/>
				</div>

				<b>Answer</b>
				<div className="mt-4">
					<JoditEditor
						value={answer}
						onChange={(value) => setAnswer(value)}
					/>
				</div>
			</div>
			{faqs?.length ? <div className="mt-4">
				<h2 className="mb-2"> FAQs</h2>
				{faqs.map((faq, index) => (

					<Fragment key={index}>
						<div>
							Question:
							<div className="p-2 questionWrapper d-flex">
								<div className="questionContent">{faq?.question}</div>
								<div className="iconWrapper">
									<div className="editIcon cursor-pointer"><TbEditCircle onClick={() => { handleEdit(index, faqs) }} /></div>
									<div className="deleteIcon cursor-pointer"><RiDeleteBin6Line onClick={() => { handleDelete(index) }} /></div>
								</div>
							</div>
						</div>
						<div>
							Answer:
							<div className="mt-3 mb-4 p-2 answerWrapper h-150" dangerouslySetInnerHTML={{ __html: faq?.answer }} />
						</div>
					</Fragment>

				))}
			</div> : null}
		</>
	)
}

export default FAQContent