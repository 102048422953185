import React, { useState } from "react";
import "./DepartmentForm.scss";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SucessModal from "../../../modal/SucessModal";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const DepartmentForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const onSubmit = (fields) => {
    let updatedFileds = {
      ...fields,
      active: checked,
      department: fields?.department?.trim(),
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}career-add-department/create`,
        updatedFileds
      )
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true);
        } else {
          alert("Fail");
          console.log("failed...");
        }
      });
  };

  return (
    <>
      {/* <Home> */}
      <div
        onClick={() => navigate("/careerdepartment")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-title">
          <Link
            to="/dashboard"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Dashboard</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>&#62;</span>
          <Link
            to="/careers"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Careers</span>
          </Link>
          <span style={{ paddingLeft: "0.8%", fontSize: "15px" }}>&#62;</span>
          <span style={{ paddingLeft: "0.4%", fontSize: "15px" }}>
            Add Department / Function
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
          <div className="row car-dep-form">
            <div className="col-5">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Type Department / Function name <span className="star">*</span>
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="department"
                {...register("department", { required: true })}
                placeholder="Type here..."
              />
              {errors.department && (
                <div className={`invalid-feedback d-block`}>
                  Please Enter department
                </div>
              )}
            </div>

            <div className="col-3">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Status <span className="star">*</span>
              </label>
              <div className="d-flex">
                <div className="form-check form-check-inline me-3 mt-1">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="status"
                    id="status"
                    {...register("status", { required: true })}
                    value="Active"
                    checked={checked ? "checked" : ""}
                    onClick={() => setChecked(true)}
                  />
                  <label htmlFor="option-1" className="text-dark ">
                    Active
                  </label>
                </div>
                <div className="form-check form-check-inline mt-1">
                  <input
                    className="form-check-input  me-3"
                    type="radio"
                    name="status"
                    id="status"
                    value="Inactive"
                    checked={checked ? "" : "checked"}
                    onClick={() => setChecked(false)}
                    {...register("status", { required: true })}
                  />
                  <label htmlFor="option-2" className="text-dark">
                    Inactive
                  </label>
                </div>
              </div>
              {errors.status && (
                <div className={`invalid-feedback d-block`}>
                  Please select an Option
                </div>
              )}
            </div>
          </div>

          <div className="car-dep-form-button">
            <button type="submit" style={{ marginTop: "5%" }}>
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/careerdepartment"}
          name="Career Department"
          post="posted"
        />
      )}
    </>
  );
};
export default DepartmentForm;
