import { memo } from "react";
import { useLocation, useNavigate } from "react-router";
import { adLandingFormsList } from "../../constants";
import buttonadd from "../../assets/images/plus.png";

const AdLandingBanner = () => {

  const navigate = useNavigate();
  const pathName = useLocation();

  const handleOrder = (pathName) => {
    navigate(`/ad-landing-forms/${pathName}`);
  };

  return (
    <div className="bg-white">
      <div className="ms-2 mb-2">
        <div className="addNewfaq">
          <div>
            <img src={buttonadd} alt="plus" />
          </div>
          <div>
            <button
            >Add New Landing Page</button>
          </div>
        </div>
      </div>
      <div className="faq-tab">
        {adLandingFormsList.map((data, idx) => {
          return (
            <div
              key={idx}
              className={`${pathName.pathname.split("/")[2] === data?.slugurl
                ? "rose-line"
                : ""
                } ${idx !== 0 ? "ms-5" : ""}`}
              onClick={() => handleOrder(data?.slugurl)}
            >
              {data?.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(AdLandingBanner);