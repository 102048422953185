import axios from "axios";
import { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import moment from "moment/moment";
import search from "../../../../assets/images/searchIcon.png";
import filter1 from "../../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../../assets/svg/Health Article/sortbyicon.svg";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import Deletepopup from "../../../modal/Deletepopup";
import '../index.scss';
import apiCall from "../../../../api";

const DoctorsReportError = ({
    type
}) => {

    const navigate = useNavigate();
    const [del, setDel] = useState(false);
    const [id, setId] = useState("");

    const handleDelete = (id) => {
        fetch(
            `${process.env.REACT_APP_API_URL}doctors-report/delete/${id}`,
            {
                method: "DELETE",
            }
        ).then((result) => {
            result.json().then((res) => {
                list_api_call();
                setDel(false);
            });
        });
    };

    const [filtered_records, setFilteredRecords] = useState([]);
    // const [rows_count, setRowCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [current_page, setCurrentPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState("newest");
    const [sortName, setSortName] = useState("Sort by");
    const [filter, setFilter] = useState({});
    const [records, setRecords] = useState([]);

    const [sortshow, setSortShow] = useState(false);
    const [textcolor, setTextColor] = useState(0);
    const [filtershow, setFilterShow] = useState(false);
    const [submitfilter, setSubmitFilter] = useState(false);

    useEffect(() => {
        list_api_call();
    }, [filter, limit, sort, skip, type]);

    useEffect(() => {
        if (current_page > 1) {
            setSkip((current_page - 1) * limit);
        } else {
            setSkip(0);
        }
    }, [current_page]);

    const list_api_call = async() => {
        try{

        let response 
        if(type === "online-doctor"){
            response  = await apiCall(`doctors-report/list`, "POST",  {
                ...filter,
                skip: skip,
                limit: limit,
                sort: sort,
            });
        } else {
            response  = await apiCall(`clinic-report/list`, "POST",  {
                ...filter,
                skip: skip,
                limit: limit,
                sort: sort,
                clinicProfile: type === "clinic"
            });

        }
        if(response){
            setFilteredRecords([]);
            setRecords(response.data.rows);
           // setRowCount(response.data.count);
            setPages(Math.ceil(response.data.count / limit));
        } else {
            console.error("err");
        }
    } catch(err){
        console.error(err);
        alert(err)
    }
    };

    const filter_records = (filter) => {
        let rows = records.filter((x) => {
            return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
        setFilteredRecords(rows);
    };

    const handleAccept = (id) => {
        axios
            .patch(
                `${process.env.REACT_APP_API_URL}doctors-report/update/${id}`,
                {
                    accept: true,
                    reject: false
                }
            )
            .then((result) => {
                list_api_call();
            });
    }

    const handleReject = (id) => {
        axios
            .patch(
                `${process.env.REACT_APP_API_URL}doctors-report/update/${id}`,
                {
                    accept: false,
                    reject: true,
                }
            )
            .then((result) => {
                list_api_call();
            });
    }


    return (
        <>
            {/* <Home> */}
            <div className="healtharticle">
                <div className="healtharticle-container">
                    <div className="healtharticle-title carrer-job-title ms-4">
                        <Breadcrumbs
                            breadcrumbText="Doctor"
                            breadcrumbLink=""
                            nestedText="Report a Error"
                            nestedLink=''
                        />
                    </div>
                    <div className="careers-tab" style={{ marginLeft: "2%" }}>
                        <div
                            className={`${ type === "online-doctor" ? "master-doctor" : "career-tab-jobpost"}`}
                            onClick={() => navigate("/doctors-report")}
                        >
                            Online Doctor
                        </div>
                        <div
                            className={`${ type === "inclinic-doctor" ? "master-doctor" : "career-tab-jobpost"}`}
                            onClick={() => navigate("/inclinic-doctors-report")}
                        >
                            InClinic Doctor
                        </div>
                        <div
                            className={`${ type === "clinic" ? "master-doctor" : "career-tab-jobpost"}`}
                            onClick={() => navigate("/clinics-report")}
                        >
                            Clinic Profile
                        </div>
                    </div>
                    <div className="healtharticle-show">
                        <div>Show</div>
                        <div>
                            <select
                                value={limit}
                                onChange={(e) => setLimit(e.target.value)}
                                className="me-2"
                            >
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                                <option value="0">All</option>
                            </select>
                        </div>
                        <div>entries</div>
                        <div>
                            <div>
                                {/* <FaSearch /> */}
                                <img src={search} alt="" />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="form-control"
                                    onChange={(e) => filter_records(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="sortandfilter-new">
                            <div className="sortByPosition">
                                <div>
                                    <div>
                                        <div
                                            onClick={() => {
                                                setSortShow(!sortshow);
                                            }}
                                        >
                                            {sortName}
                                        </div>
                                    </div>
                                    <div>
                                        <img src={sort1} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="sortByDepartment">
                                <div>
                                    <div>
                                        <div
                                            onClick={() => {
                                                setFilterShow(true);
                                            }}
                                        >
                                            Filter
                                        </div>
                                    </div>
                                    <div>
                                        <img src={filter1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="healtharticle-table master-doctor-table">
                        <table>
                            <thead>
                                <tr>
                                    <td>S.No</td>
                                    <td>{type === "clinic"? "Clinic Name" : "Doctor Name"}</td>
                                    <td>What do you want to report</td>
                                    <td>Name</td>
                                    <td>Email</td>
                                    <td>Mobile Number</td>
                                    <td>Brief about your concern</td>
                                    <td>Received Time</td>
                                    <td>Received Date</td>
                                    <td>Status</td>
                                    <td>Who are you</td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filtered_records.length > 0
                                    ? filtered_records
                                    : records
                                ).map((el, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{type === "clinic"? el?.clinic_name : el.doctor_name ? el.doctor_name : "-"}</td>
                                            <td>{el.report}</td>
                                            <td>{el.name}</td>
                                            <td>{el.email}</td>
                                            <td>{el.mobile}</td>
                                            <td>{el.concern}</td>
                                            <td>{moment(el.createdAt).format("LT")}</td>
                                            <td>{moment(el.createdAt).format("DD-MM-YYYY")}</td>
                                            <td>
                                                <span
                                                    className={`career-reject reportAccept ${el.accept === true ? "career-reject-bold" : ""}`}
                                                    onClick={() => {
                                                        handleAccept(el._id)
                                                    }}
                                                >{el.accept ? "Accepted" : "Accept"}</span>
                                                {' | '}
                                                <span
                                                    className={`career-reject ${el.reject === true ? "career-reject-bold" : ""}`}
                                                    onClick={() => {
                                                        handleReject(el._id)
                                                    }}
                                                >{el.reject ? "Rejected" : "Reject"}</span>
                                            </td>
                                            <td className="doctorReport">{el.who_are_you ? el.who_are_you : ""}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col d-flex pagination justify-content-end mt-5 admin-pagination">
                <input
                    type="button"
                    className="page-item page-link"
                    value="<<"
                    onClick={(e) => setCurrentPage(1)}
                    disabled={current_page === 1}
                />
                <input
                    type="button"
                    className="page-item page-link"
                    value="<"
                    onClick={(e) => setCurrentPage(current_page - 1)}
                    disabled={current_page === 1}
                />

                {current_page > 1 && (
                    <input
                        type="button"
                        value={current_page - 1}
                        onClick={(e) => {
                            setCurrentPage(current_page - 1);
                        }}
                    />
                )}
                <input
                    className="page-item page-link"
                    type="button"
                    value={current_page}
                />
                {current_page < pages && (
                    <input
                        type="button"
                        value={current_page + 1}
                        onClick={(e) => {
                            setCurrentPage(current_page + 1);
                        }}
                    />
                )}
                <input
                    type="button"
                    value=">"
                    className="page-item page-link"
                    onClick={(e) => setCurrentPage(current_page + 1)}
                    disabled={current_page === pages}
                />
                <input
                    type="button"
                    value=">>"
                    className="page-link"
                    onClick={(e) => setCurrentPage(pages)}
                    disabled={current_page === pages}
                />
            </div>
            {/* </Home> */}
            {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

            {sortshow ? (
                <div className="sortby-Common-career-main sortby-master-common">
                    <div
                        onClick={() => {
                            setTextColor(11);
                            setSortShow(false);
                            setSort("oldest");
                            setSortName("Oldest");
                        }}
                        style={{
                            color: textcolor === 11 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 11 ? "bold" : "",
                        }}
                    >
                        Oldest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(12);
                            setSortShow(false);
                            setSort("newest");
                            setSortName("Newest");
                        }}
                        style={{
                            color: textcolor === 12 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 12 ? "bold" : "",
                        }}
                    >
                        Newest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(13);
                            setSortShow(false);
                            setSort("ascending");
                            setSortName("Ascending");
                        }}
                        style={{
                            color: textcolor === 13 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 13 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↑
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(14);
                            setSortShow(false);
                            setSort("descending");
                            setSortName("Descending");
                        }}
                        style={{
                            color: textcolor === 14 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 14 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↓
                    </div>
                </div>
            ) : null}

            {/*---------Filter Portion--------*/}
            {
                filtershow
                    ?
                    <div className="filter-container-career">
                        <div className="filter-header">Filter</div>
                        <div className="filter-header-hr"></div>
                        <div className="filter-content-doctor">
                            <div
                                className="filter-content-card-doctor"
                                onClick={() => {
                                    setTextColor(1)
                                }}
                            >
                                <div style={{ color: textcolor === 1 ? "#CB1B5B" : "", fontWeight: textcolor === 1 ? "bold" : "" }}>Primary Specialisation</div>
                                <div><FaAngleRight className="rightarrow" /></div>
                            </div>
                            <div
                                className="filter-content-card-doctor"
                                onClick={() => {
                                    setTextColor(2)
                                }}
                            >
                                <div style={{ color: textcolor === 2 ? "#CB1B5B" : "", fontWeight: textcolor === 2 ? "bold" : "" }}>Status</div>
                                <div><FaAngleRight className="rightarrow" /></div>
                            </div>
                        </div>
                        <div className="filter-foot-btn">
                            <div>
                                <button onClick={() => {
                                    setFilterShow(false)
                                }}>Clear</button>
                            </div>
                            <div>
                                <button
                                    onClick={() => {
                                        setFilterShow(false)
                                        setSubmitFilter(true)
                                    }}
                                >Apply</button>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    );
};

export default DoctorsReportError