import JoditEditor from "jodit-react";
import { Fragment, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbEditCircle } from "react-icons/tb";

import NextBackBtns from "./nextBackBtns";

const FaqForm = ({
	faqs,
	setFaqs,
	handleBack = () => { }
}) => {
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');

	const handleSave = () => {
		if (!question || !answer) {
			alert('Please provide both question and answer.');
			return;
		}

		const newFaq = { question, answer, id: Date.now() };
		setFaqs([...faqs, newFaq]);
		setQuestion('');
		setAnswer('');
	};

	const handleDelete = (index) => {
		const updatedFaqs = [...faqs];
		updatedFaqs.splice(index, 1);
		setFaqs(updatedFaqs);
	};


	const handleEdit = (index) => {
		const faqToEdit = faqs[index];
		setQuestion(faqToEdit.question);
		setAnswer(faqToEdit.answer);
		const updatedFaqs = [...faqs];
		updatedFaqs.splice(index, 1);
		setFaqs(updatedFaqs);
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mt-4">
				<div><b>Create a FAQ</b></div>
				{/* <select
					// {...register("type", { required: true })}
					className="form-select car-job-select w-25"
					defaultValue={""}
				>
					<option value="" disabled pri-specialization="true">
						Select page type
					</option>
				</select> */}
				<div>
					<button className="btn btn-success createFaqBtn" type="button" onClick={handleSave}>
						Save
					</button>
				</div>
			</div>
			<div className="mt-3">
				<b>Question</b>
				<div className="mt-4 mb-4">
					<input
						type="text"
						className="w-100 questionWrapper border-0"
						value={question}
						onChange={(e) => setQuestion(e.target.value)}
					/>
				</div>

				<b>Answer</b>
				<div className="mt-4">
					{/* Assuming JoditEditor component sets its value through a callback */}
					<JoditEditor
						value={answer}
						onChange={(value) => setAnswer(value)}
					/>
				</div>
			</div>

			{faqs?.length ? <div className="mt-4">
				<h2 className="mb-2"> FAQs</h2>
				{faqs.map((faq, index) => (

					<Fragment key={index}>
						<div>
							Question:
							<div className="p-2 questionWrapper d-flex">
								<div className="questionContent">{faq?.question}</div>
								<div className="iconWrapper">
									<div className="editIcon cursor-pointer"><TbEditCircle onClick={() => { handleEdit(index, faqs) }} /></div>
									<div className="deleteIcon cursor-pointer"><RiDeleteBin6Line onClick={() => { handleDelete(index) }} /></div>
								</div>
							</div>
						</div>
						<div>
							Answer:
							<div className="mt-3 mb-4 p-2 answerWrapper h-150" dangerouslySetInnerHTML={{ __html: faq?.answer }} />
						</div>
					</Fragment>

				))}
			</div> : null}
			<div className="backSubmitBtns mb-5">
				<NextBackBtns
					next={false}
					handleBack={handleBack}
				/>
				<div className="nextBtnStyle mt-5">
					<div>
						<button type="submit">Submit</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default FaqForm