import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../Common/backBtn";
import InputRadioField from "../../../Common/inputRadioField";

const DoctorMedicalCouncilView = () => {

    const [data, setData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}doctors-medical-council/get/${id}`)
            .then((res) => {
                setData(res.data);
            });
    };

    return (
        <>
            <BackBtn
                navigatePath="/doctors-medical-council"
            />
            <div className="healtharticle ms-0 pb-4 bg-white mt-5 ps-4 pt-5">
                <Breadcrumbs
                    breadcrumbText="Doctors"
                    breadcrumbLink="/add-new-doctors"
                    nestedText="Common"
                    nestedLink=""
                    nestedText2="View Medical Council"
                    nestedLink2=""
                />
                <div className="row mt-5 ms-2 new-menu ">
                    <div className="menu-field col-md-3 me-0">
                        <p className="form-label " htmlFor="">
                            Medical Council
                        </p>
                        <div className="blog-view-text-half-bor">{data.medical_council}</div>
                    </div>

                    <div className="col">
                        <InputRadioField
                            viewOnly={true}
                            checked={data.active === true ? "checked" : null}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DoctorMedicalCouncilView