import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import "./index.scss";
import SucessModal from "../modal/SucessModal";

const AdLAndingMetaTags = () => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);

  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}meta-tags/list`)
      .then((res) => {
        console.log(res);
        setData(res.data.rows);
        setId(res.data.rows[0]?._id);
        setCount(res.data.count);
        res.data?.rows?.length && Object.keys(res.data.rows[0]).map((x) => {
          setValue(x, res.data.rows[0][x]);
        });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const onSubmit = (fields) => {
    if (count === 0) {
      axios
        .post(`${process.env.REACT_APP_API_URL}meta-tags/create`, fields)
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else if (count === 1) {
      let fields = getValues();
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}meta-tags/update/${id}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setEditSuccess(true);
          } else {
            alert("Fail");
          }
        });
    }
  };

  const metaTagFields = [
    {
      pageName: 'Doctors Ad Landing',
      titleName: 'doctors_ad_landing.metaTitle',
      descriptionName: 'doctors_ad_landing.metaDescription',
      keyWordsName: 'doctors_ad_landing.metaKeywords',
      othersName: 'doctors_ad_landing.others',
    },
    {
      pageName: 'Doctors Ad Landing Terms',
      titleName: 'doctors_ad_landing_terms.metaTitle',
      descriptionName: 'doctors_ad_landing_terms.metaDescription',
      keyWordsName: 'doctors_ad_landing_terms.metaKeywords',
      othersName: 'doctors_ad_landing_terms.others',
    },
    {
      pageName: 'Doctors Ad Landing Privacy',
      titleName: 'doctors_ad_landing_privacy.metaTitle',
      descriptionName: 'doctors_ad_landing_privacy.metaDescription',
      keyWordsName: 'doctors_ad_landing_privacy.metaKeywords',
      othersName: 'doctors_ad_landing_privacy.others',
    },
    {
      pageName: 'InstaDoc Android App Download APK',
      titleName: 'instadoc_android_download_apk.metaTitle',
      descriptionName: 'instadoc_android_download_apk.metaDescription',
      keyWordsName: 'instadoc_android_download_apk.metaKeywords',
      othersName: 'instadoc_android_download_apk.others',
    },
    {
      pageName: 'User General',
      titleName: 'user_general.metaTitle',
      descriptionName: 'user_general.metaDescription',
      keyWordsName: 'user_general.metaKeywords',
      othersName: 'user_general.others',
    },
    {
      pageName: 'Partnered Pharmacies Ratings',
      titleName: 'pharmacy_rating.metaTitle',
      descriptionName: 'pharmacy_rating.metaDescription',
      keyWordsName: 'pharmacy_rating.metaKeywords',
      othersName: 'pharmacy_rating.others',
    },
    {
      pageName: 'Pharmacy Owner QR Ratings',
      titleName: 'pharmacy_owner_tracking.metaTitle',
      descriptionName: 'pharmacy_owner_tracking.metaDescription',
      keyWordsName: 'pharmacy_owner_tracking.metaKeywords',
      othersName: 'pharmacy_owner_tracking.others',
    },
  ]

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-title ms-4">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Dashboard</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>&#62;</span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>Ad Landing Meta Tags</span>
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
          <div className="meta-tags-scroll">
            {
              metaTagFields.map((data, idx) => {
                return (
                  <Fragment key={idx}>
                    <div
                      className="mt-3"
                      style={{ fontWeight: "bold", color: "#cb1b5b" }}
                    >
                      {data.pageName}
                    </div>
                    <div className="row car-dep-form mt-2">
                      <div className="col-6">
                        <label
                          className="car-dep-form-txt mb-2"
                          style={{ color: "#4B4853" }}
                          htmlFor=""
                        >
                          Meta Title
                        </label>
                        <input
                          className="form-control w-75"
                          type="text"
                          name=""
                          id="type"
                          {...register(data.titleName, { required: false })}
                          placeholder="Type here..."
                        />
                      </div>

                      <div className="col-6">
                        <label
                          className="car-dep-form-txt mb-2"
                          style={{ color: "#4B4853" }}
                          htmlFor=""
                        >
                          Meta Description
                        </label>
                        <input
                          className="form-control w-75"
                          type="text"
                          name=""
                          id="type"
                          {...register(data.descriptionName, { required: false })}
                          placeholder="Type here..."
                        />
                      </div>

                      <div className="col-6 mt-4">
                        <label
                          className="car-dep-form-txt mb-2"
                          style={{ color: "#4B4853" }}
                          htmlFor=""
                        >
                          Meta Keyword
                        </label>
                        <input
                          className="form-control w-75"
                          type="text"
                          name=""
                          id="type"
                          {...register(data.keyWordsName, { required: false })}
                          placeholder="Type here..."
                        />
                      </div>

                      <div className="col-6 mt-4">
                        <label
                          className="car-dep-form-txt mb-2"
                          style={{ color: "#4B4853" }}
                          htmlFor=""
                        >
                          Others
                        </label>
                        <textarea
                          className="form-control w-75"
                          style={{ height: "150px" }}
                          type="text"
                          name=""
                          id="type"
                          {...register(data.othersName, { required: false })}
                          placeholder="Type here..."
                        />
                      </div>
                    </div>
                  </Fragment>
                )
              })
            }
            <div className="car-dep-form-button mb-5 mt-5 submit-btn">
              <button type="submit">SUBMIT</button>
            </div>
          </div>
        </form>
      </div>
      {success && (
        <SucessModal
          page={"/meta-tags"}
          name="Meta tags"
          post="posted"
        />
      )}

      {editSuccess && (
        <SucessModal
          page={"/meta-tags"}
          name="Meta tags"
          post="updated"
        />
      )}
    </>
  );
};

export default AdLAndingMetaTags;
