
import graytick from "../../assets/images/graytick.PNG";
import greentick from "../../assets/images/greentick.PNG";

const CustomFieldsLevel = ({
  page,
  customFieldLength
}) => {
  return (
    <>
      {
        <div className={page === 1 ? "doctor-page-tick" : page === 2 ? "doctor-page2-tick" : page === 3 ? "doctor-page3-tick" : page === 4 ? "doctor-page4-tick" : ""}>
          <div>
            <img src={greentick} alt="" />
            <div>Page 1</div>
          </div>
          {Array.from({ length: ((customFieldLength - 1) / 30) + 1 }, (_, index) => {
            return (
              <div key={index + 1}>
                <img src={page === 1 ? graytick : greentick} alt="" />
                <div>Page {index + 2}</div>
              </div>
            );
          })}
        </div>
      }
    </>
  )
}

export default CustomFieldsLevel;