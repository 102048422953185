import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../Common/backBtn";
import InputRadioField from "../../../Common/inputRadioField";
import InputTextField from "../../../Common/inputTextField";
import SucessModal from "../../../modal/SucessModal";

const DoctorExperienceForm = ({
    edit = false
}) => {

    const { id } = useParams()

    const {
        register,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [success, setSuccess] = useState(false);

    const getMenuData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}doctors-experience/get/${id}`).then(response => {
            setValue('active', response.data.active);
            setValue('type', response.data.type);
        }).catch(err => {
            console.error(err);
            alert("Some error");
        })
    }

    useEffect(() => {
        edit && getMenuData();
    }, [edit]);

    const onSubmit = (fields) => {
        if (edit) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}doctors-experience/update/${id}`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        } else {

            axios
                .post(
                    `${process.env.REACT_APP_API_URL}doctors-experience/create`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        }

    };

    return (
        <>
            <BackBtn
                navigatePath="/doctors-experience"
            />
            <div className="healtharticle mt-5">
                <div className="pt-3 ms-3">
                    <Breadcrumbs
                        breadcrumbText="Doctor"
                        breadcrumbLink="/add-new-doctors"
                        nestedText="Common Heads"
                        nestedLink=""
                        nestedText2="Experience"
                        nestedLink2=""
                        nestedText3={edit ? "Edit Experience" : "Add New Experience"}
                        nestedLink3=""
                    />
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="carr-dep-form"
                >
                    <div className="row car-dep-form">
                        <div className="col-5">
                            <InputTextField
                                register={register}
                                errors={errors}
                                labelName="Experience"
                                errorText="Please enter the Experience."
                                name="type"
                                placeholder="Type here..."
                            />

                        </div>
                        <div className="col-3">
                            <InputRadioField
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                watch={watch}
                            />
                        </div>
                    </div>

                    <div className="car-dep-form-button">
                        <button type="submit" style={{ marginTop: "5%" }}>
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {success && (
                <SucessModal
                    page={"/doctors-experience"}
                    name="new experience"
                    post={edit ? 'updated' : 'added'}
                />
            )}
        </>
    )
}

export default DoctorExperienceForm