import { useRef } from "react";
import { useLocation, useNavigate } from "react-router";

import rightArrow from "../../assets/images/right-arrow.svg";

const SitemapNavHeader = ({
    titles = [],
    className = "",
    scrollable = false
}) => {
    const pathName = useLocation();
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null);

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 100,
                behavior: 'smooth'
            });
        }
    };

    const handleNavigation = (slug) => {
        navigate(`/sitemap/${slug}`);
    };

    return (
        <div className={`careers-tab ${className} position-relative`} style={{ marginLeft: "2%", display: 'flex', alignItems: 'center' }}>
            <div
                ref={scrollable ? scrollContainerRef : null}
                className={`scroll-container navScrollContainer p-0 ${scrollable ? 'scrollable' : ''}`}
                style={{
                    overflowX: scrollable ? 'auto' : 'visible',
                    whiteSpace: 'nowrap',
                    flexGrow: 0,
                }}
            >
                {titles.map((value, inx) => {
                    const slug = value?.slug;
                    return (
                        <div
                            key={inx}
                            className={`${pathName?.pathname === `/sitemap/${slug}` ? "career-language" : ""} ${inx === 0 ? "" : "ms-5"} career-tab-jobpost customNavtabTitle`}
                            onClick={() => handleNavigation(slug)}
                        >
                            {value?.title}
                        </div>
                    );
                })}
            </div>
            <div>
                {scrollable &&
                    <div onClick={scrollRight}>
                        <button className="border-0 customNavRightArrow">
                            <img src={rightArrow} alt="Scroll Right" />
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default SitemapNavHeader;