import { FaAngleRight } from "react-icons/fa";
import backArrow from "../../assets/svg/back-arrow-icon.svg";
import search from "../../assets/images/searchIcon.png";
import RangeSlider from 'react-range-slider-input';

const FiltersRootModal = ({
  title = "Filter",
  filterOptions = [],
  onClickClear = () => { },
  onClickApply = () => { },
  selectOption = 1,
  setSelectOption = () => { },
  className = ""
}) => {

  return (
    <div className={`filter-container filterRootModal ${className}`}>
      <div className="doctorFilterModalHeader fw-bold">{title}</div>
      <div className="filterModalHr mb-3"></div>
      <div className="">
        {filterOptions.map((data, inx) => {
          return (<div
            className="d-flex align-items-center justify-content-between rootFilterModal"
            key={inx}
            onClick={() => {
              setSelectOption(inx + 1);
            }}
          >
            <div
              style={{
                color: selectOption === (inx + 1) ? "#CB1B5B" : "",
                fontWeight: selectOption === (inx + 1) ? "bold" : "",
              }}
              className="filterModalSelectOption"
            >
              {data}
            </div>
            <div>
              <FaAngleRight className="rightarrow" />
            </div>
          </div>)
        })}
      </div>
      <div className="filterFooterBtn partneredDoctorFooterBtn">
        <button
          onClick={onClickClear}
          className="me-2"
        >
          Clear
        </button>
        <button
          className="ms-2"
          onClick={onClickApply}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default FiltersRootModal

export const FilterSubModal = ({
  name = "",
  onSearchChange = () => { },
  searchQuery = "",
  optionList = [],
  selectedOptions = [],
  handleChange = () => { },
  keyName = "",
  isSearchable = false,
  dataName = "",
}) => {

  return (
    <div className="filter-doctor-fee-partnered-doctor filter-partnered-doctors-container">
      <div className="d-flex align-items-center px-3 gap-3">
        <div><img src={backArrow} alt="" /></div>
        <div className="filter-doctor-fee-header">{name}</div>
      </div>
      <div className="filter-doctor-fee-hr"></div>
      <div className="filter-doctor-fee-container">
        {isSearchable ? <div className="filter-doctor-search">
          <div>
            <div><img src={search} alt="" /></div>
            <div>
              <input
                type="text"
                id="filter-search"
                placeholder="Search"
                onChange={(e) => onSearchChange(e.target.value)}
                value={searchQuery}
              />
            </div>
          </div>
        </div> : null}
        <div>
          {optionList?.map((data, inx) => (
            <div className="d-flex doctorsFilterModalBox justify-content-between" key={inx}>
              <div className="filterModalSelectOption">
                {data?.[dataName]}
              </div>
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name={data?._id}
                  checked={selectedOptions.includes(data?._id)}
                  className="cursor-pointer"
                  onChange={() => handleChange(keyName, data?._id)}
                />
              </div>
            </div>
          ))}
        </div>
        { }
      </div>
    </div>
  )
}

export const FilterRangeSubModel = ({
  name = "",
  subName = "",
  minValue = [],
  maxValue = [],
  feeValues = "",
  setFeeValues = "",
  rangeMinValue = [],
  rangeMaxValue = []
}) => {

  return (
    <div className="filter-doctor-fee-partnered-doctor">
      <div className="d-flex align-items-center px-3 gap-3">
        <div><img src={backArrow} alt="" /></div>
        <div className="filter-doctor-fee-header">{name}</div>
      </div>
      <div className="filter-doctor-fee-hr"></div>
      <div className="filter-doctor-fee-container doctor-fees-range">
        <div className="pt-3">{subName}</div>
        <div className="rangeContainer mt-4" style={{ width: "100%" }}>
          <div className="rangeContainer mt-4" style={{ width: "100%" }}>
            <RangeSlider
              min={minValue}
              max={maxValue}
              value={feeValues}
              onInput={setFeeValues}
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div>{` ₹${minValue}`}</div>
            <div>₹ {maxValue}</div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div>{`₹ ${rangeMinValue}`}</div>
            <div>₹ {rangeMaxValue}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SortByModel = ({
  latest = "",
  oldest = "",
  handleSortClick = () => { },
  selectedSort = "",
  textcolor = "",
  latestTextValue = 11,
  oldestTextValue = 12,
  handleApplyClick = () => { }
}) => {

  return (
    <div className="sortby-doctors-records">
      <div className="d-flex justify-content-between">
        <div className="pb-4 mb-1">{latest}</div>
        <div className="checkbox-wrapper sort-by-checkbox-wrapper">
          <input
            type="checkbox"
            id="latest"
            className="cursor-pointer"
            checked={selectedSort === 'newest'}
            onChange={() => handleSortClick('newest', 'Newest', latestTextValue)}
            style={{
              color: textcolor === latestTextValue ? '#CB1B5B' : '',
              fontWeight: textcolor === latestTextValue ? 'bold' : '',
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>{oldest}</div>
        <div className="checkbox-wrapper sort-by-checkbox-wrapper">
          <input
            type="checkbox"
            id="oldest"
            className="cursor-pointer"
            checked={selectedSort === 'oldest'}
            onChange={() => handleSortClick('oldest', 'Oldest', oldestTextValue)}
            style={{
              color: textcolor === oldestTextValue ? '#CB1B5B' : '',
              fontWeight: textcolor === oldestTextValue ? 'bold' : '',
            }}
          />
        </div>
      </div>
      <div>
        <button className="sortByBtn" onClick={handleApplyClick}>
          Apply
        </button>
      </div>
    </div>
  )
}