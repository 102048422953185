import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";

import SucessModal from "../../modal/SucessModal";
import backRed from "../../../assets/svg/back-rose-icon.svg";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";

const DcotorsFaq = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [success, setSuccess] = useState(false);
    const pathname = location?.pathname?.split('/')[2];

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (fields) => {

        axios
            .post(`${process.env.REACT_APP_API_URL}doctors-faq/create`, { ...fields, section: pathname })
            .then((response) => {
                if (response.data?._id) {
                    setSuccess(true);
                } else {
                    errors.submit_fail = response.data;
                }
            })
            .catch((err) => {
                errors.submit_fail = err.response.data.error;
            });
    };

    return (
        <div className="mt-5 faqContainer p-4">
            <div
                onClick={() => navigate("/doctor-clinic-profile-create")}
                className="rose back-button"
            >
                <img src={backRed} alt="" />
                <span className="rose-back">Back</span>
            </div>
            <Breadcrumbs
                breadcrumbText="Clinic Profile"
                breadcrumbLink="#"
                nestedText="Add New Clinic & Hosiptal"
                nestedLink="#"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex justify-content-between align-items-center">
                    <div><b>Create a FAQ</b></div>
                    <div> <button className="btn btn-success createFaqBtn" type="submit">Save</button></div>
                </div>
                <div className="mt-3">
                    <b>Question</b>
                    <div className="mt-4 mb-4">
                        <input type="text"
                            className="w-100 questionWrapper border-0"
                            name=""
                            id="type"
                            {...register("question", { required: true })}
                        />
                        {errors.question && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a question
                            </div>
                        )}
                    </div>
                    <b>Answer</b>
                    <div className="mt-4">
                        <textarea type="text"
                            className="w-100 answerWrapper border-0"
                            name=""
                            id="type"
                            {...register("answer", { required: true })}
                        />
                        {errors.answer && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a answer
                            </div>
                        )}
                    </div>
                </div>
            </form>
            {success && (
                <SucessModal
                    page={`/doctors-faq/${pathname}`}
                    name="Doctors New FAQ's"
                    post="added"
                />
            )}
        </div>
    )
}

export default DcotorsFaq