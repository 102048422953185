import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";

import SucessModal from "../modal/SucessModal";
import { degree, medicalCouncil } from "../../constants";

const AdLandingDoctorEdit = () => {

    const { id } = useParams();
    const [success, setSuccess] = useState(false);
    const [primaryspl, setPrimaryspl] = useState([]);
    const [experience, setExperience] = useState([]);
    const [inclinic, setInclinic] = useState([]);
    const [gender, setGender] = useState([]);
    const [location, setLocation] = useState("");

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        control,
        trigger,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getPrimaryspl();
        getExperience();
        getInClinic();
        getDoctorsAd();
        getGender();
    }, []);

    const getPrimaryspl = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}primary-specialisation/list`, {
                noLimit: true,
            })
            .then((res) => {
                setPrimaryspl(res.data.rows);
            });
    };

    const getExperience = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}experience/list`)
            .then((res) => {
                setExperience(res.data.rows);
            });
    };

    const getInClinic = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}in-clinic-fee/list`)
            .then((res) => {
                setInclinic(res.data.rows);
            });
    };

    const getGender = () => {
        axios.post(`${process.env.REACT_APP_API_URL}gender/list`).then((res) => {
            setGender(res.data.rows);
        });
    };

    const getDoctorsAd = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}doctors-ad/get/${id}`)
            .then((response) => {
                setValue("name", response?.data?.name);
                setValue("email", response?.data?.email);
                setValue("mobile", response?.data?.mobile);
                setValue("gender", response?.data?.gender);
                setValue("specialization", response?.data?.specialization);
                setValue("secondary_specialization", response?.data?.secondary_specialization);
                setValue("mci", response?.data?.mci);
                setValue("registered_year", response?.data?.registered_year);
                setValue("medical_council", response?.data?.medical_council);
                setValue("experience", response?.data?.experience);
                setValue("degree", response?.data?.degree);
                setValue("city", response?.data?.city);
                setLocation(response?.data?.city)
                setValue("pincode", response?.data?.pincode);
                setValue("in_clinic_fee", response?.data?.in_clinic_fee);
                setValue("online_consultation_fee", response?.data?.online_consultation_fee);
                setValue("clinic_name", response?.data?.clinic_name);
                setValue("clinic_address", response?.data?.clinic_address);
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    };

    const onSubmit = (fields) => {
        fields.city = location;
        if (!location) {
            setValue("city", "");
            trigger()
        } else {

            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}doctors-ad/doctors-ad-update/${id}`, fields)
                .then((response) => {
                    setSuccess(true);
                    reset()
                });
        }
    }

    return (
        <>
            <div className="healtharticle">
                <div className="healtharticle-container">
                    <div className="healtharticle-title">
                        <Link
                            to="/doctor-inclinic-appointment"
                            style={{
                                textDecoration: "none",
                                color: "black",
                                fontSize: "15px",
                            }}
                        >
                            <span style={{ paddingLeft: "5%" }}>Doctor</span>
                        </Link>
                        <span style={{ fontSize: "15px", paddingLeft: "1%" }}>{`>`}</span>
                        <Link
                            to="/doctor-inclinic-appointment"
                            style={{
                                textDecoration: "none",
                                color: "black",
                                fontSize: "15px",
                                paddingLeft: "0.4%",
                            }}
                        >
                            <span>Ads Landing Form</span>
                        </Link>
                        <span style={{ paddingLeft: "1%", fontSize: "15px" }}>{`>`}</span>
                        <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
                            AdLanding Doctors Edit
                        </span>
                    </div>
                    <form className="addEditInsiders" onSubmit={handleSubmit(onSubmit)}>
                        <div className="doctor-page1">
                            {/* one */}
                            <div className="addnew-form1-autor-detail mt-3 d-flex">
                                <div className="col-md-6">
                                    <label for="inputAddress" className="form-label title">
                                        Name <span className="star">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register("name", { required: true })}
                                        className="form-control addnew-input"
                                        // id="authordetails"
                                        style={{ paddingLeft: "10px" }}
                                    />
                                    {errors.name && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Name
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    <label for="inputAddress" className="form-label title">
                                        Email <span className="star">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register("email", { required: true })}
                                        className="form-control addnew-input"
                                        // id="authordetails"
                                        style={{ paddingLeft: "10px" }}
                                    />
                                    {errors.email && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Email
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* two */}
                            <div className="addnew-form1-autor-detail mt-5 d-flex">
                                <div className="col-md-6">
                                    <label for="inputAddress" className="form-label title">
                                        Mobile Number <span className="star">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register("mobile", { required: true })}
                                        className="form-control addnew-input"
                                        // id="mobile"
                                        // placeholder="Type here"
                                        style={{ paddingLeft: "10px" }}
                                    />
                                    {errors.mobile && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Mobile Number
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Gender <span className="star">*</span>
                                    </label>{" "}
                                    <br />
                                    <div className="d-flex">
                                        {gender.map((el, index) => {
                                            if (el.active === true) {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="form-check form-check-inline d-flex align-items-center"
                                                    >
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            {...register("gender", { required: true })}
                                                            value={el.type}
                                                        />
                                                        <label className="ps-2">
                                                            {el.type}
                                                        </label>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                    {errors.gender && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please select an option
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* three */}
                            <div className="addnew-form1-autor-detail mt-5 d-flex">
                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Primary Specialisation <span className="star">*</span>
                                    </label>

                                    <select
                                        {...register("specialization", { required: true })}
                                        className="form-control addnew-input"
                                        defaultValue={""}
                                    >
                                        <option value="" disabled pri-specialization="true">
                                            Select Your specialisation
                                        </option>
                                        {primaryspl.map((option, inx) => {
                                            if (option.active === true) {
                                                return (
                                                    <option key={inx} value={option.type}>
                                                        {option.type}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </select>

                                    {errors.specialization && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Primary Specialisation
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Secondary Specialisation{" "}
                                        <span className="star"></span>
                                    </label>
                                    <select
                                        {...register("secondary_specialization", { required: true })}
                                        className="form-control addnew-input"
                                        defaultValue={""}
                                    >
                                        <option value="" disabled pri-specialization="true">
                                            Select Your specialisation
                                        </option>
                                        {primaryspl.map((option, inx) => {
                                            if (option.active === true) {
                                                return (
                                                    <option key={inx} value={option.type}>
                                                        {option.type}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>

                            {/* four */}
                            <div className="addnew-form1-autor-detail mt-5 d-flex">
                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Medical Registration Number (MCI)
                                        <span className="star"> * </span>
                                    </label>
                                    <input
                                        className="form-control addnew-input"
                                        type="text"
                                        {...register("mci", { required: true })}
                                    />

                                    {errors.mci && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a MCI
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Year of Registration with Medical Council <span className="star">*</span>
                                    </label>
                                    <input
                                        className="form-control addnew-input"
                                        type="number"
                                        {...register("registered_year", { required: true })}
                                        placeholder="Type Your Registered Year"
                                    />

                                    {errors.registered_year && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Year of Registration with Medical Council
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* five */}
                            <div className="addnew-form1-autor-detail mt-5 d-flex">

                                <div className="col-md-6">
                                    <label className="form-label title">Which Medical Council do you belong to ?<span className="star"> *</span></label>
                                    <select
                                        {...register("medical_council", { required: true })}
                                        className="form-control addnew-input"
                                        defaultValue={""}
                                    >
                                        <option value="" disabled pri-specialization="true">
                                            Select Your Medical Council
                                        </option>
                                        {medicalCouncil.map((datas, idx) => {
                                            return (
                                                <option key={idx}>
                                                    {datas}
                                                </option>
                                            );
                                        }
                                        )}
                                    </select>
                                    {errors.medical_council && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please choose a Medical Council
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Experience <span className="star">*</span>
                                    </label>

                                    <select
                                        {...register("experience", { required: true })}
                                        className="form-control addnew-input"
                                        defaultValue={""}
                                    >
                                        <option value="" disabled pri-specialization="true">
                                            Select experience
                                        </option>
                                        {experience.map((option) => {
                                            if (option.active === true) {
                                                return (
                                                    <option key={option.type} value={option.type}>
                                                        {option.type}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </select>

                                    {errors.experience && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Experience
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="addnew-form1-autor-detail mt-5 d-flex">
                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Degree{" "}
                                        <span className="star">*</span>
                                    </label>
                                    <select
                                        {...register("degree", { required: true })}
                                        className="form-control addnew-input"
                                        defaultValue={""}
                                    >
                                        <option value="" disabled pri-specialization="true">
                                            Select degree
                                        </option>
                                        {degree.map((data, idx) => {
                                            return (
                                                <option key={idx}>
                                                    {data}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    {errors.degree && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a degree
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label for="inputAddress" className="form-label title">
                                        Location{" "}
                                        <span className="star">*</span>
                                    </label>
                                    <Controller
                                        control={control}
                                        name="city"
                                        rules={{ required: "City is required" }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                                                value={field.value}
                                                onChange={(event) => {
                                                    field.onChange(event.target.value)
                                                    if (location) {
                                                        setLocation("")
                                                    }
                                                }
                                                }
                                                onPlaceSelected={(place) => {
                                                    field.onChange(place ? place.formatted_address : "")
                                                    setLocation(place.formatted_address)
                                                }}
                                                types={["address"]}
                                                className={`form-control  addnew-input`}
                                                componentrestrictions={{ country: "us" }}
                                                preventdefault="true"
                                            />
                                        )}
                                    />
                                    {errors.city && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a City
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="addnew-form1-autor-detail mt-5 d-flex">
                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Pincode <span className="star">*</span>
                                    </label>
                                    <input
                                        className="form-control addnew-input"
                                        type="text"
                                        {...register("pincode", {
                                            required: true,
                                            min: 110000,
                                            pattern: /[0-9]{6}/,
                                        })}
                                        placeholder="Enter pincode"
                                        maxLength="6"
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.pincode && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Pincode
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label title">
                                        In-Clinic Fee
                                    </label>
                                    <select
                                        {...register("in_clinic_fee", { required: true })}
                                        className="form-control addnew-input"
                                        defaultValue={""}
                                    >
                                        <option value="" disabled pri-specialization="true">
                                            Select fee
                                        </option>
                                        {inclinic.map((option) => {
                                            if (option.active === true) {
                                                return (
                                                    <option key={option.type} value={option.type}>
                                                        {option.type}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="addnew-form1-autor-detail mt-5 d-flex">
                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Online Consultation Fee
                                    </label>

                                    <select
                                        {...register("online_consultation_fee", { required: true })}
                                        className="form-control addnew-input"
                                        defaultValue={""}
                                    >
                                        <option value="" disabled pri-specialization="true">
                                            Select fee
                                        </option>
                                        {inclinic.map((option) => {
                                            if (option.active === true) {
                                                return (
                                                    <option key={option.type} value={option.type}>
                                                        {option.type}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-6">
                                    <label for="inputAddress" className="form-label title">
                                        Clinic Name <span className="star">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register("clinic_name", { required: true })}
                                        className="form-control addnew-input"
                                        id="authordetails"
                                        style={{ paddingLeft: "10px" }}
                                    />
                                    {errors.clinic_name && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Clinic Name
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="addnew-form1-autor-detail mt-5 d-flex">
                                <div className="col-md-6">
                                    <label className="form-label title">
                                        Clinic Address <span className="star">*</span>
                                    </label>
                                    <textarea
                                        className="form-control addnew-input"
                                        type="textArea"
                                        placeholder="Enter Address"
                                        {...register("clinic_address", { required: true })}
                                    />
                                    {errors.clinic_address && (
                                        <div className={`invalid-feedback d-block`}>
                                            Please provide a Clinic Address
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="addnew1-next mt-3">
                                <div>
                                    <button
                                        type="submit"
                                        className="bg-transparent border-0 text-white"
                                    >
                                        UPDATE
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            {success && (
                <SucessModal
                    page={"/doctor-inclinic-appointment"}
                    name="Doctors Ad"
                    post="updated"
                />
            )}
        </>
    );
};

export default AdLandingDoctorEdit;