import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";

import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import AddNew from "../Common/AddNew";
import { convertToTitleCase } from "../../constants";
import backRed from "../../assets/svg/back-rose-icon.svg";

const EditHealthCondition = () => {
    const [serviceCategoryList, setServiceCategoryList] = useState([])
    const pathName = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        list_api_call()
    }, [])

    const list_api_call = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}services/service-categories/${pathName.pathname.split("/")[2]}`)
            .then((response) => {
                setServiceCategoryList(response?.data);
            });
    }

    return (
        <>
            <div
                onClick={() => navigate(-1)}
                className="rose back-button"
            >
                <img src={backRed} alt="" />
                <span className="rose-back">Back</span>
            </div>
            <div className="mt-4">
                <Breadcrumbs
                    breadcrumbText="Web Bookings, Consultations & Appointments"
                    breadcrumbLink="/web-bookings/home-healthcare-bookings"
                    nestedText={serviceCategoryList?.name}
                    nestedLink={`/web-bookings/${serviceCategoryList?.slug_url}`}
                    nestedText2={convertToTitleCase(pathName.pathname.split('/')[3])}
                    nestedLink2={`/web-bookings/${serviceCategoryList?.slug_url}/${pathName.pathname.split('/')[3]}/view-health-conditions`}
                    nestedText3="Edit Health Condition"
                    nestedLink3="#"
                />
                <AddNew
                    text="Health Condition"
                    registerContent="health_condition"
                    serviceSlug={pathName.pathname.split("/")[2]}
                    serviceCategorySlug={pathName.pathname.split("/")[3]}
                    healthConditionSlug={pathName.pathname.split("/")[4]}
                    edit={true}
                    sucessNavigate={`/web-bookings/${pathName.pathname.split("/")[2]}/${pathName.pathname.split("/")[3]}/view-health-conditions`}
                />
            </div>
        </>
    )
}

export default EditHealthCondition;