import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import apiCall from "../../../api";
import { convertSlugToNormalString } from "../../../constants";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../Common/backBtn";
import InputRadioField from "../../Common/inputRadioField";
import InputTextField from "../../Common/inputTextField";
import SucessModal from "../../modal/SucessModal";

const LocalityForm = ({ edit = false }) => {
	const { city_name, id } = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const cityId = queryParams.get("city_id");
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		id && getlocality()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const getlocality = async () => {
		const result = await apiCall(`locality/get/${id}`, "GET");
		if (result?.data) {
			setValue("locality", result.data.locality)
			setValue("active", result.data.active)
		}
	}

	const {
		register,
		setValue,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();


	const onSubmit = async (fields) => {
		if (edit) {
				const result = await apiCall(`locality/update/${id}`, "PATCH", fields)
				if (result.data?._id) {
					setSuccess(true);
				}
		} else {
			const result = await apiCall(`locality/create`, "POST", { ...fields, city: cityId })
			if (result.data?.data?._id) {
				setSuccess(true);
			}
		}
	};

	return (
		<>
			<BackBtn
				navigatePath={`/online-doctor-view-health-concern/${city_name}`}
			/>
			<div className="healtharticle">
				<div className="healtharticle-container">
					<div className="healtharticle-title">
						<Breadcrumbs
							breadcrumbText='Doctor'
							breadcrumbLink=''
							nestedText={"City"}
							nestedLink=''
							nestedText2={convertSlugToNormalString(city_name)}
							nestedLink2={`/doctors-localities/${city_name}?city_id=${cityId}`}
							nestedText3={`${edit ? "Edit" : "Add New "} Locality`}
							nestedLink3=''
						/>
					</div>

					<form className="career-jobpost-form" onSubmit={handleSubmit(onSubmit)}>
						<div className="addnew-form1-autor-detail mt-3">
							<div className="col-md-4">
								<InputTextField
									labelName="Name of Locality"
									name="locality"
									register={register}
									errors={errors}
									errorText={"Please provide a Name"}
									onInput={(e) => { e.target.value = e.target.value.replace(/,/g, ''); }}
								/>
							</div>
							<div className="col-md-4 mt-4">
								<InputRadioField
									register={register}
									errors={errors}
									setValue={setValue}
									watch={watch}
									labelName="Active of Health Concern"
									yesNo={true}
								/>
							</div>
						</div>
						<div className="car-dep-form-button mt-5 mb-5">
							<button type="submit" style={{ marginTop: "" }}>
								SUBMIT
							</button>
						</div>
					</form>
				</div>
			</div>
			{success && (
				<SucessModal
					page={`/doctors-localities/${city_name}?city_id=${cityId}`}
					name="Health Concern"
					post={edit ? 'updated' : 'posted'}
				/>
			)}
		</>
	);
}

export default LocalityForm