import { useEffect, useState } from "react";
import axios from "axios";
import { FaAngleDown } from "react-icons/fa";

import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import AssignedTo from "../modal/AssignedTo";
import filter1 from "../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../assets/images/searchIcon.png";
import SucessModal from "../modal/SucessModal";
import Deletepopup from "../modal/Deletepopup";

const actions = [
    "Select",
    "Delete",
    "Contacted",
    "Yet to Contact",
    "Assigned",
    "Registration Completed",
]

const InstaDocQrCore = () => {
    const [doctorsAdList, setDoctorsAdList] = useState([]);
    const [assignModal, setAssignModal] = useState({ id: "", status: false })
    const [success, setSuccess] = useState(false);
    const [pages, setPages] = useState(0);
    const [current_page, setCurrentPage] = useState(1);

    const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
    // const navigate = useNavigate()

    useEffect(() => {
        getInstaDocQrCodeList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getInstaDocQrCodeList = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}insta-doc-qr-code/insta-doc-qr-code-list`)
            .then((response) => {
                setDoctorsAdList(response?.data?.rows);
            });
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}insta-doc-qr-code/insta-doc-qr-code-delete/${id}`)
            .then(() => {
                getInstaDocQrCodeList()
                setDeleteModal({ id: "", status: false });
            });
    };

    const handleClose = () => {
        setDeleteModal({ id: "", status: false });
    };

    const successModalClose = () => {
        setSuccess(false)
        getInstaDocQrCodeList()
    }

    const handleOptionChange = (e, id) => {
        const selectedValue = e.target.value;
        const fields = {};

        fields.action = selectedValue

        axios
            .patch(
                `${process.env.REACT_APP_API_URL}insta-doc-qr-code/insta-doc-qr-code-update/${id}`,
                fields
            )
            .then((response) => {
                getInstaDocQrCodeList()
            })


        if (selectedValue === "Delete") {
            setDeleteModal({ id: id, status: true });
        }
    }

    return (
        <>
            <div className="mt-4 p-4 bg-white">
                <Breadcrumbs
                    breadcrumbText="RP QR InstaDoc"
                    breadcrumbLink="/ad-landing-forms/partnered-doctors"
                />
                <div className="healtharticle-show m-0 ps-4">
                    <div>Show</div>
                    <div>
                        <select
                            className="me-2"
                        >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option value="0">All</option>
                        </select>
                    </div>
                    <div>entries</div>
                    <div>
                        <div>
                            <img src={search} alt="" />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="sortandfilter-new">
                        <div className="sortByPositionNew">
                            <div>
                                <div>Sort by</div>
                                <div>
                                    <img src={sort1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="sortByDepartment">
                            <div>
                                <div>
                                    <div>
                                        Filter
                                    </div>
                                </div>
                                <div>
                                    <img src={filter1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    {doctorsAdList?.length ? (
                        <div className="adLandingFormsTable ms-4">
                            <table className="text-center">
                                <thead>
                                    <tr>
                                        <td className="">S.No</td>
                                        <td className="">Pharmacy Partner ID</td>
                                        <td className="">Name</td>
                                        <td className="">Age</td>
                                        <td className="">Gender</td>
                                        <td className="">Mobile No</td>
                                        <td className="">Symptoms</td>
                                        <td className="">City / Town</td>
                                        <td className="">Pincode</td>
                                        <td className="">App Download Android Click</td>
                                        <td className="">App Download iOS Click</td>
                                        <td className="">Brochure Icon Click Status</td>
                                        <td className="">Actions</td>
                                        <td className="">Assigned to</td>
                                        <td className="">Lead Action Stages</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {doctorsAdList?.map((el, i) => {
                                        return (
                                            <tr key={el._id}>
                                                <td>{i + 1}</td>
                                                <td>{el.pharmacy_partner_id}</td>
                                                <td>{el.name}</td>
                                                <td>{el.age}</td>
                                                <td>{el.gender}</td>
                                                <td>{el.mobile}</td>
                                                <td>{el.symptoms.map((data, inx) => (<span key={inx}>{inx > 0 && ', '}{data.value}</span>))}</td>
                                                <td>{el.city}</td>
                                                <td>{el.pincode}</td>
                                                <td>{el.android_click}</td>
                                                <td>{el.ios_click}</td>
                                                <td>{el.brochure_click}</td>
                                                <td>{
                                                    <select
                                                        className="bg-white"
                                                        onChange={(e) => handleOptionChange(e, el._id)}
                                                        style={{ color: "#CB1B5B" }}
                                                        value={el.action}
                                                    >
                                                        {actions.map((action, inx) => {
                                                            return <option key={inx} value={action} className="text-dark">{action}</option>
                                                        })}
                                                    </select>
                                                }</td>
                                                <td>{el.assigned_to ? el.assigned_to : "-"}</td>
                                                <td>
                                                    <span
                                                        className="pe-1"
                                                        onClick={() => setAssignModal({ id: el._id, status: true })}
                                                    >
                                                        Assigned
                                                    </span>
                                                    <span className="pe-1">
                                                        <FaAngleDown className="drop-down-arrow" />
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="mt-4"> No InstaDoc QRCode Forms are registered </div>
                    )}
                </div>
                <div className="col d-flex pagination admin-pagination justify-content-end">
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<<"
                        // onClick={(e) => setCurrentPage(1)}
                        disabled={current_page === 1}
                    />
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<"
                        // onClick={(e) => setCurrentPage(current_page - 1)}
                        disabled={current_page === 1}
                    />

                    {current_page > 1 && (
                        <input
                            type="button"
                            value={current_page - 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page - 1);
                            }}
                        />
                    )}
                    <input
                        className="page-item page-link"
                        type="button"
                        value={current_page}
                    />
                    {current_page < pages && (
                        <input
                            type="button"
                            value={current_page + 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page + 1);
                            }}
                        />
                    )}
                    <input
                        type="button"
                        value=">"
                        className="page-item page-link"
                        // onClick={(e) => setCurrentPage(current_page + 1)}
                        disabled={current_page === pages}
                    />
                    <input
                        type="button"
                        value=">>"
                        className="page-link"
                        // onClick={(e) => setCurrentPage(pages)}
                        disabled={current_page === pages}
                    />
                </div>
            </div>
            {assignModal.status === true && (
                <AssignedTo
                    id={assignModal?.id}
                    setAssignModal={setAssignModal}
                    setSuccess={setSuccess}
                    pathName="insta-doc-qr-code"
                />
            )}
            {success && (
                <SucessModal
                    name="BD"
                    post="Assigned"
                    onClick={() => successModalClose()}
                />
            )}

            {deleteModal.status === true && (
                <Deletepopup
                    handleDelete={handleDelete}
                    id={deleteModal?.id}
                    isCloseModalEnable={true}
                    handleClose={handleClose}
                />
            )}
        </>
    );
};

export default InstaDocQrCore;