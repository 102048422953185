import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import apiCall from "../../../../../api";
import BackBtn from "../../../../Common/backBtn";
import Breadcrumbs from "../../../../Common/Breadcrumbs/Breadcrumbs";
import InputRadioField from "../../../../Common/inputRadioField";
import InputTextField from "../../../../Common/inputTextField";
import SucessModal from "../../../../modal/SucessModal";

const DoctorClinicSpecialityForm = ({
  edit = false,
}) => {

  const { id } = useParams()

  const {
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);

  const getMenuData = async() => {
    const result = await apiCall(`clinic-speciality/get/${id}`, "GET");
    if(result?.data?._id){
      setValue('active', result.data.active);
      setValue('clinic_speciality', result.data.clinic_speciality);
    }
  }

  useEffect(() => {
    edit && getMenuData();
  }, [edit]);

  const onSubmit = async (fields) => {
    if (edit) {
      const result = await apiCall(`clinic-speciality/update/${id}`, "PATCH",fields )
      if (result.data?._id) {
        setSuccess(true);
      } 
    } else {
      const result = await apiCall(`clinic-speciality/create`, "POST",fields )
      if (result.data?._id) {
        setSuccess(true);
      } 
    }
  };

  return (
    <>
      <BackBtn
        navigatePath="/doctors-clinic-speciality"
      />
      <div className="healtharticle mt-5">
        <div className="pt-3 ms-3">
          <Breadcrumbs
            breadcrumbText="Doctor"
            breadcrumbLink="/"
            nestedText="Common Heads"
            nestedLink=""
            nestedText2="Clinic Speciality"
            nestedLink2=""
            nestedText3={edit ? "Edit Clinic Speciality" : "Add New Clinic Speciality"}
            nestedLink3=""
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="carr-dep-form"
        >
          <div className="row car-dep-form">
            <div className="col-5">
              <InputTextField
                register={register}
                errors={errors}
                labelName="Clinic Speciality"
                errorText="Please enter the clinic speciality"
                name="clinic_speciality"
                placeholder="Type here..."
              />
            </div>
            <div className="col-3">
              <InputRadioField
                register={register}
                errors={errors}
                setValue={setValue}
                watch={watch}
              />
            </div>
          </div>

          <div className="car-dep-form-button">
            <button type="submit" style={{ marginTop: "5%" }}>
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      {success && (
        <SucessModal
          page={"/doctors-clinic-speciality"}
          name="new clinic speciality"
          post={edit ? 'updated' : 'added'}
        />
      )}
    </>
  )
}

export default DoctorClinicSpecialityForm