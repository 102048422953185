import backArrow from "../../assets/svg/back-icon.svg";
import nextArrow from "../../assets/svg/next-icon.svg";


const NextBackBtns = ({
  back = true,
  next = true,
  nextBtnType = "button",
  handleBack = () => { },
  handleNext = () => { },
  submitBtn = false,
  submitBtnText = "Submit"

}) => {

  return (
    <div className="nextBackBtns my-5">
      {back ? <div className="addnew1-back me-3">
        <div onClick={handleBack}>
          <div>
            <img src={backArrow} alt="arrow" />
          </div>
          <div>BACK</div>
        </div>
      </div> : null}
      {next ? <div onClick={handleNext} className="nextBtnStyle me-3">
        <div>
          <button type={nextBtnType}>NEXT</button>
        </div>
        <div>
          <img src={nextArrow} alt="arrow" />
        </div>
      </div> : null}
      {submitBtn ? (<div className="addnew1-next w-auto">
        <div>
          <button
            type="submit"
            className="bg-transparent border-0 text-white"
          >
            {submitBtnText}
          </button>
        </div>
      </div>) : <></>}
    </div>
  )
}

export default NextBackBtns