import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import "./index.scss";
import SucessModal from "../../modal/SucessModal";
import InputTextField from "../../Common/inputTextField";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";

const SiteMapSettings = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState({ show: false, modalName: '' });
  const [editSuccess, setEditSuccess] = useState(false);
  const [fileName, setFileName] = useState({ siteMap: '', robots: '' });
  const [previewUrl, setPreviewUrl] = useState({
    siteMapUrl: null,
    robotsUrl: null,
    showSiteMapUrl: false,
    showRobotsUrl: false,
  });

  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}site-map-settings/list`)
      .then((res) => {
        setId(res.data.rows[0]?._id);
        setCount(res.data.count);
        setFileName({ siteMap: res?.data?.rows[0]?.siteMap, robots: res?.data?.rows[0]?.robots })
        setValue("analytics_code", res?.data?.rows[0]?.analytics_code);
        setValue("google_ads", res?.data?.rows[0]?.google_ads);
        setValue("facebook_pixel_code", res?.data?.rows[0]?.facebook_pixel_code);
        setValue("others", res?.data?.rows[0]?.others);
      });
  }, [count]);

  const handleInputChange = (event, fileNames) => {
    const file = event.target.files[0];
    if (file) {
      if (file && fileNames === 'siteMap') {
        // setPreviewUrl((val) => ({ ...val, siteMapUrl: file }))
        setFileName((val) => ({ ...val, siteMap: file.name }));
      } else if (file && fileNames === 'robots') {
        // setPreviewUrl((val) => ({ ...val, robotsUrl: file }))
        setFileName((val) => ({ ...val, robots: file.name }));
      }
      else {
        setFileName({ siteMap: '', robots: '' });
      }
    }
    // const reader = new FileReader();
    // reader.onload = () => {
    //   console.log('reader.result',reader.result);
    //   setPreviewUrl(reader.result);
    // };
    // reader.readAsDataURL(event.target.files[0]);
  };

  const onSubmit = (fields) => {

    const formData = new FormData();
    // formData.append('siteMap', fields.siteMap[0]);
    // formData.append('robots', fields.robots[0]);
    formData.set('facebook_pixel_code', fields.facebook_pixel_code);
    formData.set('others', fields.others);

    if (count === 0) {

      axios
        .post(`${process.env.REACT_APP_API_URL}site-map-settings/create`, formData)
        .then((response) => {
          if (response.data?._id) {
            setSuccess({ show: true, modalName: 'create' });
          } else {
            errors.submit_fail = response.data;
          }
        })
        .catch((err) => {
          errors.submit_fail = err.response.data.error;
        });
    }
    else {

      axios
        .put(
          `${process.env.REACT_APP_API_URL}site-map-settings/update/${id}`,
          formData
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess({ show: true, modalName: 'edit' });
          } else {
            alert("Fail");
          }
        });
    }
  };

  return (
    <>
      <div className="healtharticle mt-5">
        <div className="mt-4 pt-5 ms-5">
          <Breadcrumbs
            breadcrumbText="Sitemap"
            breadcrumbLink="/services"
            nestedText="Other Codes / Pixel"
            nestedLink="#"
          />
        </div>
        <form className="carr-dep-form ms-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <div className="d-flex mt-3">
              {/* <div className="md-6">
                <label
                  className="car-dep-form-txt mt-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Site map file
                </label>
                <div className="meta-tag-file mt-2 d-flex align-items-center">
                  <input type="file"
                    className="hidden custom-file-input bg-white w-50"
                    accept=".xml,application/xml"
                    {...register("siteMap", { defaultValue: "fileName" })}
                    onChange={(e) => handleInputChange(e, "siteMap")}
                  />
                  <label>{fileName.siteMap === '' ? "No file Choosen" : fileName.siteMap}</label>
                </div>
              </div> */}
              <div className="md-6 pb-4">
                <label
                  className="car-dep-form-txt mt-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Robots File Upload <span className="primaryColor">*</span>
                </label>
                <div className="meta-tag-file mt-2 d-flex align-items-center">
                  <input type="file"
                    className="hidden custom-file-input bg-white w-50"
                    accept=".txt,text/plain"
                    {...register("robots", { defaultValue: "fileName" })}
                    onChange={(e) => handleInputChange(e, "robots")}
                  />
                  <label>{fileName.robots === '' ? "No file Choosen" : fileName.robots}</label>
                </div>
              </div>
            </div>

            {/*--------------Site Map Settings----------*/}
            <div className="mt-3 ms-2 pb-4">
              <InputTextField
                labelName="Analytics Code"
                name="analytics_code"
                register={register}
                type="text"
                errors={errors}
                isRequired={false}
                className="mt-2 w-100"
                placeholder="Type here"
                errorText={"Please provide a Sitemap Name"}
              />
            </div>

            {/*--------------Site Map Settings----------*/}
            <div className="mt-3 ms-2 pb-4">
              <InputTextField
                labelName="Google Ads"
                name="google_ads"
                register={register}
                type="text"
                errors={errors}
                isRequired={false}
                className="mt-2 w-100"
                placeholder="Type here"
                errorText={"Please provide a Google Ads"}
              />
            </div>
            {/*--------------Facebook Pixel Code----------*/}
            <div className="mt-3 ms-2 pb-4">
              <InputTextField
                labelName="Facebook Pixel Code"
                name="facebook_pixel_code"
                register={register}
                type="text"
                errors={errors}
                isRequired={false}
                className="mt-2 w-100"
                placeholder="Type here"
                errorText={"Please provide a Facebook Pixel Code"}
              />
            </div>
            {/*--------------Others----------*/}
            <div className="mt-3 ms-2">
              <InputTextField
                labelName="Others"
                name="others"
                register={register}
                type="text"
                errors={errors}
                isRequired={false}
                className="mt-2 w-100"
                placeholder="Type here"
                errorText={"Please provide a Others"}
              />
            </div>
            <div className="car-dep-form-button mb-5 mt-5 pb-5">
              <button type="submit">SUBMIT</button>
            </div>
          </div>
        </form>
      </div>
      {success.show ?
        <SucessModal
          page={`/site-map-setting`}
          name='Sitemap'
          post={success.modalName === 'create' ? 'Created' : "Updated"}
        /> : null}
    </>
  );
};

export default SiteMapSettings;
