
const FilterModal = ({
  title = "",
  data = [],
  className = "",
  activeFilters = [],
  onChange = () => { }
}) => {
  
  return (
    <div className={`filterModal ${className}`}>
      <div className="filterModalHeader fw-bold">{title}</div>
      <div className="filterModalHr"></div>
      <div className="filterModalContainer">
        {data.map((el, i) => {
          return (
            <div className="filterModalBox d-flex" key={i}>
              <div>{el}</div>
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name={el}
                  id=""
                  checked={activeFilters.includes(el)}
                  className="cursor-pointer"
                  onChange={onChange}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default FilterModal