
import React from "react";
import addIcon from "../../assets/images/add-icon.svg";

const MultipleAddInputs = ({
  register,
  errors,
  inputValues,
  setInputValues,
  name = "",
  labelName = "",
  isRequired = true
}) => {

  const addeducation = () => {
    setInputValues([
      ...inputValues,
      {
        [`${name}${inputValues.length}`]: "",
        id: inputValues.length,
      },
    ]);
  };

  const onUpdateInputs = (evt, id) => {
    const list = [...inputValues];
    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return false;

    list[index][evt.target.name] = evt.target.value;
    setInputValues(list);
  };

  const removeInputs = (id,inx) => {
    const list = [...inputValues];
    if (list.length === 1) return false;
    const filteredInputs = list.filter((item, index) => index !== inx);
    setInputValues([...filteredInputs]);
  };

  return (
    <div className="mb-32 col-9">
      <div className="d-flex justify-content-between">
        <label>
          {labelName}
          {isRequired ? <span className="color-mandatory">*</span> : null}
        </label>{" "}
        <span
          onClick={() => addeducation()}
          className="addPlusIcon text-end text-white"
          style={{
            marginLeft: "0px",
            cursor: "pointer",
          }}
        >
          <img src={addIcon} className='mob-plus-icon' alt="Add"></img>
          Add
        </span>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
        }}
      >
        {inputValues?.map((item, inx) => (
          <div
            key={`input-experience-${item.id}`}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              columnGap: "24px",
            }}
          >
            <div
              style={{ flexGrow: "1", flexShrink: "0" }}
            >
              <input
                type="text"
                style={{ width: "100%" }}
                {...register(`${name}${item.id}`, {
                  required: false,
                })}
                placeholder="Ex:- College of medical"
                defaultValue={item.value || null}
                onInput={(evt) =>
                  onUpdateInputs(evt, item.id)
                }
                className="form-control"
              // autoFocus
              />
              {/* {errors[`experiences${item.id}`] && (
                <div
                  className={`invalid-feedback d-block`}
                >
                  Please provide a Education
                </div>
              )} */}
            </div>

            {inputValues.length > 1 ? (
              <div
                onClick={() => removeInputs(item.id, inx)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <span
                  className="plus-icon"
                  style={{
                    marginLeft: "0px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      height: "2px",
                      width: "50%",
                      backgroundColor: "#fff",
                    }}
                  ></span>
                </span>
              </div>

            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultipleAddInputs