import axios from "axios";

export const SendEmail = (id = "", data = {}) => {
  try {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}lead-status-update-email/${id}`,
        data
      );
  } catch (err) {
    console.log("Send email err - ", err);
  }
}