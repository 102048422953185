import React from "react";
import { IoCloseCircleSharp } from "react-icons/io5";

import { UpdateLeadStatus } from "../../api/leadStatus";
import { SendEmail } from "../../api/sendEmail";
import YesNoBtns from "../Common/yesNoBtns";
import "./SucessModel.scss";

const LeadStatusConfirm = ({
  setLeadStatusSuccess = () => { },
  setLeadStatusConfirmModal = () => { },
  leadStatusConfirmModal = "",
  formType = ""
}) => {

  const onClickYes = async () => {
    try {
      const id = leadStatusConfirmModal?.id
      const lead_status = leadStatusConfirmModal?.leadType
      if (formType) {
        const response = await UpdateLeadStatus(id, formType, { lead_status });
        setLeadStatusSuccess(true);
        setLeadStatusConfirmModal({ id: "", status: false, leadType: "" });
        if (response.status === 200 && response?.data?._id) {
          SendEmail(response.data._id,
            {
              lead_status: lead_status,
              email: response.data.email,
              name: response.data?.name,
              mobile: response.data?.mobile,
              formType,

            })
        }
      }
    } catch (err) {
      console.log("Update Lead status err -", err);
    }
  }

  return (
    <>
      <div className="h-auto">
        <div className="modal-backdrop fade show success-model"></div>
        <div
          style={{ display: "block" }}
          className="modal fade show modal-sm"
          id="confirmModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className=" modal-content p-4">
              <div className="d-flex justify-content-between w-100 h-auto mb-2 mt-2">
                <p className="assignTitle">Do you want change the Lead Status to {leadStatusConfirmModal?.leadType}?</p>
                <div>
                  <IoCloseCircleSharp
                    size={30}
                    color={"gray"}
                    className="cursorPointer"
                    onClick={() => setLeadStatusConfirmModal({ leadType: "", status: false, id: "" })}
                  />
                </div>
              </div>
              <YesNoBtns
                onClickYes={onClickYes}
                onClickNo={() => setLeadStatusConfirmModal({ leadType: "", status: false, id: "" })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadStatusConfirm;