import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../Common/backBtn";
import InputTextField from "../../../Common/inputTextField";
import SelectField from "../../../Common/selectField";
import TextAreaField from "../../../Common/textAreaField";
import SucessModal from "../../../modal/SucessModal";
import "./index.scss";
import apiCall from "../../../../api";

const SpecializationMetaForm = ({ edit = false }) => {

  const { id } = useParams()
  const [success, setSuccess] = useState(false);
  const [cityList, setCityList] = useState([]);

  const {
    register,
    setValue,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const listingPageType = watch("type");
  const [specializations, setSpecialization] = useState([])

  const consultationMode = [
    "Online Consultation",
    "In-Clinic Appoinment",
  ]

  useEffect(() => {
    if (specializations?.length && getValues()?.specialization) {
      setValue('specialization', getValues()?.specialization);
    }
    if (specializations?.length && getValues()?.inclinic_specialization) {
      setValue('inclinic_specialization', getValues()?.inclinic_specialization);
    }
  }, [specializations]);

  useEffect(() => {
    if (cityList?.length && getValues()?.city) {
      setValue('city', getValues()?.city);
    }
  }, [cityList]);

  const getDoctorSpecialisation = () => {
    axios.post(`${process.env.REACT_APP_API_URL}doctor-specialisation/list`, { active: true }).then(response => {
      setSpecialization(response.data?.rows?.map((data) => ({ value: data?._id, label: data.online_doctor_specialization })))
    }).catch(err => {
      console.error(err);
      alert("Some error");
    })
  }
  const getInClinicSpecialisation = () => {
    axios.post(`${process.env.REACT_APP_API_URL}inclinc-doctor-specialization/list`, { active: true }).then(response => {
      setSpecialization(response.data?.rows?.map((data) => ({ value: data?._id, label: data.inclinic_doctor_specialization })))
    }).catch(err => {
      console.error(err);
      alert("Some error");
    })
  }
  useEffect(() => {
    if (listingPageType === "Online Consultation") {
      getDoctorSpecialisation()
    } else if (listingPageType === "In-Clinic Appoinment") {
      getInClinicSpecialisation()
    }
  }, [listingPageType])

  useEffect(() => {
    edit && getMenuData();
    getCitiesList();
  }, [edit]);

  const getCitiesList = async () => {
    try {
      const result = await apiCall(`doctor-cities/city-list`, "POST", { active: true });
      if (result.data) {
        setCityList(result.data)
      }
    } catch (err) {
      console.error(err);
    }
  }


  const getMenuData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}doctor-listing/get/${id}`).then(response => {
      setValue('type', response.data.type);
      setValue('page_name', response.data.page_name);
      setValue('specialization', response.data?.specialization?._id);
      setValue('inclinic_specialization', response.data?.inclinic_specialization?._id);
      if (response.data.type === "Online Consultation") {

        // setValue('city', response.data.city?._id)
        setValue('meta_title', response?.data.meta_title);
        setValue('meta_keyword', response?.data.meta_keyword);
        setValue('meta_description', response?.data.meta_description);
        setValue('others', response?.data.others);
      }
    }).catch(err => {
      console.error(err);
      alert("Some error");
    })
  }

  const onSubmit = (fields) => {
    fields.inclinic_specialization = fields.inclinic_specialization === "" ? null : fields.inclinic_specialization
    fields.specialization = fields.specialization === "" ? null : fields.specialization
    if (edit) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}doctor-listing/update/${id}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}doctor-listing/create`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    }
  };

  return (
    <>
      <BackBtn
        navigatePath="/add-new-doctors"
      />
      <div className="healtharticle">
        <div className="healtharticle-title mt-5 ms-4">
          <Breadcrumbs
            breadcrumbText="Create Doctor Listing Page With Meta Set"
            breadcrumbLink="/add-new-doctors"
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="carr-dep-form"
        >
          <div>
            <div className="col-md-6">
              <SelectField
                id="clinicProfileState"
                labelName="Listing Page Type "
                name="type"
                options={consultationMode}
                register={register}
                errors={errors}
                errorText="Please select the Page Type"
                defaultOption="page type"
              />
            </div>
            <div className="mt-4">
              <InputTextField
                labelName="Page Name"
                name="page_name"
                register={register}
                errors={errors}
                className="w-100"
                placeholder="Enter Page Name"
                errorText={"Please provide a Page Name"}
              />
            </div>
            <div className="d-flex">
              {/* {pageType === 'Online Consultation' && ( */}
              <div className="col-md-6 mt-4">
                <SelectField
                  id="specialization"
                  labelName="Specialization"
                  name={listingPageType === "Online Consultation" ? "specialization" : "inclinic_specialization"}
                  options={specializations}
                  register={register}
                  errors={errors}
                  className="w-75"
                  errorText="Please choose a Specialization"
                  defaultOption="specialization"
                  isOptionObject={true}
                />
              </div>
              {/* {listingPageType === 'In-Clinic Appoinment' && ( */}
              {/* <> */}
              {/* <div className="col-md-6 mt-4">
                    <SelectField
                      id="city"
                      labelName="City "
                      name="city"
                      options={cityList.map((data) => ({ value: data._id, label: data?.city }))}
                      isOptionObject={true}
                      className="w-75"
                      register={register}
                      errors={errors}
                      errorText="Please choose a City"
                      defaultOption="City"
                    />
                  </div> */}
              {/* </> */}
              {/* )} */}
            </div>
            {/* One */}
            {listingPageType === "Online Consultation" ? <>
              <div className="mt-4">
                <InputTextField
                  labelName="Meta Title"
                  name="meta_title"
                  register={register}
                  errors={errors}
                  className="w-100"
                  placeholder="Enter Meta Title"
                  errorText={"Please provide a Meta Title"}
                />
              </div>
              <div className="mt-4">
                <InputTextField
                  labelName="Meta Keyword"
                  name="meta_keyword"
                  register={register}
                  errors={errors}
                  className="w-100"
                  placeholder="Enter Meta Keyword"
                  errorText={"Please provide a Meta Keyword"}
                />
              </div>
              <div className="mt-4">
                <TextAreaField
                  labelName="Meta Description "
                  name="meta_description"
                  register={register}
                  id="meta_description"
                  errors={errors}
                  errorText={"Please provide a Meta Description"}
                  placeholder="Enter Meta Description"
                />
              </div>
              <div className="mt-4">
                <TextAreaField
                  id="others"
                  labelName="Others "
                  name="others"
                  register={register}
                  errors={errors}
                  // errorText={"Please provide a Meta Description"}
                  isRequired={false}
                />
              </div>
            </> : null
            }
          </div>
          <div className="car-dep-form-button mt-5 mb-5">
            <button type="submit" style={{ marginTop: "" }}>
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      {success && (
        <SucessModal
          page={"/add-new-doctors"}
          name="New Doctor Listing Page with Meta Set"
          post={edit ? 'updated' : 'Created'}
        />
      )}
    </>
  )
};

export default SpecializationMetaForm;
