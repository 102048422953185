import React from 'react'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../../assets/images/searchIcon.png";
import buttonadd from "../../../assets/images/plus.png";
import "./index.scss"
import { useState } from 'react';
import { FaAngleRight } from "react-icons/fa";

const HHSPSpecialisation = () => {

    const [sort, setSort] = useState("newest");
    const [sortName, setSortName] = useState("Sort by");

    const [sortshow, setSortShow] = useState(false);
    const [textcolor, setTextColor] = useState(0);
    const [filtershow, setFilterShow] = useState(false);
    const [submitfilter, setSubmitFilter] = useState(false);
    const [spldata, setSplData] = useState([])
    const [statusdata, setStatusData] = useState([])
    const [isChecked, setIsChecked] = useState(true);
    
    const primaryspl = [
      {
        text: "Physiotheraphy",
      },
      {
        text: "Nursing Support Service",
      },
      {
        text: "Mother & Baby Care Support ",
      },
      {
        text: "Speech Thearopy",
      }
    ];
  
    const status=[
      {
        text:"Active"
      },
      {
        text:"Inactive"
      }
    ]

    const navigate = useNavigate();

  const handlehhspForm=()=>{
    navigate("/home-healthcare-hhsp-form")
  }

  const handleEdit=()=>{
    navigate("/home-healthcare-hhsp-edit")
  }

    const data =[
    {
      sno:"01",
      hhsp:"Physiotheraphy",
      image:"",
      total:"268",
      status:"Active"
    },
    {
      sno:"02",
      hhsp:"Nursing Support Service",
      image:"",
      total:"1669",
      status:"Active"
    },
    {
      sno:"03",
      hhsp:"Mother & Baby Care Support",
      image:"",
      total:"297",
      status:"Active"
    },
    {
      sno:"04",
      hhsp:"Speech Thearopy",
      image:"",
      total:"116",
      status:"Active"
    },
  ]

  return (
    <>
     {/* <Home> */}
        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title carrer-job-title ms-4">
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "0%" }}>Dashboard</span>
              </Link>
              <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>{`>`}</span>
              <Link
                to=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span>Home Healthcare Service Provider</span>
              </Link>
              <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>{`>`}</span>
              <Link
                to=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span>HHSP Specialisation</span>
              </Link>
            </div>

            <div className="healtharticle-addnew">
              <div
                id="addnewpost"
                onClick={handlehhspForm}
                className="addnewhealth"
                style={{ width: "290px" }}
              >
                <div>
                  {/* <BsFillPlusCircleFill /> */}
                  <img src={buttonadd} alt="" />
                </div>
                <div>
                  <button>Add New HHSP Specialisation</button>
                </div>
              </div>
            </div>

            <div className="healtharticle-show mt-5">
              <div>Show</div>
              <div>
                <select
                    // value={limit}
                    // onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    // onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
              <div className="sortandfilter-new">
                <div className="sortByPosition">
                  <div>
                    <div>
                    <div
                        onClick={()=>{
                          setSortShow(!sortshow)
                        }}
                      >
                        {sortName}
                        {/* Sort by */}
                      </div>
                      {/* <select
                        // onChange={(e) => setSort(e.target.value)}
                      >
                        <option>Sort by</option>
                        <option value="id-desc">
                          <div style={{ color: "red" }}>Oldest</div>
                        </option>
                        <option value="name-asc">
                          <div>Newest</div>
                        </option>
                        <option value="id-asc">
                          <div>Alphabetical ↑</div>
                        </option>
                        <option value="id-asc">
                          <div>Alphabetical ↓</div>
                          <span>
                            <img src="" alt="" />
                          </span>
                        </option>
                      </select> */}
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="sortByDepartment">
                  <div>
                    <div>
                    <div
                        onClick={()=>{
                        setFilterShow(true)
                        }}
                    >Filter</div>
                      {/* <select
                        name=""
                        id=""
                        // onChange={handleSort}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="healtharticle-table home-healthcare-hhsp">
              <table>
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td>HHSP Specialisation</td>
                    <td>Image / Icon</td>
                    <td>Total No of Service Provider</td>
                    <td>Status</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((x, i)=>{
                      return(
                        <tr>
                          <td>{x.sno}</td>
                          <td>{x.hhsp}</td>
                          <td>{x.image}</td>
                          <td>{x.total}</td>
                          <td>{x.status}</td>
                          <td>
                          <span 
                              className='doctor-edit'
                              onClick={()=>{
                                handleEdit()
                              }}
                            >Edit</span>
                            <span className='doctor-edit-logo'></span>
                            <span className='doctor-delete'>Delete</span>
                            <span className='doctor-delete-logo'></span>
                            <span 
                              className='doctor-view'
                              onClick={()=>{
                                navigate("/home-healthcare-hhsp-view")
                              }}
                            >View</span>
                            <span className='doctor-view-logo'></span>
                            <span 
                              className='doctor-activate'
                              onClick={()=>{
                                if(x.status === "Active"){
                                  navigate("/hhsp-activate")
                                }
                                if(x.status === "Track"){
                                  navigate("/doctor-track")
                                }
                              }}
                            >{x.status}</span>
                            <span className='doctor-activate-logo'></span>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      {/* </Home> */}
      {sortshow ? (
        <div className="sortby-Common-career-main sortby-hhsp">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}

       {/*---------Filter Portion--------*/}
       {
        filtershow
        ?
        <div className="filter-container-career">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(1)
              }}
            >
              <div style={{color:textcolor==1?"#CB1B5B":"", fontWeight:textcolor==1?"bold":""}}>HHSP Specialisation</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(2)
              }}
            >
              <div style={{color:textcolor==2?"#CB1B5B":"", fontWeight:textcolor==2?"bold":""}}>Status</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button onClick={()=>{
                setFilterShow(false)
              }}>Clear</button>
            </div>
            <div>
              <button 
                onClick={()=>{
                  setFilterShow(false)
                  setSubmitFilter(true)
                }}
              >Apply</button>
            </div>
          </div>
        </div>
        : null
      }

       {/*-------HHSP Specialisation---------*/}
       {
        textcolor === 1 && filtershow === true
        ? 
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">HHSP Specialisation</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              primaryspl.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                    <input 
                      type="checkbox" 
                      className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setSplData([...spldata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }

      {/*-------Status---------*/}
      {
        textcolor === 2 && filtershow === true
        ? 
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Type of Job</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              status.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                    <input 
                      type="checkbox" 
                      className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setStatusData([...statusdata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }
    </>
  )
}

export default HHSPSpecialisation