import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";

import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import ViewContent from "../Common/ViewContent";
import backRed from "../../assets/svg/back-rose-icon.svg";

const ViewService = () => {
    const [serviceList, setServiceList] = useState([])
    const pathName = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        list_api_call()
    }, [])

    const list_api_call = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}services/list`)
            .then((response) => {
                setServiceList(response?.data);
            });
    }


    const servicesBreadcrumbs = useMemo(() => {
        return serviceList?.filter(
            (data) => data?.slug_url === pathName.pathname.split("/")[2]
        ).map((val) => val)
    }, [serviceList])

    return (
        <>
            <div
                onClick={() => navigate(-1)}
                className="rose back-button"
            >
                <img src={backRed} alt="" />
                <span className="rose-back">Back</span>
            </div>
            <div className="mt-5">
                <Breadcrumbs
                    breadcrumbText="Web Bookings, Consultations & Appointments"
                    breadcrumbLink="/web-bookings/home-healthcare-bookings"
                    nestedText={servicesBreadcrumbs[0]?.name}
                    nestedLink={`/web-bookings/${servicesBreadcrumbs[0]?.slug_url}`}
                    nestedText2="View Service"
                    nestedLink2="#"
                />
                <ViewContent
                    service={true}
                    serviceList={serviceList}
                    btnText="Add New Service"
                    btnNavigation={`/web-bookings/${pathName.pathname.split('/')[2]}/add-new-service`}
                />
            </div>
        </>
    )
}

export default ViewService;