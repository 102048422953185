import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import "./DepartmentForm.scss";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import SucessModal from "../../../modal/SucessModal";

const DepartmentEdit = () => {
  const { id } = useParams();
  const [fields, setFields] = useState({});
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const handleUpdateData = () => {
    let item = {
      department: fields.department,
      active: fields.active,
    };
    fetch(
      `${process.env.REACT_APP_API_URL}career-add-department/update/${id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      }
    ).then((result) => {
      result.json().then((resp) => {
        setSuccess(true);
      });
    });
  };

  const getDepartmentData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}career-add-department/get/${id}`)
      .then((response) => {
        setFields(response.data);
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  useEffect(() => {
    getDepartmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/careerdepartment")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle ps-2 mt-5">
          <div className="healtharticle-title ms-0">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ fontSize: "15px", paddingLeft: "1%" }}>&#62;</span>
            <Link
              to="/careers"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
                paddingLeft: "0.2%",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Careers</span>
            </Link>
            <span style={{ paddingLeft: "0.8%", fontSize: "15px" }}>&#62;</span>
            <span style={{ paddingLeft: "0.4%", fontSize: "15px" }}>
              Edit Department / Function
            </span>
          </div>
          <div className="row car-dep-form">
            <div className="col-5">
              <label className="text-muted small mb-2" htmlFor="">
                Type Department / Function name{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="department"
                value={fields.department}
                onChange={(e) =>
                  setFields((prevState) => {
                    return { ...prevState, department: e.target.value };
                  })
                }
                placeholder="Type here..."
              />
            </div>
            <div className="col-3">
              <label className="text-muted small mb-2" htmlFor="">
                Status <span className="text-danger">*</span>
              </label>
              <div className="d-flex">
                <div className="form-check form-check-inline me-3">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="status"
                    onChange={(e) => {
                      setFields((prevState) => {
                        return { ...prevState, active: true };
                      });
                    }}
                    value={true}
                    checked={fields.active === true ? "checked" : null}
                  />
                  <label htmlFor="option-1" className="text-dark">
                    Active
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="status"
                    value={false}
                    onChange={(e) => {
                      setFields((prevState) => {
                        return { ...prevState, active: false };
                      });
                    }}
                    checked={fields.active === false ? "checked" : null}
                  />
                  <label htmlFor="option-2" className="text-dark">
                    Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="car-dep-form-button">
            <button onClick={handleUpdateData} style={{ marginTop: "5%" }}>
              UPDATE
            </button>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal 
          page={"/careerdepartment"} 
          name="Department" 
          post='updated'
        />
      )}
    </>
  );
};
export default DepartmentEdit;
