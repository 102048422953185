import { useNavigate } from "react-router"

const NavBarTitles = ({
    titles = [],
    className
}) => {
    const navigate = useNavigate()

    return (
        <div className="careers-tab" style={{ marginLeft: "2%" }}>
            {titles.map((value, inx) => (
                <div
                    key={inx}
                    className={`${value?.className ? value.className : ""} career-tab-jobpost`}
                    onClick={() => navigate(value?.navigatePath)}
                >{value?.name}
                </div>
            ))}
        </div>
    )
}

export default NavBarTitles