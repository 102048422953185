
import { AiFillCloseSquare } from "react-icons/ai";
import { RiEditBoxFill } from "react-icons/ri";
import NextBackBtns from "./nextBackBtns";

const CustomFieldsComp = ({
  register,
  errors,
  customFields,
  setCustomFields,
  setLevel,
  level,
  customFieldsLength,
  setCustomFieldEditModel
}) => {

  const handleInputChange = (index, value) => {
    const updatedFields = [...customFields];
    updatedFields[index].fieldInput = value;
    setCustomFields(updatedFields);
  };

  const updateInputField = (index) => {
    setCustomFieldEditModel({ status: true, index: ((level - 2) * 30) + index })
  }

  const removeCustomField = (index) => {
    if (customFieldsLength % 30 === 1) {
      setLevel(level - 1)
    }
    setCustomFields(customFields.filter((ele, inx) => (((level - 2) * 30) + index) !== inx))
  }

  return (
    <>
      <div className="row">
        {customFields.slice((level - 2) * 30, (level - 2) * 30 + 30).map((field, index) => {
          return (
            <div key={index} className="col-6mb-4 col-12 col-md-6 ">
              <div className="form-label">{field.fieldName}</div>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  value={field.fieldInput}
                  disabled
                  className="form-control w-75 fw-bold bg-white"
                  {...register(`customFields[${index}].fieldInput`)}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
                <RiEditBoxFill onClick={() => updateInputField(index)} height={50} width={50} className="formEditIcon" />

                <AiFillCloseSquare height={50} width={50} onClick={() => removeCustomField(index)} className="formCloseIcon" />
                {/* 
                <div onClick={() => removeCustomField(index)} className="bg-danger mx-4 rounded">
                  <img src={closeImage} width="30px" height="30px" className="mx-2 px-1 mt-2 cursorPointer"></img>
                </div> */}
              </div>
              {errors.customFields?.[index]?.fieldInput && (
                <p>{errors.customFields[index].fieldInput.message}</p>
              )}
            </div>
          )
        })}
      </div>
      {customFieldsLength > ((level - 1) * 30) ? (
        <NextBackBtns
          handleBack={() => setLevel(level - 1)}
          handleNext={() => setLevel(level + 1)}
        />) : (<NextBackBtns
          next={false}
          handleBack={() => setLevel(level - 1)}
          submitBtn={true}
          submitBtnText={"Update"}
        />)}
    </>
  )
}

export default CustomFieldsComp