import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Moment from "moment";
import axios from "axios";
import { Link } from "@chakra-ui/react";

import Deletepopup from "../components/modal/Deletepopup";
import newdelete from "../../src/assets/images/HealthSy/delete.png";

const ContactUs = () => {
  const [contactFormList, setContactFormList] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const { id } = useParams();

  const getContactFormList = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}contact-us/contactUsList`)
      .then((response) => {
        setContactFormList(response?.data);
      });
  }

  useEffect(() => {
    getContactFormList()
  }, [id]);

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}contact-us/delete/${id}`)
      .then(() => {
        getContactFormList()
        setDeleteModal({ id: "", status: false });
      });
  };

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  return (
    <>
      <div className="mt-4 faqContainer p-4">
        <div className="mt-5 faqContainer">
          <div className="faq-title ms-0">
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/dashboard"
              >
                Dashboard
              </Link>
            </span>
            <span>&#62;</span>
            <span>
              Contact Form
            </span>
          </div>
        </div>
        <div className="mt-4">
          {contactFormList?.length ? (
            <div className="healtharticle-table ms-4">
              <table style={{ width: "100% " }}>
                <thead>
                  <tr>
                    <td className="">S.No</td>
                    <td className="" style={{ width: "7%" }}>Name</td>
                    <td className="career-jobpost-td6">Mobile No</td>
                    <td className="">Email</td>
                    <td className="">City / Town</td>
                    <td className="career-jobpost-td6">Message</td>
                    <td className="career-jobpost-td6">Upload Time</td>
                    <td className="career-jobpost-td6">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {contactFormList.map((el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{i + 1}</td>
                        <td>{el.name}</td>
                        <td>{el.mobile}</td>
                        <td>{el.email}</td>
                        <td>{el.location}</td>
                        <td>{el.message}</td>
                        <td>
                          {" "}
                          {Moment(el.createdAt).format("DD MMMM YYYY LT")}
                        </td>
                        <td>
                          <span
                            className="newdelete"
                            onClick={() => {
                              setDeleteModal({ id: el._id, status: true });
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="mt-4">No Contact us details</div>
          )}
        </div>
      </div>
      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default ContactUs;
