import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from "react-router-dom";

import search from "../../../../../assets/images/searchIcon.png";
import { convertSlugToNormalString } from '../../../../../constants';
import Breadcrumbs from '../../../../Common/Breadcrumbs/Breadcrumbs';
import AddButton from '../../../../Common/addButton';
import FilterOptions from '../../../../Common/filterOptions';
import Pagination from '../../../../Common/pagination';
import Deletepopup from '../../../../modal/Deletepopup';

const InclinicViewHealthConcern = () => {

    const navigate = useNavigate();
    const { id: inclinicSpecialization
    } = useParams();
    const [del, setDel] = useState(false)
    const [id, setId] = useState("")

    const handleDoctorSpecialisationForm = () => {
        navigate(`/inclinic-add-health-concern/${inclinicSpecialization}`)
    }

    const handleEdit = (id) => {
        navigate(`/inclinic-edit-health-concern/${inclinicSpecialization}/${id}`)
    }


    const handleDelete = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}inclinic-health-concern/delete/${id}`,
            {
                method: "DELETE",
            }
        ).then((result) => {
            result.json().then((res) => {
                list_api_call();
                setDel(false)
            });
        });
    };

    const handleActive = (id, status) => {
        axios
            .patch(
                `${process.env.REACT_APP_API_URL}inclinic-health-concern/update/active/${id}`,
                {
                    active: !status,
                }
            )
            .then((result) => {
                list_api_call();
            });
    };

    const [filtered_records, setFilteredRecords] = useState([]);
    const [rows_count, setRowCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [current_page, setCurrentPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState("newest");
    const [sortName, setSortName] = useState("Sort by");
    const [filter, setFilter] = useState({});
    const [records, setRecords] = useState([]);

    const [sortshow, setSortShow] = useState(false);
    const [textcolor, setTextColor] = useState(0);
    const [filtershow, setFilterShow] = useState(false);
    const [submitfilter, setSubmitFilter] = useState(false);
    const [statusdata, setStatusData] = useState([]);
    const [isChecked, setIsChecked] = useState(true);

    const status = [
        {
            text: "Active",
        },
        {
            text: "Inactive",
        },
    ];

    useEffect(() => {
        list_api_call();
    }, [filter, limit, sort, skip]);

    useEffect(() => {
        if (current_page > 1) {
            setSkip((current_page - 1) * limit);
        } else {
            setSkip(0);
        }
    }, [current_page]);

    const list_api_call = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}inclinic-health-concern/list`, {
                ...filter,
                skip: skip,
                limit: limit,
                sort: sort,
                inclinic_doctor_specialization_slug: inclinicSpecialization
            })
            .then((response) => {
                setFilteredRecords([]);
                setRecords(response.data.rows);
               // setRowCount(response.data.count);
                setPages(Math.ceil(response.data.count / limit));
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    };

    const filter_records = (filter) => {
        let rows = records.filter((x) => {
            return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
        setFilteredRecords(rows);
    };

    return (
        <>
            <div className="healtharticle">
                <div className="healtharticle-container">
                    <div className="healtharticle-title carrer-job-title ms-4">
                        <Breadcrumbs
                            breadcrumbText='Doctor'
                            breadcrumbLink=''
                            nestedText='In-clinic Specialization'
                            nestedLink='/inclinic-specialization'
                            nestedText2={convertSlugToNormalString(inclinicSpecialization)}
                            nestedLink2=''
                            nestedText3='View Health Concern'
                            nestedLink3=''
                        />
                    </div>

                    <div className="healtharticle-addnew">
                        <AddButton
                            onClick={handleDoctorSpecialisationForm}
                            btnName="Add New Health Concern"
                        />
                    </div>
                    <FilterOptions
                        limit={limit}
                        sortshow={sortshow}
                        sortName={sortName}
                        search={search}
                        setFilterShow={setFilterShow}
                        setSortShow={setSortShow}
                        filter_records={filter_records}
                        setLimit={setLimit}
                    />
                    <div className="healtharticle-table home-healthcare-hhsp">
                        <table>
                            <thead>
                                <tr>
                                    <td className="">S.No</td>
                                    <td className="">Health Concern</td>
                                    <td className="">Image / Icon</td>
                                    <td className="">Status</td>
                                    <td className="">Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filtered_records.length > 0
                                    ? filtered_records : records).map((x, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{x.health_concern}</td>
                                                <td>{x.image ? x.image : "-"}</td>
                                                <td>{x.active === true ? "Verified" : "Not Verified"}</td>
                                                <td>
                                                    <span
                                                        className="inactive-health"
                                                        onClick={() => {
                                                            handleActive(x._id, x.active);
                                                        }}
                                                    >
                                                        {x.active ? "Inactive" : "Active"}
                                                    </span>
                                                    <span className="master-edit ps-3" onClick={() => { handleEdit(x._id) }}>Edit</span>
                                                    <span className="master-edit-logo m-0"></span>
                                                    <span className="master-delete p-3" onClick={() => {
                                                        setId(x._id)
                                                        setDel(true);
                                                    }}>Delete</span>
                                                    <span className="master-delete-icon"></span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                setCurrentPage={setCurrentPage}
                current_page={current_page}
                pages={pages}
            />
            {
                del === true
                &&
                <Deletepopup
                    handleDelete={handleDelete}
                    id={id}
                />
            }

            {sortshow ? (
                <div className="sortby-Common-career-main sortby-master-common">
                    <div
                        onClick={() => {
                            setTextColor(11);
                            setSortShow(false);
                            setSort("oldest");
                            setSortName("Oldest");
                        }}
                        style={{
                            color: textcolor === 11 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 11 ? "bold" : "",
                        }}
                    >
                        Oldest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(12);
                            setSortShow(false);
                            setSort("newest");
                            setSortName("Newest");
                        }}
                        style={{
                            color: textcolor === 12 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 12 ? "bold" : "",
                        }}
                    >
                        Newest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(13);
                            setSortShow(false);
                            setSort("ascending");
                            setSortName("Ascending");
                        }}
                        style={{
                            color: textcolor === 13 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 13 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↑
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(14);
                            setSortShow(false);
                            setSort("descending");
                            setSortName("Descending");
                        }}
                        style={{
                            color: textcolor === 14 ? "#CB1B5B" : "",
                            fontWeight: textcolor === 14 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↓
                    </div>
                </div>
            ) : null}
        </>
    )
}


export default InclinicViewHealthConcern