import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import axios from "axios";
import one from "../../../assets/images/dashboard/one.png";
import two from "../../../assets/images/dashboard/two.png";
import three from "../../../assets/images/dashboard/three.png";
import four from "../../../assets/images/dashboard/four.png";
import five from "../../../assets/images/dashboard/five.png";
import six from "../../../assets/images/dashboard/six.png";
import doctor from "../../../assets/images/dashboard/lady-doctor.png";

const Dashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}career-add-department/dashboard`)
      .then((res) => {
        setData(res.data);
      });
  }, []);

  const overAllArr = [
    {
      image: one,
      text: "InstaDoc Registrations",
      count: data.instaDoc,
      bgColor: "#f3f3ff",
      countColor: "#030344",
    },
    {
      image: two,
      text: "Total Pharmacy Registrations",
      count: data.pharmacy,
      bgColor: "#eefcef",
      countColor: "#003904",
    },
    {
      image: three,
      text: "Total Doctor Registrations",
      count: data.doctor,
      bgColor: "#ffefe8",
      countColor: "#431400",
    },
    {
      image: four,
      text: "Total HHSP Registrations",
      count: data.healthCare,
      bgColor: "#e5f5fa",
      countColor: "#022D3A",
    },
    {
      image: five,
      text: "Total Other Registrations",
      count: data.other,
      bgColor: "#fff4e0",
      countColor: "#5A3A00",
    },
    {
      image: six,
      text: "Total Careers Registrations",
      count: data.career,
      bgColor: "#f3ecfe",
      countColor: "#1D004B",
    },

  ]

  const adLandingArr = [
    {
      image: doctor,
      text: "Total Doctor Registrations",
      count: data.doctorsAd,
      bgColor: "#f3f3ff",
      countColor: "#030344",
    },

  ]

  return (
    <>
      <div className="home-dashboard">
        <div className="home-dashboard-box">
          <div className="home-dashboard-title">Dashboard</div>
          <div className="home-dashboard-overall">Overall</div>
          <div className="home-dashboard-container">
            {overAllArr.map((el, inx) => {
              return (
                <div style={{ background: el.bgColor }} key={inx}>
                  <div>
                    <img src={el.image} alt="" />
                  </div>
                  <div>{el.text}</div>
                  <div style={{ color: el.countColor }}>{el.count}</div>
                </div>
              )
            })}
          </div>
          <div className="home-dashboard-overall">Ad Landing Forms</div>
          <div className="home-dashboard-container">
            {adLandingArr.map((el, inx) => {
              return (
                <div style={{ background: el.bgColor }} key={inx}>
                  <div>
                    <img src={el.image} alt="" />
                  </div>
                  <div>{el.text}</div>
                  <div style={{ color: el.countColor }}>{el.count}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
