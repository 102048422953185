
const InputRadioDynamic = ({
	register = () => { },
	errors = {},
	watch,
	setValue = () => { },
	name = "",
	label = "",
	errorMessage = "",
	options,
	isOptionObject = false,
	trigger = () => { }
}) => {
	const activeValue = watch(name);
	return (
		<>
			<label
				className="car-dep-form-txt mb-2"
				style={{ color: "#4B4853" }}
				htmlFor=""
			>
				{label} <span className="star">*</span>
			</label>
			<div className="d-flex">
				{options.map((value, inx) => {
					const isChecked = isOptionObject ? (activeValue === value.id ): (activeValue ===  value.name);

					return (
						<div className="form-check d-flex align-items-center form-check-inline me-3 mt-1" key={inx} >
							<input
								className="form-check-input me-3"
								type="radio"
								name={name}
								{...register(name, { required: true })}
								value={isOptionObject? value.id : value.name}
								checked={isChecked}
								onChange={() => {
									setValue(name, isOptionObject ? value.id : value.name);
									trigger()
								}}
							/>
							<label htmlFor="option-1" className="text-dark ">
								{value?.name}
							</label>
						</div>
					)
				})}
			</div>
			{errors[name] && (
				<div className={`invalid-feedback d-block`}>
					{errorMessage}
				</div>
			)}
		</>
	)
}

export default InputRadioDynamic;