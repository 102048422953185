
const InputRadioField = ({
    register = () => { },
    errors = {},
    checked,
    watch,
    setValue = () => { },
    viewOnly = false,
    labelName = "Active ",
    yesNo = false
}) => {
    const activeValue = viewOnly ? "" : watch("active");
    return (
        <>
            <label
                className="form-label title mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
            >
                {viewOnly ? "Active" : labelName}<span className="star">*</span>
            </label>
            <div className="d-flex">
                <div className="form-check form-check-inline me-3 mt-1">
                    {viewOnly ? (
                        <input
                            className="form-check-input me-3"
                            type="radio"
                            name="active"
                            id="active"
                            value={true}
                            checked={checked ? "checked" : ""}
                            disabled
                        />) : (
                        <input
                            className="form-check-input me-3"
                            type="radio"
                            name="active"
                            id="active"
                            {...register("active", { required: true })}
                            value={true}
                            checked={activeValue === true || activeValue === "true"}
                            onChange={() => setValue("active", true)}
                        />)}
                    <label htmlFor="option-1" className="text-dark ">
                        {(viewOnly || yesNo) ? "Yes" : "Active"}
                    </label>
                </div>
                <div className="form-check form-check-inline mt-1">
                    {viewOnly ? (<input
                        className="form-check-input me-3"
                        type="radio"
                        name="active"
                        id="active"
                        value={false}
                        checked={checked ? "" : "checked"}
                        disabled
                    />) : (<input
                        className="form-check-input  me-3"
                        type="radio"
                        name="active"
                        id="active"
                        {...register("active", { required: true })}
                        value={false}
                        checked={activeValue === false || activeValue === "false"}
                        onChange={() => setValue("active", false)}
                    />)}
                    <label htmlFor="option-2" className="text-dark">
                        {(viewOnly || yesNo) ? "No" : "Inactive"}
                    </label>
                </div>
            </div>
            {errors.active && (activeValue === null || activeValue === undefined) && (
                <div className={`invalid-feedback d-block`}>
                    Please select an Option
                </div>
            )}
        </>
    )
}

export default InputRadioField;