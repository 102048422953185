
const TextAreaField = ({
	labelName = "",
	name = "",
	register,
	id = "",
	errors,
	errorText = "",
	placeholder = "",
	isRequired = true,
	className = ""
}) => {
	return (
		<>
			<label for="inputAddress" className="form-label title">
				{labelName}{isRequired ? <span className="star">*</span> : null}
			</label>
			<textarea
				type="text"
				{...register(name, { required: isRequired })}
				className="form-control w-100"
				style={{ height: "130px", paddingLeft: "10px" }}
				placeholder={placeholder}
				id={id}
			/>
			{errors.meta_description && (
				<div className={`invalid-feedback d-block`}>
					{errorText}
				</div>
			)}
		</>
	)
}

export default TextAreaField;