import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form"

import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs"
import SucessModal from "../../modal/SucessModal";

const ClinicMetaTags = () => {

    const [success, setSuccess] = useState("")

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (fields) => {
        console.log('fields', fields)
        axios
            .post(
                `${process.env.REACT_APP_API_URL}doctors-meta-tags/create`,
                fields
            )
            .then((response) => {
                if (response.data?._id) {
                    setSuccess(true);
                } else {
                    alert("Fail");
                    console.log("failed...");
                }
            });
    };

    return (
        <>
            <div className="mt-5 faqContainer p-4">
                <Breadcrumbs
                    breadcrumbText="Clinic Profile"
                    breadcrumbLink=""
                    nestedText="Add Meta Tags"
                    nestedLink=""
                />
                <form onClick={handleSubmit(onSubmit)}>
                    {/* One */}
                    <div className="mt-4">
                        <label for="inputAddress" className="form-label title">
                            Meta Title <span className="star">*</span>
                        </label>
                        <input
                            type="text"
                            {...register("meta_title", { required: true })}
                            className="form-control addnew-input w-100"
                            placeholder="Enter meta title"
                            id="meta_title"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.meta_title && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Meta Title
                            </div>
                        )}
                    </div>
                    {/* Two */}
                    <div className="mt-4">
                        <label for="inputAddress" className="form-label title">
                            Meta Keyword <span className="star">*</span>
                        </label>
                        <input
                            type="text"
                            {...register("meta_keyword", { required: true })}
                            className="form-control addnew-input w-100"
                            placeholder="Enter meta keyword"
                            id="meta_keyword"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.meta_keyword && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Meta Keyword
                            </div>
                        )}
                    </div>
                    {/* Three */}
                    <div className="mt-4">
                        <label for="inputAddress" className="form-label title">
                            Meta Description <span className="star">*</span>
                        </label>
                        <input
                            type="text"
                            {...register("meta_description", { required: true })}
                            className="form-control addnew-input w-100"
                            placeholder="Enter meta description"
                            id="meta_description"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.meta_description && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Meta Description
                            </div>
                        )}
                    </div>
                    {/* Four */}
                    <div className="mt-4">
                        <label for="inputAddress" className="form-label title">
                            Others <span className="star">*</span>
                        </label>
                        <input
                            type="text"
                            {...register("others", { required: true })}
                            className="form-control addnew-input w-100"
                            placeholder="Enter meta description"
                            id="others"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.others && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a others
                            </div>
                        )}
                    </div>
                    <div className="car-dep-form-button m-0">
                        <button type="submit" style={{ marginTop: "5%" }}>
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {success && (
                <SucessModal
                    page={"/doctor-clinic-profile"}
                    name="Clinic Profile"
                    post='posted'
                />
            )}
        </>
    )
}

export default ClinicMetaTags