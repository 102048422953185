import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import Moment from "moment";
import axios from "axios";

import InsidersBanner from "./InsidersBanner"
import newdelete from "../../assets/images/HealthSy/delete.png";
import Deletepopup from "../modal/Deletepopup";
import onboard from "../../assets/images/HealthSy/onboard.png";

const Insiders = () => {
  const [insiders, setInsiders] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });

  const { id } = useParams();
  const navigate = useNavigate();

  const getDataList = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}insiders/insidersList`)
      .then((response) => {
        setInsiders(response?.data);
      });
  };

  useEffect(() => {
    getDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}insiders/delete/${id}`)
      .then(() => {
        getDataList();
        setDeleteModal({ id: "", status: false });
      });
  };

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  // const updateApiCall = (data) => {
  //   axios.patch(
  //     `${process.env.REACT_APP_API_URL}insiders/update/${data?._id}`,
  //     { ...data, activeAppStore: !data?.activeAppStore }
  //   );
  //   getDataList();
  // };

  const getsrcValue = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "insider-images/" + value;
  };

  return (
    <>
      <div className="mt-4 appDownloadLinkContainer p-4">
        <InsidersBanner />
        <div className="mt-4">
        {insiders?.length ? (
          <div className="InsidersTable ms-4">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td className="">S.No</td>
                  <td className="">Name of Insiders</td>
                  <td className="">
                    Designation
                  </td>
                  <td className="">Linked in profile (link)</td>
                  <td className="">Profile Image</td>
                  <td className="">Upload Time</td>
                  <td className="">Actions</td>
                </tr>
              </thead>
              <tbody>
                {insiders.map((el, i) => {
                  return (
                    <tr key={el._id}>
                      <td>{i + 1}</td>
                      <td>{el.name}</td>
                      <td>{el.designation}</td>
                      <td>{el.linkedin}</td>
                      <td>
                        <img
                          src={getsrcValue(el.insiderImage)}
                          style={{
                            width: "100px",
                            height: "100px",
                            marginTop: "2%",
                            marginBottom: "5%",
                          }}
                          alt="image showing"
                        />
                      </td>
                      <td>
                        {" "}
                        {Moment(el.createdAt).format("DD MMMM YYYY LT")}
                      </td>
                      <td>
                      <span
                            className={`contact `}
                            onClick={() => {
                              navigate(
                                `/insiders/${el._id}/edit-insider`
                              );
                            }}
                          >
                            Edit
                          </span>
                          <span className="onboard-logo">
                            <img src={onboard} alt="" />
                          </span>
                        <span
                          className="newdelete"
                          onClick={() => {
                            setDeleteModal({ id: el._id, status: true });
                          }}
                        >
                          Delete
                        </span>
                        <span className="newdelete-logo">
                          <img src={newdelete} alt="" />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          ) : (
            <div className="mt-4">Employees details not available. </div>
          )}
        </div>
      </div>
      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default Insiders;
