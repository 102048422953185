import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import buttonadd from "../../assets/images/plus.png";

const CTABanner = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-5 faqContainer">
      <div className="faq-title ms-0">
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/dashboard"
          >
            Dashboard
          </Link>
        </span>
        <span>&#62;</span>
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/settings-social-media-form"
          >
            Setings
          </Link>
        </span>
        <span>&#62;</span>
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/insiders"
          >
            Insiders Image
          </Link>
        </span>
      </div>
      <div className="faq-addnew ms-2">
        <div className="faq-addnew ms-2">
          <div className="addNewfaq">
            <div>
              <img src={buttonadd} alt="" />
            </div>
            <div>
              <button onClick={() => navigate("/insiders/add-insider")}> Add New Insiders</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTABanner;
