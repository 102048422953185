import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const Logout = () => {
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/login");
    }
  }, [logout]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setLogout(true);
    navigate('/login')
    window.location.reload();
  };

  return (
    <div className="logout-modal">
      <div
        className="modal fade"
        id="logoutModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog "
          style={{
            marginLeft: "40rem",
            marginTop: "20%",
          }}
        >
          <div
            style={{ width: "68%", padding: "1.7rem" }}
            className="modal-content logout-content"
          >
            <div className="modal-body ">
              <p className="ps-4">Are you sure want to log out?</p>
              <div className="text-center mt-4">
                <button
                  className="btn me-4"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    border: "1px solid red",
                    color: "red",
                    width: "72px",
                    height: "50px",
                  }}
                >
                  No
                </button>
                <button
                  className="btn"
                  style={{
                    background: "#1AAA55 0% 0% no-repeat padding-box",
                    color: "white",
                    width: "72px",
                    height: "50px",
                  }}
                  onClick={handleLogout}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
