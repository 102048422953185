import addIcon from "../../assets/images/add-icon.svg";

const MultipleAddAwards = ({
  register,
  errors,
  Awards,
  setAwards,

}) => {

  const addAwards = () => {
    setAwards([
      ...Awards,
      {
        [`awards${Awards.length}`]: "",
        id: Awards.length,
      },
    ]);
  };

  const onUpdateAwards = (evt, id) => {
    const list = [...Awards];
    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return false;

    list[index][evt.target.name] = evt.target.value;
    setAwards(list);
  };

  const removeAwards = (id) => {
    const list = [...Awards];
    if (list.length === 1) return false;

    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return false;

    list.splice(index, 1);
    setAwards(list);
  };

  return (
    <div className="mb-32 col-9">
      <div className="d-flex justify-content-between">
        <label>
          Awards and Recognitions{" "}
        </label>{" "}
        <span
          onClick={() => addAwards()}
          className="addPlusIcon text-end text-white"
          style={{
            marginLeft: "0px",
            cursor: "pointer",
          }}
        >
          <img src={addIcon} className='mob-plus-icon' alt="Add"></img>
          Add
        </span>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
        }}
      >
        {Awards?.map((item, idx) => (
          <div
            key={`input-awards-${item.id}`}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              columnGap: "24px",
            }}
          >
            <div
              style={{ flexGrow: "1", flexShrink: "0" }}
            >
              <input
                type="text"
                style={{ width: "100%" }}
                {...register(`awards${item.id}`, {
                  required: false,
                })}
                placeholder="Add Awards & Recognition"
                defaultValue={item.value || null}
                onInput={(evt) =>
                  onUpdateAwards(evt, item.id)
                }
                className="form-control"
              // autoFocus
              />
              {/* {errors[`awards${item.id}`] && (
                <div
                  className={`invalid-feedback d-block`}
                >
                  Please provide a Awards & Recognitions
                </div>
              )} */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              {Awards.length > 1 ? (
                <span
                  onClick={() => removeAwards(item.id)}
                  className="plus-icon"
                  style={{
                    marginLeft: "0px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      height: "2px",
                      width: "50%",
                      backgroundColor: "#fff",
                    }}
                  ></span>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultipleAddAwards