import React, { useEffect } from "react";
import Home from "../../../Home/Home";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router";
import add from "../../../../assets/images/buttonAdd.png";
import "./menu.scss";
import axios from "axios";
import edit from "../../../../assets/svg/edit.svg";
import inactive from "../../../../assets/svg/inactive.svg";
import { Link } from "react-router-dom";
import Deletepopup from "../../../modal/Deletepopup";
import search from "../../../../assets/images/searchIcon.png"

export default function Menu() {
  const [filtered_records, setFilteredRecords] = useState([]);
  const [rows_count, setRowCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("id-desc");
  const [filter, setFilter] = useState({ name: "" });
  const [records, setRecords] = useState([]);
  const [del, setDel] = useState(false)
  const [id, setId] = useState("")

  const handleActive = (id, status) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}health-article-menu/update/${id}`,
        {
          active: !status,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  useEffect(() => {
    list_api_call();
  }, [limit, sort, skip]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
  }, [current_page]);

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}health-article-menu/list`, {
        ...filter,
        skip: skip,
        limit: limit,
        sort: sort,
      })
      .then((response) => {
        console.log(response.data.count, "career data...");
        localStorage.setItem("CareerTotal", response.data.count);
        setRecords(response.data.rows);
        setRowCount(response.data.count);
        setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows);
  };

  const handleMenu = () => {
    navigate("/addNewMenuForm");
  };

  const handleCategory = () => {
    navigate("/addNewCategoryForm");
  };

  const handleClick = () => {
    navigate("/Category");
  };

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}health-article-menu/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call()
        setDel(false)
      });
    });
  };

  const handleEdit = (id) => {
    navigate(`/addNewMenuEditForm/${id}`);
  };

  const handleMenuView = (id) => {
    navigate(`/viewMenuForm/${id}`);
  };

  const handleComment = () => {
    navigate("/comment")
  }

  return (
    <div>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container ps-3">
          <div className="healtharticle-title ms-2">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span>Dashboard</span>
            </Link>
            <span>{`>`}</span>
            <span>Manage Menu and Category</span>
          </div>
          <div className="add-btn mt-3 ms-2">
            <button onClick={handleMenu} className=" new-menu-btn me-3">
              <img className="add-img" src={add} alt="" /> Add New Menu
            </button>
            <button onClick={handleCategory} className="new-category-btn">
              <img className="add-img" src={add} alt="" />
              Add New Category
            </button>
          </div>
          {/* <div className='menu-tab mt-5'>
          
          <small  className='menuTab text-muted '>Menu</small>
         
      
          <small onClick={handleClick}  className='ms-5  text-muted' >Category</small>

      
          </div> */}
          <div className="careers-tab mt-5 ms-2">
            <div className="career-tab-career menuTab">Menu</div>
            <div
              className="career-tab-jobpost text-muted"
              onClick={handleClick}
            >
              Category
            </div>
            {/* <div className="" onClick={handleComment}>Comments</div> */}
          </div>

          <div className="healtharticle-show menu-show ms-2">
            <div>Show</div>
            <div className="value">
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                {/* <FaSearch /> */}
                <img src={search} alt="" />
              </div>
              <div className="search">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="healtharticle-table menu-table table-responsive mt-3 ms-2">
            <table className="table table-bordered ">
              <thead>
                <tr>
                  <th className="t-head">S.No</th>
                  <th className="t-head">Menu</th>
                  <th className="t-head total-category">Total Category</th>
                  <th className="t-head status">Status</th>
                  <th className="t-head">Actions</th>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0
                  ? filtered_records
                  : records
                ).map((el, i) => {
                  console.log(el.active, "status");
                  if (el.show === undefined || el.show === true)
                    return (
                      <tr key={el._id}>
                        <td>{(current_page - 1) * limit + (i + 1)}</td>
                        <td>{el.name}</td>
                        <td></td>
                        <td>{el.active ? "Active" : "Inactive"}</td>
                        <td>
                          <span
                            className="inactive-health"
                            onClick={() => {
                              handleActive(el._id, el.active);
                            }}
                          >
                            {el.active ? "Inactive" : "Active"}
                          </span>
                          <span className="inactive-icon">
                            <img src={inactive} alt="" />
                          </span>

                          <span
                            onClick={() => {
                              // handleDelete(el._id)
                              setId(el._id)
                              setDel(true);
                            }}
                            className="delete"
                            type="submit"
                          >
                            Delete
                          </span>
                          <span className="delete-icon"></span>

                          <span className="edit-health" onClick={() => handleEdit(el._id)}>Edit</span>
                          <span className="edit-icon">
                            <img src={edit} alt="" />
                          </span>

                          <span
                            className="view"
                            onClick={() => handleMenuView(el._id)}
                          >
                            View
                          </span>
                          <span className="view-icon"></span>
                        </td>
                      </tr>
                    );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col d-flex pagination justify-content-end mt-5 admin-pagination">
        <input
          type="button"
          className="page-item page-link"
          value="<<"
          // onClick={(e) => setCurrentPage(1)}
          disabled={current_page === 1}
        />
        <input
          type="button"
          className="page-item page-link"
          value="<"
          // onClick={(e) => setCurrentPage(current_page - 1)}
          disabled={current_page === 1}
        />

        {current_page > 1 && (
          <input
            type="button"
            value={current_page - 1}
            onClick={(e) => {
              // setCurrentPage(current_page - 1);
            }}
          />
        )}
        <input
          className="page-item page-link"
          type="button"
          value={current_page}
        />
        {current_page < pages && (
          <input
            type="button"
            value={current_page + 1}
            onClick={(e) => {
              // setCurrentPage(current_page + 1);
            }}
          />
        )}
        <input
          type="button"
          value=">"
          className="page-item page-link"
          // onClick={(e) => setCurrentPage(current_page + 1)}
          disabled={current_page === pages}
        />
        <input
          type="button"
          value=">>"
          className="page-link"
          // onClick={(e) => setCurrentPage(pages)}
          disabled={current_page === pages}
        />
      </div>
      {/* </Home> */}
      {
        del === true
        &&
        <Deletepopup
          handleDelete={handleDelete}
          id={id}
        />
      }
    </div>
  );
}
