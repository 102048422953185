import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import Autocomplete from "react-google-autocomplete";
import { degree, startedYear, states } from "../../../constants";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import LanguageField from "../../Common/LanguageField";
import AddButton from "../../Common/addButton";
import CustomFieldsComp from "../../Common/customFields";
import CustomFieldsLevel from "../../Common/customFieldsLevel";
import NextBackBtns from "../../Common/nextBackBtns";
import DoctorFormCustomField from "../../modal/DoctorFormCustomField";
import SucessModal from "../../modal/SucessModal";

const InstaDocRegistrationEdit = () => {

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        control,
        trigger,
        getValues,
        formState: { errors },
    } = useForm();

    const { id } = useParams();
    const [gender, setGender] = useState([]);
    const [city, setCity] = useState("");
    const [success, setSuccess] = useState(false);
    const [language, setLanguage] = useState([]);
    const [selectedLang, setSelectedLang] = useState([]);
    const [customFields, setCustomFields] = useState([])
    const [level, setLevel] = useState(1);
    const [customFieldModel, setCustomFieldModel] = useState(false)
    const [customFieldEditModel, setCustomFieldEditModel] = useState({ status: false, index: null })

    useEffect(() => {
        getGender();
        getInstaDocRegistrationForm();
        getLangauges();
    }, []);

    const getLangauges = () => {
        axios.post(`${process.env.REACT_APP_API_URL}languages/list`).then((res) => {
            setLanguage(res.data.rows);
        });
    };

    const getGender = () => {
        axios.post(`${process.env.REACT_APP_API_URL}gender/list`).then((res) => {
            setGender(res.data.rows);
        });
    };

    const getInstaDocRegistrationForm = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}insta-doc-registration/get/${id}`)
            .then((response) => {
                setValue("name", response?.data?.name);
                setValue("mobile", response?.data?.mobile);
                setValue("email", response?.data?.email);
                setValue("gender", response?.data?.gender);
                setValue("degree", response?.data?.degree);
                setValue("other_course", response?.data?.other_course);
                setValue("location", response?.data?.location);
                setValue("state", response?.data?.state);
                setValue("city", response?.data?.city);
                setCity(response?.data?.city)
                setValue("address", response?.data?.address);
                setValue("pincode", response?.data?.pincode);
                setValue("specialization", response?.data?.specialization);
                setValue("registered_year", response?.data?.registered_year);
                setValue("mci", response?.data?.mci);
                setValue("receive_online_consultations", response?.data?.receive_online_consultations);
                setSelectedLang(response?.data?.languages_known?.map((data) => ({ value: data })))
                if (response?.data?.customFields?.length) {
                    setCustomFields(response.data.customFields)
                }
                trigger()
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    };

    const onSubmit = (fields) => {
        fields.city = city;
        fields.languages_known = fields.languages_known?.length && fields.languages_known?.map((data) => data?.value)
        if (customFields) {
            fields.customFields = customFields;
        }
        if (!city) {
            setValue("city", "");
            trigger()
        } else {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}insta-doc-registration/update/${id}`, fields)
                .then((response) => {
                    setSuccess(true);
                    reset()
                });
        }
    }

    return (
        <>
            <div className="healtharticle p pb-4 my-5">
                <div className="healtharticle instaDocEditFormWrapper pb-4">
                    <div className="healtharticle-container">
                        <div className="px-3 d-flex justify-content-between">
                            <Breadcrumbs
                                breadcrumbText="HealthSynergy"
                                breadcrumbLink="/doctor-registration"
                                nestedText2="InstaDoc"
                                nestedLink2="/insta-doc"
                                nestedText3="Edit"
                                nestedLink="#"
                                className="w-auto"
                            />
                            <div className="doctorAddNewField">
                                <AddButton
                                    onClick={() => setCustomFieldModel(true)}
                                    btnClassName="fw-bold w-100"
                                    btnName="Add New Field"
                                />
                            </div>
                        </div>
                        {customFields?.length ? <CustomFieldsLevel
                            page={level}
                            customFieldLength={customFields?.length}
                        /> : <></>}
                        <form className="addEditInsiders" onSubmit={handleSubmit(onSubmit)}>
                            {(level === 1 || !customFields?.length) ? <div className="doctor-page1">
                                <div className="addnew-form1-autor-detail mt-3 d-flex">
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Name of the Doctor <span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("name", { required: true })}
                                            className="form-control addnew-input"
                                            // id="authordetails"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.name && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Name
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Email ID<span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("email", { required: true })}
                                            className="form-control addnew-input"
                                            // id="authordetails"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.email && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Email
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Contact Number <span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("mobile", { required: true })}
                                            className="form-control addnew-input"
                                            // id="mobile"
                                            // placeholder="Type here"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.mobile && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Mobile Number
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Degree{" "}
                                            <span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("degree", { required: true })}
                                            className="form-control addnew-input"
                                            defaultValue={""}
                                        >
                                            <option value="" disabled pri-specialization="true">
                                                Select degree
                                            </option>
                                            {degree.map((data, idx) => {
                                                return (
                                                    <option key={idx}>
                                                        {data}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        {errors.degree && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a degree
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Other Certified Education
                                            <span className="star"> * </span>
                                        </label>
                                        <input
                                            className="form-control addnew-input"
                                            type="text"
                                            placeholder="Type here"
                                            {...register("other_course")}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Gender <span className="star">*</span>
                                        </label>{" "}
                                        <br />
                                        <div className="d-flex">
                                            {gender.map((el, index) => {
                                                if (el.active === true) {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="form-check form-check-inline d-flex align-items-center"
                                                        >
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                {...register("gender", { required: true })}
                                                                value={el.type}
                                                            />
                                                            <label className="ps-2">
                                                                {el.type}
                                                            </label>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                        {errors.gender && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please select an option
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label className="form-label">
                                            Specialisation<span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("specialization", { required: true })}
                                            className="form-control addnew-input"
                                            defaultValue={""}
                                        >
                                            <option value="General Physician">General Physician</option>
                                        </select>
                                        {errors.specialization && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Specialisation
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">
                                            Year of Registration with Medical Council <span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("registered_year", { required: true })}
                                            className="form-control addnew-input"
                                            defaultValue={""}>
                                            <option>Select the Year</option>
                                            {startedYear.map((option, inx) => {
                                                return (
                                                    <option key={inx} value={option}>{option}</option>
                                                )
                                            })}
                                        </select>
                                        {errors.registered_year && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Year of Registration with Medical Council
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">
                                            MCI / SMC Number
                                            <span className="star">*</span>
                                        </label>
                                        <input
                                            className="form-control addnew-input"
                                            type="text"
                                            {...register("mci", { required: true })}
                                        />
                                        {errors.mci && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a MCI
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label className="form-label">
                                            Are you okay to receive online consultations from 9 PM - 9 AM ( Night - Day )<span className="star">*</span>
                                        </label>{" "}
                                        <br />
                                        <div className="form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                {...register("receive_online_consultations", {
                                                    required: true,
                                                })}
                                                value="Yes"
                                            />
                                            <label className="form-check-label ms-2">Yes</label>
                                        </div>
                                        <div className="form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                {...register("receive_online_consultations", {
                                                    required: true,
                                                })}
                                                value="No"
                                            />
                                            <label className="form-check-label ms-2">No</label>
                                        </div>
                                        {errors.receive_online_consultations && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please select an option
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            State<span className="star">*</span>
                                        </label>
                                        <select
                                            {...register("state", { required: true })}
                                            className="form-control addnew-input"
                                            defaultValue={""}
                                        >
                                            <option value="" disabled>
                                                Select your State
                                            </option>
                                            {states.map((el, inx) => {
                                                return <option value={el} key={inx}>{el}</option>;
                                            })}
                                        </select>
                                        {errors.state && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a State
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            City{" "}
                                            <span className="star">*</span>
                                        </label>
                                        <Controller
                                            control={control}
                                            name="city"
                                            rules={{ required: "City is required" }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                                                    value={field.value}
                                                    onChange={(event) => {
                                                        field.onChange(event.target.value)
                                                        if (city) {
                                                            setCity("")
                                                        }
                                                    }
                                                    }
                                                    onPlaceSelected={(place) => {
                                                        field.onChange(place ? place.formatted_address : "")
                                                        setCity(place.formatted_address)
                                                    }}
                                                    types={["address"]}
                                                    className={`form-control  addnew-input`}
                                                    componentrestrictions={{ country: "us" }}
                                                    preventdefault="true"
                                                />
                                            )}
                                        />
                                        {errors.city && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a City
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="addnew-form1-autor-detail mt-5 d-flex">
                                    <div className="col-md-4">
                                        <label for="inputAddress" className="form-label title">
                                            Location <span className="star">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register("location", { required: true })}
                                            className="form-control addnew-input"
                                            // id="authordetails"
                                            style={{ paddingLeft: "10px" }}
                                        />
                                        {errors.location && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Location
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label title">
                                            Pincode <span className="star">*</span>
                                        </label>
                                        <input
                                            className="form-control addnew-input"
                                            type="text"
                                            {...register("pincode", {
                                                required: true,
                                                min: 110000,
                                                pattern: /[0-9]{6}/,
                                            })}
                                            placeholder="Enter pincode"
                                            maxLength="6"
                                            onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.pincode && (
                                            <div className={`invalid-feedback d-block`}>
                                                Please provide a Pincode
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <LanguageField
                                            errors={errors}
                                            language={language}
                                            register={register}
                                            setValue={setValue}
                                            trigger={trigger}
                                            getValues={getValues}
                                            multiSelectClassName={"form-control addnew-input"}
                                            selectedLang={selectedLang}
                                        />
                                    </div>
                                </div>
                                <NextBackBtns
                                    back={false}
                                    next={customFields?.length ? true : false}
                                    handleNext={() => setLevel(2)}
                                    submitBtn={true}
                                    submitBtnText={"Update"}
                                />

                            </div> :
                                <CustomFieldsComp
                                    customFields={customFields}
                                    setCustomFields={setCustomFields}
                                    setCustomFieldEditModel={setCustomFieldEditModel}
                                    register={register}
                                    customFieldsLength={customFields?.length}
                                    errors={errors}
                                    setLevel={setLevel}
                                    level={level}
                                />
                            }
                        </form>
                    </div>
                </div>
            </div>
            {success && (
                <SucessModal
                    page={"/insta-doc"}
                    name="InstaDoc Registration"
                    post="updated"
                />
            )}
            {customFieldModel && (
                <DoctorFormCustomField
                    setCustomFieldModel={setCustomFieldModel}
                    setCustomFields={setCustomFields}
                    setLevel={setLevel}
                    level={level}
                    customFieldsLength={customFields?.length}
                />
            )}
            {customFieldEditModel?.status && (
                <DoctorFormCustomField
                    setCustomFieldModel={setCustomFieldModel}
                    setCustomFieldEditModel={setCustomFieldEditModel}
                    setCustomFields={setCustomFields}
                    setLevel={setLevel}
                    customFieldIndex={customFieldEditModel?.index}
                    customFieldValue={customFields[customFieldEditModel?.index]}
                    edit={true}
                    level={level}
                    customFieldsLength={customFields?.length}
                />
            )}
        </>
    )
}

export default InstaDocRegistrationEdit;