import React from 'react'
import "./careers.scss"
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../../assets/images/searchIcon.png"
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { FaAngleRight } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";

//Action Icons
import contact from "../../../assets/images/HealthSy/contact.png";
import notcontact from "../../../assets/images/HealthSy/notcontacted.png";
import Autocomplete from "react-google-autocomplete";

const fileDotExts = {
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
  "application/pdf": ".pdf",
  "unknown": ".pdf"
}

const Careers = () => {

  const [filtered_records, setFilteredRecords] = useState([]);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [filter, setFilter] = useState({ name: "" });
  const [records, setRecords] = useState([]);

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [submitfilter, setSubmitFilter] = useState(false);
  const [departmentdata, setDepartmentData] = useState([])
  const [designationdata, setDesignationData] = useState([])
  const [typeofjobdata, setTypeofJobData] = useState([])
  const [statusdata, setStatusData] = useState([])
  const [genderdata, setGenderdata] = useState([])
  const [location, setLocation] = useState('')

  const navigate = useNavigate()

  const department = [
    {
      text: "Engineering"
    },
    {
      text: "Sales"
    },
    {
      text: "Content"
    }
  ]

  const designation = [
    {
      text: "Web Developer"
    },
    {
      text: "Content Market"
    },
    {
      text: "Web Designer"
    }
  ]

  const typeofjob = [
    {
      text: "Full Time"
    },
    {
      text: "Part Time"
    },
    {
      text: "Freelancer"
    }
  ]

  const status = [
    {
      text: "Active"
    },
    {
      text: "Inactive"
    }
  ]

  const gender = [
    {
      text: "Male"
    },
    {
      text: "Female"
    },
    {
      text: "Others"
    },
  ]

  const base64ToBlob = async ({ uploadResume: base64String, name, location }) => {

    if (base64String.includes('data:application/')) {
      const link = document.createElement("a");
      const ext = fileDotExts[base64String.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1] ?? "unknown"]
      link.href = base64String;
      link.download = name + ' ' + location + ext;
      link.click();
    }
    else {

      axios({
        url: `${process.env.REACT_APP_API_URL}career/download/${base64String}`,
        method: 'GET',
        responseType: 'blob',
      }).
        then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', response?.config?.url?.split('-')?.slice(1).join("_"));
          document.body.appendChild(link);
          link.click();
        });
    }
  };

  const handleJobPost = () => {
    navigate("/careerjobpost")
  }

  const handleDepartment = () => {
    navigate("/careerdepartment")
  }

  const handlePosition = () => {
    navigate("/careerposition")
  }

  useEffect(() => {
    list_api_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, limit, sort, skip]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page]);

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}career/list`, {
        // ...filter,
        // skip: skip,
        // limit: limit,
        // sort: sort,
        // key: "job_post",
        // value: sort,
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data.rows);
        // setRowCount(response.data.count);
        // setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows);
  };

  //Contact Functionality
  const handleContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}career/update/${id}`,
        {
          contact: true,
          reject: false,
          select: false,
          interview: false,
          notContact: false
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //Not Contact Functionality
  const handleNotContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}career/update/${id}`,
        {
          contact: false,
          reject: false,
          select: false,
          interview: false,
          notContact: true
        }
      )
      .then((result) => {
        list_api_call();
      });
  }

  //Reject Functionality
  const handleReject = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}career/update/${id}`,
        {
          contact: false,
          reject: true,
          select: false,
          interview: false,
          notContact: false
        }
      )
      .then((result) => {
        list_api_call();
      });
  }

  //Select Functionality
  const handleSelect = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}career/update/${id}`,
        {
          contact: false,
          reject: false,
          select: true,
          interview: false,
          notContact: false
        }
      )
      .then((result) => {
        list_api_call();
      });
  }

  //Interview Functionality
  const handleInterview = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}career/update/${id}`,
        {
          contact: false,
          reject: false,
          select: false,
          interview: true,
          notContact: false
        }
      )
      .then((result) => {
        list_api_call();
      });
  }

  return (
    <>
      {/* <Home> */}
      <div className='healtharticle'>
        <div className="healtharticle-container">
          <div className='healtharticle-title health-carrer-title'><Link to="/dashboard" style={{ textDecoration: "none", color: "black", fontSize: "15px" }}><span style={{ paddingLeft: "5%" }}>Dashboard</span></Link><span style={{ paddingLeft: "1%" }}>&gt;</span><span style={{ fontSize: "15px" }}>Careers</span></div>
          <div className='careers-tab careers-car-tab' style={{ marginLeft: "2%" }}>
            <div className='career-tab-career career-tab'>Careers</div>
            <div className='career-tab-jobpost' onClick={handleJobPost}>Job Post</div>
            <div className='career-post-department' onClick={handleDepartment}>Department/Function</div>
            <div className='career-post-position' onClick={handlePosition}>Position / Designation</div>
          </div>
          <div className='healtharticle-show'>
            <div>Show</div>
            <div>
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                {/* <FaSearch /> */}
                <img src={search} alt="" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>

            <div className="sortandfilter-new">
              <div className="sortByPosition">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setSortShow(!sortshow);
                      }}
                    >
                      {sortName}
                    </div>
                    {/* <select 
                          onChange={(e) => setSort(e.target.value)}
                        >
                          <option>Sort by</option>
                          <option value="id-desc"><div style={{color:"red"}}>Oldest</div></option>
                          <option value="name-asc"><div>Newest</div></option>
                          <option value="id-asc"><div>Alphabetical ↑</div></option>
                          <option value="id-asc"><div>Alphabetical ↓</div><span><img src="" alt="" /></span></option>
                        </select> */}
                  </div>
                  <div>
                    <img src={sort1} alt="" />
                  </div>
                </div>
              </div>
              <div className="sortByDepartment">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setFilterShow(true);
                      }}
                    >
                      Filter
                    </div>
                    {/* <select
                        name=""
                        id=""
                        // onChange={handleSort}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                  </div>
                  <div>
                    <img src={filter1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='career-main-table'>
            <table className='ms-3'>
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Name of the Candidate</td>
                  <td>Mobile Number</td>
                  <td>Email ID</td>
                  <td>Age</td>
                  <td>Gender</td>
                  <td>Job Position</td>
                  <td>Educational Qualification</td>
                  <td>City / Town</td>
                  <td>Hyperlinks</td>
                  <td>Resume CV</td>
                  <td>Across Healthsy</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0
                  ? filtered_records
                  : records
                ).map((el, i) => {
                  //-----hyperlink mapping---------
                  if (!el?.hyperLink?.length || (el?.hyperLink?.length && el?.hyperLink[0]?.id)) {
                    var res = Object.values(el.hyperLink).map(obj => Object.values(obj)[0]);
                  } else {
                    var hype = Object.values(el.hyperLink).map(i => Object.values(i))
                    var hyperlink;
                    Object.keys(hype).forEach((x) => {
                      hyperlink = hype[x]
                    })
                    var res = Object.values(hyperlink).map(i => Object.values(i)[0])
                  }
                  //-----hyperlink mapping---------

                  if (el.show === undefined || el.show === true)
                    return (
                      <tr key={el._id}>
                        <td>{(current_page - 1) * limit + (i + 1)}</td>
                        <td>{el.name}</td>
                        <td>{el.mobile}</td>
                        <td>{el.email}</td>
                        <td>{el.age}</td>
                        <td>{el.gender}</td>
                        <td>{el.jobPosition}</td>
                        <td>{el.educationQualification}</td>
                        <td>{el.location}</td>
                        <td>{res.map((ele) => { return <><a style={{ textDecoration: "none", fontSize: "13px" }} href={ele}>{ele}</a><br /></> })}</td>
                        <td>
                          <button className='downloadBtn' onClick={() => { base64ToBlob(el) }}>
                            <div className='d-flex align-items-center'>
                              Download
                              <FiDownload className='ml-2' />
                            </div></button>
                        </td>
                        <td>{el.acrossHealthsy ? el.acrossHealthsy : "-"}</td>
                        <td>
                          <span
                            className={`career-contact ${el.contact === true ? "career-contact-bold" : ""}`}
                            onClick={() => {
                              handleContact(el._id)
                            }}
                          >Contact</span>
                          <span className='career-contact-icon'><img src={contact} alt="" /></span>
                          <span
                            className={`career-reject ${el.reject === true ? "career-reject-bold" : ""}`}
                            onClick={() => {
                              handleReject(el._id)
                            }}
                          >Reject</span>
                          <span className='career-reject-icon'><img src="" alt="" /></span>
                          <span
                            className={`career-select ${el.select === true ? "career-select-bold" : ""}`}
                            onClick={() => {
                              handleSelect(el._id)
                            }}
                          >Select</span>
                          <span className='career-selct-icon'><img src="" alt="" /></span>
                          <span
                            className={`career-interview ${el.interview === true ? "career-interview-bold" : ""}`}
                            onClick={() => {
                              handleInterview(el._id)
                            }}
                          >Interview</span>
                          <span className='career-interview-icon'><img src="" alt="" /></span>
                          <span
                            className={`career-not-contact ${el.notContact === true ? "career-not-contact-bold" : ""}`}
                            onClick={() => {
                              handleNotContact(el._id)
                            }}
                          >Not Contacted</span>
                          <span className='career-not-contact-icon'><img src={notcontact} alt="" /></span>
                        </td>
                      </tr>
                    );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col d-flex pagination admin-pagination justify-content-end my-5 ">
        <input
          type="button"
          className="page-item page-link"
          value="<<"
          // onClick={(e) => setCurrentPage(1)}
          disabled={current_page === 1}
        />
        <input
          type="button"
          className="page-item page-link"
          value="<"
          // onClick={(e) => setCurrentPage(current_page - 1)}
          disabled={current_page === 1}
        />

        {current_page > 1 && (
          <input
            type="button"
            value={current_page - 1}
            onClick={(e) => {
              // setCurrentPage(current_page - 1);
            }}
          />
        )}
        <input
          className="page-item page-link"
          type="button"
          value={current_page}
        />
        {current_page < pages && (
          <input
            type="button"
            value={current_page + 1}
            onClick={(e) => {
              // setCurrentPage(current_page + 1);
            }}
          />
        )}
        <input
          type="button"
          value=">"
          className="page-item page-link"
          // onClick={(e) => setCurrentPage(current_page + 1)}
          disabled={current_page === pages}
        />
        <input
          type="button"
          value=">>"
          className="page-link"
          // onClick={(e) => setCurrentPage(pages)}
          disabled={current_page === pages}
        />
      </div>
      {/* </Home> */}


      {/*---------Filter Portion--------*/}
      {
        filtershow
          ?
          <div className="filter-container-career">
            <div className="filter-header">Filter</div>
            <div className="filter-header-hr"></div>
            <div className="filter-content-doctor">
              <div
                className="filter-content-card-doctor"
                onClick={() => {
                  setTextColor(1)
                }}
              >
                <div style={{ color: textcolor === 1 ? "#CB1B5B" : "", fontWeight: textcolor === 1 ? "bold" : "" }}>Department</div>
                <div><FaAngleRight className="rightarrow" /></div>
              </div>
              <div
                className="filter-content-card-doctor"
                onClick={() => {
                  setTextColor(2)
                }}
              >
                <div style={{ color: textcolor === 2 ? "#CB1B5B" : "", fontWeight: textcolor === 2 ? "bold" : "" }}>Gender</div>
                <div><FaAngleRight className="rightarrow" /></div>
              </div>
              <div
                className="filter-content-card-doctor"
                onClick={() => {
                  setTextColor(3)
                }}
              >
                <div style={{ color: textcolor === 3 ? "#CB1B5B" : "", fontWeight: textcolor === 3 ? "bold" : "" }}>Location</div>
                <div><FaAngleRight className="rightarrow" /></div>
              </div>
              <div
                className="filter-content-card-doctor"
                onClick={() => {
                  setTextColor(4)
                }}
              >
                <div style={{ color: textcolor === 4 ? "#CB1B5B" : "", fontWeight: textcolor === 4 ? "bold" : "" }}>Status</div>
                <div><FaAngleRight className="rightarrow" /></div>
              </div>
              <div
                className="filter-content-card-doctor"
                onClick={() => {
                  setTextColor(5)
                }}
              >
                <div style={{ color: textcolor === 5 ? "#CB1B5B" : "", fontWeight: textcolor === 5 ? "bold" : "" }}>Type</div>
                <div><FaAngleRight className="rightarrow" /></div>
              </div>
              <div
                className="filter-content-card-doctor"
                onClick={() => {
                  setTextColor(6)
                }}
              >
                <div style={{ color: textcolor === 6 ? "#CB1B5B" : "", fontWeight: textcolor === 6 ? "bold" : "" }}>Designation</div>
                <div><FaAngleRight className="rightarrow" /></div>
              </div>
            </div>
            <div className="filter-foot-btn">
              <div>
                <button onClick={() => {
                  setFilterShow(false)
                }}>Clear</button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setFilterShow(false)
                    setSubmitFilter(true)
                  }}
                >Apply</button>
              </div>
            </div>
          </div>
          : null
      }

      {/*-------Department---------*/}
      {
        textcolor === 1 && filtershow === true
          ?
          <div className="filter-doctor-fee-career">
            <div className="filter-doctor-fee-header">Department</div>
            <div className="filter-doctor-fee-hr"></div>
            <div className="filter-doctor-fee-container">
              {
                department.map((el, i) => {
                  return (
                    <div className='filter-doctor-fee-box'>
                      <div>{el.text}</div>
                      <div>
                        <input
                          type="radio"
                          name={el.text}
                          id=""
                          onChange={(e) => {
                            setDepartmentData([...departmentdata, e.target.name])
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          : null
      }

      {/*-------Gender---------*/}
      {
        textcolor === 2 && filtershow === true
          ?
          <div className="filter-doctor-fee-career">
            <div className="filter-doctor-fee-header">Department</div>
            <div className="filter-doctor-fee-hr"></div>
            <div className="filter-doctor-fee-container">
              {
                gender.map((el, i) => {
                  return (
                    <div className='filter-doctor-fee-box'>
                      <div>{el.text}</div>
                      <div>
                        <input
                          type="radio"
                          name={el.text}
                          id=""
                          onChange={(e) => {
                            setGenderdata([...genderdata, e.target.name])
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          : null
      }

      {/*-------Location--------*/}
      {
        textcolor === 3 && filtershow === true
          ?
          <div className="filter-doctor-fee-career">
            <div className="filter-doctor-fee-header">Location</div>
            <div className="filter-doctor-fee-hr"></div>
            <div className="filter-doctor-fee-container">
              <div className="filter-doctor-search">
                <div>
                  <div><img src={search} alt="" /></div>
                  <div>
                    {/* <input 
                    type="text" 
                    name="" 
                    id="filter-search" 
                    placeholder="Search"
                    onChange={(e)=>setLocation(e.target.value)}
                  /> */}
                    <Autocomplete
                      apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                      onPlaceSelected={(place) => {
                        setLocation(place.formatted_address);
                      }}
                      types={["address"]}
                      className="form-control newlocation"
                      defaultValue={location}
                      placeholder=""
                      componentRestrictions={{ country: "us" }}
                      preventDefault
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
      }

      {/*-------Status---------*/}
      {
        textcolor === 4 && filtershow === true
          ?
          <div className="filter-doctor-fee-career">
            <div className="filter-doctor-fee-header">Status</div>
            <div className="filter-doctor-fee-hr"></div>
            <div className="filter-doctor-fee-container">
              {
                status.map((el, i) => {
                  return (
                    <div className='filter-doctor-fee-box'>
                      <div>{el.text}</div>
                      <div>
                        <input
                          type="radio"
                          name={el.text}
                          id=""
                          onChange={(e) => {
                            setStatusData([...statusdata, e.target.name])
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          : null
      }

      {/*-------Type---------*/}
      {
        textcolor === 5 && filtershow === true
          ?
          <div className="filter-doctor-fee-career">
            <div className="filter-doctor-fee-header">Status</div>
            <div className="filter-doctor-fee-hr"></div>
            <div className="filter-doctor-fee-container">
              {
                typeofjob.map((el, i) => {
                  return (
                    <div className='filter-doctor-fee-box'>
                      <div>{el.text}</div>
                      <div>
                        <input
                          type="radio"
                          name={el.text}
                          id=""
                          onChange={(e) => {
                            setTypeofJobData([...typeofjobdata, e.target.name])
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          : null
      }

      {/*-------Designation---------*/}
      {
        textcolor === 6 && filtershow === true
          ?
          <div className="filter-doctor-fee-career">
            <div className="filter-doctor-fee-header">Status</div>
            <div className="filter-doctor-fee-hr"></div>
            <div className="filter-doctor-fee-container">
              {
                designation.map((el, i) => {
                  return (
                    <div className='filter-doctor-fee-box'>
                      <div>{el.text}</div>
                      <div>
                        <input
                          type="radio"
                          name={el.text}
                          id=""
                          onChange={(e) => {
                            setDepartmentData([...designationdata, e.target.name])
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          : null
      }


      {sortshow ? (
        <div className="sortby-Common-career-main">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Careers