import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import buttonadd from "../../assets/images/plus.png";
import axios from "axios";
import "./faq.scss";

const Faq = () => {
  const navigate = useNavigate();
  const pathName = useLocation()
  const [faqSections, setFaqsections] = useState([])

  useEffect(() => {
    getFaqSections()
  }, [])

  const faqBredcrumbs = ()=>{
    return faqSections?.filter((data)=> data?.slugurl === pathName.pathname.split('/')[2]).map((val)=>val.name)
  }

  const getFaqSections = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}faq-section/list`)
      .then((response) => {
        if (response?.data?.rows?.length) {
          navigate(`/faq/${response?.data?.rows[0]?.slugurl}`)
          setFaqsections(response?.data?.rows)
        }
        else {
          navigate('/faq/add-faq-section')
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const handleOrder = (pathName) => {
    navigate(`/faq/${pathName}`);

  };

  const handleNewFaq = () => {
    navigate("/faq/add-faq-section");
  };
  return (
    <div className="mt-5 faqContainer">
      <div className="faq-title ms-0">
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/dashboard"
          >
            Dashboard
          </Link>
        </span>
        <span>&#62;</span>
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/faq"
          >
            FAQ's
          </Link>
        </span>
        <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
        <span style={{ paddingLeft: "0.4%" }}>{faqBredcrumbs()}</span>
      </div>
      <div className="faq-addnew ms-2">
        <div className="addNewfaq">
          <div>
            <img src={buttonadd} alt="" />
          </div>
          <div>
            <button onClick={() => navigate(`/faq/${pathName.pathname.split('/')[2]}/add-faq`)}> Add New FAQ's</button>
          </div>
        </div>
        <div
          //  onClick={handleMenu}
          onClick={handleNewFaq}
          className="addNewFaqSection"
          style={{ width: "250px" }}
        >
          <div className="d-flex align-items-center ms-3">
            <div>
              <img src={buttonadd} alt="" />
            </div>
            <button>Add New FAQ Section</button>
          </div>
        </div>
      </div>
      <div className="faq-tab">
        {
          faqSections && faqSections?.map((data, idx) => {
            return (
                <div key={idx} className={`${pathName.pathname.split('/')[2] === data?.slugurl ? 'rose-line' : ''} ${idx !== 0 ? 'ms-5' : ''}`} onClick={() => handleOrder(data?.slugurl)}>
                  {data?.name}
                </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default Faq;
