import filterIcon from "../../assets/svg/filter-img.svg"
import sortIcon from "../../assets/svg/sort_icon.svg";

const DoctorsFilterOptions = ({
	setLimit = () => { },
	onChange = () => { },
	setSortShow = () => { },
	setFilterShow = () => { },
	limit,
	sortName,
	sortshow,
	search,
	filterBy = true,
}) => {

	return (
		<div className="healtharticle-show mt-3">
			<div>Show</div>
			<div>
				<select
					value={limit}
					onChange={(e) => setLimit(e.target.value)}
					className="me-2"
				>
					<option>10</option>
					<option>25</option>
					<option>50</option>
					<option>100</option>
					<option value="0">All</option>
				</select>
			</div>
			<div>entries</div>
			<div>
				<div>
					{/* <FaSearch /> */}
					<img src={search} alt="" />
				</div>
				<div>
					<input
						type="text"
						placeholder="Search"
						className="form-control"
						onChange={onChange}
					/>
				</div>
			</div>
			<div className="sortandfilter-new">
				<div className="sortByDepartment">
					{filterBy ? (
						<div>
							<div>
								<div
									onClick={() => {
										setFilterShow(true);
									}}
								>
									Filter By
								</div>
							</div>
							<div>
								<img src={filterIcon} alt="" />
							</div>
						</div>
					) : <></>}
				</div>
				<div className="sortByPosition">
					<div>
						<div>
							<div
								// onClick={handleToggleSortShow}
								onClick={() => {
									setSortShow(!sortshow);
								}}
							>
								{/* Sort By */}
								{sortName}
							</div>
						</div>
						<div>
							<img src={sortIcon} alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DoctorsFilterOptions