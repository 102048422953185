import React from "react";
import { Route, Routes } from "react-router";
import Login from "../Account/Login";
import Careers from "../Pages/Careers/Careers";
import JobPost from "../Pages/Careers/JobPost/JobPost";
import JobPostEdit from "../Pages/Careers/JobPostForm/JobPostEdit";
import JobPostForm from "../Pages/Careers/JobPostForm/JobPostForm";
import Position from "../Pages/Careers/Position/Position";
import PositionEdit from "../Pages/Careers/PositionForm/PositionEdit";
import PositionForm from "../Pages/Careers/PositionForm/PositionForm";
import Department from "../Pages/Careers/department/Department";
import DepartmentEdit from "../Pages/Careers/departmentForm/DepartmentEdit";
import DepartmentForm from "../Pages/Careers/departmentForm/DepartmentForm";
import Dashboard from "../Pages/Dashboard/Dashboard";
// todo remove
// import AddNewPageOne from "../Pages/HealthArticle/AddNew/AddNew";
import HealthArticleEdit from "../Pages/HealthArticle/AddNew/HealthArticleEdit";
import HealthArticleView from "../Pages/HealthArticle/AddNew/HealthArticleView";
import Category from "../Pages/HealthArticle/Category/Category";
import CategoryEdit from "../Pages/HealthArticle/Category/CategoryEdit";
import CategoryForm from "../Pages/HealthArticle/Category/CategoryForm";
import ViewCategory from "../Pages/HealthArticle/Category/ViewCategory";
import HealthArticle from "../Pages/HealthArticle/HealthArticle";
import Menu from "../Pages/HealthArticle/menu/Menu";
import MenuEdit from "../Pages/HealthArticle/menu/MenuEdit";
import MenuForm from "../Pages/HealthArticle/menu/MenuForm";
import ViewMenu from "../Pages/HealthArticle/menu/ViewMenu";
import DoctorDemo from "../Pages/HealthSynergy/DoctorDemo";
import DoctorRegistration from "../Pages/HealthSynergy/DoctorRegistration";
import HealthcareRegistration from "../Pages/HealthSynergy/HealthcareRegistration";
import OtherRegistration from "../Pages/HealthSynergy/OtherRegistration";
import { default as PharmacyRegistration, default as RetailPharmacy } from "../Pages/HealthSynergy/PharmacyRegistration";
import Comments from "../PopupData/Comments/Comments";
import SocialForm from "../Settings/SocialMediaLinks/Form/SocialForm";
import SocialMediaLink from "../Settings/SocialMediaLinks/SocialMediaLink";
import BD from "../masters/Common/BD/BD";
import BDForm from "../masters/Common/BD/Form/BDForm";
import BDView from "../masters/Common/BD/Form/BDView";
import Days from "../masters/Common/Days/Days";
import DaysEdit from "../masters/Common/Days/Form/DaysEdit";
import DaysForm from "../masters/Common/Days/Form/DaysForm";
import DaysView from "../masters/Common/Days/Form/DaysView";
import Experience from "../masters/Common/Experience/Experience";
import ExperienceEdit from "../masters/Common/Experience/Form/ExperienceEdit";
import ExperienceForm from "../masters/Common/Experience/Form/ExperienceForm";
import ExperienceView from "../masters/Common/Experience/Form/ExperienceView";
import GenderEdit from "../masters/Common/Gender/Form/GenderEdit";
import GenderForm from "../masters/Common/Gender/Form/GenderForm";
import GenderView from "../masters/Common/Gender/Form/GenderView";
import Gender from "../masters/Common/Gender/Gender";
import LanguagesEdit from "../masters/Common/Languages/Form/LanguagesEdit";
import LanguagesForm from "../masters/Common/Languages/Form/LanguagesForm";
import LanguagesView from "../masters/Common/Languages/Form/LanguagesView";
import Lanuages from "../masters/Common/Languages/Lanuages";
import LeadStatusEdit from "../masters/Common/LeadStatus/Form/LeadStatusEdit";
import LeadStatusForm from "../masters/Common/LeadStatus/Form/LeadStatusForm";
import LeadStatusView from "../masters/Common/LeadStatus/Form/LeadStatusView";
import LeadStatus from "../masters/Common/LeadStatus/LeadStatus";
import LocationEdit from "../masters/Common/Location/Form/LocationEdit";
import LocationForm from "../masters/Common/Location/Form/LocationForm";
import LocationView from "../masters/Common/Location/Form/LocationView";
import Location from "../masters/Common/Location/Location";
import TimingEdit from "../masters/Common/Timing/Form/TimingEdit";
import TimingForm from "../masters/Common/Timing/Form/TimingForm";
import TimingView from "../masters/Common/Timing/Form/TimingView";
import Timings from "../masters/Common/Timing/Timings";
import InClinicForm from "../masters/Doctors/InClinicFee/Form/InClinicForm";
import InclinicEdit from "../masters/Doctors/InClinicFee/Form/InclinicEdit";
import InclinicView from "../masters/Doctors/InClinicFee/Form/InclinicView";
import InClinicFee from "../masters/Doctors/InClinicFee/InClinicFee";
import Edit from "../masters/Doctors/PrimarySpecialisation/Form/Edit";
import SpecialisationForm from "../masters/Doctors/PrimarySpecialisation/Form/SpecialisationForm";
import View from "../masters/Doctors/PrimarySpecialisation/Form/View";
import PrimarySpecialisation from "../masters/Doctors/PrimarySpecialisation/PrimarySpecialisation";
import HealthcareForm from "../masters/HomeHealthCare/Form/HealthcareForm";
import HomeHealthcareEdit from "../masters/HomeHealthCare/Form/HomeHealthcareEdit";
import HomeHealthcareView from "../masters/HomeHealthCare/Form/HomeHealthcareView";
import HomeHealthCare from "../masters/HomeHealthCare/HomeHealthCare";
import OtherView from "../masters/Others/Form/OtherView";
import OthersEdit from "../masters/Others/Form/OthersEdit";
import OthersForm from "../masters/Others/Form/OthersForm";
import Others from "../masters/Others/Others";
import InventoryEdit from "../masters/PartneredPharmacies/Inventory/Form/InventoryEdit";
import InventoryForm from "../masters/PartneredPharmacies/Inventory/Form/InventoryForm";
import InventoryView from "../masters/PartneredPharmacies/Inventory/Form/InventoryView";
import Inventory from "../masters/PartneredPharmacies/Inventory/Inventory";
import PharmacyBusinessEdit from "../masters/PartneredPharmacies/PharmacyBusiness/Form/PharmacyBusinessEdit";
import PharmacyBusinessForm from "../masters/PartneredPharmacies/PharmacyBusiness/Form/PharmacyBusinessForm";
import PharmacyBusinessView from "../masters/PartneredPharmacies/PharmacyBusiness/Form/PharmacyBusinessView";
import PharmacyBusiness from "../masters/PartneredPharmacies/PharmacyBusiness/PharmacyBusiness";
import PharmacyStoreEdit from "../masters/PartneredPharmacies/PharmacyStore/Form/PharmacyStoreEdit";
import PharmacyStoreForm from "../masters/PartneredPharmacies/PharmacyStore/Form/PharmacyStoreForm";
import PharmacyStoreView from "../masters/PartneredPharmacies/PharmacyStore/Form/PharmacyStoreView";
import PharmacyStore from "../masters/PartneredPharmacies/PharmacyStore/PharmacyStore";
// import GetNotified from "../PopupData/GetNotified/GetNotified";
// import Notifyme from "../PopupData/Notifyme/Notifyme";
import DoctorInstaDoc from "../Doctors/AddNewDoctor/DoctorsInstaDoc/DoctorInstaDoc";
import DoctorActivate from "../Doctors/AddNewDoctor/Pages/DoctorActivate";
import DoctorTrack from "../Doctors/AddNewDoctor/Pages/DoctorTrack";
import DoctorSpecializationMeta from "../Doctors/AddNewDoctor/specializationMeta";
import ViewComment from "../PopupData/Comments/ViewComment";
import Subscribe from "../PopupData/Subscribe/Subscribe";
import BannerImages from "../Settings/BannerImages/BannerImages";
import BusinessSettings from "../Settings/BusinessSettings/BusinessSettings";
import StoreLinks from "../Settings/StoreLinks/StoreLinks";
// import EditDoctorSplecialisation from "../Doctors/OnlineDoctorSpecialisation/form/EditDoctorSplecialisation";
// import ViewDoctorSpecialisation from "../Doctors/OnlineDoctorSpecialisation/form/ViewDoctorSpecialisation";
import ContactUs from "../../contactUs/contactUs";
import AdLandingDoctorEdit from "../AdLandingForms/AdLandingDoctorEdit";
import AdLandingForms from "../AdLandingForms/AdLandingForms";
import AddCTA from "../CTA/AddCTA";
import CTA from "../CTA/CTA";
import AddNewClinic from "../ClinicHospital/AddNewClinic";
import DoctorInclinicAppointment from "../ClinicHospital/DoctorInclinicAppointment/DoctorInclinicAppointment";
import AddDoctorInclinicform from "../ClinicHospital/DoctorInclinicAppointment/form/AddDoctorInclinicform";
import EditDoctorInclinicform from "../ClinicHospital/DoctorInclinicAppointment/form/EditDoctorInclinicform";
import ViewDoctorInclinicForm from "../ClinicHospital/DoctorInclinicAppointment/form/ViewDoctorInclinicForm";
import AddNewClinicForm from "../ClinicHospital/form/AddNewClinicForm";
import EditNewClinicForm from "../ClinicHospital/form/EditNewClinicForm";
import ViewNewClinicForm from "../ClinicHospital/form/ViewNewClinicForm";
import DoctorInclinicRedirectOrder from "../ClinicHospital/pages/DoctorInclinicRedirectOrder";
import DoctorInclinicTrack from "../ClinicHospital/pages/DoctorInclinicTrack";
import DoctorInclinicTrackEdit from "../ClinicHospital/pages/DoctorInclinicTrackEdit";
import DoctorInclinicTrackView from "../ClinicHospital/pages/DoctorInclinicTrackView";
import CTAForm from "../CtaForm/CTAForm";
import DoctorListingForm from "../DoctorDetails/DoctorListingForm";
import DoctorInstaDocEdit from "../Doctors/AddNewDoctor/DoctorsInstaDoc/form/DoctorInstaDocEdit";
import DoctorInstaDocView from "../Doctors/AddNewDoctor/DoctorsInstaDoc/form/DoctorInstaDocView";
import DoctorsInstaDocForm from "../Doctors/AddNewDoctor/DoctorsInstaDoc/form/DoctorsInstaDocForm";
import InstaDocTrack from "../Doctors/AddNewDoctor/DoctorsInstaDoc/pages/InstaDocTrack";
import InstaDocTrackRedirect from "../Doctors/AddNewDoctor/DoctorsInstaDoc/pages/InstaDocTrackRedirect";
import InstaDocTrackView from "../Doctors/AddNewDoctor/DoctorsInstaDoc/pages/InstaDocTrackView";
import SpecializationMetaForm from "../Doctors/AddNewDoctor/Forms/SpecializationMetaForm";
import InclinicDoctorSpecialization from "../Doctors/AddNewDoctor/InClinicDoctorSpecialization/InclinicDoctorSpecialization";
import InclinicDoctorSpecializationForm from "../Doctors/AddNewDoctor/InClinicDoctorSpecialization/forms/InclinicDoctorSpecializationForm";
import InclinicHealthConcernForm from "../Doctors/AddNewDoctor/InClinicDoctorSpecialization/forms/InclinicHealthConcernForm";
import InclinicViewHealthConcern from "../Doctors/AddNewDoctor/InClinicDoctorSpecialization/forms/InclinicViewHealthConcern";
import DoctorTrackRedirect from "../Doctors/AddNewDoctor/Pages/DoctorTrackRedirect";
import DoctorTrackView from "../Doctors/AddNewDoctor/Pages/DoctorTrackView";
import AddClinicService from "../Doctors/ClincService/AddClinicService";
import ClinicService from "../Doctors/ClincService/ClinicService";
import AddClinicProfile from "../Doctors/ClinicProfile/AddClinicProfile";
import ClinicMetaTags from "../Doctors/ClinicProfile/ClinicMetaTags";
import DoctorsClinicProfile from "../Doctors/ClinicProfile/DoctorsClinicProfile";
import DoctorsFaq from "../Doctors/ClinicProfile/DoctorsFaq";
import EditClinicProfile from "../Doctors/ClinicProfile/EditClinicProfile";
import EditDoctorFaq from "../Doctors/ClinicProfile/EditDoctorFaq";
import EditMetaTags from "../Doctors/ClinicProfile/EditMetaTags";
import DoctorClinicSpeciality from "../Doctors/CommonHeidge/ClinicSpeciality/DoctorClinicSpeciality";
import DoctorClinicSpecialityForm from "../Doctors/CommonHeidge/ClinicSpeciality/Form/DoctorClinicSpecialityForm";
import DoctorClinicSpecialityView from "../Doctors/CommonHeidge/ClinicSpeciality/Form/DoctorClinicSpecialityView";
import DoctorExperience from "../Doctors/CommonHeidge/DoctorsExperience";
import DoctorsGender from "../Doctors/CommonHeidge/DoctorsGender";
import DoctorsLanguage from "../Doctors/CommonHeidge/DoctorsLanguage";
import DoctorMedicalCouncil from "../Doctors/CommonHeidge/DoctorsMedicalCouncil";
import DoctorExperienceForm from "../Doctors/CommonHeidge/Experience/DoctorExperienceForm";
import DoctorExperienceView from "../Doctors/CommonHeidge/Experience/DoctorExperienceView";
import DoctorMedicalCouncilForm from "../Doctors/CommonHeidge/Experience/DoctorMedicalCouncilForm";
import DoctorMedicalCouncilView from "../Doctors/CommonHeidge/Experience/DoctorMedicalCouncilView";
import DoctorGenderForm from "../Doctors/CommonHeidge/Gender/DoctorGenderForm";
import DoctorGenderView from "../Doctors/CommonHeidge/Gender/DoctorGenderView";
import InclinicSpecialization from "../Doctors/CommonHeidge/InclinicSpecialization";
import InclinicSpecializationForm from "../Doctors/CommonHeidge/InclinicSpecialization/InclinicSpecializationForm";
import InclinicSpecializationView from "../Doctors/CommonHeidge/InclinicSpecialization/InclinicSpecializationView";
import DoctorLanguageForm from "../Doctors/CommonHeidge/Language/DoctorLanguageForm";
import DoctorLanguageView from "../Doctors/CommonHeidge/Language/DoctorLanguageView";
import OnlineSpecializationForm from "../Doctors/CommonHeidge/OnlineSpecialization/OnlineSpecializationForm";
import OnlineSpecializationView from "../Doctors/CommonHeidge/OnlineSpecialization/OnlineSpecializationView";
import OnlineSpecialization from "../Doctors/CommonHeidge/OnlineSpecicalization";
import OnlineDoctorSpecialisation from "../Doctors/OnlineDoctorSpecialisation/OnlineDoctorSpecialisation";
import OnlineDoctorSplecialisationForm from "../Doctors/OnlineDoctorSpecialisation/form/OnlineDoctorSplecialisationForm";
import OnlineHealthConcernForm from "../Doctors/OnlineDoctorSpecialisation/form/OnlineHealthConcernForm";
import OnlineViewHealthConcern from "../Doctors/OnlineDoctorSpecialisation/form/OnlineViewHealthConcern";
import PartneredDoctor from "../Doctors/PartneredDcotor/PartneredDoctor";
import PartneredDoctorForInClinic from "../Doctors/PartneredDcotor/PartneredDoctorForInClinic";
import EditPartneredDoctorForm from "../Doctors/PartneredDcotor/form/EditPartneredDoctorForm";
import PartneredDoctorForm from "../Doctors/PartneredDcotor/form/PartneredDoctorForm";
import PartneredDoctorOnline from "../Doctors/PartneredDcotor/form/PartneredDoctorOnline";
// import DoctorMetaForm from "../Doctors/PartneredDcotor/metaForm";
import DoctorsReportError from "../Doctors/ReportError/Doctor/DoctorsReportError";
import AddDownloadLink from "../DownloadLink/AddDownloadLink";
import DownloadLink from "../DownloadLink/DownloadLink";
import EditAppDownloadLink from "../DownloadLink/EditAppDownloadLink";
import DoctorBrochures from "../EmailBrochures/DoctorBrochures";
import DoctorDemoBrochures from "../EmailBrochures/DoctorDemoBrochures";
import HealthcareBrochures from "../EmailBrochures/HealthcareBrochures";
import OtherBrochures from "../EmailBrochures/OtherBrochures";
import PharmacyBrochures from "../EmailBrochures/PharmacyBrochures";
import Faq from "../Faq/Faq";
import FaqSectionsQuestionsAndAnswer from "../Faq/FaqSectionsQuestionAndAnswers";
import NewFaqSection from "../Faq/NewFaqSection";
import CreateFaqQuestionAndAnswer from "../Faq/createFaqQuestionAndAnswers";
import EditFaqQuestionAndAnswer from "../Faq/editFaqQuestionAndAnswers";
import Home from "../Home/Home";
import AddNewServiceProvider from "../HomeHealthcare/AddNewServiceProvider/AddNewServiceProvider.";
import AddNewServiceProviderForm from "../HomeHealthcare/AddNewServiceProvider/form/AddNewServiceProviderForm";
import EditNewServiceProviderForm from "../HomeHealthcare/AddNewServiceProvider/form/EditNewServiceProviderForm";
import ViewNewServicdProvider from "../HomeHealthcare/AddNewServiceProvider/form/ViewNewServicdProvider";
import HealthcareActivate from "../HomeHealthcare/AddNewServiceProvider/pages/HealthcareActivate";
import HealthcareTrack from "../HomeHealthcare/AddNewServiceProvider/pages/HealthcareTrack";
import HealthcareTrackRedirect from "../HomeHealthcare/AddNewServiceProvider/pages/HealthcareTrackRedirect";
import HealthcareTrackView from "../HomeHealthcare/AddNewServiceProvider/pages/HealthcareTrackView";
import HHSPSpecialisation from "../HomeHealthcare/HHSPSpecialisation/HHSPSpecialisation";
import AddHsspSpecialisation from "../HomeHealthcare/HHSPSpecialisation/form/AddHsspSpecialisation";
import EditHsspSpecialisation from "../HomeHealthcare/HHSPSpecialisation/form/EditHsspSpecialisation";
import ViewHsspSpecialisation from "../HomeHealthcare/HHSPSpecialisation/form/ViewHsspSpecialisation";
import HHSPSpecialisationActive from "../HomeHealthcare/HHSPSpecialisation/pages/HHSPSpecialisationActive";
import AddEditInsider from "../Insiders/AddEditInsider";
import Insiders from "../Insiders/Insiders";
import InstaDocQrCore from "../InstaDocQrCore/InstaDocQrCore";
import AdLAndingMetaTags from "../MetaTags/AdLAndingMetaTags";
import DoctorCityList from "../OnlineConsultationDoctor/DoctorsCity/DoctorCityList";
import DoctorsCityForm from "../OnlineConsultationDoctor/DoctorsCity/DoctorsCityForm";
import DoctorslocalitiesList from "../OnlineConsultationDoctor/DoctorsCity/DoctorslocalitiesList";
import LocalityForm from "../OnlineConsultationDoctor/DoctorsCity/LocalityForm";
import DoctorRegistrationEdit from "../Pages/HealthSynergy/DoctorRegistrationEdit";
import HealthcareRegistrationEdit from "../Pages/HealthSynergy/HealthcareRegistrationEdit";
import InstaDocRegistrationEdit from "../Pages/HealthSynergy/InstaDocRegistrationEdit";
import PharmacyRegistrationEdit from "../Pages/HealthSynergy/PharmacyRegistrationEdit ";
import InstaDocRegistration from "../Pages/HealthSynergy/instaDocRegistration";
import AddNewRetailPharmacies from "../RetailPharmacies/AddNewRetailPharmacies";
import AddNewPharmacyForm from "../RetailPharmacies/forms/AddNewPharmacyForm";
import EditNewPharmacyForm from "../RetailPharmacies/forms/EditNewPharmacyForm";
import ViewNewPharmacyForm from "../RetailPharmacies/forms/ViewNewPharmacyForm";
import RetailPharmacyActive from "../RetailPharmacies/pages/RetailPharmacyActive";
import RetailPharmacyTrack from "../RetailPharmacies/pages/RetailPharmacyTrack";
import RetailPharmacyTrackEdit from "../RetailPharmacies/pages/RetailPharmacyTrackEdit";
import RetailPharmacyTrackRedirect from "../RetailPharmacies/pages/RetailPharmacyTrackRedirect";
import RetailPharmacyView from "../RetailPharmacies/pages/RetailPharmacyView";
import AddNewPharmacyPartnerID from "../RpQrRatings/AddNewPharmacyPartnerID";
import PharmaciesPartnerID from "../RpQrRatings/PharmaciesPartnerID";
import PharmacyRatingForm from "../RpQrRatings/PharmacyRatingForm";
import EmailSettings from "../Settings/EmailSettings/EmailSettings";
import MetaTags from "../Settings/MetaTags/MetaTags";
import SiteMapSettings from "../Settings/SiteMapSettings/SiteMapSettings";
import SustainabilityPolicy from "../SustainabilityPolicy/SustainabilityPolicy";
import AddNewCity from "../WebBookings/AddNewCity";
import AddNewHealthCondition from "../WebBookings/AddNewHealthCondition";
import AddNewServiceCategory from "../WebBookings/AddNewServiceCategory";
import EditCity from "../WebBookings/EditCity";
import EditHealthCondition from "../WebBookings/EditHealthCondition";
import EditServiceCategory from "../WebBookings/EditServiceCategory";
import ViewCities from "../WebBookings/viewCities";
import ViewHealthCondition from "../WebBookings/viewHealthCondition";
import ViewService from "../WebBookings/viewService";
import ViewServiceCategory from "../WebBookings/viewServiceCategory";
import WebBookings from "../WebBookings/webBookings";
import AdUserCounter from "../counter/AdUserCounter";
import HomeCounter from "../counter/HomeCounter";
import SustainabilityPolicyCounter from "../counter/SustainabilityPolicyCounter";
import FranchiseProgramme from "../franchiseProgramme/franchiseProgramme";
import AddFrpCity from "../frpStates/addFrpCity";
import AddNewState from "../frpStates/addNewState";
import FrpCities from "../frpStates/frpCities";
import FrpStates from "../frpStates/frpStates";
import PGDegreeForm from "../masters/Doctors/PGDegree/Form/PGDegreeForm";
import PGDegreeView from "../masters/Doctors/PGDegree/Form/PGDegreeVeiw";
import PGDegree from "../masters/Doctors/PGDegree/PGDegree";
import UGDegreeForm from "../masters/Doctors/UGCourse/Form/UGDegreeForm";
import UGDegreeView from "../masters/Doctors/UGCourse/Form/UGDegreeView";
import UGDegree from "../masters/Doctors/UGCourse/UGDegree";
import HealthsyStories from "../Pages/HealthArticle/AddNew/HealthsyStories";
import AddNewHealthsyStories from "../Pages/HealthArticle/AddNew/AddNewHealthsyStories";
import MasterLegalPlatform from "../DownloadLink/MasterLegalPlatform";
import AddMasterLegalPlatformForm from "../DownloadLink/AddMasterLegalPlatformForm";
import MasterLegalPageType from "../DownloadLink/MasterLegalPageType";
import AddMasterLegalPageType from "../DownloadLink/AddMasterLegalPageType";
import MasterLegalDraftedBy from "../DownloadLink/MasterLegalDraftedBy";
import AddLegalDraftedBy from "../DownloadLink/AddLegalDraftedBy";
import CompanyReels from "../Settings/CompanyReels/CompanyReels";
import AddCompanyReels from "../Settings/CompanyReels/AddCompanyReels";
import SitemapNavHeaders from "../Settings/CommonMasters/SitemapCategory";
import Sitemap from "../Settings/SitemapAnalysis/Sitemap";
import AddSitemapHeaderForm from "../Settings/CommonMasters/AddSitemapCategoryForm";
import AddSitemap from "../Settings/SitemapAnalysis/AddSitemap";
import HealthArticleBlogSearch from "../Pages/HealthArticle/AddNew/HealthArticleBlogSearch";
import HealthArticleBlogSearchForm from "../Pages/HealthArticle/AddNew/HealthArticleBlogSearchForm";
import BlogBannerImage from "../Pages/HealthArticle/AddNew/BlogBannerImage";

const AllRoutes = () => {
  return (
    <>
      <Home>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path='/login' element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/doctor-demo" element={<DoctorDemo />} />
          <Route path="/doctor-registration" element={<DoctorRegistration />} />
          <Route
            path="/pharmacy-registration"
            element={<PharmacyRegistration />}
          />
          <Route
            path="/healthcare-registration"
            element={<HealthcareRegistration />}
          />
          <Route path="/other-registration" element={<OtherRegistration />} />
          <Route path="/healtharticle" element={<HealthArticle />} />
          <Route path="/blog-search-keyword" element={<HealthArticleBlogSearch />} />
          <Route path="/blog-search-keyword-create" element={<HealthArticleBlogSearchForm />} />
          <Route path="/blog-search-keyword-edit/:id" element={<HealthArticleBlogSearchForm edit={true} />} />
          <Route path="/blog-banner-image" element={<BlogBannerImage />} />
          <Route path="/healtharticleaddnew" element={<HealthArticleEdit edit={false} />} />
          <Route path="/healthsystories" element={<HealthsyStories />} />
          <Route path="/addNewHealthsyStories" element={<AddNewHealthsyStories />} />
          <Route path="/healthsystories-edit/:id" element={<AddNewHealthsyStories edit={true} />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careerjobpost" element={<JobPost />} />
          <Route path="/careerjobpostform" element={<JobPostForm />} />
          <Route path="/jobpostedit/:id" element={<JobPostEdit />} />
          <Route path="/departmentFunctionForm" element={<DepartmentForm />} />
          <Route path="/careerdepartment" element={<Department />} />
          <Route
            path="/careerDepartmentEdit/:id"
            element={<DepartmentEdit />}
          />
          <Route path="/careerposition" element={<Position />} />
          <Route path="/careerpositionForm" element={<PositionForm />} />
          <Route path="/careerpositionEdit/:id" element={<PositionEdit />} />
          <Route path="/healtharticlemenu" element={<Menu />} />
          <Route path="/addNewMenuForm" element={<MenuForm />} />
          <Route path="/addNewMenuEditForm/:id" element={<MenuEdit />} />
          <Route path="/addNewCategoryForm" element={<CategoryForm />} />
          <Route
            path="/addNewCategoryEditForm/:id"
            element={<CategoryEdit />}
          />
          <Route path="/Category" element={<Category />} />
          <Route path="/RetailPharmacy" element={<RetailPharmacy />} />
          <Route
            path="/healthArticleView/:id"
            element={<HealthArticleView />}
          />
          <Route path="/viewMenuForm/:id" element={<ViewMenu />} />
          <Route path="/viewCategoryForm/:id" element={<ViewCategory />} />
          <Route
            path="/healtharticleEdit/:id"
            element={<HealthArticleEdit edit={true} />}
          />

          {/*----------------masters--------------------*/}
          <Route
            path="/master-primary-specialisation"
            element={<PrimarySpecialisation />}
          />
          <Route path="/master-inclinic-fee" element={<InClinicFee />} />
          <Route
            path="/master-specialisation-create"
            element={<SpecialisationForm />}
          />
          <Route path="/master-in-clinic-create" element={<InClinicForm />} />
          <Route path="/master-specialisation-edit/:id" element={<Edit />} />
          <Route path="/master-specialisation-view/:id" element={<View />} />
          <Route path="/master-inclinic-edit/:id" element={<InclinicEdit />} />
          <Route path="/master-inclinic-view/:id" element={<InclinicView />} />

          {/* UG Degree */}
          <Route path="/master-ug-course" element={<UGDegree />} />
          <Route path="/master-ug-course-create" element={<UGDegreeForm />} />
          <Route path="/master-ug-course-edit/:id" element={<UGDegreeForm edit={true} />} />
          <Route path="/master-ug-course-view/:id" element={<UGDegreeView />} />

          {/* PG Degree*/}
          <Route path="/master-pg-degree" element={<PGDegree />} />
          <Route path="/master-pg-degree-create" element={<PGDegreeForm />} />
          <Route path="/master-pg-degree-edit/:id" element={<PGDegreeForm edit={true} />} />
          <Route path="/master-pg-degree-view/:id" element={<PGDegreeView />} />

          <Route path="/master-health-care" element={<HomeHealthCare />} />
          <Route
            path="/master-health-care-create"
            element={<HealthcareForm />}
          />
          <Route
            path="/master-health-care-edit/:id"
            element={<HomeHealthcareEdit />}
          />
          <Route
            path="/master-health-care-view/:id"
            element={<HomeHealthcareView />}
          />

          <Route
            path="/master-partnered-pharmacy"
            element={<PharmacyBusiness />}
          />
          <Route
            path="/master-partnered-pharmacy-create"
            element={<PharmacyBusinessForm />}
          />
          <Route
            path="/master-partnered-pharmacy-edit/:id"
            element={<PharmacyBusinessEdit />}
          />
          <Route
            path="/master-partnered-pharmacy-view/:id"
            element={<PharmacyBusinessView />}
          />
          <Route path="/master-inventory" element={<Inventory />} />
          <Route path="/master-inventory-create" element={<InventoryForm />} />
          <Route
            path="/master-inventory-edit/:id"
            element={<InventoryEdit />}
          />
          <Route
            path="/master-inventory-view/:id"
            element={<InventoryView />}
          />
          <Route path="/master-pharmacy-store" element={<PharmacyStore />} />
          <Route
            path="/master-pharmacy-store-create"
            element={<PharmacyStoreForm />}
          />
          <Route
            path="/master-pharmacy-store-edit/:id"
            element={<PharmacyStoreEdit />}
          />
          <Route
            path="/master-pharmacy-store-view/:id"
            element={<PharmacyStoreView />}
          />

          <Route path="/master-others" element={<Others />} />
          <Route path="/master-others-form" element={<OthersForm />} />
          <Route path="/master-others-edit/:id" element={<OthersEdit />} />
          <Route path="/master-others-view/:id" element={<OtherView />} />

          <Route path="/master-gender" element={<Gender />} />
          <Route path="/master-gender-create" element={<GenderForm />} />
          <Route path="/master-gender-edit/:id" element={<GenderEdit />} />
          <Route path="/master-gender-view/:id" element={<GenderView />} />
          <Route path="/master-experience" element={<Experience />} />
          <Route
            path="/master-experience-create"
            element={<ExperienceForm />}
          />
          <Route
            path="/master-experience-edit/:id"
            element={<ExperienceEdit />}
          />
          <Route
            path="/master-experience-view/:id"
            element={<ExperienceView />}
          />
          <Route path="/master-days" element={<Days />} />
          <Route path="/master-days-create" element={<DaysForm />} />
          <Route path="/master-days-edit/:id" element={<DaysEdit />} />
          <Route path="/master-days-view/:id" element={<DaysView />} />
          <Route path="/master-languages" element={<Lanuages />} />
          <Route path="/master-languages-create" element={<LanguagesForm />} />
          <Route
            path="/master-languages-edit/:id"
            element={<LanguagesEdit />}
          />
          <Route
            path="/master-languages-view/:id"
            element={<LanguagesView />}
          />
          <Route path="/master-location" element={<Location />} />
          <Route path="/master-location-create" element={<LocationForm />} />
          <Route path="/master-location-edit/:id" element={<LocationEdit />} />
          <Route path="/master-location-view/:id" element={<LocationView />} />
          <Route path="/master-timing" element={<Timings />} />
          <Route path="/master-timing-create" element={<TimingForm />} />
          <Route path="/master-timing-edit/:id" element={<TimingEdit />} />
          <Route path="/master-timing-view/:id" element={<TimingView />} />

          <Route path="/master-bd" element={<BD />} />
          <Route path="/master-bd-create" element={<BDForm />} />
          <Route path="/master-bd-edit/:id" element={<BDForm edit={true} />} />
          {/* <Route path="/master-bd-edit/:id" element={<BDEdit />} /> */}
          <Route path="/master-bd-view/:id" element={<BDView />} />

          <Route path="/master-lead-status" element={<LeadStatus />} />
          <Route path="/master-lead-status-create" element={<LeadStatusForm />} />
          <Route path="/master-lead-status-edit/:id" element={<LeadStatusEdit />} />
          <Route path="/master-lead-status-view/:id" element={<LeadStatusView />} />

          {/*---------------Settings----------------*/}
          <Route
            path="/settings-social-media-link"
            element={<SocialMediaLink />}
          />
          <Route path="/settings-social-media-form" element={<SocialForm />} />
          <Route path="/add-banner-images" element={<BannerImages />} />
          <Route path="/store-links" element={<StoreLinks />} />
          <Route path="/business-settings" element={<BusinessSettings />} />
          <Route path="/meta-tags" element={<MetaTags />} />
          <Route path="/site-map-setting" element={<SiteMapSettings />} />
          <Route path="/email-setting" element={<EmailSettings />} />
          <Route path="/company-reels" element={<CompanyReels />} />
          <Route path="/add-company-reels" element={<AddCompanyReels />} />
          <Route path="/company-reels-edit/:id" element={<AddCompanyReels edit={true} />} />

          {/* Common Masters */}
          <Route path="/sitemap-category" element={<SitemapNavHeaders />} />
          <Route path="/add-sitemap-category-form" element={<AddSitemapHeaderForm />} />
          <Route path="/edit-sitemap-category-form/:id" element={<AddSitemapHeaderForm edit={true} />} />

          {/* Sitemap */}
          <Route path="/sitemap/:category" element={<Sitemap />} />
          <Route path="/add-sitemap-form/:category" element={<AddSitemap />} />
          <Route path="/edit-sitemap-form/:category/:id" element={<AddSitemap edit={true} />} />

          {/*--------------Popup Data---------------*/}
          <Route path="/comment" element={<Comments />} />
          {/* <Route path="/getnotified" element={<GetNotified />} />
          <Route path="/notifyme" element={<Notifyme />} /> */}
          <Route path="/blog-subscribe" element={<Subscribe />} />
          <Route path="/view-comment/:id" element={<ViewComment />} />

          {/* --------------Doctors------------------ */}
          {/* <Route path="/add-new-doctors" element={<AddNewDoctors />} />
          <Route path="/add-new-doctors-form" element={<SpecializationMetaForm />} />
          {/* <Route path="/view-new-doctors-form" element={<ViewDoctorForm />} /> */}

          <Route path="/online-doctor-specialisation" element={<OnlineDoctorSpecialisation />} />
          <Route path="/online-doctor-add-new-splecialisation-form" element={<OnlineDoctorSplecialisationForm />} />
          <Route path="/online-doctor-splecialisation-edit/:id" element={<OnlineDoctorSplecialisationForm edit={true} />} />

          <Route path="/online-doctor-add-health-concern/:doctor_specialization_id" element={<OnlineHealthConcernForm />} />
          <Route path="/online-doctor-health-concern-edit/:doctor_specialization_id/:id" element={<OnlineHealthConcernForm edit={true} />} />
          <Route path="/online-doctor-view-health-concern/:id" element={<OnlineViewHealthConcern />} />

          <Route path="/inclinic-specialization" element={<InclinicDoctorSpecialization />} />
          <Route path="/inclinic-specialization-create" element={<InclinicDoctorSpecializationForm />} />
          <Route path="/inclinic-specialization-edit/:id" element={<InclinicDoctorSpecializationForm edit={true} />} />

          <Route path="/inclinic-view-health-concern/:id" element={<InclinicViewHealthConcern />} />
          <Route path="/inclinic-add-health-concern/:inclinic_specialization_id" element={<InclinicHealthConcernForm />} />
          <Route path="/inclinic-edit-health-concern/:inclinic_specialization_id/:id" element={<InclinicHealthConcernForm edit={true} />} />

          {/* <Route path="/doctor-add-new-splecialisation-edit" element={<EditDoctorSplecialisation />} /> */}
          {/* <Route path="/doctor-add-new-splecialisation-view" element={<ViewDoctorSpecialisation />} /> */}
          <Route path="/doctor-activate" element={<DoctorActivate />} />
          <Route path="/doctor-insta-doc" element={<DoctorInstaDoc />} />
          <Route path="/doctor-insta-doc-add-form" element={<DoctorsInstaDocForm />} />
          <Route path="/doctor-insta-doc-add-edit" element={<DoctorInstaDocEdit />} />
          <Route path="/doctor-insta-doc-add-view" element={<DoctorInstaDocView />} />
          <Route path="/doctor-track" element={<DoctorTrack />} />
          <Route path="/doctor-track-view" element={<DoctorTrackView />} />
          <Route path="/doctor-track-redirect" element={<DoctorTrackRedirect />} />
          <Route path="/insta-doc-track" element={<InstaDocTrack />} />
          <Route path="/insta-doc-track-redirect" element={<InstaDocTrackRedirect />} />
          <Route path="/insta-doc-track-view" element={<InstaDocTrackView />} />

          {/*--------------Home Healthcare------------------*/}
          <Route
            path="/home-healthcare-service-provider"
            element={<AddNewServiceProvider />}
          />
          <Route
            path="/home-healthcare-service-provider-form"
            element={<AddNewServiceProviderForm />}
          />
          <Route
            path="/home-healthcare-service-provider-edit"
            element={<EditNewServiceProviderForm />}
          />
          <Route path="/healthcare-view" element={<ViewNewServicdProvider />} />
          <Route
            path="/home-healthcare-hhsp"
            element={<HHSPSpecialisation />}
          />
          <Route
            path="/home-healthcare-hhsp-form"
            element={<AddHsspSpecialisation />}
          />
          <Route
            path="/home-healthcare-hhsp-edit"
            element={<EditHsspSpecialisation />}
          />
          <Route
            path="/home-healthcare-hhsp-view"
            element={<ViewHsspSpecialisation />}
          />
          <Route path="/healthcare-activate" element={<HealthcareActivate />} />
          <Route path="/healthcare-track" element={<HealthcareTrack />} />
          <Route
            path="/healthcare-track-redirect"
            element={<HealthcareTrackRedirect />}
          />
          <Route
            path="/healthcare-track-view"
            element={<HealthcareTrackView />}
          />
          <Route path="/hhsp-activate" element={<HHSPSpecialisationActive />} />

          {/*--------------Retail Pharmacies------------------*/}
          <Route
            path="/add-new-retail-pharmacy"
            element={<AddNewRetailPharmacies />}
          />
          <Route
            path="/add-new-retail-pharmacy-form"
            element={<AddNewPharmacyForm />}
          />
          <Route
            path="/edit-retail-pharmacy-form"
            element={<EditNewPharmacyForm />}
          />
          <Route
            path="/view-retail-pharmacy-form"
            element={<ViewNewPharmacyForm />}
          />
          <Route
            path="/retail-pharmacy-active"
            element={<RetailPharmacyActive />}
          />
          <Route
            path="/retail-pharmacy-track"
            element={<RetailPharmacyTrack />}
          />
          <Route
            path="/retail-pharmacy-track-redirect"
            element={<RetailPharmacyTrackRedirect />}
          />
          <Route
            path="/retail-pharmacy-track-view"
            element={<RetailPharmacyView />}
          />
          <Route
            path="/retail-pharmacy-track-edit"
            element={<RetailPharmacyTrackEdit />}
          />

          {/*--------------Clinic Hospital--------------------*/}
          <Route path="/add-new-clinic" element={<AddNewClinic />} />
          <Route path="/add-new-clinic-form" element={<AddNewClinicForm />} />
          <Route path="/edit-clinic-form" element={<EditNewClinicForm />} />
          <Route path="/view-clinic-form" element={<ViewNewClinicForm />} />
          <Route
            path="/doctor-inclinic-appointment"
            element={<DoctorInclinicAppointment />}
          />
          <Route
            path="/add-new-inclinic-form"
            element={<AddDoctorInclinicform />}
          />
          <Route
            path="/edit-inclinic-form"
            element={<EditDoctorInclinicform />}
          />
          <Route
            path="/view-inclinic-form"
            element={<ViewDoctorInclinicForm />}
          />
          <Route path="/clinic-track" element={<DoctorInclinicTrack />} />
          <Route
            path="/doctor-inclinic-redirect-order"
            element={<DoctorInclinicRedirectOrder />}
          />
          <Route
            path="/doctor-inclinic-view"
            element={<DoctorInclinicTrackView />}
          />
          <Route
            path="/doctor-inclinic-track-edit"
            element={<DoctorInclinicTrackEdit />}
          />

          {/*--------------Email Brochures--------------------*/}
          <Route
            path="/doctor-registration-brochures"
            element={<DoctorBrochures />}
          />
          <Route
            path="/doctor-demo-registration-brochures"
            element={<DoctorDemoBrochures />}
          />
          <Route
            path="/pharmacy-registration-brochures"
            element={<PharmacyBrochures />}
          />
          <Route
            path="/healthcare-registration-brochures"
            element={<HealthcareBrochures />}
          />
          <Route
            path="/others-registration-brochures"
            element={<OtherBrochures />}
          />

          {/*--------------------Faq----------------------*/}
          <Route path="/faq" element={<Faq />} />
          <Route path="/faq/:id" element={<FaqSectionsQuestionsAndAnswer />} />
          <Route path="/faq/add-faq-section" element={<NewFaqSection />} />
          <Route
            path="/faq/:id/add-faq"
            element={<CreateFaqQuestionAndAnswer />}
          />
          <Route
            path="/faq/:id/edit-faq"
            element={<EditFaqQuestionAndAnswer />}
          />
          {/*--------------------CTA Banner----------------------*/}
          <Route path="/cta-banner/:id" element={<CTA />} />
          <Route
            path="/cta-banner/:id/add-new-cta-banner"
            element={<AddCTA />}
          />
          {/*--------------------CTA Form----------------------*/}
          <Route path="/cta-form/:id" element={<CTAForm />} />
          {/*--------------------Contact Form----------------------*/}
          <Route path="/contact-us" element={<ContactUs />} />
          {/*--------------------Download Links----------------------*/}
          <Route path="/app-download-link/:id" element={<DownloadLink />} />
          <Route
            path="/app-download-link/:id/add-new-app-link"
            element={<AddDownloadLink />}
          />
          <Route
            path="/app-download-link/:id/edit-download-link"
            element={<AddDownloadLink edit={true} />}
          />
          {/* todo */}
          {/* <Route
            path="/app-download-link/:id/edit-download-link"
            element={<EditAppDownloadLink />}
          /> */}

          {/* Master For Legal Page */}
          <Route path="/master-legal-pages" element={<MasterLegalPlatform />} />
          <Route path="/add-master-legal-pages" element={<AddMasterLegalPlatformForm />} />
          <Route path="/edit-master-legal-pages/:id" element={<AddMasterLegalPlatformForm edit={true} />} />

          {/* Page Type */}
          <Route path="/master-legal-page-type" element={<MasterLegalPageType />} />
          <Route path="/add-master-page-type" element={<AddMasterLegalPageType />} />
          <Route path="/edit-master-page-type/:id" element={<AddMasterLegalPageType edit={true} />} />

          {/* Drafted By */}
          <Route path="/master-legal-drafted-by" element={<MasterLegalDraftedBy />} />
          <Route path="/add-legal-drafted-by" element={<AddLegalDraftedBy />} />
          <Route path="/edit-legal-drafted-by/:id" element={<AddLegalDraftedBy edit={true} />} />

          {/*--------------------Insiders----------------------*/}
          <Route path="/insiders" element={<Insiders />} />
          <Route path="/insiders/add-insider" element={<AddEditInsider />} />
          <Route path="/insiders/:id/edit-insider" element={<AddEditInsider edit={true} />} />
          {/*--------------------Insta Doc----------------------*/}
          <Route path="/insta-doc" element={<InstaDocRegistration />} />
          {/*--------------------Web Bookings----------------------*/}
          <Route path="/web-bookings/:id" element={<WebBookings />} />
          <Route path="/web-bookings/:id/view-services" element={<ViewService />} />
          <Route path="/web-bookings/:id/view-service-categories" element={<ViewServiceCategory />} />
          <Route path="/web-bookings/:id/add-new-service-category" element={<AddNewServiceCategory />} />
          <Route path="/web-bookings/:id/:id/view-health-conditions" element={<ViewHealthCondition />} />
          <Route path="/web-bookings/:id/:id/add-new-health-condition" element={<AddNewHealthCondition />} />
          <Route path="/web-bookings/:id/:id/view-cities" element={<ViewCities />} />
          <Route path="/web-bookings/:id/:id/add-new-city" element={<AddNewCity />} />
          <Route path="/web-bookings/:id/edit-service-category/:id" element={<EditServiceCategory />} />
          <Route path="/web-bookings/:id/:id/:id/edit-health-condition" element={<EditHealthCondition />} />
          <Route path="/web-bookings/:id/:id/:id/edit-city" element={<EditCity />} />
          {/*--------Ad Landing Forms----------*/}
          <Route path="/ad-landing-forms/:id" element={<AdLandingForms />} />
          <Route path="/ad-landing-doctor-edit/:id" element={<AdLandingDoctorEdit />} />
          {/*--------Ad Landing Meta Tags----------*/}
          <Route path="/ad-landing-meta-tags" element={<AdLAndingMetaTags />} />
          {/*--------InstaDoc QRCode----------*/}
          <Route path="/insta-doc-qr-code" element={<InstaDocQrCore />} />
          {/*--------Counter----------*/}
          <Route path="/counter-home" element={<HomeCounter />} />
          <Route path="/ad-user-counter" element={<AdUserCounter />} />
          <Route path="/sustainability-policy-counter" element={<SustainabilityPolicyCounter />} />
          {/*--------Sustainability Policy----------*/}
          <Route path="/sustainability-policy" element={<SustainabilityPolicy />} />
          {/*--------Franchise Programme----------*/}
          <Route path="/franchise-programme" element={<FranchiseProgramme />} />
          {/*--------FRP----------*/}
          <Route path="/frp-states" element={<FrpStates />} />
          <Route path="/frp-states/add-new-state" element={<AddNewState />} />
          <Route path="/frp-states/edit-state/:id" element={<AddNewState edit={true} />} />
          <Route path="/frp-states/:id/view-cities" element={<FrpCities />} />
          <Route path="/frp-states/:id/add-city" element={<AddFrpCity />} />
          <Route path="/frp-states/:id/edit-city/:id" element={<AddFrpCity edit={true} />} />
          {/*--------HealthSynergy Forms Edit----------*/}
          <Route path="/doctor-registration-edit/:id" element={<DoctorRegistrationEdit />} />
          <Route path="/healthcare-registration-edit/:id" element={<HealthcareRegistrationEdit />} />
          <Route path="/insta-doc-registration-edit/:id" element={<InstaDocRegistrationEdit />} />
          <Route path="/pharmacy-registration-edit/:id" element={<PharmacyRegistrationEdit />} />
          {/*--------RP QR Ratings----------*/}
          <Route path="/rp-qr-ratings/pharmacies-partner-id" element={<PharmaciesPartnerID />} />
          <Route path="/rp-qr-ratings/add-new-pharmacy-partner-id" element={<AddNewPharmacyPartnerID />} />
          <Route path="/rp-qr-ratings/edit-pharmacy-partner-id/:id" element={<AddNewPharmacyPartnerID edit={true} />} />
          <Route path="/rp-qr-ratings/pharmacy-rating" element={<PharmacyRatingForm />} />

          {/* Doctor Listing Form */}
          <Route path="/booking-details/doctor-consultation" element={<DoctorListingForm />} />

          {/* Doctors Gender */}
          <Route path="/doctors-gender" element={<DoctorsGender />} />
          <Route path="/doctor-gender-create" element={<DoctorGenderForm />} />
          <Route path="/doctor-gender-edit/:id" element={< DoctorGenderForm edit={true} />} />
          <Route path="/doctor-gender-view/:id" element={< DoctorGenderView />} />

          {/* Doctors Experience */}
          <Route path="/doctors-experience" element={<DoctorExperience />} />
          <Route path="/doctors-experience-create" element={<DoctorExperienceForm />} />
          <Route path="/doctors-experience-view/:id" element={<DoctorExperienceView />} />
          <Route path="/doctors-experience-edit/:id" element={<DoctorExperienceForm edit={true} />} />

          {/* Medical Council */}
          <Route path="/doctors-medical-council" element={<DoctorMedicalCouncil />} />
          <Route path="/doctors-medical-council-create" element={<DoctorMedicalCouncilForm />} />
          <Route path="/doctors-medical-council-view/:id" element={<DoctorMedicalCouncilView />} />
          <Route path="/doctors-medical-council-edit/:id" element={<DoctorMedicalCouncilForm edit={true} />} />

          {/* Clinic Speciality */}
          <Route path="/doctors-clinic-speciality" element={<DoctorClinicSpeciality />} />
          <Route path="/doctors-clinic-speciality-create" element={<DoctorClinicSpecialityForm />} />
          <Route path="/doctors-clinic-speciality-edit/:id" element={<DoctorClinicSpecialityForm edit={true} />} />
          <Route path="/doctors-clinic-speciality-view/:id" element={<DoctorClinicSpecialityView />} />

          {/* Doctors Language */}
          <Route path="/doctors-language" element={<DoctorsLanguage />} />
          <Route path="/doctors-language-create" element={<DoctorLanguageForm />} />
          <Route path="/doctors-language-edit/:id" element={<DoctorLanguageForm edit={true} />} />
          <Route path="/doctors-language-view/:id" element={< DoctorLanguageView />} />

          {/*Online Specialisation */}
          <Route path="/online-specialization" element={<OnlineSpecialization />} />
          <Route path="/online-specialization-create" element={<OnlineSpecializationForm />} />
          <Route path="/online-specialization-edit/:id" element={<OnlineSpecializationForm edit={true} />} />
          <Route path="/online-specialization-view/:id" element={<OnlineSpecializationView />} />

          {/* Inclinic Specialization */}
          <Route path="/inclinic-specializations" element={<InclinicSpecialization />} />
          <Route path="/inclinic-specializations-create" element={<InclinicSpecializationForm />} />
          <Route path="/inclinic-specializations-edit/:id" element={<InclinicSpecializationForm edit={true} />} />
          <Route path="/inclinic-specialization-view/:id" element={<InclinicSpecializationView />} />

          {/* Doctors */}
          <Route path="/doctors-location" element={<DoctorCityList />} />
          <Route path="/doctors-location-create" element={<DoctorsCityForm />} />
          <Route path="/doctors-location-edit/:id" element={<DoctorsCityForm edit={true} />} />
          <Route path="/doctors-localities/:id" element={<DoctorslocalitiesList />} />
          <Route path="/doctors-localities-list/:id" element={<DoctorsCityForm edit={true} localityEdit={true} />} />
          <Route path="/:city_name/add-locality" element={<LocalityForm />} />
          <Route path="/:city_name/edit-locality/:id" element={<LocalityForm edit={true} />} />
          {/* <Route path="/doctors-localities-edit/:id" element={<EditDoctorsLocalities />} /> */}

          {/* Doctor Clinic Service */}
          <Route path="/doctor-clinic-service" element={<ClinicService />} />
          <Route path="/doctor-clinic-service-create" element={<AddClinicService />} />
          <Route path="/doctor-clinic-service-edit/:id" element={<AddClinicService edit={true} />} />

          {/* Doctor Clinic Profile */}
          <Route path="/doctor-clinic-profile" element={<DoctorsClinicProfile />} />
          <Route path="/doctor-clinic-profile-create" element={<AddClinicProfile />} />
          <Route path="/clinic-profile-edit/:id" element={<EditClinicProfile />} />
          <Route path="/doctor-add-new-faq" element={<DoctorsFaq />} />
          <Route path="/doctor-faq/:id/doctor-edit-faq" element={<EditDoctorFaq />} />
          <Route path="/clinic-meta-tags-create" element={<ClinicMetaTags />} />
          <Route path="/clinic-meta-tags-edit" element={<EditMetaTags />} />

          {/* Report a Error */}
          <Route path="/doctors-report" element={<DoctorsReportError type="online-doctor" />} />
          <Route path="/inclinic-doctors-report" element={<DoctorsReportError type="inclinic-doctor" />} />
          <Route path="/clinics-report" element={<DoctorsReportError type="clinic" />} />

          {/* Doctor Lising */}
          <Route path="/add-new-doctors" element={<DoctorSpecializationMeta />} />
          <Route path="/create-doctor-listing-page-meta-set" element={<SpecializationMetaForm />} />
          {/* todo remove */}
          {/* <Route path="/partnered-doctor/:id/meta" element={<DoctorMetaForm />} /> */}
          <Route path="/create-doctor-listing-page-meta-set/:id" element={<SpecializationMetaForm edit={true} />} />

          {/* Partnered Doctor */}
          <Route path="/partnered-doctor" element={<PartneredDoctor />} />
          <Route path="/partnered-doctor-for-inclinic" element={<PartneredDoctorForInClinic />} />
          <Route path="/partnered-doctor-form-create" element={<PartneredDoctorForm />} />
          <Route path="/partnered-doctor/edit/:id" element={<EditPartneredDoctorForm />} />
          <Route path="/partnered-doctor-online" element={<PartneredDoctorOnline />} />


        </Routes>
      </Home>
    </>
  );
};

export default AllRoutes;