import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import Faq from "./Faq";
import Deletepopup from "../modal/Deletepopup";

const FaqSectionsQuestionsAndAnswer = () => {

    const [faqQuestionsAndAnswer, setFaqQuestionsAndAnswer] = useState([])
    const [isdelete, setIsdelete] = useState({ modal: false, id: '' })
    const { id } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        getFaqquestionAndAnswers()
    }, [id])

    const getFaqquestionAndAnswers = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}faq/list`, { section: id })
            .then((response) => {
                setFaqQuestionsAndAnswer(response?.data?.rows)
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    }

    const handleDelete = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}faq/delete/${id}`,
            {
                method: "DELETE",
            }
        ).then((result) => {
            result.json().then((res) => {
                getFaqquestionAndAnswers();
                setIsdelete({ modal: false, id: '' })
            });
        });
    };

    return (
        <>
            <div className="mt-4 faqContainer p-4">
                <Faq />
                <div className="mt-4">
                    {
                        faqQuestionsAndAnswer.length ? faqQuestionsAndAnswer.map((data, idx) => {
                            return (
                                <Fragment key={idx}>
                                    <div className="p-2 questionWrapper d-flex">
                                        <div className="questionContent">Q. {data?.question}</div>
                                        <div className="iconWrapper">

                                            <div className="editIcon cursor-pointer"><TbEditCircle onClick={()=>navigate(`/faq/${data._id}/edit-faq`)}/></div>
                                            <div className="deleteIcon cursor-pointer"><RiDeleteBin6Line onClick={() => setIsdelete({ modal: true, id: data._id })} /></div>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-4 p-2 answerWrapper">A. {data?.answer}</div>
                                </Fragment>
                            )
                        })
                            :
                            <div className="mt-4"> No questions and Answers </div>
                    }
                    {isdelete.modal && <Deletepopup handleDelete={handleDelete} id={isdelete?.id} />}
                </div>
            </div>
        </>
    )
}
export default FaqSectionsQuestionsAndAnswer