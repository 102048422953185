
const YesNoBtns = ({
    yesBtnName = "Yes",
    noBtnName = "No",
    onClickYes = () => { },
    onClickNo = () => { },
}) => {

    return (
        <div className="text-center mt-3 d-flex justify-content-center h-auto" >
            <button
                className="btn me-3 confirmBtn"
                onClick={onClickYes}
            >
                {yesBtnName}
            </button>
            <button
                className="btn confirmBtn"
                onClick={onClickNo}
            >
                {noBtnName}
            </button>
        </div>
    )
}

export default YesNoBtns