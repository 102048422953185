import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Moment from "moment";
import axios from "axios";
import { MdDelete } from "react-icons/md";

import CTABanner from "./CTABanner";
import Deletepopup from "../modal/Deletepopup";

const CTA = () => {
  const [CTABannerList, setCTABannerList] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const { id } = useParams();

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}cta-banner/delete/${id}`)
      .then(() => {
        fetchCTABannerList();
        setDeleteModal({ id: "", status: false });
      });
  };

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  const fetchCTABannerList = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}cta-banner/ctabannerlist`, {
        category: id,
      })
      .then((response) => {
        setCTABannerList(response?.data?.rows);
      });
  };
  useEffect(() => {
    fetchCTABannerList();
  }, [id]);

  const getsrcValue = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "cta-images/" + value;
  };

  const handleActive = (status, id) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}cta-banner/update-status/${id}`, {
        active: !status,
      })
      .then(() => {
        fetchCTABannerList();
      });
  };

  return (
    <>
      <div className="mt-4 faqContainer p-4">
        <CTABanner />
        <div className="mt-4">
          {CTABannerList?.length ? (
            <div className="healtharticle-table ms-4">
              <table style={{ width: "100% " }}>
                <thead>
                  <tr>
                    <td className="">S.No</td>
                    <td className="" style={{ width: "10%" }}>
                      Banner Title
                    </td>
                    <td className="">Image</td>
                    <td className="">Total Prospects</td>
                    <td className="">Total Clicks</td>
                    <td className="career-jobpost-td6">Upload Time</td>
                    <td className="career-jobpost-td6">Status</td>
                    <td className="career-jobpost-td6">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {CTABannerList.map((el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{i + 1}</td>
                        <td>{el.bannerTitle}</td>
                        <td>
                          <img
                            src={getsrcValue(el.bannerImage)}
                            style={{
                              width: "100px",
                              height: "50px",
                              marginTop: "2%",
                              marginBottom: "5%",
                            }}
                            alt="image showing"
                          />
                        </td>
                        <td>{el.totalProspects}</td>
                        <td>{el.totalClicks}</td>
                        <td>
                          {" "}
                          {Moment(el.createdAt).format("DD MMMM YYYY LT")}
                        </td>
                        <td
                          className="cursorPointer"
                          onClick={() => handleActive(el.active, el._id)}
                        >
                          {!el.active ? "Active" : "Inactive"}
                        </td>
                        <td>
                          <span className="text-danger d-flex align-items-center" onClick={() => {
                            setDeleteModal({ id: el._id, status: true });
                          }}><MdDelete className="me-2" size={15} />Delete</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="mt-4"> No Banner Images for this Category </div>
          )}
        </div>
      </div>
      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default CTA;
