import { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineRightCircle } from "react-icons/ai";
import { useNavigate } from "react-router";

import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import filter1 from "../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../assets/images/searchIcon.png";
import Deletepopup from "../modal/Deletepopup";
import buttonadd from "../../assets/images/plus.png";

const FrpStates = () => {
    const [stateList, setStateList] = useState([]);
    const [pages, setPages] = useState(0);
    const [current_page, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState({ id: "", status: false });

    const navigate = useNavigate();

    useEffect(() => {
        getStateList()
    }, []);

    const getStateList = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}frp-states/state-list`)
            .then((response) => {
                setStateList(response?.data);
            });
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}frp-states/state-delete/${id}`)
            .then(() => {
                getStateList()
                setDeleteModal({ id: "", status: false });
            });
    };

    const handleClose = () => {
        setDeleteModal({ id: "", status: false });
    };

    const updateActiveStatus = (data) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}frp-states/state-update/${data?._id}`, { ...data, active: !data?.active })
            .then(() => getStateList())
    };

    return (
        <>
            <div className="mt-4 p-4 bg-white">
                <Breadcrumbs
                    breadcrumbText="FRP"
                    breadcrumbLink="/frp-states"
                    nestedText="States"
                    nestedLink="/frp-states"
                />
                <div className="faq-addnew ms-2">
                    <div className="addNewfaq">
                        <div>
                            <img src={buttonadd} alt="" />
                        </div>
                        <div>
                            <button
                                onClick={() => navigate(`/frp-states/add-new-state`)}
                            >
                                Add New State
                            </button>
                        </div>
                    </div>
                    <div className="managehealth viewBtn">
                        <div>
                            <button
                                onClick={() => navigate(`/frp-states/${stateList[0].slug_url}/view-cities`)}
                            >
                                View Cities
                            </button>
                        </div>
                        <div>
                            <AiOutlineRightCircle />
                        </div>
                    </div>
                </div>
                <div className="healtharticle-show m-0 ps-4">
                    <div>Show</div>
                    <div>
                        <select
                            className="me-2"
                        >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option value="0">All</option>
                        </select>
                    </div>
                    <div>entries</div>
                    <div>
                        <div>
                            <img src={search} alt="" />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="sortandfilter-new">
                        <div className="sortByPositionNew">
                            <div>
                                <div>Sort by</div>
                                <div>
                                    <img src={sort1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="sortByDepartment">
                            <div>
                                <div>
                                    <div>
                                        Filter
                                    </div>
                                </div>
                                <div>
                                    <img src={filter1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    {stateList?.length ? (
                        <div className="frpStatesTable ms-4">
                            <table className="text-center w-100">
                                <thead>
                                    <tr>
                                        <td className="">S.No</td>
                                        <td className="">States</td>
                                        <td className="">Actions</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stateList?.map((el, i) => {
                                        return (
                                            <tr key={el._id}>
                                                <td>{i + 1}</td>
                                                <td>{el.state}</td>
                                                <td>
                                                    <span
                                                        className="pe-3 text-primary cursorPointer"
                                                        onClick={() => navigate(`/frp-states/edit-state/${el._id}`)}
                                                    >
                                                        Edit
                                                    </span>
                                                    <span
                                                        className="pe-3 text-danger cursorPointer"
                                                        onClick={() => setDeleteModal({ id: el._id, status: true })}
                                                    >
                                                        Delete
                                                    </span>
                                                    <span
                                                        className="pe-3 text-warning cursorPointer"
                                                        onClick={() => {
                                                            updateActiveStatus(el);
                                                        }}
                                                    >
                                                        {el.active ? "Inactive" : "Active"}
                                                    </span>
                                                    <span
                                                        className="pe-1 text-success cursorPointer"
                                                        onClick={() => navigate(`/frp-states/${el.slug_url}/view-cities`)}
                                                    >
                                                        View Cities
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="mt-4">No States Are Available </div>
                    )}
                </div>
                <div className="col d-flex pagination admin-pagination justify-content-end">
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<<"
                        // onClick={(e) => setCurrentPage(1)}
                        disabled={current_page === 1}
                    />
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<"
                        // onClick={(e) => setCurrentPage(current_page - 1)}
                        disabled={current_page === 1}
                    />

                    {current_page > 1 && (
                        <input
                            type="button"
                            value={current_page - 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page - 1);
                            }}
                        />
                    )}
                    <input
                        className="page-item page-link"
                        type="button"
                        value={current_page}
                    />
                    {current_page < pages && (
                        <input
                            type="button"
                            value={current_page + 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page + 1);
                            }}
                        />
                    )}
                    <input
                        type="button"
                        value=">"
                        className="page-item page-link"
                        // onClick={(e) => setCurrentPage(current_page + 1)}
                        disabled={current_page === pages}
                    />
                    <input
                        type="button"
                        value=">>"
                        className="page-link"
                        // onClick={(e) => setCurrentPage(pages)}
                        disabled={current_page === pages}
                    />
                </div>
            </div>
            {
                deleteModal.status === true && (
                    <Deletepopup
                        handleDelete={handleDelete}
                        id={deleteModal?.id}
                        isCloseModalEnable={true}
                        handleClose={handleClose}
                    />
                )
            }
        </>
    );
};

export default FrpStates;