import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import SucessModal from "../modal/SucessModal";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";

const AdUserCounter = () => {
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [success, setSuccess] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);
    const [count, setCount] = useState(0);
    const [id, setId] = useState("");

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}user-general-counter/user-counter-list`)
            .then((res) => {
                setCount(res.data.count);
                setId(res.data.rows[0]._id);
                setValue("doctor_count", res?.data?.rows[0]?.doctor_count);
                setValue("mediacl_bills_count", res?.data?.rows[0]?.mediacl_bills_count);
            });
    }, [])

    const onSubmit = (fields) => {
        if (count === 0) {
            axios
                .post(`${process.env.REACT_APP_API_URL}user-general-counter/user-counter-create`, fields)
                .then((res) => {
                    setSuccess(true)
                });
        } else if (count === 1) {
            axios
                .patch(`${process.env.REACT_APP_API_URL}user-general-counter/user-counter-update/${id}`, fields)
                .then((res) => {
                    setEditSuccess(true)
                });
        }
    };

    return (
        <>
            <div className="healtharticle mt-5 pt-3 ps-2">
                <Breadcrumbs
                    breadcrumbText="Counter"
                    breadcrumbLink="/counter-home"
                    nestedText="User General"
                    nestedLink="#"
                />
                <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
                    <div className="row car-dep-form">
                        <div className="col-6">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                Partnered Doctor Count <span className="star">*</span>
                            </label>
                            <input
                                className="form-control w-75"
                                type="number"
                                name=""
                                id="type"
                                {...register("doctor_count", { required: true })}
                                placeholder="Enter the Count"
                            />
                            {errors.doctor_count && (
                                <div className={`invalid-feedback d-block`}>
                                    Please Enter Doctor Count
                                </div>
                            )}
                        </div>

                        <div className="col-6">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                Medical Bills Count <span className="star">*</span>
                            </label>
                            <input
                                className="form-control w-75"
                                type="number"
                                name=""
                                id="type"
                                {...register("mediacl_bills_count", { required: true })}
                                placeholder="Enter the Count"
                            />
                            {errors.mediacl_bills_count && (
                                <div className={`invalid-feedback d-block`}>
                                    Please Enter Medical Bills Count
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="car-dep-form-button m-0 pt-5">
                        <button type="submit">SUBMIT</button>
                    </div>
                </form>
            </div>
            {/* </Home> */}
            {success && (
                <SucessModal
                    page={"/ad-user-counter"}
                    name="Count"
                    post="Added"
                />
            )}

            {editSuccess && (
                <SucessModal
                    page={"/ad-user-counter"}
                    name="Count"
                    post="Updated"
                />
            )}
        </>
    );
};

export default AdUserCounter;