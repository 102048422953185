import { useState } from "react";
import { Link } from "react-router-dom"
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../modal/SucessModal";
import backRed from "../../assets/svg/back-rose-icon.svg";

const CreateFaqQuestionAndAnswer = () => {
    const location = useLocation()
    const pathname= location?.pathname?.split('/')[2];
    const navigate = useNavigate()
    const [success, setSuccess] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (fields) => {

        axios
            .post(`${process.env.REACT_APP_API_URL}faq/create`, { ...fields, section: pathname })
            .then((response) => {
                if (response.data?._id) {
                    setSuccess(true);
                } else {
                    errors.submit_fail = response.data;
                }
            })
            .catch((err) => {
                errors.submit_fail = err.response.data.error;
            });
    };

    return (
        <div className="mt-5 faqContainer p-4">
            <div
                onClick={() => navigate("/faq")}
                className="rose back-button"
            >
                <img src={backRed} alt="" />
                <span className="rose-back">Back</span>
            </div>
            <div className="faq-title ms-0">
                <span>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to="/dashboard"
                    >
                        Dashboard
                    </Link>
                </span>
                <span>&#62;</span>
                <span>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to="/faq"
                    >
                        FAQ's
                    </Link>
                </span>
                <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
                <span style={{ paddingLeft: "0.4%" }}>{pathname}</span>
                <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
                <span style={{ paddingLeft: "0.4%" }}>Add FAQs</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex justify-content-between align-items-center">
                    <div> Create a FAQ</div>
                    <div> <button className="btn btn-success createFaqBtn" type="submit">Save</button></div>
                </div>
                <div className="mt-3">
                    Question
                    <div className="mt-4 mb-4">
                        <input type="text"
                            className="w-100 questionWrapper border-0"
                            name=""
                            id="type"
                            {...register("question", { required: true })}
                        />
                        {errors.question && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a question
                            </div>
                        )}
                    </div>
                    Answer
                    <div className="mt-4">
                        <textarea type="text"
                            className="w-100 answerWrapper border-0"
                            name=""
                            id="type"
                            {...register("answer", { required: true })}
                        />
                        {errors.answer && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a answer
                            </div>
                        )}
                    </div>
                </div>
            </form>
            {success && (
                <SucessModal
                    page={`/faq/${pathname}`}
                    name="New FAQ's"
                    post="added"
                />
            )}
        </div>
    )
}
export default CreateFaqQuestionAndAnswer