import axios from "axios";
import { debounce } from 'lodash';
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";

// actions icons
import apiCall from "../../../api";
import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import notcontact from "../../../assets/images/HealthSy/notcontacted.png";
import onboard from "../../../assets/images/HealthSy/onboard.png";
import searchImg from "../../../assets/images/searchIcon.png";

import { filterDoctorDemoOptions, preferredDay, specialisation } from "../../../constants";
import AssignedTo from "../../modal/AssignedTo";
import Deletepopup from "../../modal/Deletepopup";
import LeadStatus from "../../modal/LeadStatus";
import SucessModal from "../../modal/SucessModal";
import FiltersRootModal from "../../Common/filtersRootModals";
import FilterModal from "../../Common/filterModal";

const DoctorDemo = () => {
  const [filtered_records, setFilteredRecords] = useState([]);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [records, setRecords] = useState([]);

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [specialisationdata, setSpecialisationData] = useState([]);
  const [daydata, setDayData] = useState([]);
  const [submitfilter, setSubmitFilter] = useState(false);
  // const[spltdata, setSpltdata]=useState('')
  // const[checked1, setChecked1]=useState(null)
  // const[checked2, setChecked2]=useState(null)

  // const[specialisation, setSpecialisation]=useState([])
  // const[preferredDay, setPreferredDay]=useState([])

  // useEffect(()=>{
  //   getSpecialisationData()
  //   getPreferredDayData()
  // }, [])

  // const getSpecialisationData=()=>{
  //   axios
  //   .post(`${process.env.REACT_APP_API_URL}primary-specialisation/list`)
  //   .then((res)=>{
  //     setSpecialisation(res.data.rows)
  //   })
  // }

  // const getPreferredDayData=()=>{
  //   axios
  //   .post(`${process.env.REACT_APP_API_URL}days/list`)
  //   .then((res)=>{
  //     setPreferredDay(res.data.rows)
  //   })
  // }

  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");
  const [search, setSearch] = useState(0);

  useEffect(() => {
    list_api_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sort, skip, search]);

  useEffect(() => {
    filter_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daydata, specialisationdata, submitfilter]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page]);

  const list_api_call = async (clearFilter = false) => {
    const result = await apiCall(`doctor-book-a-demo/list`, "POST",
      {
        // ...filter,
        skip: skip,
        limit: limit,
        // key: "retail_pharmacy",
        sort: sort,
        searchText: search,
        values: clearFilter ? {} : {
          specialization: specialisationdata,
          day: daydata,
        },
      })
    setFilteredRecords([]);
    setRecords(result.data?.rows);
    // setRowCount(result.data.count);
    setPages(Math.ceil(result.data.count / limit));
  };

  const filter_call = async () => {
    const result = await apiCall(`doctor-book-a-demo/list`, "POST",
      {
        values: {
          specialization: specialisationdata,
          day: daydata,
        },
        skip: skip,
        limit: limit,
        sort: sort,
        searchText: search
      })
    setRecords(result.data?.rows);
    setPages(Math.ceil(result.data?.count / limit));
  };

  const handleDoctor = () => {
    navigate("/doctor-registration");
  };
  const handleService = () => {
    navigate("/healthcare-registration");
  };
  const handleRetail = () => {
    navigate("/pharmacy-registration");
  };
  const handleOthers = () => {
    navigate("/other-registration");
  };
  const handleInstaDoc = () => {
    navigate("/insta-doc");
  };

  //Actions

  //Delete Functionality
  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}doctor-book-a-demo/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  //Contact Functionality
  const handleContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-book-a-demo/update/${id}`,
        {
          contact: true,
          notContacted: false,
          onBoard: false,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //NotContact Functionality
  const handleNotContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-book-a-demo/update/${id}`,
        {
          contact: false,
          notContacted: true,
          onBoard: false,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //On-Board Functionality
  const handleOnBoard = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-book-a-demo/update/${id}`,
        {
          contact: false,
          notContacted: false,
          onBoard: true,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    list_api_call()
  }

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  const debouncedSearch = debounce((value) => {
    handlePageChange({ selected: 0 })
    setSearch(value); // Update the state after debounce
  }, 500);

  const onChangeSearch = (value) => {
    debouncedSearch(value); // Trigger the debounced function
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="top-container ps-3 ">
            <div className="row">
              <div className="healtharticle-title ms-0">
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/dashboard"
                  >
                    Dashboard
                  </Link>
                </span>
                <span className="bread-crumb">&#62;</span>
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/doctor-registration"
                  >
                    HealthSynergy
                  </Link>
                </span>
                <span className="bread-crumb1" style={{ paddingLeft: "0.4%" }}>
                  &#62;
                </span>
                <span style={{ paddingLeft: "0.4%" }}>Book a Demo</span>
              </div>
            </div>

            <div className="careers-tab  ">
              <div className="career-tab-career " onClick={handleDoctor}>
                Doctors
              </div>
              <div className="career-tab-jobpost " onClick={handleRetail}>
                Retail Pharmacy
              </div>
              <div className="career-post-department" onClick={handleService}>
                Home Healthcare Service Providers
              </div>
              <div className="career-post-department" onClick={handleInstaDoc}>
                InstaDoc
              </div>
              <div
                className="career-post-position  other"
                onClick={handleOthers}
              >
                Others
              </div>
              <div className="career-post-position pink-line">Book a Demo</div>
            </div>
            <div className="healtharticle-show mt-5 ms-2">
              <div>Show</div>
              <div>
                <select
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  {/* <option value="0">All</option> */}
                </select>
              </div>
              <div>entries</div>
              <div>
                <div className="">
                  <img src={searchImg} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    onChange={(e) => onChangeSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="sortandfilter-new">
                <div className="sortByPosition">
                  <div
                    onClick={() => {
                      setSortShow(!sortshow);
                    }}
                  >
                    <div>
                      <div>
                        {sortName}
                      </div>
                      {/* <select onChange={(e) => setSort(e.target.value)}>
                          <option>Sort by</option>
                          <option value="id-desc">
                            <div style={{ color: "red" }}>Oldest</div>
                          </option>
                          <option value="name-asc">
                            <div>Newest</div>
                          </option>
                          <option value="id-asc">
                            <div>Alphabetical ↑</div>
                          </option>
                          <option value="id-asc">
                            <div>Alphabetical ↓</div>
                            <span>
                              <img src="" alt="" />
                            </span>
                          </option>
                        </select> */}
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="sortByDepartment">
                  <div
                    onClick={() => {
                      setFilterShow(true);
                    }}
                  >
                    <div>
                      <div>
                        Filter
                      </div>
                      {/* <select
                          name=""
                          id=""
                          // onChange={handleSort}
                        >
                          <option value="">Filter</option>
                          <option value="Asc to Des">Asc to Des</option>
                          <option value="Des to Asc">Des to Asc</option>
                        </select> */}
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="SortByposition">
                    <div>
                      <select onChange={(e) => setSort(e.target.value)}>
                        <option>Sort by</option>
                        <option value="id-desc">Latest</option>
                        <option value="name-asc">By Name</option>
                        <option value="id-asc">Oldest</option>
                      </select>
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                  <div className="SortByDepartment">
                    <div>
                      <select
                        name=""
                        id=""
                        // onChange={handleSort}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select>
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div> */}
            </div>
          </div>
          <div className="healtharticle-table doctor-demo-table ms-2">
            <table className="">
              <thead className="t-head ">
                <tr>
                  <td className="align-middle">S.No</td>
                  <td className="align-middle">Name</td>
                  <td className="align-middle">Email</td>
                  <td className="align-middle">Mobile Number</td>
                  <td className="align-middle">Specialisation</td>
                  {/* <td className="align-middle">Preferred Day</td> */}
                  <td className="align-middle">Preferred Contact Time</td>
                  <td className="align-middle">Registration Date and Time</td>
                  <td className="align-middle">Assigned To</td>
                  <td className="align-middle">Lead Status</td>
                  <td className="align-middle">Actions</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0 ? filtered_records : records).map(
                  (x, i) => {
                    if (x.show === undefined || x.show === true)
                      return (
                        <tr key={x._id}>
                          <td>{(current_page - 1) * limit + (i + 1)}</td>
                          <td>{x.name}</td>
                          <td>{x.email}</td>
                          <td>{x.mobile}</td>
                          <td>{x.specialization}</td>
                          {/* <td>{new Date(x.day).toLocaleDateString()}</td> */}
                          {/* <td>{Moment(x.createdAt).format("DD MMMM YYYY")}</td> */}
                          <td>{x.time}</td>
                          <td>
                            {Moment(x.createdAt).format("DD MMMM YYYY LT")}
                          </td>
                          <td>{x.assigned_to ? x.assigned_to : "-"}</td>
                          <td>{x.lead_status ? x.lead_status : "-"}</td>
                          <td>
                            <span
                              className={`contact ${x.contact === true ? "contact-highlight" : ""
                                }`}
                              onClick={() => {
                                handleContact(x._id);
                              }}
                            >
                              Contact
                            </span>
                            <span className="contact-logo">
                              <img src={contact} alt="" />
                            </span>
                            <span
                              className={`onboard ${x.onBoard === true ? "onboard-highlight" : ""
                                }`}
                              onClick={() => {
                                // if (
                                //   x.contact === true &&
                                //   x.notContacted === false
                                // ) {
                                //   handleOnBoard(x._id, x.onBoard);
                                // }
                                handleOnBoard(x._id);
                              }}
                            >
                              On-Board
                            </span>
                            <span className="onboard-logo">
                              <img src={onboard} alt="" />
                            </span>
                            <span
                              className={`notcontact ${x.notContacted === true
                                ? "notcontact-highlight"
                                : ""
                                }`}
                              onClick={() => {
                                handleNotContact(x._id);
                              }}
                            >
                              Not Contacted
                            </span>
                            <span className="notcontacted-logo">
                              <img src={notcontact} alt="" />
                            </span>
                            <span
                              className="newdelete"
                              onClick={() => {
                                setId(x._id);
                                setDel(true);
                              }}
                            >
                              Delete
                            </span>
                            <span className="newdelete-logo">
                              <img src={newdelete} alt="" />
                            </span>
                            <span
                              className="pe-1 primaryColor"
                              onClick={() => setAssignModal({ id: x._id, status: true })}
                            >
                              Assigned To
                            </span>
                            <span className="pe-1">
                              <FaAngleDown className="drop-down-arrow" />
                            </span>

                            <span
                              className="pe-1 primaryColor"
                              onClick={() => setLeadStatusModal({ id: x._id, status: true })}
                            >
                              Lead Status
                            </span>
                            <span className="pe-1">
                              <FaAngleDown className="drop-down-arrow" />
                            </span>
                          </td>
                        </tr>
                      );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            type="button"
            className="page-item page-link"
            value="<<"
            onClick={() => handlePageChange({ selected: 0 })}
            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            type="button"
            value=">>"
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}
            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <FiltersRootModal
          filterOptions={filterDoctorDemoOptions}
          onClickClear={() => {
            list_api_call(true)
            setFilterShow(false);
            setSpecialisationData([]);
            setDayData([]);
          }}
          onClickApply={() => {
            setFilterShow(false);
            filter_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
        />
      ) : null}

      {/*------------ Specialization ------------*/}
      {textcolor === 1 && filtershow === true ? (
        <FilterModal
          title={"Specialisation"}
          data={specialisation.map((data) => data.text)}
          className="filterModelNestedLevel"
          activeFilters={specialisationdata}
          onChange={(e) => {
            if (specialisationdata.includes(e.target.name)) {
              setSpecialisationData(specialisationdata.filter((ele) => ele !== e.target.name))
            } else {
              setSpecialisationData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*-------Preferred Day---------*/}
      {textcolor === 2 && filtershow === true ? (
        <FilterModal
          title={"Preferred Day"}
          data={preferredDay}
          className="filterModelNestedLevel"
          activeFilters={daydata}
          onChange={(e) => {
            if (daydata.includes(e.target.name)) {
              setDayData(daydata.filter((ele) => ele !== e.target.name))
            } else {
              setDayData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*--------Sort By Portion---------*/}

      {sortshow ? (
        <div className="sortby-Common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
          pathName="doctor-demo"
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
        />
      )}

      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
    </>
  );
};

export default DoctorDemo;
