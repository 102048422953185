import apiCall from "../../api";
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";

import BackBtn from "../Common/backBtn";
import AddButton from "../Common/addButton";
import Deletepopup from "../modal/Deletepopup";
import NavBarTitles from "../Common/navTabTitles";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import contact from "../../assets/images/HealthSy/contact.png";
import newdelete from "../../assets/images/HealthSy/delete.png";
import DoctorsFilterOptions from "../Common/doctorsFilterOptions";

const MasterLegalPlatform = () => {

    const navigate = useNavigate();

    const [id, setId] = useState("");
    const [skip, setSkip] = useState(0);
    const [del, setDel] = useState(false);
    const [pages, setPages] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState("newest");
    const [records, setRecords] = useState([]);
    const [sortshow, setSortShow] = useState(false);
    const [pageOffset, setPageOffset] = useState(0);
    const [current_page, setCurrentPage] = useState(1);
    const [sortName, setSortName] = useState("Sort by");
    const [filtershow, setFilterShow] = useState(false);
    const [filtered_records, setFilteredRecords] = useState([]);

    useEffect(() => {
        list_api_call();
    }, []);

    useEffect(() => {
        if (current_page > 1) {
            setSkip((current_page - 1) * limit);
        } else {
            setSkip(0);
        }
    }, [current_page]);

    const handlePlatformName = () => {
        navigate("/add-master-legal-pages")
    }

    const handleEdit = (id) => {
        navigate(`/edit-master-legal-pages/${id}`)
    }

    const handleDelete = async (id) => {
        try {
            const response = await apiCall(`platform-name/delete/${id}`, "DELETE");

            if (response) {
                list_api_call();
                setDel(false);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleActive = async (id, status) => {
        try {
            await apiCall(`platform-name/update/active/${id}`, "PATCH", {
                active: !status,
            });

            list_api_call();
        } catch (err) {
            console.error(err);
        }
    };

    const list_api_call = async () => {
        try {
            const response = await apiCall(`platform-name/list`, "POST",
                {
                    ...filter,
                    skip: skip,
                    limit: limit,
                    sort: sort,
                })
            setPages(Math.ceil(response.data.totalCount / Number(limit)))
            setRecords(response?.data?.rows);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <BackBtn
                navigatePath={`/app-download-link/user-app`}
            />
            <div className="healtharticle">
                <div className="healtharticle-container">

                    <div className="healtharticle-title carrer-job-title ms-4">
                        <Breadcrumbs
                            breadcrumbText="Master"
                            breadcrumbLink="#"
                            nestedText="Common"
                            nestedLink=""
                            nestedText2="Legal Pages"
                            nestedLink2=""
                        />
                    </div>

                    <div className="healtharticle-addnew">
                        <AddButton
                            onClick={handlePlatformName}
                            btnName="Add New PlatForm Name"
                        />
                    </div>
                    <NavBarTitles
                        titles={
                            [
                                {
                                    name: "Platform Name",
                                    navigatePath: "/master-legal-pages",
                                    className: "career-tab-jobpost career-gender"
                                },
                                {
                                    name: "Page Type",
                                    navigatePath: "/master-legal-page-type",
                                },
                                {
                                    name: "Drafted By",
                                    navigatePath: "/master-legal-drafted-by",
                                }
                            ]
                        }
                    />

                    <DoctorsFilterOptions
                        limit={limit}
                        setLimit={setLimit}
                        sortshow={sortshow}
                        sortName={sortName}
                        setSortShow={setSortShow}
                        setFilterShow={setFilterShow}
                    />

                    <div className="healtharticle-table master-doctor-table">
                        <table>
                            <thead>
                                <tr>
                                    <td className="career-jobpost-td1">S.No</td>
                                    <td className="career-jobpost-td2">Platform Name</td>
                                    <td className="career-jobpost-td3">Status</td>
                                    <td className="career-jobpost-td12">Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filtered_records.length > 0
                                    ? filtered_records
                                    : records
                                ).map((el, i) => {
                                    return (
                                        <tr key={el._id}>
                                            <td>{(current_page - 1) * limit + (i + 1)}</td>
                                            <td>{el.platform_name}</td>
                                            <td>{el.active === true ? "Verified" : "Not Verified"}</td>
                                            <td>
                                                <span className="master-edit" onClick={() => { handleEdit(el._id) }}>Edit</span>
                                                <span className="master-edit-logo m-2">
                                                    <img src={contact} alt="" />
                                                </span>
                                                <span
                                                    className="master-delete p-2"
                                                    onClick={() => {
                                                        handleActive(el._id, el.active);
                                                    }}
                                                >
                                                    {el.active ? "Inactive" : "Active"}
                                                </span>
                                                <span className="master-delete-icon">
                                                    <img src={newdelete} alt="" />
                                                </span>
                                                <span className="master-delete" onClick={() => {
                                                    setId(el._id)
                                                    setDel(true);
                                                }}>Delete</span>
                                                <span className="master-delete-icon px-2">
                                                    <img src={newdelete} alt="" />
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
                <button
                    className="page-link"
                    disabled={skip === 0}
                >
                    <AiOutlineBackward size={20} />
                </button>
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination my-0"
                    activeClassName="active"
                    forcePage={pageOffset}
                />
                <button
                    className="page-link"
                    disabled={(skip / limit) === (pages - 1)}
                >
                    <AiOutlineForward size={20} />
                </button>
            </div>
            {
                del === true
                &&
                <Deletepopup
                    handleDelete={handleDelete}
                    id={id}
                />
            }
        </>
    )
}

export default MasterLegalPlatform