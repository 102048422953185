
const Pagination = ({
    setCurrentPage = () => {},
    current_page,
    pages
}) => {

    return (
        <div className="col d-flex pagination justify-content-end mt-5 admin-pagination">
                <input
                    type="button"
                    className="page-item page-link"
                    value="<<"
                    onClick={(e) => setCurrentPage(1)}
                    disabled={current_page === 1}
                />
                <input
                    type="button"
                    className="page-item page-link"
                    value="<"
                    onClick={(e) => setCurrentPage(current_page - 1)}
                    disabled={current_page === 1}
                />

                {current_page > 1 && (
                    <input
                        type="button"
                        value={current_page - 1}
                        onClick={(e) => {
                            setCurrentPage(current_page - 1);
                        }}
                    />
                )}
                <input
                    className="page-item page-link"
                    type="button"
                    value={current_page}
                />
                {current_page < pages && (
                    <input
                        type="button"
                        value={current_page + 1}
                        onClick={(e) => {
                            setCurrentPage(current_page + 1);
                        }}
                    />
                )}
                <input
                    type="button"
                    value=">"
                    className="page-item page-link"
                    onClick={(e) => setCurrentPage(current_page + 1)}
                    disabled={current_page === pages}
                />
                <input
                    type="button"
                    value=">>"
                    className="page-link"
                    onClick={(e) => setCurrentPage(pages)}
                    disabled={current_page === pages}
                />
            </div>
    )
}

export default Pagination