import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router";

import { slugUrl } from "../../constants";
import SucessModal from "../modal/SucessModal";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { useEffect } from "react";
import { convertToTitleCase } from "../../constants"

const AddFrpCity = ({ edit = false }) => {
    const [success, setSuccess] = useState(false);
    const [checked, setChecked] = useState(true);

    const { id } = useParams();
    const navigate = useNavigate()
    const pathName = useLocation();
    const statePath = pathName.pathname.split('/')[2];

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (statePath) {
            setValue("state", convertToTitleCase(statePath))
        }
    }, [statePath])

    useEffect(() => {
        edit && getState()
    }, [])

    const getState = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}frp-states/${statePath}/${id}/get-city`)
            .then((response) => {
                setValue("city", response?.data?.city);
                setChecked(response?.data?.active || false);
            })
    }

    const onSubmit = (fields) => {
        fields.slug_url = slugUrl(fields.city)
        {
            edit ? axios
                .post(`${process.env.REACT_APP_API_URL}frp-states/${statePath}/${id}/edit-city`, fields)
                .then((response) => {
                    if (response.status === 200) {
                        setSuccess(true);
                    } else {
                        alert("Failed to edit city. Please try again");
                        console.log("failed...");
                    }
                }) :
                axios
                    .post(`${process.env.REACT_APP_API_URL}frp-states/create-city/${statePath}`, fields)
                    .then((response) => {
                        if (response.status === 200) {
                            setSuccess(true);
                        } else {
                            alert("Failed to add city. Please try again");
                            console.log("failed...");
                        }
                    });
        }
    }

    return (
        <>
            <div className="healtharticle mt-5 pt-3 ps-2">
                <Breadcrumbs
                    breadcrumbText="FRP"
                    breadcrumbLink="/frp-states"
                    nestedText="View Cities"
                    nestedLink={`/frp-states/${statePath}/view-cities`}
                    nestedText2={edit ? "Edit City" : "Add New City"}
                    nestedLink2="#"
                />
                <form className="carr-dep-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row car-dep-form">
                        <div className="col-6">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                Select State <span className="star">*</span>
                            </label>
                            <select
                                className="form-control w-75"
                                {...register("state")}
                                defaultValue={statePath ? statePath : ""}
                                disabled={statePath}
                            >
                                <option value={convertToTitleCase(statePath)}>{convertToTitleCase(statePath)}</option>
                            </select>
                        </div>

                        <div className="col-6">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                City <span className="star">*</span>
                            </label>
                            <input
                                className="form-control w-75"
                                type="text"
                                name=""
                                id="type"
                                placeholder="Enter city"
                                {...register("city", { required: true })}
                            />
                            {errors.city && (
                                <div className={`invalid-feedback d-block`}>
                                    Please Enter city
                                </div>
                            )}
                        </div>
                        <div className="col-6 mt-5">
                            <label
                                className="car-dep-form-txt mb-2"
                                style={{ color: "#4B4853" }}
                                htmlFor=""
                            >
                                Active <span className="star">*</span>
                            </label>
                            <div className="d-flex">
                                <div className="form-check form-check-inline d-flex align-items-center me-3 mt-1">
                                    <input
                                        className="form-check-input me-3"
                                        type="radio"
                                        name="status"
                                        id="status"
                                        value={true}
                                        checked={checked}
                                        onClick={() => setChecked(true)}
                                        {...register("active", { required: true })}
                                    />
                                    <label htmlFor="option-1" className="text-dark ">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center mt-1">
                                    <input
                                        className="form-check-input me-3"
                                        type="radio"
                                        name="status"
                                        id="status"
                                        value={false}
                                        checked={!checked}
                                        onClick={() => setChecked(false)}
                                        {...register("active", { required: true })}
                                    />
                                    <label htmlFor="option-2" className="text-dark">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="car-dep-form-button m-0 pt-5">
                        <button type="submit">SUBMIT</button>
                    </div>
                </form>
            </div>
            {success && (
                <SucessModal
                    page={`/frp-states/${statePath}/view-cities`}
                    name="City"
                    post={edit ? 'updated' : 'added'}
                    onClick={() => navigate(`/frp-states/${statePath}/view-cities`)}
                />
            )}
        </>
    );
};

export default AddFrpCity;