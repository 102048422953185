import { useLocation, useNavigate } from "react-router";

const titles = [
    {
        name: "Gender",
        navigatePath: "/doctors-gender",
    },
    {
        name: "Experience",
        navigatePath: "/doctors-experience",
    },
    {
        name: "Languages Known",
        navigatePath: "/doctors-language",
    },
    {
        name: "Medical Council",
        navigatePath: "/doctors-medical-council",
    },
    {
        name: "Clinic Speciality",
        navigatePath: "/doctors-clinic-speciality",
    },
    {
        name: "Online Specialization",
        navigatePath: "/online-specialization"
    },
    {
        name: "Inclinic Specialization",
        navigatePath: "/inclinic-specializations"
    }
]

const DoctorCommonNavTitles = ({
}) => {
    const pathName = useLocation();
    const navigate = useNavigate()

    return (
        <div className="careers-tab" style={{ marginLeft: "2%" }}>
            {titles.map((value, inx) => (
                <div
                    key={inx}
                    className={`${pathName?.pathname === value.navigatePath ? "career-language" : ""} ${inx === 0 ? "" : "ms-5"} career-tab-jobpost  me-4`}
                    onClick={() => navigate(value?.navigatePath)}
                >{value?.name}
                </div>
            ))}
        </div>
    )
}

export default DoctorCommonNavTitles