import contact from "../../assets/images/HealthSy/contact.png";
import newdelete from "../../assets/images/HealthSy/delete.png";
import inactive from "../../assets/svg/inactive.svg";

const Actions = ({
  isEdit = true,
  isDelete = true,
  isActive = true,
  isView = true,
  onClickEdit = () => { },
  onClickDelete = () => { },
  onClickActive = () => { },
  onClickView = () => { },
}) => {
  return (
    <>
      {isEdit ? <>
        <span className="master-edit" onClick={onClickEdit}>Edit</span>
        <span className="contact-logo">
          <img src={contact} alt="" />
        </span>
      </> : null}
      {isDelete ? <>
        <span className="master-delete" onClick={onClickDelete}>Delete</span>
        <span className="newdelete-logo">
          <img src={newdelete} alt="" />
        </span>
      </> : null}
      {isActive ? <><span
        className="inactive-health ps-5"
        onClick={onClickActive}
      >
        {/* {el.active ? "Inactive" : "Active"} */}
      </span>
        <span className="inactive-icon">
          <img src={inactive} alt="" />
        </span>
        <span className="master-delete-icon"></span>
      </> : null}
      {isView ? <>
        <span className="master-view" onClick={onClickView}>View</span>
        <span className="master-view-icon"></span>
      </> : null}
    </>
  )
}

export default Actions