import { useEffect, useState, memo } from "react";
import { useNavigate } from "react-router";
import axios from "axios";

import buttonadd from "../../assets/images/plus.png";
import newdelete from "../../assets/images/HealthSy/delete.png";
import editLogo from "../../assets/images/HealthSy/contact.png";
import onboard from "../../assets/images/HealthSy/onboard.png";
import Deletepopup from "../modal/Deletepopup";

const ViewContent = ({
    service = false,
    serviceList = [],
    serviceCategory = false,
    healthCondition = false,
    city = false,
    btnText = "",
    btnNavigation = "#",
    serviceName = null,
    serviceCategoryName = null
}) => {

    const [serviceCategoryList, setServiceCategoryList] = useState([])
    const [healthConditionList, sethealthConditionList] = useState([])
    const [citiesList, setCitiesList] = useState([])
    const [deleteModal, setDeleteModal] = useState({ id: "", status: false });

    const navigate = useNavigate();

    useEffect(() => {
        if (serviceName) {
            list_api_call()
        }
    }, [serviceName])

    const list_api_call = () => {
        if (serviceCategory) {
            axios
                .get(`${process.env.REACT_APP_API_URL}services/service-categories/${serviceName}`)
                .then((response) => {
                    setServiceCategoryList(response?.data);
                });
        } else if (healthCondition) {
            axios
                .get(`${process.env.REACT_APP_API_URL}services/health-conditions/${serviceName}/${serviceCategoryName}`)
                .then((response) => {
                    sethealthConditionList(response?.data);
                });
        } else if (city) {
            axios
                .get(`${process.env.REACT_APP_API_URL}services/cities/${serviceName}/${serviceCategoryName}`)
                .then((response) => {
                    setCitiesList(response?.data);
                });
        }
    }

    const deleteServiceCategory = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}services/${serviceName}/delete-service-category/${id}`)
            .then(() => {
                list_api_call()
                setDeleteModal({ id: "", status: false });
            });
    }

    const deleteHealthCondition = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}services/${serviceName}/${serviceCategoryName}/${id}/delete-health-condition`)
            .then(() => {
                list_api_call()
                setDeleteModal({ id: "", status: false });
            });
    }

    const deleteCity = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}services/${serviceName}/${serviceCategoryName}/${id}/delete-city`)
            .then(() => {
                list_api_call()
                setDeleteModal({ id: "", status: false });
            });
    }

    const handleClose = () => {
        setDeleteModal({ id: "", status: false });
    };

    return (
        <>
            <div className="viewContentSection">
                {!service && <div>
                    <div className="ms-2">
                        <div className="addBtn">
                            <div>
                                <img src={buttonadd} alt="" />
                            </div>
                            <div>
                                <button onClick={() => navigate(btnNavigation)}>{btnText}</button>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="webBookingsTable ms-3 mt-5">
                    <table className="w-100">
                        <thead>
                            <tr>
                                <td className="">S.No</td>
                                {service &&
                                    <>
                                        <td className="">Service Name</td>
                                        <td className="">Specialisations / Service Categories</td>
                                    </>
                                }
                                {
                                    serviceCategory &&
                                    <>
                                        <td className="">Service Category</td>
                                        <td className="">Health Conditions</td>
                                        <td className="">Cities</td>
                                    </>
                                }
                                {
                                    healthCondition && <td>Health Condition</td>
                                }
                                {
                                    city && <td>City Name</td>
                                }
                                <td className="">Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {service && serviceList.map((data, i) => {
                                return (
                                    <>
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{data.name}</td>
                                            <td>{data.categories.length}</td>
                                            <td>
                                                <span
                                                    className="text-success newdelete"
                                                    onClick={() => navigate(`/web-bookings/${data.slug_url}/view-service-categories`)}
                                                >
                                                    View Service Category
                                                </span>
                                                <span className="newdelete-logo">
                                                    <img src={onboard} alt="" />
                                                </span>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                            {serviceCategory && serviceCategoryList?.categories?.map((el, i) => {
                                return (
                                    <>
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{el.service_category}</td>
                                            <td>{el.healthConditions.map((data, inx) => (<span key={inx}>{inx > 0 && ', '}{data.health_condition}</span>))}</td>
                                            <td>{el.cities.map((data, inx) => (<span key={inx}>{inx > 0 && ', '}{data.city}</span>))}</td>
                                            <td>
                                                <span
                                                    className={`contact `}
                                                    onClick={() => {
                                                        navigate(
                                                            `/web-bookings/${serviceCategoryList.slug_url}/edit-service-category/${el.slug_url}`
                                                        );
                                                    }}
                                                >
                                                    Edit
                                                </span>
                                                <span className="onboard-logo">
                                                    <img src={editLogo} alt="" />
                                                </span>
                                                <span
                                                    className="newdelete"
                                                    onClick={() => { setDeleteModal({ id: el._id, status: true }) }}
                                                >
                                                    Delete
                                                </span>
                                                <span className="newdelete-logo">
                                                    <img src={newdelete} alt="" />
                                                </span>
                                                <span
                                                    className="newdelete text-success"
                                                    onClick={() => navigate(`/web-bookings/${serviceCategoryList.slug_url}/${el.slug_url}/view-health-conditions`)}
                                                >
                                                    View Health Condition
                                                </span>
                                                <span className="newdelete-logo">
                                                    <img src={onboard} alt="" />
                                                </span>
                                                <span
                                                    className="newdelete text-success"
                                                    onClick={() => navigate(`/web-bookings/${serviceCategoryList.slug_url}/${el.slug_url}/view-cities`)}
                                                >
                                                    View Cities
                                                </span>
                                                <span className="newdelete-logo">
                                                    <img src={onboard} alt="" />
                                                </span>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                            {
                                healthCondition && healthConditionList.map((el, i) => {
                                    return (
                                        <>
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{el.health_condition}</td>
                                                <td>
                                                    <span
                                                        className={`contact `}
                                                        onClick={() => {
                                                            navigate(
                                                                `/web-bookings/${serviceName}/${serviceCategoryName}/${el.slug_url}/edit-health-condition`
                                                            );
                                                        }}
                                                    >
                                                        Edit
                                                    </span>
                                                    <span className="onboard-logo">
                                                        <img src={editLogo} alt="" />
                                                    </span>
                                                    <span
                                                        className="newdelete"
                                                        onClick={() => { setDeleteModal({ id: el._id, status: true }) }}
                                                    >
                                                        Delete
                                                    </span>
                                                    <span className="newdelete-logo">
                                                        <img src={newdelete} alt="" />
                                                    </span>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                            {
                                city && citiesList.map((el, i) => {
                                    return (
                                        <>
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{el.city}</td>
                                                <td>
                                                    <span
                                                        className={`contact `}
                                                        onClick={() => {
                                                            navigate(
                                                                `/web-bookings/${serviceName}/${serviceCategoryName}/${el.slug_url}/edit-city`
                                                            );
                                                        }}
                                                    >
                                                        Edit
                                                    </span>
                                                    <span className="onboard-logo">
                                                        <img src={editLogo} alt="" />
                                                    </span>
                                                    <span
                                                        className="newdelete"
                                                        onClick={() => { setDeleteModal({ id: el._id, status: true }) }}
                                                    >
                                                        Delete
                                                    </span>
                                                    <span className="newdelete-logo">
                                                        <img src={newdelete} alt="" />
                                                    </span>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {deleteModal.status === true && (
                <Deletepopup
                    handleDelete={serviceCategory ? deleteServiceCategory : healthCondition ? deleteHealthCondition : deleteCity}
                    id={deleteModal?.id}
                    isCloseModalEnable={true}
                    handleClose={handleClose}
                />
            )}
        </>
    )
}

export default memo(ViewContent);