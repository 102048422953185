import React from 'react';
import jwt_decode from 'jwt-decode';

import './App.scss';
import Login from './components/Account/Login';
import AllRoutes from './components/AllRoutes/AllRoutes';

function App() {
  const token = localStorage.getItem('authToken');

  if (token) {
    try {
      const decodedToken = jwt_decode(token);

      // Check if the token is not expired
      if (decodedToken.exp * 1000 > Date.now()) {
        return (
          <div className="App">
            <AllRoutes />
          </div>
        );
      } else {
        // Token has expired, remove it from localStorage
        localStorage.removeItem('authToken');
      }
    } catch (error) {
      // Token decoding failed, remove it from localStorage
      console.error('Token decoding failed:', error);
    }
  }

  // If token is missing or expired, show the Login component
  return (
    <div className="App">
      <Login />
    </div>
  );
}

export default App;