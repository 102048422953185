

import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../Common/backBtn";
import InputRadioField from "../../../Common/inputRadioField";
import InputTextField from "../../../Common/inputTextField";
import SucessModal from "../../../modal/SucessModal";

const AddNewHealthsyStories = ({
    edit = false
}) => {
    const { id } = useParams()

    const {
        register,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
        trigger
    } = useForm();

    const [success, setSuccess] = useState(false);

    const getMenuData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}healthsy-stories/get/${id}`).then(response => {
            setValue('active', response.data.active);
            setValue('video_title', response.data.video_title);
            setValue('video_url', response.data.video_url);
            trigger()
        }).catch(err => {
            console.error(err);
            alert("Some error");
        })
    }

    useEffect(() => {
        edit && getMenuData();
    }, [edit]);

    const onSubmit = (fields) => {
        if (edit) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}healthsy-stories/update/${id}`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });

        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}healthsy-stories/create`,
                    fields
                )
                .then((response) => {
                    console.log("response...");

                    if (response?.data?._id) {
                        setSuccess(true);
                    } else {
                        console.log("failed...");
                        alert("Fail");
                    }
                });
        }
    };

    return (
        <>
            <BackBtn
                navigatePath="/healthsystories"
            />
            <div className="healtharticle mt-5 pt-5 ps-5">
                <div className="pt-3 ms-3">
                    <Breadcrumbs
                        breadcrumbText="Blogs"
                        breadcrumbLink="/healthsystories"
                        nestedText="Add New Story"
                        nestedLink="#"
                    />
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="carr-dep-form"
                >
                    <div className="row car-dep-form">
                        <div className="col-12">
                            <InputTextField
                                register={register}
                                errors={errors}
                                labelName="Video Title"
                                errorText="Please enter the Video Title"
                                name="video_title"
                                // placeholder="Type here..."
                            />
                        </div>
                    </div>
                    <div className="row car-dep-form">
                        <div className="col-6">
                        <InputTextField
                                register={register}
                                errors={errors}
                                labelName="Video Url"
                                errorText="Please enter the Video Url"
                                name="video_url"
                                // placeholder="Type here..."
                            />
                        </div>
                        <div className="col-6">
                            <InputRadioField
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                watch={watch}
                            />
                        </div>
                    </div>

                    <div className="car-dep-form-button">
                        <button type="submit" style={{ marginTop: "5%" }}>
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {/* </Home> */}
            {success && (
                <SucessModal
                    page={"/healthsystories"}
                    name="new healthsy stories"
                    post={edit ? 'updated' : 'added'}
                />
            )}
        </>
    )
}

export default AddNewHealthsyStories