import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineForward, AiOutlineBackward } from "react-icons/ai";

import newdelete from "../../../assets/images/HealthSy/delete.png";
import notcontact from "../../../assets/images/HealthSy/notcontacted.png";
import search from "../../../assets/images/searchIcon.png";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import AddButton from "../../Common/addButton";
import Deletepopup from "../../modal/Deletepopup";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DoctorsFilterOptions from "../../Common/doctorsFilterOptions";
import { establishTypes, filterClinicProfileOptions } from "../../../constants";
import apiCall from "../../../api";
import FiltersRootModal, { FilterSubModal } from "../../Common/filtersRootModals";
import FilterStatusModal from "../../Common/filterStatusModal";

const Timings = ({ data: timings }) => {
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const formattedHours = {};

  daysOfWeek.forEach(day => {
    if (timings[`${day}Closed`]) {
      formattedHours[day] = `Closed`;
    } else {
      const startTime = timings[`${day}_start_timing`]
      const endTime = timings[`${day}_end_timing`];
      let formattedStartTime = ""
      let formattedEndTime = ""
      if (startTime === "Invalid date") {
      } else if (startTime?.length <= 8) {
        formattedStartTime = startTime
      } else {
        formattedStartTime = moment(startTime).format("h:mm A");
      }
      if (endTime === "Invalid date") {
      } else if (endTime?.length <= 8) {
        formattedEndTime = endTime
      } else {
        formattedEndTime = moment(endTime).format("h:mm A");
      }
      formattedHours[day] = `${formattedStartTime} to ${formattedEndTime}`;
    }
  });

  return <ul>
    {Object.keys(formattedHours).map((data, inx) => {
      return <li key={inx}>{data} : {formattedHours[data]}</li>
    })}
  </ul>
}

const DoctorsClinicProfile = () => {

  const navigate = useNavigate();

  const [del, setDel] = useState(false);
  const [id, setId] = useState("");
  const [pages, setPages] = useState(0);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [records, setRecords] = useState([]);
  const [sortshow, setSortShow] = useState(false);
  const [filtershow, setFilterShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const [success, setSuccess] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [isClearClicked, setIsClearClicked] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filterOptions, setFilterOptions] = useState({
    clinicSplList: [],
    establishType: [],
    cityData: [],
  });
  const [filteredData, setFilteredData] = useState({
    clinicSplList: [],
    establishType: [],
    cityData: []
  });
  const [searchClinicSpeciality, setSearchClinicSpeciality] = useState([]);
  const [textcolor, setTextColor] = useState(0);
  const [selectedSort, setSelectedSort] = useState(null);
  const [searchCityQuery, setSearchCityQuery] = useState([]);
  const [searchcityList, setSearchCityList] = useState([]);
  const [debounceTimer, setDebounceTimer] = useState(null);


  const [filter, setFilter] = useState({});

  const list_api_call = () => {
    let filterOptions = {};

    if (filteredData?.clinicSplList?.length) {
      filterOptions["clinicSplList"] = filteredData?.clinicSplList
    }

    if (filteredData?.cityData.length) {
      filterOptions["cityData"] = filteredData?.cityData
    }

    if (filteredData?.establishType?.length === 1) {
      filterOptions["establishType"] = filteredData?.establishType[0];
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}clinic-profiles/list`, {
        filterOptions: filterOptions,
        skip: skip,
        limit: limit,
        sort: sort,
        populateDoctorsName: true,
        searchText
      })
      .then((response) => {
        setRecords(response.data.rows);
        setPages(Math.ceil(response.data.totalCount / Number(limit)))
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filtersDataList = async () => {
    try {
      const response = await apiCall('clinic-profiles/filter-options', 'POST', {});

      let tempFilterOptions = {};

      if (response?.data) {
        if (response.data?.clinicSpecialtyList?.length) {
          tempFilterOptions["clinicSplList"] = response.data?.clinicSpecialtyList;
        }
        if (response.data?.cityList?.length) {
          tempFilterOptions["cityData"] = response.data?.cityList;
        }
      }
      setFilterOptions((prev) => ({ ...prev, ...tempFilterOptions }));
    } catch (err) {
      console.error(err);
      alert("Some error occurred while fetching filter options");
    }
  };

  useEffect(() => {
    // list_api_call();
    filtersDataList()
  }, []);


  useEffect(() => {
    list_api_call();
  }, [skip, limit, sort, searchText]);

  useEffect(() => {
    if (isClearClicked) {
      list_api_call();
      setIsClearClicked(false)
    }
  }, [isClearClicked]);

  const handleSpecialisationForm = () => {
    navigate("/doctor-clinic-profile-create");
  };

  const handleEdit = (id) => {
    navigate(`/clinic-profile-edit/${id}`);
  };

  const handleDelete = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}doctors-profile/delete/${id}`,
      {
        method: "DELETE",
      }
    ).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  const onChangeSearch = (e) => {
    const value = e.target.value;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(() => {
      setSearchText(value);
    }, 200);

    setDebounceTimer(timer);
  }

  const successModalClose = () => {
    setSuccess(false)
    list_api_call()
  }

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };


  const handleSortClick = (sortOption, colorCode) => {
    setSelectedSort(sortOption);
    setTextColor(colorCode);
  };

  const handleApplyClick = () => {
    if (selectedSort) {
      setSort(selectedSort);
      setSortShow(false);
    }
  };

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value)
    if (value?.length) {
      setSearchClinicSpeciality(filterOptions?.clinicSplList.filter(data => data?.name?.toLowerCase()?.includes(value.toLowerCase())))
    } else {
      setSearchClinicSpeciality([])
    }
  }
  const handleCitySearchChange = (value) => {
    setSearchCityQuery(value)
    if (value?.length) {
      setSearchCityList(filterOptions?.cityData.filter(data => data?.name?.toLowerCase()?.includes(value.toLowerCase())))
    } else {
      setSearchCityList([])
    }
  }

  const handleCheckboxChange = (type, _id) => {
    if (type === 'spl') {
      if (filteredData.clinicSplList.includes(_id)) {

        setFilteredData((pre) => ({ ...pre, clinicSplList: pre.clinicSplList.filter((data) => data !== _id) }))

      } else {
        setFilteredData((pre) => ({ ...pre, clinicSplList: [...pre.clinicSplList, _id] }))
      }
    }
  };

  const handleCityCheckboxChange = (name, _id) => {
    if (name === 'city') {
      if (filteredData.cityData.includes(_id)) {

        setFilteredData((pre) => ({ ...pre, cityData: pre.cityData.filter((data) => data !== _id) }))

      } else {
        setFilteredData((pre) => ({ ...pre, cityData: [...pre.cityData, _id] }))
      }
    }
  }

  const handleStatusCheckboxChange = (type, status) => {
    if (type === 'status') {
      if (filteredData.establishType.includes(status)) {
        setFilteredData((prev) => ({
          ...prev,
          establishType: prev.establishType.filter((val) => val !== status),
        }));
      } else {
        setFilteredData((prev) => ({
          ...prev,
          establishType: [...prev.establishType, status],
        }));
      }
    }
  };

  const handleActive = (id, status) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctors-profile/update/active/${id}`,
        {
          active: !status,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  const getsrcValue = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "clinic-profile/" + value;
  };

  const getsrcValue1 = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "clinic-profile/" + value;
  };

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="pt-3 ms-3">
            <Breadcrumbs
              breadcrumbText="Doctors"
              breadcrumbLink="/add-new-doctors"
              nestedText="Clinic Profiles"
              nestedLink=""
            // nestedText2={(edit ? "Edit" : "Add New") + " " + "Language Known"}
            />
          </div>
          <div className="healtharticle-addnew">
            <AddButton
              onClick={handleSpecialisationForm}
              btnName="Add New Clinic / Hosiptal Profile"
            />
          </div>
          <DoctorsFilterOptions
            limit={limit}
            sortshow={sortshow}
            sortName={sortName}
            search={search}
            setFilterShow={setFilterShow}
            setSortShow={setSortShow}
            onChange={onChangeSearch}
            setLimit={setLimit}
          />
          <div className="healtharticle-table add-new-doctors-table clinicProfileTable">
            {/* healtharticle-table master-doctor-table */}
            <table>
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Name of Clinic / Hosiptal</td>
                  <td>Clinic Speciality</td>
                  <td>Establishment Type</td>
                  <td>Concerned Doctor's Name</td>
                  <td>City</td>
                  <td style={{ width: "200px" }}>Location of Clinic / Hospital</td>
                  {/* <td>City</td> */}
                  <td style={{ width: "120px" }}>Pincode of Clinic / Hospital</td>
                  <td>Clinic / Hospital Photos</td>
                  <td style={{ width: "120px" }}>Status</td>
                  <td>License / Registration Number</td>
                  <td>Logo of Clinic / Hospital</td>
                  <td style={{ width: "370px" }}>Timings</td>
                  <td style={{ width: "200px" }}>Google Map Location Mark</td>
                  {/* <td style={{ width: "150px" }} >Meta</td> */}
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {records
                  .map((el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{skip + (i + 1)}</td>
                        <td className="doctorClinicProfile">{el.clinic_name}</td>
                        <td>{el.clinic_specialty_status?.clinic_speciality}</td>
                        <td>{el.organisationType}</td>
                        <td>{el.doctors?.length ?
                          <ul>{el.doctors.map((data, acc) => <li key={data?.inx}>{data?.doctor_name}</li>)}
                          </ul> : null}</td>
                        <td>{el.city?.city}</td>
                        <td>{el.clinic_location?.locality}</td>
                        <td>{el.pincode}</td>
                        <td>
                          {el.photos.map((data, key) => {
                            return (<img
                              src={getsrcValue(data)}
                              key={key}
                              className="doctorProfileImage"
                              alt="image showing"
                            />)

                          })}
                        </td>
                        <td>{el.active === true ? "Verified" : "Not Verified"}</td>
                        <td>{el.license_no}</td>
                        <td>
                          <img
                            src={getsrcValue1(el.logo)}
                            className="doctorProfileImage"
                            alt="logo"
                          />
                        </td>
                        <td>
                          <Timings data={el?.timings} />
                        </td>
                        <td>{el.map_location}</td>
                        {/* <td>
                        <span
                          className="master-edit primaryColor"
                          onClick={() => {
                            handleMetaTags();
                          }}
                        >
                          Add Meta Set
                        </span>
                      </td> */}
                        <td>
                          <span
                            className="master-edit"
                            onClick={() => {
                              handleEdit(el._id);
                            }}
                          >
                            Edit
                          </span>
                          <span className="master-edit-logo"></span>
                          <span
                            className="master-delete"
                            onClick={() => {
                              setId(el._id);
                              setDel(true);
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="" />
                          </span>
                          <span
                            className="inactive-health"
                            onClick={() => {
                              handleActive(el._id, el.active);
                            }}
                          >
                            {el.active ? "Inactive" : "Active"}
                          </span>
                          <span className="notcontacted-logo">
                            <img src={notcontact} alt="" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
        <button
          className="page-link"
          onClick={() => handlePageChange({ selected: 0 })}

          disabled={skip === 0}
        >
          <AiOutlineBackward size={20} />
        </button>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination my-0"
          activeClassName="active"
          forcePage={pageOffset}
        />
        <button
          className="page-link"
          onClick={() => handlePageChange({ selected: pages - 1 })}

          disabled={(skip / limit) === (pages - 1)}
        >
          <AiOutlineForward size={20} />
        </button>
      </div>
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}


      {sortshow ? (
        <div className="sortby-doctors-records">
          <div className="d-flex justify-content-between">
            <div className="pb-4 mb-1">Latest</div>
            <div className="checkbox-wrapper sort-by-checkbox-wrapper">
              <input
                type="checkbox"
                id="latest"
                className="cursor-pointer"
                checked={selectedSort === 'newest'}
                onChange={() => handleSortClick('newest', 'Newest', 11)}
                style={{
                  color: textcolor === 11 ? '#CB1B5B' : '',
                  fontWeight: textcolor === 11 ? 'bold' : '',
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Oldest</div>
            <div className="checkbox-wrapper sort-by-checkbox-wrapper">
              <input
                type="checkbox"
                id="oldest"
                className="cursor-pointer"
                checked={selectedSort === 'oldest'}
                onChange={() => handleSortClick('oldest', 'Oldest', 12)}
                style={{
                  color: textcolor === 12 ? '#CB1B5B' : '',
                  fontWeight: textcolor === 12 ? 'bold' : '',
                }}
              />
            </div>
          </div>
          <div>
            <button className="sortByBtn" onClick={handleApplyClick}>
              Apply
            </button>
          </div>
        </div>
      ) : null}
      {filtershow ? (
        <FiltersRootModal
          filterOptions={filterClinicProfileOptions}
          onClickClear={() => {
            setFilterShow(false);
            setFilteredData({
              clinicSplList: [],
              establishType: [],
              cityData: []
            })
            setIsClearClicked(true)
          }}
          onClickApply={() => {            
            setFilterShow(false);
            setSkip(0);
            setPageOffset(0)
            list_api_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
          className="filter-container-partnered-doctor"
        />
      ) : null}

      {
        textcolor === 1 && filtershow === true
          ?
          <FilterSubModal
            name="Specialist"
            onSearchChange={handleSearchChange}
            searchQuery={searchQuery}
            optionList={searchQuery?.length ? searchClinicSpeciality : filterOptions?.clinicSplList}
            selectedOptions={filteredData?.clinicSplList}
            handleChange={handleCheckboxChange}
            keyName={"spl"}
            isSearchable={true}
            dataName="name"
          />
          : null
      }
      {
        textcolor === 2 && filtershow === true
          ?
          <FilterSubModal
            name="City"
            onSearchChange={handleCitySearchChange}
            searchQuery={searchCityQuery}
            optionList={searchCityQuery?.length ? searchcityList : filterOptions?.cityData}
            selectedOptions={filteredData?.cityData}
            handleChange={handleCityCheckboxChange}
            keyName={"city"}
            isSearchable={true}
            dataName="name"
          />
          : null
      }
      {
        textcolor === 3 && filtershow === true
          ?
          <FilterStatusModal
            title={"Establishment Type"}
            data={establishTypes}
            className="filter-doctor-fee-partnered-doctor"
            activeFilters={filteredData.establishType}
            onChange={(e) => handleStatusCheckboxChange("status", e.target.name)}
          />
          : null
      }
    </>
  );
}

export default DoctorsClinicProfile