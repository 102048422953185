import axios from "axios";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import BackBtn from "../../Common/backBtn";
import { useEffect, useState } from "react";
import SucessModal from "../../modal/SucessModal";
import InputTextField from "../../Common/inputTextField";
import InputRadioField from "../../Common/inputRadioField";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";

const AddCompanyReels = ({
    edit = false
}) => {
    const { id } = useParams()

    const {
        register,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
        trigger
    } = useForm();

    const [success, setSuccess] = useState(false);

    const getMenuData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}company-reels/get/${id}`).then(response => {
            setValue('active', response.data.active);
            setValue('reel_title', response.data.reel_title);
            setValue('reel_url', response.data.reel_url);
            trigger()
        }).catch(err => {
            console.error(err);
            alert("Some error");
        })
    }

    useEffect(() => {
        edit && getMenuData();
    }, [edit]);

    const onSubmit = (fields) => {
        if (edit) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}company-reels/update/${id}`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });

        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}company-reels/create`,
                    fields
                )
                .then((response) => {
                    console.log("response...");

                    if (response?.data?._id) {
                        setSuccess(true);
                    } else {
                        console.log("failed...");
                        alert("Fail");
                    }
                });
        }
    };

    return (
        <>
            <BackBtn
                navigatePath="/company-reels"
            />
            <div className="healtharticle mt-5 pt-5 ps-5">
                <div className="pt-3 ms-3">
                    <Breadcrumbs
                        breadcrumbText="Company Reels"
                        breadcrumbLink="/company-reels"
                        nestedText="Add New Reel"
                        nestedLink="#"
                    />
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="carr-dep-form"
                >
                    <div className="row car-dep-form">
                        <div className="col-12">
                            <InputTextField
                                register={register}
                                errors={errors}
                                labelName="Reel Title"
                                errorText="Please enter the Reel Title"
                                name="reel_title"
                            // placeholder="Type here..."
                            />
                        </div>
                    </div>
                    <div className="row car-dep-form">
                        <div className="col-6">
                            <InputTextField
                                register={register}
                                errors={errors}
                                labelName="Reel Url"
                                errorText="Please enter the Reel Url"
                                name="reel_url"
                            // placeholder="Type here..."
                            />
                        </div>
                        <div className="col-6">
                            <InputRadioField
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                watch={watch}
                            />
                        </div>
                    </div>

                    <div className="car-dep-form-button">
                        <button type="submit" style={{ marginTop: "5%" }}>
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {/* </Home> */}
            {success && (
                <SucessModal
                    page={"/company-reels"}
                    name="new company reel"
                    post={edit ? 'updated' : 'added'}
                />
            )}
        </>
    )
}

export default AddCompanyReels