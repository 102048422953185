import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoCloseCircleSharp } from "react-icons/io5";

import InputTextField from "../Common/inputTextField";
import "./SucessModel.scss";

const DoctorFormCustomField = ({
  setCustomFields = () => { },
  setCustomFieldModel = () => { },
  setCustomFieldEditModel = () => { },
  setLevel = () => { },
  level,
  customFieldsLength,
  customFieldIndex,
  customFieldValue,
  edit = false
}) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  useEffect(() => {
    if (edit) {
      setValue("fieldName", customFieldValue?.fieldName)
      setValue("fieldInput", customFieldValue?.fieldInput)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit])

  const onSubmit = (fields) => {
    if (edit) {
      setCustomFields((pre) => {
        pre[customFieldIndex] = fields
        return pre
      })
      setCustomFieldEditModel({ status: false, index: null })
    } else {
      setCustomFields((pre) => [...pre, fields])
      setCustomFieldModel(false)
      if (customFieldsLength % 30 === 0) {
        setLevel(level + 1)
      }
    }
  }
  const onClickClose = () => {
    if (edit) {
      setCustomFieldEditModel({ status: false, index: null })
    } else {
      setCustomFieldModel(false)
    }
  }
  return (
    <>
      <div className="h-auto">
        <div className="modal-backdrop fade show success-model"></div>
        <div
          style={{ display: "block" }}
          className="modal fade show modal-sm"
          id="confirmModal"
        >
          <div className="modal-dialog">
            <div className=" modal-content p-4">
              <div className="d-flex justify-content-between w-100 h-auto mb-2 mt-2">
                <p className="assignTitle fw-bold">Custom Field for Doctor  Form</p>
                <div>
                  <IoCloseCircleSharp
                    size={30}
                    color={"gray"}
                    className="cursorPointer"
                    onClick={onClickClose}
                  />
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputTextField
                  labelName="Name of the Field"
                  name="fieldName"
                  register={register}
                  errors={errors}
                  className="w-100 mb-3"
                  placeholder="Enter the field name"
                  errorText={"Please provide a field Name"}
                />
                <InputTextField
                  labelName="Field Input"
                  name="fieldInput"
                  register={register}
                  errors={errors}
                  className="w-100"
                  placeholder="Enter the field input"
                  errorText={"Please provide a field input"}
                />
                <div className="car-dep-form-button mt-4 mb-3">
                  <button type="submit" style={{ marginTop: "" }}>
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorFormCustomField;