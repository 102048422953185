
import { useNavigate } from "react-router";

import backRed from "../../assets/svg/back-rose-icon.svg";

const BackBtn = ({
	navigatePath = "",
	navigation = true,
	onClick = () => { }
}) => {
	const navigate = useNavigate();

	return (
		<div
			onClick={navigation ? () => navigate(navigatePath) : onClick}
			className="rose back-button"
		>
			<img src={backRed} alt="" />
			<span className="rose-back">Back</span>
		</div>
	)
}

export default BackBtn