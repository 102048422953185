import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Moment from "moment";
import axios from "axios";

import CTABanner from "./CTABanner";
import Deletepopup from "../modal/Deletepopup";

import newdelete from "../../assets/images/HealthSy/delete.png";

const CTAForm = () => {
  const [CTAFormList, setCTAFormList] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const { id } = useParams();

  const getCtaFormList = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}cta-form/ctaformlist`, { category: id })
      .then((response) => {
        setCTAFormList(response?.data?.rows);
      });
  }

  useEffect(() => {
    getCtaFormList()
  }, [id]);

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}cta-form/delete/${id}`)
      .then(() => {
        getCtaFormList()
        setDeleteModal({ id: "", status: false });
      });
  };

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  return (
    <>
      <div className="mt-4 faqContainer p-4">
        <CTABanner />
        <div className="mt-4">
          {CTAFormList?.length ? (
            <div className="healtharticle-table ms-4">
              <table style={{ width: "100% " }}>
                <thead>
                  <tr>
                    <td className="">S.No</td>
                    <td className="" style={{ width: "7%" }}>Name</td>
                    <td className="">{id === "retail-pharmacies" ? "Pharmacy Name" : id === "doctors" ? "Special Category" : "Service Category"}</td>
                    <td className="">Location</td>
                    <td className="">Pincode</td>
                    <td className="career-jobpost-td6">Mobile No</td>
                    <td className="career-jobpost-td6">Upload Time</td>
                    <td className="career-jobpost-td6">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {CTAFormList.map((el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{i + 1}</td>
                        <td>{el.name}</td>
                        <td>
                          {id === "retail-pharmacies" ? el.pharmacy_name : id === "doctors" ? el.special_category : el.service_category}
                        </td>
                        <td>{el.location}</td>
                        <td>{el.pincode}</td>
                        <td>{el.mobile}</td>
                        <td>
                          {" "}
                          {Moment(el.createdAt).format("DD MMMM YYYY LT")}
                        </td>
                        <td>
                          <span
                            className="newdelete"
                            onClick={() => {
                              setDeleteModal({ id: el._id, status: true });
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="mt-4"> No CTA Forms get for this Category </div>
          )}
        </div>
      </div>
      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default CTAForm;
