import axios from "axios";
import { useEffect } from "react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import "./index.scss";
import SucessModal from "../../../modal/SucessModal";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import apiCall from "../../../../api";

const BlogSearchBannerImage = () => {
	const {
		getValues,
		handleSubmit,
	} = useForm();

	const [success, setSuccess] = useState(false);
	const [editSuccess, setEditSuccess] = useState(false);

	const [id, setId] = useState("");
	const [count, setCount] = useState(0);
	const [error, setError] = useState("");
	const [uploadImage, setUploadImage] = useState(null);
	const [uploadMobBannerImage, setUploadMobBannerImage] = useState(null);

	useEffect(() => {
		list_api_call();
	}, [count])

	const list_api_call = async () => {
		try {
			const res = await apiCall(`blog-banner-image/list`, "POST",
				{})
			const {
				upload_banner_image = "",
				upload_mob_banner_image = "",
			} = res.data.rows[0];
			setUploadImage(upload_banner_image)
			setUploadMobBannerImage(upload_mob_banner_image);
			setId(res.data.rows[0]._id);
			setCount(res.data.count);
		} catch (err) {
			console.error(err);
		}
	};

	const getsrcValue = (value) => {
		if (typeof value === "string" && value.length)
			return process.env.REACT_APP_API_URL + "images/blogBanner/" + value;
		return typeof value === "object" ? URL.createObjectURL(value) : " ";
	};

	const onSubmit = (fields) => {
		if (count === 0) {
			fields["upload_banner_image"] = uploadImage;
			fields["upload_mob_banner_image"] = uploadMobBannerImage;
			if (!uploadImage) {
				setError("Banner image is required.");
				return;
			}
			if (!uploadMobBannerImage) {
				setError("Mobile Banner image is required.");
				return;
			}
			axios
				.postForm(
					`${process.env.REACT_APP_API_URL}blog-banner-image/create`,
					fields
				)
				.then((response) => {
					if (response.data?._id) {
						setSuccess(true);
					} else {
						alert("Fail");
						console.log("failed...");
					}
				});
		} else if (count === 1) {
			let fields = getValues();
			fields["upload_banner_image"] = uploadImage;
			fields["upload_mob_banner_image"] = uploadMobBannerImage;
			axios
				.postForm(
					`${process.env.REACT_APP_API_URL}blog-banner-image/update/${id}`,
					fields
				)
				.then((response) => {
					if (response.data?._id) {
						setEditSuccess(true);
					} else {
						alert("Fail");
					}
				});
		}
	};

	return (
		<>
			<div className="healtharticle mt-5 p-3">
				<div className="healtharticle-title carrer-job-title ms-4">
					<Breadcrumbs
						breadcrumbText="Blogs"
						breadcrumbLink="/healtharticle"
						nestedText="Blog Banner Image"
						nestedLink="#"
					/>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
					<div className="row car-dep-form">
						{/* Logo Field */}
						<div className="col-6">
							<label className="car-dep-form-txt mb-2" style={{ color: "#4B4853" }}>
								Upload Banner Image
							</label>
							<div>
								{uploadImage ? (
									<img
										src={getsrcValue(uploadImage, 'upload_banner_mage')}
										className="bannerImg"
										alt="uploadImage"
									/>
								) : null}
							</div>
							<input
								className="form-control-file custom-file-input logoFileChoose"
								type="file"
								onChange={(e) => setUploadImage(e.target.files[0])}
								required={false}
							/>
							{uploadImage && (
								<button
									className="btn btn-danger ms-2"
									onClick={() => setUploadImage(null)}
								>
									Remove
								</button>
							)}

							{error && (
								<div className="text-danger mt-2">
									{error}
								</div>
							)}
						</div>

						{/* Banner Image Field */}
						<div className="col-6">
							<label className="car-dep-form-txt mb-2" style={{ color: "#4B4853" }}>
								Upload Mobile Banner Image
							</label>
							<div>
								{uploadMobBannerImage ? (
									<img
										src={getsrcValue(uploadMobBannerImage, 'upload_mob_banner_image')}
										className="bannerImg"
										alt="Banner"
									/>
								) : null}
							</div>
							<input
								className="form-control-file custom-file-input logoFileChoose"
								type="file"
								onChange={(e) => setUploadMobBannerImage(e.target.files[0])}
								required={false}
							/>
							{uploadMobBannerImage && (
								<button
									className="btn btn-danger ms-2"
									onClick={() => setUploadMobBannerImage(null)}
								>
									Remove
								</button>
							)}
							{error && (
								<div className="text-danger mt-2">
									{error}
								</div>
							)}
						</div>
					</div>
					<div className="car-dep-form-button blog-search-banner-button">
						<button type="submit" className="mb-5">
							SUBMIT
						</button>
					</div>
				</form>
			</div>

			{success && (
				<SucessModal
					page={"/blog-banner-image"}
					name="Blog Banner Image"
					post="posted"
				/>
			)}

			{editSuccess && (
				<SucessModal
					page={"/blog-banner-image"}
					name="Blog Banner Image"
					post="updated"
				/>
			)}
		</>
	);
};

export default BlogSearchBannerImage