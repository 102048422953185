import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./form.scss";
import backRed from "../../../../../assets/svg/back-rose-icon.svg";

const PharmacyStoreView = () => {

  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}pharmacy-store-size/get/${id}`)
      .then((res) => {
        setData(res.data);
      });
  };

  return (
    <>
      {/* <Home> */}
      <div
        onClick={() => navigate("/master-pharmacy-store")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-title ms-2">
          <span>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/master-primary-specialisation"
            >
              Master
            </Link>
          </span>
          <span className="bread-crumb">{`>`}</span>
          <span>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/master-partnered-pharmacy"
            >
              Partnered Pharmacies
            </Link>
          </span>
          <span className="bread-crumb1" style={{ paddingLeft: "0.4%" }}>
            {`>`}
          </span>
          <span style={{ paddingLeft: "0.4%" }}>View Pharmacy Store Size</span>
        </div>
        <div className="row mt-5 ms-2 new-menu ">
          <div className="menu-field col-md-3 me-0">
            <p className="form-label " htmlFor="">
              Pharmacy Store Size
            </p>
            <div className="blog-view-text-half-bor">{data.type}</div>
          </div>

          <div className="col">
            <label htmlFor="" className="mb-3 ms-3 ">
              Active<span className="text-danger"></span>
            </label>
            <div className="d-flex ">
              <div className="form-check form-check-inline">
                <input
                  className="me-3"
                  type="radio"
                  name="active"
                  id="status"
                  value={data.active}
                  checked={data.active === true ? "checked" : null}
                />
                <label className="" htmlFor="inline1">
                  yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="me-3"
                  type="radio"
                  name="active"
                  id="status"
                  value={data.active}
                  checked={data.active === false ? "checked" : null}
                />
                <label className="" htmlFor="inline2">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Home> */}
    </>
  )
}

export default PharmacyStoreView