import filter1 from "../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../assets/svg/Health Article/sortbyicon.svg";

const FilterOptions = ({
    setLimit = ()=>{},
    filter_records = ()=>{},
    setSortShow = ()=>{},
    setFilterShow = ()=>{},
    limit,
    sortName,
    sortshow,
    search
}) => {
    
    return (
        <div className="healtharticle-show mt-3">
        <div>Show</div>
        <div>
            <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="me-2"
            >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
            </select>
        </div>
        <div>entries</div>
        <div>
            <div>
                {/* <FaSearch /> */}
                <img src={search} alt="" />
            </div>
            <div>
                <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    onChange={(e) => filter_records(e.target.value)}
                />
            </div>
        </div>
        <div className="sortandfilter-new">
            <div className="sortByPosition">
                <div>
                    <div>
                        <div
                            onClick={() => {
                                setSortShow(!sortshow);
                            }}
                        >
                            {/* Sort By */}
                            {sortName}
                        </div>
                        {/* <select 
              onChange={(e) => setSort(e.target.value)}
            >
              <option>Sort by</option>
              <option value="id-desc"><div style={{color:"red"}}>Oldest</div></option>
              <option value="name-asc"><div>Newest</div></option>
              <option value="id-asc"><div>Alphabetical ↑</div></option>
              <option value="id-asc"><div>Alphabetical ↓</div><span><img src="" alt="" /></span></option>
            </select> */}
                    </div>
                    <div>
                        <img src={sort1} alt="" />
                    </div>
                </div>
            </div>
            <div className="sortByDepartment">
                <div>
                    <div>
                        <div
                            onClick={() => {
                                setFilterShow(true);
                            }}
                        >
                            Filter
                        </div>
                        {/* <select
            name=""
            id=""
            // onChange={handleSort}
          >
            <option value="">Filter</option>
            <option value="Asc to Des">Asc to Des</option>
            <option value="Des to Asc">Des to Asc</option>
          </select> */}
                    </div>
                    <div>
                        <img src={filter1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default FilterOptions