import axios from "axios";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import apiCall from "../../../../api";
import graytick from "../../../../assets/images/graytick.PNG";
import greentick from "../../../../assets/images/greentick.PNG";
import { slugUrl } from "../../../../constants";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../Common/backBtn";
import FAQContent from "../../../Common/faqContent";
import InputTextField from "../../../Common/inputTextField";
import NextBackBtns from "../../../Common/nextBackBtns";
import SucessModal from "../../../modal/SucessModal";
import AsyncBlogOptions from './asyncBlogOptions';
import "./index.scss";

const StepOne = ({
  register,
  unregister,
  errors,
  trigger,
  isValid,
  setLevel,
  editor,
  getValues,
  control,
  menu,
  checked1,
  setChecked1,
  checked2,
  setChecked2,
  category,
  content,
  bannerImage,
  setBannerImage,
  setAuthorImage,
  blogTags,
  setBlogTags,
  setValue,
  setDescription,
  description,
}) => {

  const handleNext = async () => {
    await trigger();
    if (isValid) {
      setLevel(2)
    }
  }
  const handleAddBlogTag = () => {
    const tagId = blogTags[blogTags?.length - 1]?.tagId + 1
    setBlogTags((pre) => [...pre, { tagId }]);
  };

  const handleRemoveBlogTag = (tagId) => {
    trigger()
    unregister(`tagname-${tagId}`)
    unregister(`blog-${tagId}`)
    setBlogTags(blogTags.filter((data) => data?.tagId !== tagId));
  };

  return (
    <div className="addnew1-form">
      <div className="d-flex mb-2">
        <div className="col-md-4">
          <label for="inputState" className="form-label title">
            Menu <span className="star">*</span>
          </label>
          <select
            id="menu"
            className="form-select addnew-input"
            {...register("menu", { required: true })}
          >
            <option selected hidden>Select Menu</option>
            {
              menu.map((el, i) => {
                if (el.active === true) {
                  return (
                    <option key={i} value={el.name}>{el.name}</option>
                  )
                }
              })
            }
          </select>
          {errors.menu && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Menu
            </div>
          )}
        </div>
        <div>
        </div>
        <div className="col-md-4">
          <label for="inputState" className="form-label">
            Category <span className="star">*</span>
          </label>
          <select
            id="category"
            className="form-select addnew-input title"
            {...register("category", { required: true })}
          >
            <option selected hidden>Select Category</option>
            {
              category.map((el) => {
                if (el.active === true) {
                  return (
                    <option value={el.category}>{el.category}</option>
                  )
                }
              })
            }
          </select>
          {errors.category && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Category
            </div>
          )}
        </div>
      </div>

      <div className="col-12">
        <label for="inputAddress" className="form-label title">
          Title <span className="star">*</span>
        </label>
        <input
          type="text"
          className="form-control addnew-input"
          style={{ paddingLeft: "10px" }}
          id="title"
          {...register("title", { required: true })}
        />
        {errors.title && (
          <div className={`invalid-feedback d-block`}>
            Please provide a Title
          </div>
        )}
      </div>
      <div className="addnew-form-active mb-3">
        <div className="addnew-active">
          <label className="fo-tit">
            Active <span className="star">*</span>
          </label>
          <div>
            <div>
              <div>
                <input
                  type="radio"
                  value={true}
                  id="active"
                  name="active"
                  checked={checked1 ? "checked" : ""}
                  onClick={() => setChecked1(true)}
                  {...register("active", { required: true })}
                // checked
                />
              </div>
              <div>Yes</div>
            </div>
            <div>
              <div>
                <input
                  type="radio"
                  value={false}
                  id="active"
                  name="active"
                  checked={checked1 ? "" : "checked"}
                  onClick={() => setChecked1(false)}
                  {...register("active", { required: true })}

                />
              </div>
              <div>No</div>
            </div>
          </div>
          {errors.active && (
            <div className={`invalid-feedback d-block`}>
              Please Select a option
            </div>
          )}
        </div>
        <div className="addnew-featured">
          <label className="fo-tit">
            Featured <span className="star">*</span>
          </label>
          <div>
            <div>
              <div>
                <input
                  type="radio"
                  id="featured"
                  name="featured"
                  value={true}
                  {...register("featured", { required: true })}
                  checked={checked2 ? "checked" : ""}
                  onClick={() => setChecked2(true)}
                />
              </div>
              <div>Yes</div>
            </div>
            <div>
              <div>
                <input
                  type="radio"
                  id="featured"
                  name="featured"
                  value={false}
                  checked={checked2 ? "" : "checked"}
                  onClick={() => setChecked2(false)}
                  {...register("featured", { required: true })}
                />
              </div>
              <div>No</div>
            </div>
          </div>
          {errors.featured && (
            <div className={`invalid-feedback d-block`}>
              Please Select a option
            </div>
          )}
        </div>
      </div>
      <div className="addnew-form-upload">
        <div>
          <label className="title fo-tit fo-tit-top ">
            Upload image <span className="star">*</span>
          </label>
          {/* <div id="img-preview-one"></div> */}
          <div style={{ "width": "200px" }}>
            <ImageUploadAndPreview bannerImage={bannerImage}
              onChange={files => setBannerImage(files.length ? files[0] : null)}
            />
            {errors.uploadImage && (
              <div className={`invalid-feedback d-block`}>
                Please Select Upload Image
              </div>
            )}
          </div>
        </div>
        <div className="authorUpload">
          <label className="title fo-tit fo-tit-top">
            Upload Author image <span className="star">*</span>
          </label>
          <div id="img-preview-two"></div>
          <div>

            <ImageUploadAndPreview
              onChange={files => setAuthorImage(files.length ? files[0] : null)}
            />
            {errors.authorImage && (
              <div className={`invalid-feedback d-block`}>
                Please Select Author Image
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-3 d-flex">
        <div className="col-md-4">
          <label for="inputAddress" className="form-label title">
            Banner Image Alt <span className="star">*</span>
          </label>
          <input
            type="text"
            className="form-control addnew-input"
            id="bannername"
            {...register("bannername", { required: true })}
            style={{ paddingLeft: "10px" }}
          />
          {errors.bannername && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Banner Name
            </div>
          )}
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-3 d-flex">
        <div className="col-md-4">
          <label for="inputAddress" className="form-label title">
            Author Name <span className="star">*</span>
          </label>
          <input
            type="text"
            className="form-control addnew-input"
            id="authordetails"
            {...register("authordetails", { required: true })}
            style={{ paddingLeft: "10px" }}
          />
          {errors.authordetails && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Author Name
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label for="inputAddress" className="form-label title">
            Author Specialization <span className="star">*</span>
          </label>
          <input
            type="text"
            className="form-control addnew-input"
            id="authorSpecialization"
            {...register("authorSpecialization", { required: true })}
            style={{ paddingLeft: "10px" }}
          />
          {errors.authorSpecialization && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Author Specialization
            </div>
          )}
        </div>
      </div>
      <div className="my-5 ">
        <div className="form-label title fw-bold">Blog Tags</div>
        {blogTags.map((blog, index) => (
          <div key={index} className="row mt-3">
            <div className="col-md-4">
              <InputTextField
                labelName="Tag Name"
                name={`tagname-${blog?.tagId}`}
                register={register}
                errors={errors}
                className="w-100"
                placeholder="Enter Tag Name"
                errorText={"Please provide a Tag Name"}
              />
            </div>
            <div className="col-md-4 blogTitleSelect">
              <AsyncBlogOptions
                control={control}
                errors={errors}
                labelName={"Blog Title"}
                defaultValue={getValues()?.[`blog-${blog?.tagId}`]}
                inputValue={getValues()?.[`blog-${blog?.tagId}`]}
                defaultOptions={[getValues()?.[`blog-${blog?.tagId}`]]}
                name={`blog-${blog?.tagId}`}
              />
            </div>
            {blogTags?.length > 1 ? <div className="col-md-4">
              <span
                onClick={() => handleRemoveBlogTag(blog?.tagId)}
                className="plus-icon"
                style={{
                  marginLeft: "0px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    height: "2px",
                    width: "50%",
                    backgroundColor: "#fff",
                  }}
                ></span>
              </span>
            </div> : null}
          </div>
        ))}
        <div className="d-flex justify-content-between col-md-4">
          <span
            onClick={handleAddBlogTag}
            className="addPlusIcon text-end text-white"
            style={{
              marginLeft: "0px",
              height: '34px',
              cursor: "pointer",
              marginTop: 32
            }}
          >
            Add
          </span>
        </div>
      </div>
      <div className="addnew-form1-desc mt-3">
        <label className="title">
          Description <span className="star">*</span>
        </label>
        <div>
          {/* <JoditEditor
            {...register("description", { required: false })}
            ref={editor}
            value={content}
            id="description"
            // name="description"
            config={config}
          />
          {errors.description && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Description
            </div>
          )} */}
          {/* <JoditEditor
            ref={editor}
            value={content}
            config={config}
            {...register("description", { required: false })}
            id="description"
          // name="about"
          />
          {errors.description && (
            <div className={`invalid-feedback d-block`}>
              Please provide a description
            </div>
          )} */}
          <JoditEditor
            value={`${description}`}
            onChange={(value) => {
              setDescription(value);
              setValue("description", value);
            }}
          />
          {!description?.length && (
            <div className="invalid-feedback d-block"> About field is required</div>
          )}
        </div>
      </div>
      <div className="addnew-form1-videourl mt-3">
        <div className="col-7">
          <label for="inputAddress" className="form-label title">
            Video URL
          </label>
          <input
            type="text"
            className="form-control addnew-input  w-100"
            id="videoUrl"
            {...register("videoUrl", { required: false })}
            style={{ paddingLeft: "10px" }}
          />
          {errors.videoUrl && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Video URL
            </div>
          )}
        </div>
      </div>
      <NextBackBtns
        back={false}
        handleNext={handleNext}
      />
    </div>
  )
}

const StepTwo = ({
  register,
  errors,
  setMetaTitle,
  trigger,
  isValid,
  setLevel,
  metaTitle
}) => {

  const handleBack = () => {
    setLevel(1)
  }

  const handleNext = async () => {
    await trigger();
    if (isValid) {
      setLevel(3)
    }
  }

  return (
    <div className="addnew1-form">
      <div className="col-12">
        <label for="inputAddress" className="form-label title ">
          Meta Title <span className="star">*</span>
        </label>
        <input
          type="text"
          className="form-control addnew-input w-100"
          id="metatitle"
          {...register("metatitle", { required: true })}
          // placeholder="1234 Main St"
          style={{ paddingLeft: "10px" }}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
        {errors.metatitle && (
          <div className={`invalid-feedback d-block`}>
            Please provide a Meta Title
          </div>
        )}
      </div>
      <div className="col-12">
        <label for="inputAddress" className="form-label title">
          Meta Description <span className="star">*</span>
        </label>
        <input
          type="text"
          className="form-control addnew-input w-100"
          id="metadescription"
          style={{ paddingLeft: "10px" }}
          // placeholder="1234 Main St"
          {...register("metadescription", { required: true })}
        />
        {errors.metadescription && (
          <div className={`invalid-feedback d-block`}>
            Please provide a Meta Description
          </div>
        )}
      </div>
      <div className="col-12 ">
        <label for="inputAddress" className="form-label title">
          Meta Keywords <span className="star">*</span>
        </label>
        <input
          type="text"
          className="form-control addnew-input w-100 "
          id="metakeywords"
          {...register("metakeywords", { required: true })}
          style={{ paddingLeft: "10px" }}
        />
        {errors.metakeywords && (
          <div className={`invalid-feedback d-block`}>
            Please provide a Meta Keywords
          </div>
        )}
      </div>
      <div className="col-12">
        <label htmlFor="metatags" className="form-label title">
          Meta Tag <span className="star">*</span>
        </label>
        <input
          type="text"
          className="form-control addnew-input w-100"
          id="metatags"
          {...register("metatags", { required: true })}
          // onChange={handleMetaTagsInputChange}
          placeholder="Search Meta Tags..."
        />
        {errors.metatags && (
          <div className={`invalid-feedback d-block`}>
            Please provide a Meta Tags
          </div>
        )}
      </div>
      <div className="col-12">
        <label for="inputAddress" className="form-label title">
          Others
        </label>
        <input
          type="text"
          className="form-control addnew-input w-100"
          id="others"
          {...register("others")}
          style={{ paddingLeft: "10px" }}
        />
      </div>
      <div className="col-12">
        <label for="inputAddress" className="form-label title">
          Canonical Url
        </label>
        <input
          type="text"
          readOnly
          value={slugUrl(metaTitle)}
          className="form-control addnew-input w-100"
          id="canonicalUrl"
          {...register("canonicalUrl")}
          style={{ paddingLeft: "10px" }}
        />
      </div>
      <NextBackBtns
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </div>
  )
}

const StepThree = ({
  faqs,
  setFaqs,
  setLevel,
}) => {

  const handleBack = () => {
    setLevel(2)
  }

  return (
    <>
      <FAQContent
        setFaqs={setFaqs}
        faqs={faqs}
      />
      <div className="backSubmitBtns">
        <NextBackBtns
          next={false}
          handleBack={handleBack}
        />
        <div className="nextBtnStyle mt-5">
          <div>
            <button type="submit">Submit</button>
          </div>
          {/* <div>
							<img src={next} alt="" />
						</div> */}
        </div>
      </div>
    </>
  )
}

const Level = ({
  level,
}) => {
  return (
    <>
      <Breadcrumbs
        breadcrumbText="Health Article"
        breadcrumbLink="/healtharticle"
        nestedText="Add New Health Articles"
      />
      {
        <div className={level === 1 ? "doctor-page-tick" : level === 2 ? "doctor-page2-tick" : level === 3 ? "doctor-page3-tick" : ""}>
          <div>
            <img src={greentick} alt="" />
            <div>Page 1</div>
          </div>
          <div>
            <img src={level === 1 ? graytick : greentick} alt="" />
            <div>Page 2</div>
          </div>
          <div>
            <img src={(level === 3 || level === 4) ? greentick : graytick} alt="" />
            <div>Page 3</div>
          </div>
        </div>
      }
    </>
  )
}

const AddNewPageOne = () => {

  const {
    getValues,
    register,
    unregister,
    control,
    trigger,
    setValue,
    watch,
    formState: { errors, isValid },
    handleSubmit
  } = useForm();

  const [level, setLevel] = useState(1);
  const [faqs, setFaqs] = useState([]);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [menu, setMenu] = useState([])
  const [category, setCategory] = useState([])
  const [success, setSuccess] = useState(false);
  const [bannerImage, setBannerImage] = useState(null);
  const [authorImage, setAuthorImage] = useState(null);
  const [metaTitle, setMetaTitle] = useState("")
  const [blogTags, setBlogTags] = useState([{ tagId: 1 }])
  const [description, setDescription] = useState("")

  useEffect(() => {
    getMenuData()
    getCategoryData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMenuData = async () => {
    try {
      const result = await apiCall(`health-article-menu/list`, "POST", { active: true });
      if (result.data) {
        setMenu(result.data.rows)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getCategoryData = async () => {
    try {
      const result = await apiCall(`health-article-category/list`, "POST", { active: true });
      if (result.data) {
        setCategory(result.data.rows)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const onSubmit = async (fields) => {

    fields.canonicalUrl = slugUrl(metaTitle);
    delete fields.uploadImage;
    // Object.keys(fields).map((key) => setValue(key, fields[key]?.trim()));
    // fields.description = document.getElementsByName("healtharticle_description")[0]?.value;
    fields["uploadImage"] = bannerImage;
    fields["authorImage"] = authorImage;
    fields["faq"] = faqs;
    fields["blogTags"] = blogTags.map((data) => {
      return { tagName: fields?.[`tagname-${data?.tagId}`], blogId: fields?.[`blog-${data.tagId}`]?.value }
    })

    const fieldKeys = Object.keys(fields);
    fieldKeys?.forEach((key) => {
      if (key.includes("tagname-") || key.includes("blog-")) {
        delete fields[key];
      }
    });

    let updatedFields = { ...fields, faq: faqs, ...getValues() };

    axios
      .postForm(`${process.env.REACT_APP_API_URL}health-article/create`,
        updatedFields)
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true)
        } else {
          alert("Fail");
        }
      })
  };

  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(true)

  return (
    <>
      {/* <Home> */}
      <BackBtn
        navigatePath="/healtharticle"
      />
      <div className="healtharticle mt-5 pt-4 ps-3 pb-5 ps-4">
        {/* className="healtharticle addnew1 */}
        <div style={{ marginLeft: "2%" }}>
          <Level
            level={level}
          />
          <form className="row g-3 health-art-form" onSubmit={handleSubmit(onSubmit)}>
            {level === 1 ? (
              <StepOne
                register={register}
                unregister={unregister}
                errors={errors}
                trigger={trigger}
                isValid={isValid}
                setLevel={setLevel}
                editor={editor}
                menu={menu}
                control={control}
                getValues={getValues}
                checked1={checked1}
                setChecked1={setChecked1}
                checked2={checked2}
                setValue={setValue}
                description={description}
                setDescription={setDescription}
                setChecked2={setChecked2}
                category={category}
                content={content}
                setBannerImage={setBannerImage}
                bannerImage={bannerImage}
                setAuthorImage={setAuthorImage}
                blogTags={blogTags}
                setBlogTags={setBlogTags}
              />
            ) : ""}

            {level === 2 ? (
              <StepTwo
                register={register}
                errors={errors}
                metaTitle={metaTitle}
                trigger={trigger}
                isValid={isValid}
                setLevel={setLevel}
                setMetaTitle={setMetaTitle}
              />
            ) : ""}

            {level === 3 ? (
              <StepThree
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
                faqs={faqs}
                setFaqs={setFaqs}
                trigger={trigger}
                page={level}
                getValues={getValues}
                isValid={isValid}
                setLevel={setLevel}
              />
            ) : ""}

          </form>
        </div>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/healtharticle"}
          name="Health Article"
          post='posted'
        />
      )}
    </>
  );
};

export const ImageUploadAndPreview = props => {
  const [files, setFiles] = useState(props.files || []);
  const [imageUrls, setImageUrls] = useState([]);
  const width = props.width || 200;
  const height = props.height || 250;
  const { bannerImage } = props

  useEffect(() => {
    props.onChange && props.onChange(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const onFileInputChange = e => {
    if (e.target.files === 0) {
      return;
    }
    setFiles(props.multiple ? [...files, ...e.target.files] : [...e.target.files]);

    const newImageUrls = props.multiple ? [...imageUrls] : [];
    for (let i = 0; i < e.target.files.length; i++) {
      newImageUrls.push(URL.createObjectURL(e.target.files[i]));
    }

    setImageUrls(newImageUrls)

  }

  const onClickAddFileBtn = e => {
    e.preventDefault();
    const inputs = e.target.parentNode.querySelector('input:last-child');
    if (inputs) {
      inputs.click();
    }
  }

  const removeFile = i => {
    files.splice(i, 1)
    setFiles([...files]);
    imageUrls.splice(i, 1)
    setImageUrls([...imageUrls]);
  }

  return (<>
    <div className="d-flex justify-content-center align-items-center image-upload-and-preview-container" style={{ width: width + 'px', height: height + 'px' }}>
      {imageUrls.map((url, i) => {
        return (<div key={i} className="card">
          <img
            src={url}
            className="img-fluid object-fit-cover" />
          <div className="card-footer">
            <button
              className="btn btn-sm btn-danger"
              onClick={() => { removeFile(i) }}
            >Remove</button>
          </div>
        </div>);
      })}
      {!props.multiple && files.length ? '' : <div
        onClick={onClickAddFileBtn}
        className="d-flex align-items-center justify-content-center w-100 bg-dark bg-opacity-50"
        style={{ height: height + 'px' }}
      >
        +
      </div>}
      <div className="image-upload-and-previewer visually-hidden">
        <input
          type="file"
          onChange={onFileInputChange}
          multiple={props.multiple ? 'multiple' : ''}
          className="image-upload-and-previewer-file-input" />
      </div>
    </div>
    <div>
      <p>{bannerImage?.name}</p>
    </div>
  </>);
}

export default AddNewPageOne;
