import { memo } from "react";
import { Link } from "react-router-dom";

import "./Breadcrumbs.scss"

const RenderLinkText = ({
    breadcrumbLink,
    breadcrumbText
}) => {
    return (
        <>
            {breadcrumbText && <span style={{ paddingLeft: "0.5%", paddingRight: "0.5%" }}>&#62;</span>}
            <span>
                {breadcrumbLink ? <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={breadcrumbLink}
                >
                    {breadcrumbText}
                </Link> : breadcrumbText}
            </span>
        </>
    )
}

const Breadcrumbs = ({
    breadcrumbText = "",
    breadcrumbLink = "",
    nestedText = "",
    nestedLink = "",
    nestedText2 = "",
    nestedLink2 = "",
    nestedText3 = "",
    nestedLink3 = "",
    nestedText4 = "",
    nestedLink4 = "",
    className = ""
}) => {

    return (
        <div className={`BreadcrumbSection ms-0 pb-4 bg-white ${className}`}>
            <span>
                <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/dashboard"
                >
                    Dashboard
                </Link>
            </span>
            <RenderLinkText
                breadcrumbText={breadcrumbText}
                breadcrumbLink={breadcrumbLink}
            />
            <RenderLinkText
                breadcrumbText={nestedText}
                breadcrumbLink={nestedLink}
            />
            <RenderLinkText
                breadcrumbText={nestedText2}
                breadcrumbLink={nestedLink2}
            />
            <RenderLinkText
                breadcrumbText={nestedText3}
                breadcrumbLink={nestedLink3}
            />
            <RenderLinkText
                breadcrumbText={nestedText4}
                breadcrumbLink={nestedLink4}
            />
        </div >
    )
}

export default memo(Breadcrumbs);