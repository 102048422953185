import axios from "axios";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import { slugUrl } from "../../../constants";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../Common/backBtn";
import SucessModal from "../../modal/SucessModal";
import InputTextField from "../../Common/inputTextField";
import InputRadioField from "../../Common/inputRadioField";
import TextAreaField from "../../Common/textAreaField";

const AddDoctorsCity = ({
    edit = false,
    localityEdit = false
}) => {
    const { id } = useParams()
    const {
        register,
        formState: { errors },
        setValue,
        watch,
        handleSubmit,
    } = useForm();

    const getMenuData = () => {
        if (localityEdit) {
            axios.get(`${process.env.REACT_APP_API_URL}doctor-cities/${id}`).then(response => {
                // setValue('active', response.data?.active);
                setValue('city', response.data?.city);
                setValue('localities', response.data?.localities);
            }).catch(err => {
                console.error(err);
                alert("Some error");
            })
        } else {

            axios.get(`${process.env.REACT_APP_API_URL}doctor-cities/city-get/${id}`).then(response => {
                setValue('active', response.data?.active);
                setValue('city', response.data?.city);
                // setValue('localities', response.data?.localities);
            }).catch(err => {
                console.error(err);
                alert("Some error");
            })
        }

    }

    useEffect(() => {
        edit && getMenuData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit]);

    const [success, setSuccess] = useState(false);

    const onSubmit = (fields) => {
        fields.slug_url = slugUrl(fields.city)
        if (localityEdit) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}doctor-cities/${id}`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        } else if (edit) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}doctor-cities/update/${id}`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}doctor-cities/city-create`,
                    fields
                )
                .then((response) => {
                    if (response.data.data._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        }
    };

    return (
        <>
            <BackBtn
                navigatePath="/doctors-location"
            />
            <div className="healtharticle mt-5">

                <div className="pt-3 ms-3">
                    <Breadcrumbs
                        breadcrumbText="Doctor"
                        breadcrumbLink="/doctors-location"
                        nestedLink=""
                        nestedText={edit ? "Edit City / Localities" : "Add City / Localities"}

                    />
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="carr-dep-form"
                >
                    <div className="row car-dep-form">
                        <div className="col-5 mb-4">
                            <InputTextField
                                labelName="City"
                                name="city"
                                register={register}
                                errors={errors}
                                errorText={"Please Enter a City"}
                                disable={localityEdit}
                            />
                        </div>
                        {!localityEdit ? <div className="col-3 mb-4">
                            <InputRadioField
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                watch={watch}
                            />
                        </div> : null}
                        {(edit && localityEdit) ? <div className="col-5">
                            <TextAreaField
                                labelName="Localities"
                                name="localities"
                                register={register}
                                id="localities"
                                errors={errors}
                                errorText={"Please Enter the Localities"}
                                placeholder="Enter the Localities"
                            />
                        </div> : null}
                    </div>

                    <div className="car-dep-form-button">
                        <button type="submit" style={{ marginTop: "5%" }}>
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {success && (
                <SucessModal
                    page={"/doctors-location"}
                    name="New City"
                    post={edit ? 'updated' : 'added'}
                />
            )}
        </>
    )
}

export default AddDoctorsCity