import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import apiCall from "../../../api";
import search from "../../../assets/images/searchIcon.png";
import Breadcrumbs from '../../Common/Breadcrumbs/Breadcrumbs';
import Actions from "../../Common/actions";
import AddButton from '../../Common/addButton';
import FilterOptions from '../../Common/filterOptions';
import Pagination from '../../Common/pagination';
import Deletepopup from "../../modal/Deletepopup";

const DoctorslocalitiesList = () => {
  const [localityList, setLocalityList] = useState([])

  const navigate = useNavigate()
  const location = useLocation();
  const cityPath = location.pathname.split('/')[2];
  const queryParams = new URLSearchParams(location.search);
	const cityId = queryParams.get("city_id");

  useEffect(() => {
    cityId && getCityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId]);

  const getCityList = async() => {
    const result = await apiCall(`locality/list/${cityId}`, "GET");
    if (result?.data) {
      setLocalityList(result.data)
      // setSuccess(true);
    }
  }
  const handleLocationForm = () => {
    navigate(`/${cityPath}/add-locality?city_id=${cityId}`)
  }
  const handleEdit = (id) => {
    navigate(`/${cityPath}/edit-locality/${id}?city_id=${cityId}`)
  }

  const [del, setDel] = useState(false)
  const [id, setId] = useState("")

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}doctor-cities/${cityPath}/${id}/delete-locality`,
      {
        method: "DELETE",
      }
    ).then((result) => {
      result.json().then((res) => {
        // list_api_call();
        setDel(false)
      });
    });
  };

  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");

  const [sortshow, setSortShow] = useState(false);
  const [filtershow, setFilterShow] = useState(false);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <DoctorslocalitiesListOld />
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Breadcrumbs
              breadcrumbText='Doctor'
              breadcrumbLink=''
              nestedText={capitalizeFirstLetter(cityPath)}
              nestedLink=''
              nestedText2='View Localities'
              nestedLink2=''
            />
          </div>
          <div className="healtharticle-addnew gap-4">
            <AddButton
              onClick={handleLocationForm}
              btnName="Add New Localities"
            />
          </div>
          <FilterOptions
            limit={limit}
            sortshow={sortshow}
            sortName={sortName}
            search={search}
            setFilterShow={setFilterShow}
            setSortShow={setSortShow}
            // filter_records={filter_records}
            setLimit={setLimit}
          />

          <div className="healtharticle-table master-doctor-table">
            <table>
              <thead>
                <tr>
                  <td className="career-jobpost-td1">S.No</td>
                  <td className="career-jobpost-td2">Localities</td>
                  <td className="career-jobpost-td12">Actions</td>
                </tr>
              </thead>
              <tbody>
                {localityList?.map((el, i) => { 
                  return (
                    <tr key={i}>
                      {/* <td>{(current_page - 1) * limit + (i + 1)}</td> */}
                      <td>{(current_page - 1) * limit + (i + 1)}</td>
                      <td>{el.locality}</td>
                      <td>
                        <Actions
                          isView={false}
                          isActive={false}
                          isDelete={false}
                          onClickEdit={() => handleEdit(el?._id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>

            </table>
          </div>
        </div>
      </div>
      <Pagination
        setCurrentPage={setCurrentPage}
        current_page={current_page}
        pages={pages}
      />
      {
        del === true
        &&
        <Deletepopup
          handleDelete={handleDelete}
          id={id}
        />
      }  
    </>
  )
}

export default DoctorslocalitiesList

const DoctorslocalitiesListOld = () => {
  const [cityList, setCityList] = useState([])

  const navigate = useNavigate()
  const pathName = useLocation();
  const cityPath = pathName.pathname.split('/')[2];

  useEffect(() => {
    cityPath && getCityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityPath]);

  const getCityList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}doctor-cities/${cityPath}`)
      .then((response) => {
        setCityList(response?.data);
      });
  }

  const handleLocationForm = () => {
    navigate(`/doctors-localities-list/${cityList?.slug_url}`)
  }
  const [current_page, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Breadcrumbs
              breadcrumbText='Doctor'
              breadcrumbLink=''
              nestedText={capitalizeFirstLetter(cityPath)}
              nestedLink=''
              nestedText2='View Localities (Old)'
              nestedLink2=''
            />
          </div>
          <div className="healtharticle-addnew gap-4">
            <AddButton
              onClick={handleLocationForm}
              btnName="Add / Edit New Localities"
            />
          </div>
          <div className="healtharticle-table master-doctor-table">
            <table>
              <thead>
                <tr>
                  <td className="career-jobpost-td1">S.No</td>
                  <td className="career-jobpost-td2">Localities</td>
                </tr>
              </thead>
              <tbody>
                {cityList?.localities?.split(',')?.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>{(current_page - 1) * limit + (i + 1)}</td>
                      <td>{el}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}