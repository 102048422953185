import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

import backRed from "../../../assets/svg/back-rose-icon.svg";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../Common/backBtn";
import InputTextField from "../../Common/inputTextField";
import SelectField from "../../Common/selectField";
import SucessModal from "../../modal/SucessModal";

const AddClinicService = ({
	edit = false
}) => {

	const { id } = useParams()

	const navigate = useNavigate();
	const [success, setSuccess] = useState();
	const [primaryspl, setPrimaryspl] = useState([]);

	const {
		register,
		setValue,
		getValues,
		formState: { errors },
		handleSubmit,
		trigger
	} = useForm();

	useEffect(() => {
		getPrimarySpl()
	}, [])

	useEffect(() => {
		if (getValues()?.specialization) {
			setValue("specialization", getValues()?.specialization)
		}
	}, [primaryspl])

	const getPrimarySpl = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}inclinc-doctor-specialization/list`, {
				noLimit: true,
				active: true
			})
			.then((res) => {
				setPrimaryspl(res.data.rows?.map((data) => ({ value: data?._id, label: data?.inclinic_doctor_specialization })));
			});
	};

	const getMenuData = () => {
		axios.get(`${process.env.REACT_APP_API_URL}doctors-clinic/get/${id}`).then(response => {
			setValue('specialization', response.data.specialization);
			setValue('service_name', response.data.service_name);
			trigger()
		}).catch(err => {
			console.error(err);
			alert("Some error");
		})
	}

	useEffect(() => {
		edit && getMenuData();
	}, [edit]);

	const onSubmit = (fields) => {
		if (edit) {
			axios
				.patch(
					`${process.env.REACT_APP_API_URL}doctors-clinic/update/${id}`,
					fields
				)
				.then((response) => {
					if (response.data?._id) {
						setSuccess(true);
					} else {
						alert("Fail");
						console.log("failed...");
					}
				});
		} else {
			axios
				.post(
					`${process.env.REACT_APP_API_URL}doctors-clinic/create`,
					fields
				)
				.then((response) => {
					if (response.data?._id) {
						setSuccess(true);
					} else {
						alert("Fail");
						console.log("failed...");
					}
				}).catch((err) => {
					console.log("err -", err, "message", err?.response?.data?.error);
					alert("Something went wrong");
				});
		}
	};

	return (
		<>
			<BackBtn
				navigatePath={`/doctor-clinic-service`}
			/>
			<div
				onClick={() => navigate("/doctor-clinic-service")}
				className="rose back-button"
			>
				<img src={backRed} alt="" />
				<span className="rose-back">Back</span>
			</div>
			<div className="healtharticle ps-5 mt-5 pt-4">
				<div className="healtharticle-title">
					<Breadcrumbs
						breadcrumbText="Doctor"
						breadcrumbLink=""
						nestedText="Clinic Services"
						nestedLink="/doctor-clinic-service"
						nestedText2="Add New Clinic Services"
						nestedLink2=""
					/>
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="carr-dep-form">
					<div className="row car-dep-form">
						<div className="col-md-12">
							<SelectField
								id="clinicProfileState"
								labelName="Specialization "
								name="specialization"
								options={primaryspl}
								register={register}
								errors={errors}
								errorText="Please select the Page Type"
								defaultOption="page type"
								isOptionObject={true}
							/>
						</div>
						<div className="mt-5">
							<InputTextField
								labelName="Clinic Service "
								name="service_name"
								register={register}
								errors={errors}
								// className="w-100"
								placeholder="Enter Service Name"
								errorText={"Please provide a Service Name"}
							/>
						</div>
					</div>
					<div className="car-dep-form-button mt-0 py-5">
						<button type="submit" style={{ marginTop: "5%" }}>
							SUBMIT
						</button>
					</div>
				</form>
			</div >
			{
				success && (
					<SucessModal
						page={"/doctor-clinic-service"}
						name="Clinic Service"
						post={edit ? 'updated' : "added"}
					/>
				)
			}
		</>
	)
}

export default AddClinicService