import { memo } from "react";
import { useLocation, useNavigate } from "react-router";
import { AiOutlineRightCircle } from "react-icons/ai";

const WebBookingbanner = ({ serviceList = [] }) => {

  const navigate = useNavigate();
  const pathName = useLocation();

  const handleOrder = (pathName) => {
    navigate(`/web-bookings/${pathName}`);
  };

  return (
    <div className="faqContainer">
      <div className="ms-2 mb-2">
        <div className="managehealth viewBtn">
          <div>
            <button onClick={() => navigate(`/web-bookings/${pathName.pathname.split('/')[2]}/view-services`)}>View Service</button>
          </div>
          <div>
            <AiOutlineRightCircle />
          </div>
        </div>

      </div>
      <div className="faq-tab">
        {serviceList.map((data, idx) => {
          return (
            <div
              key={idx}
              className={`${pathName.pathname.split("/")[2] === data?.slug_url
                ? "rose-line"
                : ""
                } ${idx !== 0 ? "ms-5" : ""}`}
              onClick={() => handleOrder(data?.slug_url)}
            >
              {data?.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(WebBookingbanner);
