import backArrow from "../../assets/svg/back-arrow-icon.svg"

const FilterStatusModal = ({
  title = "",
  data = [],
  className = "",
  activeFilters = [],
  onChange = () => { }
}) => {
  
  return (
    <div className={`filterModal ${className}`}>
      <div className="d-flex align-items-start">
        <img src={backArrow} alt={backArrow} className="mt-1" />
        <div className="filterModalHeader doctorsFilterModalHeader fw-bold px-3">{title}</div>
      </div>
      <div className="filterModalHr doctorFilterModalHr"></div>
      <div className="filterModalContainer">
        {data.map((el, i) => {
          return (
            <div className="d-flex justify-content-between doctorsFilterModal" key={i}>
              <div>{el}</div>
              <div className="checkbox-wrapper pt-1">
                <input
                  type="checkbox"
                  name={el}
                  id=""
                  checked={activeFilters.includes(el)}
                  className="cursor-pointer"
                  onChange={onChange}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default FilterStatusModal