import Moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router";
import apiCall from "../../api";
import { LegalPageAppDownloadLinks } from "../../constants";

import DoctorsFilterOptions from "../Common/doctorsFilterOptions";
import FiltersRootModal, { SortByModel } from "../Common/filtersRootModals";
import Deletepopup from "../modal/Deletepopup";
import DownloadLinkBanner from "./DownloadLinkBanner";

import newdelete from "../../assets/images/HealthSy/delete.png";
import onboard from "../../assets/images/HealthSy/onboard.png";

const DownloadLink = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [records, setRecords] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortshow, setSortShow] = useState(false);
  const [selectedSort, setSelectedSort] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const [filtershow, setFilterShow] = useState(false);
  const [textColor, setTextColor] = useState([]);

  useEffect(() => {
    list_api_call();
  }, [id, skip, limit, sort]);

  const handleSortClick = (sortOption) => {
    setSelectedSort(sortOption);
  };

  const handleApplyClick = () => {
    if (selectedSort) {
      setSort(selectedSort);
      setSortShow(false);
    }
  };

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  const handlePageChange = (event) => {
    const selectedPage = event.selected;
    setSkip(selectedPage * limit);
  };

  const handleDelete = async (id) => {
    try {
      const response = await apiCall(`store-link/delete/${id}`, "DELETE");

      if (response) {
        list_api_call();
        setDeleteModal({ id: "", status: false });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleActive = async (id, status) => {
    try {
      await apiCall(`store-link/update/active/${id}`, "PATCH", {
        active: !status,
      });
      list_api_call();
    } catch (err) {
      console.error(err);
    }
  };

  const list_api_call = async () => {
    const platform = localStorage.getItem('selected_platform');
    if (!platform) {
      setRecords([]);
      return;
    }

    const response = await apiCall(`store-link/list`, "POST", {
      id: platform,
      skip: skip,
      sort: sort,
      limit: Number(limit),
    });

    const totalCount = response.data.count;
    setRecords(response.data.rows);
    setPages(Math.ceil(totalCount / limit));
  };

  const handleFilterApply = (filteredData) => {
    setFilteredData(filteredData);
    setSkip(0);
    setPages(Math.ceil(filteredData.length / limit));
  };

  return (
    <>
      <div className="mt-5 appDownloadLinkContainer p-4">
        <DownloadLinkBanner dataValues={LegalPageAppDownloadLinks} />
        <DoctorsFilterOptions
          limit={limit}
          sortshow={sortshow}
          setFilterShow={setFilterShow}
          setSortShow={setSortShow}
          setLimit={setLimit}
          onFilterApply={handleFilterApply}
        />
        <div className="mt-4">
          {records?.length ? (
            <div className="appDownloadLinkTable ms-4">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td style={{ width: "7%" }}>Web Page Name</td>
                    <td>Date / Time of Last Update</td>
                    <td>Page Type</td>
                    <td>Meta Title</td>
                    <td>Meta Description</td>
                    <td>Meta Keyword</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {(filteredData?.length > 0 ? filteredData : records)?.slice(skip, skip + limit).map((el, i) => (
                    <tr key={el._id}>
                      <td>{skip + i + 1}</td>
                      <td>{el.webpage_name}</td>
                      <td>{Moment(el.createdAt).format("DD MMMM YYYY LT")}</td>
                      <td>{el.page_type?.page_name}</td>
                      <td>{el.meta_title}</td>
                      <td>{el.meta_description}</td>
                      <td>{el.meta_keyword}</td>
                      <td>
                        <span
                          className="newdelete text-success px-0"
                          onClick={() => {
                            navigate(`/app-download-link/${el._id}/edit-download-link`);
                          }}
                        >
                          Edit
                        </span>
                        <span className="newdelete-logo">
                          <img src={onboard} alt="" />
                        </span>
                        <span
                          className="inactive-health newdelete"
                          onClick={() => {
                            handleActive(el._id, el.active);
                          }}
                        >
                          {el.active ? "Inactive" : "Active"}
                        </span>
                        <span className="newdelete-logo p-3">
                          <img src={newdelete} alt="" />
                        </span>
                        <span
                          className="newdelete p-0"
                          onClick={() => {
                            setDeleteModal({ id: el._id, status: true });
                          }}
                        >
                          Delete
                        </span>
                        <span className="newdelete-logo">
                          <img src={newdelete} alt="" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="mt-4"> No Records </div>
          )}
        </div>
      </div>
      <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
        <button
          className="page-link"
          onClick={() => handlePageChange({ selected: 0 })}
          disabled={skip === 0}
        >
          <AiOutlineBackward size={20} />
        </button>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination my-0"
          activeClassName="active"
        />
        <button
          className="page-link"
          onClick={() => handlePageChange({ selected: pages - 1 })}
          disabled={(skip / limit) >= (pages - 1)}
        >
          <AiOutlineForward size={20} />
        </button>
      </div>

      {filtershow && (
        <FiltersRootModal
          onClickClear={() => {
            setFilterShow(false);
            setFilteredData([]);
            setSkip(0);
            list_api_call();
          }}
          onClickApply={() => {
            setFilterShow(false);
            list_api_call();
          }}
          selectOption={textColor}
          setSelectOption={setTextColor}
          className="filter-container-partnered-doctor"
        />
      )}

      {sortshow && (
        <SortByModel
          latest="Latest"
          oldest="Oldest"
          handleSortClick={handleSortClick}
          selectedSort={selectedSort}
          textColor={textColor}
          handleApplyClick={handleApplyClick}
        />
      )}
      {deleteModal.status && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default DownloadLink;