import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../Common/backBtn";
import InputRadioField from "../../../Common/inputRadioField";

const DoctorExperienceView = () => {

    const [data, setData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}doctors-experience/get/${id}`)
            .then((res) => {
                setData(res.data);
            });
    };

    return (
        <>
            <BackBtn
                navigatePath="/doctors-language"
            />
            <div className="healtharticle">
                <Breadcrumbs
                    breadcrumbText="Doctors"
                    breadcrumbLink="/add-new-doctors"
                    nestedText="Common"
                    nestedLink=""
                    nestedText2="View Experience"
                    nestedLink2=""
                />
                <div className="row mt-5 ms-2 new-menu ">
                    <div className="menu-field col-md-3 me-0">
                        <p className="form-label " htmlFor="">
                            Experience
                        </p>
                        <div className="blog-view-text-half-bor">{data.type}</div>
                    </div>

                    <div className="col">
                        <InputRadioField
                            viewOnly={true}
                            checked={data.active === true ? "checked" : null}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DoctorExperienceView