import React, { useState } from "react";
import "./form.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import axios from "axios";
import { useEffect } from "react";
import SucessModal from "../../../modal/SucessModal";

const OthersEdit = () => {
  
  const {id} = useParams()
  const [ fields, setFields ] = useState({});
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false);

  const handleUpdateData=()=>{
    let item = { type: fields.type, active: fields.active };
    fetch(`${process.env.REACT_APP_API_URL}types/update/${id}`, {
    method: 'PATCH',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    },
    body:JSON.stringify(item)
    })
    .then((result)=>{
        result.json()
        .then((resp) => {
          setSuccess(true);
        })
    });
  }

  const getMenuData = () =>{
    axios.get(`${process.env.REACT_APP_API_URL}types/get/${id}`).then(response=>{
      setFields(response.data);
    }).catch(err=>{
      console.error(err);
      alert("Some error");
    })
  }

  useEffect(()=>{
    getMenuData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {/* <Home> */}
      <div
        onClick={() => navigate("/master-others")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-title">
          <Link
            to="/master-primary-specialisation"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Masters</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>{`>`}</span>
          <Link
            to="/master-others"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Others</span>
          </Link>
          <span style={{ paddingLeft: "0.8%", fontSize: "15px" }}>{`>`}</span>
          <span style={{ paddingLeft: "0.4%", fontSize: "15px" }}>
            Edit Types
          </span>
        </div>
        {/* <form 
          //  onSubmit={handleSubmit(onSubmit)} 
           className="carr-dep-form"> */}
          <div className="row car-dep-form">
            <div className="col-5 ms-3">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Types{" "}
                <span className="star">*</span>
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                  value={fields.type}
                  onChange={(e)=>setFields(prevState=>{
                    return {...prevState, type: e.target.value}
                  })}
                placeholder="Type here..."
              />
              {/* {errors.department && (
                <div className={`invalid-feedback d-block`}>
                  Please Enter department
                </div>
              )} */}
            </div>

            <div className="col-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Active <span className="star">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline me-3 mt-1">
                    <input
                      className="form-check-input me-3"
                      type="radio"
                      name="status"
                      id="status"
                      onChange={(e)=>{setFields(prevState=> {
                        return {...prevState, active: true};
                       }); }}
                     value={true}
                     checked={fields.active===true ? "checked" : null}
                    />
                    <label htmlFor="option-1" className="text-dark ">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline mt-1">
                    <input
                      className="form-check-input  me-3"
                      type="radio"
                      name="status"
                      id="status"
                      value={false}
                      onChange={(e)=>{setFields(prevState=> {
                        return {...prevState, active: false};
                        }); }}
                      checked={fields.active===false ? "checked" : null}
                    />
                    <label htmlFor="option-2" className="text-dark">
                      Inactive
                    </label>
                  </div>
                </div>
                {/* {errors.status && (
                  <div className={`invalid-feedback d-block`}>
                    Please select an Option
                  </div>
                )} */}
              </div>
          </div>

          <div className="car-dep-form-button">
            <button onClick={handleUpdateData} type="submit" style={{ marginTop: "5%" }}>
              UPDATE
            </button>
          </div>
        {/* </form> */}
      </div>
    {/* </Home> */}
    
    {success && (
        <SucessModal 
          page={"/master-others"} 
          name="Others" 
          post='updated'
        />
      )}
  </>
  )
}

export default OthersEdit