import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";

import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import filter1 from "../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../assets/images/searchIcon.png";
import Deletepopup from "../modal/Deletepopup";
import buttonadd from "../../assets/images/plus.png";

const FrpCities = () => {
    const [stateList, setStateList] = useState([]);
    const [pages, setPages] = useState(0);
    const [current_page, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState({ id: "", status: false });

    const navigate = useNavigate();
    const pathName = useLocation();
    const statePath = pathName.pathname.split('/')[2];

    useEffect(() => {
        getStateList()
    }, []);

    const getStateList = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}frp-states/state-list`)
            .then((response) => {
                setStateList(response?.data);
            });
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}frp-states/${statePath}/${id}/delete-city`)
            .then(() => {
                getStateList()
                setDeleteModal({ id: "", status: false });
            });
    };

    const handleClose = () => {
        setDeleteModal({ id: "", status: false });
    };

    const updateActiveStatus = (data) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}frp-states/${statePath}/${data.slug_url}/edit-city`, { ...data, active: !data?.active })
            .then(() => getStateList())
    };

    const handleOrder = (pathName) => {
        navigate(`/frp-states/${pathName}/view-cities`);
        getStateList()
    };

    const state = useMemo(() => {
        return stateList?.filter(
            (data) => data?.slug_url === statePath
        ).map((val) => val)
    }, [stateList])

    return (
        <>
            <div className="mt-4 p-4 bg-white">
                <Breadcrumbs
                    breadcrumbText="FRP"
                    breadcrumbLink="/frp-states"
                    nestedText="View Cities"
                    nestedLink={`/frp-states/${stateList[0]?.slug_url}/view-cities`}
                    nestedText2={state[0]?.state}
                    nestedLink2="#"
                />
                <div className="faq-addnew ms-2">
                    <div className="addNewfaq">
                        <div>
                            <img src={buttonadd} alt="" />
                        </div>
                        <div>
                            <button
                                onClick={() => navigate(`/frp-states/${state[0]?.slug_url}/add-city`)}
                            >
                                Add New City
                            </button>
                        </div>
                    </div>
                </div>
                <div className="faq-tab mb-2">
                    {stateList.map((data, idx) => {
                        return (
                            <div
                                key={idx}
                                className={`${statePath === data?.slug_url
                                    ? "rose-line"
                                    : ""
                                    } ${idx !== 0 ? "ms-5" : ""}`}
                                onClick={() => handleOrder(data?.slug_url)}
                            >
                                {data?.state}
                            </div>
                        );
                    })}
                </div>
                <div className="healtharticle-show m-0 ps-4">
                    <div>Show</div>
                    <div>
                        <select
                            className="me-2"
                        >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option value="0">All</option>
                        </select>
                    </div>
                    <div>entries</div>
                    <div>
                        <div>
                            <img src={search} alt="" />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="sortandfilter-new">
                        <div className="sortByPositionNew">
                            <div>
                                <div>Sort by</div>
                                <div>
                                    <img src={sort1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="sortByDepartment">
                            <div>
                                <div>
                                    <div>
                                        Filter
                                    </div>
                                </div>
                                <div>
                                    <img src={filter1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    {state[0]?.cities?.length ? (
                        <div className="frpStatesTable ms-4">
                            <table className="text-center w-100">
                                <thead>
                                    <tr>
                                        <td className="">S.No</td>
                                        <td className="">Cities</td>
                                        <td className="">Actions</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state[0]?.cities?.map((el, i) => {
                                        return (
                                            <tr key={el._id}>
                                                <td>{i + 1}</td>
                                                <td>{el.city}</td>
                                                <td>
                                                    <span
                                                        className="pe-3 text-primary cursorPointer"
                                                        onClick={() => navigate(`/frp-states/${statePath}/edit-city/${el.slug_url}`)}
                                                    >
                                                        Edit
                                                    </span>
                                                    <span
                                                        className="pe-3 text-danger cursorPointer"
                                                        onClick={() => setDeleteModal({ id: el._id, status: true })}
                                                    >
                                                        Delete
                                                    </span>
                                                    <span
                                                        className="pe-3 text-warning cursorPointer"
                                                        onClick={() => {
                                                            updateActiveStatus(el);
                                                        }}
                                                    >
                                                        {el.active ? "Inactive" : "Active"}
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="mt-4">No Cities Are Available </div>
                    )}
                </div>
                <div className="col d-flex pagination admin-pagination justify-content-end">
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<<"
                        // onClick={(e) => setCurrentPage(1)}
                        disabled={current_page === 1}
                    />
                    <input
                        type="button"
                        className="page-item page-link"
                        value="<"
                        // onClick={(e) => setCurrentPage(current_page - 1)}
                        disabled={current_page === 1}
                    />

                    {current_page > 1 && (
                        <input
                            type="button"
                            value={current_page - 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page - 1);
                            }}
                        />
                    )}
                    <input
                        className="page-item page-link"
                        type="button"
                        value={current_page}
                    />
                    {current_page < pages && (
                        <input
                            type="button"
                            value={current_page + 1}
                            onClick={(e) => {
                                // setCurrentPage(current_page + 1);
                            }}
                        />
                    )}
                    <input
                        type="button"
                        value=">"
                        className="page-item page-link"
                        // onClick={(e) => setCurrentPage(current_page + 1)}
                        disabled={current_page === pages}
                    />
                    <input
                        type="button"
                        value=">>"
                        className="page-link"
                        // onClick={(e) => setCurrentPage(pages)}
                        disabled={current_page === pages}
                    />
                </div>
            </div>
            {
                deleteModal.status === true && (
                    <Deletepopup
                        handleDelete={handleDelete}
                        id={deleteModal?.id}
                        isCloseModalEnable={true}
                        handleClose={handleClose}
                    />
                )
            }
        </>
    );
};

export default FrpCities;