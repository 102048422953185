import axios from "axios";
import Moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import './webBooking.scss';
import Deletepopup from "../modal/Deletepopup";
import AssignedTo from "../modal/AssignedTo";
import LeadStatus from "../modal/LeadStatus";
import SucessModal from "../modal/SucessModal";
import WebBookingInClinicConsultation from "./WebBookingInClinicConsultation";

const WebBookingOnlineConsultation = () => {
  const pathName = useLocation();

  const [webBookingsList, setWebBookingsList] = useState([]);
  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);

  const location = useLocation();

  useEffect(() => {
    getWebBookingsList()
  }, []);

  const getWebBookingsList = () => {

    axios
      .post(`${process.env.REACT_APP_API_URL}booking-details/list`)
      .then((response) => {
        setWebBookingsList(response?.data);
      });
  }

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}booking-details/delete/${id}`)
      .then(() => {
        getWebBookingsList()
        setDeleteModal({ id: "", status: false });
      });
  };

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };
  const handleNotInterested = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}booking-details/update/${id}`,
        {
          not_intrested: true,
          booking_confirmed: false,
          yet_to_contact: false,
        }
      )
      .then((result) => {
        getWebBookingsList();
      });
  };

  //NotContact Functionality
  const handleBookingConfirmed = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}booking-details/update/${id}`,
        {
          not_intrested: false,
          booking_confirmed: true,
          yet_to_contact: false,
        }
      )
      .then((result) => {
        getWebBookingsList();
      });
  };

  //On-Board Functionality
  const handleYetToContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}booking-details/update/${id}`,
        {
          not_intrested: false,
          booking_confirmed: false,
          yet_to_contact: true,
        }
      )
      .then((result) => {
        getWebBookingsList();
      });
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    getWebBookingsList()
  }

  return (
    <>
      <div className="pt-4">
        {webBookingsList?.length && location.pathname === "/web-bookings/online-consultations" ? (
          <div className=" webBookingsTable ms-4">
            <table>
              <thead>
                <tr>
                  <td className="">S.No</td>
                  <td className="">Name</td>
                  <td className="">Doctor Name</td>
                  <td className="">Mobile No</td>
                  <td className="">Email id</td>
                  <td className="">Health Conditions</td>
                  <td className="">About</td>
                  <td className="">Booking Date & Time</td>
                  <td className="">Action</td>
                </tr>
              </thead>
              <tbody>
                {webBookingsList.map((el, i) => {
                  return (
                    <tr key={el._id}>
                      <td>{i + 1}</td>
                      <td>{el.name}</td>
                      <td>{el.doctor_name}</td>
                      <td>{el.mobile}</td>
                      <td>{el.email}</td>
                      <td>{el.symptoms.join(", ")}</td>
                      <td>{el.about}</td>
                      <td>
                        {" "}
                        {Moment(el.createdAt).format("DD MMMM YYYY LT")}
                      </td>
                      <td className="">
                        <div
                          className={`text-warning cursorPointer ${el.not_intrested && "highlight"}`}
                          onClick={() => {
                            handleNotInterested(el._id);
                          }}
                        >
                          Not Interested</div>
                        <div
                          className="text-danger cursorPointer"
                          onClick={() => {
                            setDeleteModal({ id: el._id, status: true })
                          }}
                        >
                          Delete</div>
                        <div
                          className={`text-success cursorPointer ${el.booking_confirmed && "highlight"}`}
                          onClick={() => {
                            handleBookingConfirmed(el._id);
                          }}
                        >
                          Booking Confirmed</div>
                        <div
                          className={`text-primary cursorPointer ${el.yet_to_contact && "highlight"}`}
                          onClick={() => {
                            handleYetToContact(el._id);
                          }}
                        >
                          Yet to Contact
                        </div>

                        {/* <span
                            className="doctor-edit"
                            onClick={() => {
                              navigate(`/web-booking-update/${el._id}`);
                            }}
                          >
                            Edit
                          </span>
                          <span className="onboard-logo">
                            <img src={onboard} alt="edit-logo" />
                          </span> */}
                      </td>
                    </tr>
                  );
                })}

              </tbody>
            </table>
          </div>
        ) : (
          <WebBookingInClinicConsultation />
        )}
      </div>

      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}

      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
        />
      )}

      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
    </>
  );
};

export default WebBookingOnlineConsultation;