import axios from "axios";

export const UpdateLeadStatus = async (id = "", formType, data = {}) => {
  try {
    const response = await axios
      .patch(
        `${process.env.REACT_APP_API_URL}${formType}/update/${id}`,
        data
      );
    return { data: response?.data, status: response?.status }
  } catch (err) {
    console.log("Send email err - ", err);
  }
}