import { FaAngleRight } from "react-icons/fa";

const FilterRootModal = ({
  title = "Filter",
  filterOptions = [],
  onClickClear = () => { },
  onClickApply = () => { },
  selectOption = 1,
  setSelectOption = () => { }
}) => {

  return (
    <div className="filter-container filterRootModal">
      <div className="filterModalHeader fw-bold">{title}</div>
      <div className="filterModalHr"></div>
      <div className="">
        {filterOptions.map((data, inx) => (
          <div
            className="filterModalBox d-flex align-items-center"
            key={inx}
            onClick={() => {
              setSelectOption(inx + 1);
            }}
          >
            <div
              style={{
                color: selectOption === (inx + 1) ? "#CB1B5B" : "",
                fontWeight: selectOption === (inx + 1) ? "bold" : "",
              }}
            >
              {data}
            </div>
            <div>
              <FaAngleRight className="rightarrow" />
            </div>
          </div>
        ))}
      </div>
      <div className="filterFooterBtn pt-3">
        <button
          onClick={onClickClear}
          className="me-2"
        >
          Clear
        </button>
        <button
          className="ms-2"
          onClick={onClickApply}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default FilterRootModal