import axios from "axios";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import BackBtn from "../../Common/backBtn";
import SucessModal from "../../modal/SucessModal";
import InputTextField from "../../Common/inputTextField";
import InputRadioField from "../../Common/inputRadioField";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import apiCall from "../../../api";

const AddSitemapCategoryForm = ({
    edit = false
}) => {
    const { id } = useParams()
    const {
        register,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
        trigger
    } = useForm();

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        edit && getMenuData();
    }, [edit]);

    const getMenuData = async () => {
        try {
            const response = await apiCall(`sitemap-category/get/${id}`, "GET");
            if (response) {
                setValue('active', response.data.active);
                setValue('title', response.data.title);
                trigger()
            }
        } catch (err) {
            console.log(false)
        }
    }

    const onSubmit = (fields) => {
        if (edit) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}sitemap-category/update/${id}`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });

        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}sitemap-category/create`,
                    fields
                )
                .then((response) => {
                    if (response.data?._id) {
                        setSuccess(true);
                    } else {
                        alert("Fail");
                        console.log("failed...");
                    }
                });
        }
    };

    return (
        <>
            <BackBtn
                navigatePath="/category"
            />
            <div className="healtharticle mt-5 pt-5 ps-5">
                <div className="pt-3 ms-3">
                    <Breadcrumbs
                        breadcrumbText="Sitemap"
                        breadcrumbLink="/category"
                        nestedText="Common Masters"
                        nestedLink="#"
                    />
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="carr-dep-form"
                >
                    <div className="row car-dep-form">
                        <div className="col-5">
                            <InputTextField
                                register={register}
                                errors={errors}
                                labelName={`${edit ? "Edit" : "Add"} Sitemap Category`}
                                errorText="Please enter the Sitemap Category"
                                name="title"
                                placeholder="Type here..."
                            />
                        </div>

                        <div className="col-3">
                            <InputRadioField
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                watch={watch}
                            />
                        </div>
                    </div>

                    <div className="car-dep-form-button">
                        <button type="submit">
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {/* </Home> */}
            {success && (
                <SucessModal
                    page={"/sitemap-category"}
                    name="new sitemap category"
                    post={edit ? 'updated' : 'added'}
                />
            )}
        </>
    )
}

export default AddSitemapCategoryForm