import { useState, memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router";

import SucessModal from "../modal/SucessModal";
import { slugUrl } from "../../constants";

const AddNew = ({
    text = "",
    registerContent = "",
    serviceSlug = "",
    serviceCategorySlug = "",
    edit = false,
    healthConditionSlug="",
    citySlug="",
    sucessNavigate="/web-bookings/home-healthcare-bookings"
}) => {
  const navigate = useNavigate();

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm();

    const [success, setSuccess] = useState(false);
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        if(edit){

            if(text === "Health Condition"){
                getHealthCondition()
            } else if(text === "City"){
                getCity()
            } else {
                getServiceCategory()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceSlug])

    const getServiceCategory = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}services/${serviceSlug}/categories/${serviceCategorySlug}`)
            .then((response) => {
                setValue(registerContent, response?.data[registerContent])
                setChecked(response?.data?.active || false);
            });
    }

    const getHealthCondition = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}services/${serviceSlug}/${serviceCategorySlug}/${healthConditionSlug}`)
            .then((response) => {
                setValue(registerContent, response?.data[registerContent])
                setChecked(response?.data?.active || false);
            });
    }
    const getCity = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}services/${serviceSlug}/${serviceCategorySlug}/${citySlug}/get-city`)
            .then((response) => {
                setValue(registerContent, response?.data[registerContent])
                setChecked(response?.data?.active || false);
            });
    }

    const onSubmit = (fields) => {
        if (edit && text === "Service Category") {
                fields.slug_url = slugUrl(fields.service_category)
                axios
                    .put(`${process.env.REACT_APP_API_URL}services/${serviceSlug}/${serviceCategorySlug}/edit-service-category`, fields)
                    .then((response) => {
                        if (response.status === 200) {
                            setSuccess(true);
                        } else {
                            alert("Failed to edit category. Please try again");
                            console.log("failed...");
                        }
                    });
        }
        else {
            if (text === "Service Category") {
                fields.slug_url = slugUrl(fields.service_category)
                axios
                    .post(
                        `${process.env.REACT_APP_API_URL}services/create-service-category/${slugUrl(serviceSlug)}`,
                        fields
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            setSuccess(true);
                        } else {
                            alert("Failed to create category. Please try again");
                            console.log("failed...");
                        }
                    });
            } else if (text === "Health Condition" && edit){
                fields.slug_url = slugUrl(fields.health_condition);
                axios
                    .put(
                        `${process.env.REACT_APP_API_URL}services/${serviceSlug}/${serviceCategorySlug}/${healthConditionSlug}`,
                        fields
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            setSuccess(true);
                        } else {
                            alert("Failed to create Health Condition. Please try again");
                            console.log("failed...");
                        }
                    });

            } else if (text === "Health Condition") {
                fields.slug_url = slugUrl(fields.health_condition)
                axios
                    .post(
                        `${process.env.REACT_APP_API_URL}services/create-health-condition/${slugUrl(serviceSlug)}/${slugUrl(serviceCategorySlug)}`,
                        fields
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            setSuccess(true);
                        } else {
                            alert("Failed to create Health Condition. Please try again");
                            console.log("failed...");
                        }
                    });
                } else if (text === "City" && edit){
                    fields.slug_url = slugUrl(fields.city);
                    axios
                        .put(
                            `${process.env.REACT_APP_API_URL}services/${serviceSlug}/${serviceCategorySlug}/${citySlug}/edit-city`,
                            fields
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                setSuccess(true);
                            } else {
                                alert("Failed to create Health Condition. Please try again");
                                console.log("failed...");
                            }
                        });
    
            } else if (text === "City") {
                fields.slug_url = slugUrl(fields.city)
                axios
                    .post(
                        `${process.env.REACT_APP_API_URL}services/create-city/${slugUrl(serviceSlug)}/${slugUrl(serviceCategorySlug)}`,
                        fields
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            setSuccess(true);
                        } else {
                            alert("Failed to create City. Please try again");
                            console.log("failed...");
                        }
                    });
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mt-5 new-menu ms-1">
                    <div className="col-3">
                        <label className="form-label " htmlFor="">
                            {text} <span>*</span>
                        </label>
                        <input
                            className="form-control w-100 input-menu "
                            type="text"
                            name="name"
                            id="name"
                            {...register(registerContent, { required: true })}
                            placeholder={`Enter ${text}`}
                        />
                        {errors[registerContent] && (
                            <div className={`invalid-feedback d-block`}>
                                Please Enter {text}
                            </div>
                        )}
                    </div>

                    <div className="col">
                        <label htmlFor="" className="mb-3 ms-3 ">
                            Active <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex ">
                            <div className="form-check form-check-inline">
                                <input
                                    className="me-3"
                                    type="radio"
                                    name="active"
                                    id="active"
                                    value={true}
                                    checked={checked}
                                    onClick={() => setChecked(true)}
                                    {...register("active", { required: true })}
                                />
                                <label className="" htmlFor="inline1">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="me-3"
                                    type="radio"
                                    name="active"
                                    id="active"
                                    value={false}
                                    checked={!checked}
                                    onClick={() => setChecked(false)}
                                    {...register("active", { required: true })}
                                />
                                <label className="" htmlFor="inline2">
                                    No
                                </label>
                            </div>
                        </div>
                        {errors.status && (
                            <div className={`invalid-feedback d-block`}>
                                Please Select an Option
                            </div>
                        )}
                    </div>
                </div>
                <div className="Add-Btn">
                    <button className="add-button" type="submit">
                        {edit ? "Update" : "Add"}
                    </button>
                </div>
            </form>
            {success && (
                <SucessModal
                    page={"/web-bookings/home-healthcare-bookings"}
                    name={text === "Service Category" ? "Service Category" : text === "Health Condition" ? "Health Condition" : "City"}
                    post={edit ? 'updated' :'added'}
                    onClick= {() => navigate(sucessNavigate)}
                />
            )}
        </>
    )
}

export default memo(AddNew);