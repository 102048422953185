import React from "react";
import addIcon from "../../assets/images/add-icon.svg";

const MultipleAddOrg = ({
  register,
  errors,
  organisation,
  setOrganisation,
  isRequired = true

}) => {

  const addOrganisation = () => {
    setOrganisation([
      ...organisation,
      {
        [`organisation${organisation.length}`]: "",
        id: organisation.length,
      },
    ]);
  };

  const onUpdateOrganisation = (evt, id) => {
    const list = [...organisation];
    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return false;

    list[index][evt.target.name] = evt.target.value;
    setOrganisation(list);
  };

  const removeOrganisation = (id) => {
    const list = [...organisation];
    if (list.length === 1) return false;

    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return false;

    list.splice(index, 1);
    setOrganisation(list);
  };

  return (
    <div className="mb-32 col-9">
      <div className="d-flex justify-content-between">
        <label>
          Organisation Worked In{" "}
          {isRequired ? <span className="color-mandatory">*</span> : null}
        </label>{" "}
        <span
          onClick={() => addOrganisation()}
          className="addPlusIcon text-end text-white"
          style={{
            marginLeft: "0px",
            cursor: "pointer",
          }}
        >
          <img src={addIcon} className='mob-plus-icon' alt="Add"></img>
          Add
        </span>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
        }}
      >
        {organisation.map((item, idx) => (
          <div
            key={`input-organisation-${item.id}`}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              columnGap: "24px",
            }}
          >
            <div
              style={{ flexGrow: "1", flexShrink: "0" }}
            >
              <input
                type="text"
                style={{ width: "100%" }}
                {...register(`organisation${item.id}`, {
                  required: isRequired,
                })}
                placeholder="Add Organisation"
                defaultValue={item.value || null}
                onInput={(evt) =>
                  onUpdateOrganisation(evt, item.id)
                }
                className="form-control grayColor"
              // autoFocus
              />
              {errors[`organisation${item.id}`] && (
                <div
                  className={`invalid-feedback d-block`}
                >
                  Please provide a Organisation
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              {organisation.length > 1 ? (
                <span
                  onClick={() => removeOrganisation(item.id)}
                  className="plus-icon"
                  style={{
                    marginLeft: "0px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      height: "2px",
                      width: "50%",
                      backgroundColor: "#fff",
                    }}
                  ></span>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultipleAddOrg