import React from "react";
import Home from "../../../Home/Home";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const ViewNewServicdProvider = () => {
 
     const navigate = useNavigate();

  return (
   <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/home-healthcare-service-provider")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle-title ms-0">
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Doctor</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>
                <Link
                  to="/home-healthcare-service-provider"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  Home Healthcare Service Provider
                </Link>
              </span>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>View Home Healthcare Service Provider</span>
            </div>

            <div>

            <div className="doctor-track-header common-view-header-portion">
              <div>
                <img src="" alt="" />
              </div>
              <div>
                <div>Julie. R</div>
                <div>Nursing Support Service</div>
              </div>
              <div><span style={{fontWeight:"700", cursor:"pointer"}}>Edit</span></div>
            </div>

            {/* One */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-4 me-5">
              <p className="form-label " htmlFor="">
              Name of Home Healthcare Service Provider
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Primary Category
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Secondary category
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            </div>

            {/* Two */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="col">
              <label htmlFor="" className="mb-3">
              Gender  <span className="text-danger"></span>
              </label>
              <div className="d-flex ">
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="gender"
                    id="status"
                    checked
                  />
                  <label className="" htmlFor="inline1">
                    Male
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="gender"
                    id="status"
                  />
                  <label className="" htmlFor="inline2">
                    Female
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="gender"
                    id="status"
                  />
                  <label className="" htmlFor="inline2">
                    Others
                  </label>
                </div>
              </div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Date of Birth
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Age
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            </div>

            {/* Three */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-2 me-5">
              <p className="form-label " htmlFor="">
              Fees (INR) Per Hour
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-2 me-5">
              <p className="form-label " htmlFor="">
              Fees
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Registered Mobile Number
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Registered Email ID
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            </div>

            {/* Four */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              City / Town
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Pincode
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Registered License No
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            </div>

            {/* Five */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Experience
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Associate Council
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Select Known languages (Upto 4)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            </div>

            {/* Six */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Educational Qualification 1
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              College / University & Year
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Name of Hospital / Clinic (Employed / Owned)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            </div>

            {/* Seven */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Address of Service Provider (For Communication)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            </div>

            {/* Eight */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Date of Onboarding (Active Since)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="col">
              <label htmlFor="" className="mb-3">
              Verified  <span className="text-danger"></span>
              </label>
              <div className="d-flex ">
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="verified"
                    id="status"
                    checked
                  />
                  <label className="" htmlFor="inline1">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="verified"
                    id="status"
                  />
                  <label className="" htmlFor="inline2">
                    No
                  </label>
                </div>
              </div>
            </div>

            </div>

            {/* Nine */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-6">
              <p className="form-label " htmlFor="">
              Upload Header / Cover Video
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

            </div>

            {/* Ten */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              About (Opt of Max 100 words)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            </div>

            {/* Eleven */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-6">
              <p className="form-label " htmlFor="">
              Registration License Document(Upload Relevant Document)
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

            <div className="menu-field col-md-6">
              <p className="form-label " htmlFor="">
              Upload Cancelled Cheque Leaf
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

            </div>

            {/* Tweleve */}
            <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

            <div className="menu-field col-md-6">
              <p className="form-label " htmlFor="">
              Upload a valid Photo ID of Service Provider
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

            <div className="menu-field col-md-6">
              <p className="form-label " htmlFor="">
              Upload Ownership Document of Clinic / Hospital / Center
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

            </div>

            </div>
      {/* </Home> */}
    </>
  );
};

export default ViewNewServicdProvider