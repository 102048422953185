import React, { useState } from "react";
import "./form.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import backRed from "../../../../../assets/svg/back-rose-icon.svg";
import Home from "../../../../Home/Home";
import { useEffect } from "react";
import axios from "axios";

const DaysView = () => {

  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}days/get/${id}`)
      .then((res) => {
        console.log(res.data.type, "data...");
        setData(res.data);
      });
  };

  return (
    <>
    {/* <Home> */}
      <div
        onClick={() => navigate("/master-days")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-title">
          <Link
            to="/master-primary-specialisation"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Masters</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
          <Link
            to="/master-inclinic-fee"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Common</span>
          </Link>
          <span style={{ paddingLeft: "0.8%", fontSize: "15px" }}>></span>
          <span style={{ paddingLeft: "0.4%", fontSize: "15px" }}>
            View Days
          </span>
        </div>
        <div className="row mt-5 ms-2 new-menu ">
            <div className="menu-field col-md-3 me-0">
              <p className="form-label " htmlFor="">
               Days
              </p>
              <div className="blog-view-text-half-bor">{data.type}</div>
            </div>

            <div className="col">
                <label htmlFor="" className="mb-3 ms-3 ">
                  Active<span className="text-danger"></span>
                </label>
                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input
                      className="me-3"
                      type="radio"
                      name="active"
                      id="status"
                      value={data.active}
                      checked={data.active === true ? "checked" : null}
                    />
                    <label className="" htmlFor="inline1">
                      yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="me-3"
                      type="radio"
                      name="active"
                      id="status"
                      value={data.active}
                      checked={data.active === false ? "checked" : null}
                    />
                    <label className="" htmlFor="inline2">
                      No
                    </label>
                  </div>
                </div>
              </div>
          </div>
      </div>
    {/* </Home> */}
  </>
  )
}

export default DaysView