import axios from "axios";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { average, states, yesOrNo } from "../../../constants";

import AddButton from "../../Common/addButton";
import SucessModal from "../../modal/SucessModal";
import SelectField from "../../Common/selectField";
import NextBackBtns from "../../Common/nextBackBtns";
import InputTextField from "../../Common/inputTextField";
import CustomFieldsComp from "../../Common/customFields";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import CustomFieldsLevel from "../../Common/customFieldsLevel";
import DoctorFormCustomField from "../../modal/DoctorFormCustomField";

const pharmacyDiscount = [
	"Yes, I provide 12% discount on medicines",
	"Yes, I provide 14% discount on medicines",
	"Yes, I provide 15% discount on medicines",
	"Yes, I provide 16% discount on medicines",
	"Yes, I provide 18% discount on medicines",
	"Yes, I provide 20% discount on medicines",
	"No, I don't provide any discounts on medicines",
	// "Others"
]

const wholesaleLicense = [
	"Yes, I have both retail and wholesale license",
	"No, I have only retail license",
	"Yes, I have wholesale license"
]

const sales = [
	"Yes, I am using the following billing / sales / ERP software",
	"No, I am not using any billing / sales / ERP software"
]

const healthTech = [
	"Yes, I am currently part of a online pharmacy / health-tech platform",
	"No, I have not used nor be associated with any other online platforms for carrying out my business activities"
]

const discountOtc = [
	"Yes I provide 7 percent", "Yes I provide 5 percent",
	"Yes I provide discounts to healthcare products",
	"No I don’t provide discounts to healthcare products"
]

const deliverOrder = [
	"Yes",
	"No"
]

const PharmacyRegistrationEdit = () => {

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		control,
		trigger,
		getValues,
		formState: { errors },
	} = useForm();

	const { id } = useParams();
	const [location, setLocation] = useState("");
	// const [fssaiLicense, setFssaiLicense] = useState("No");
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		getBusinessType();
		getInventory();
		getStoreSize();
	}, []);

	const [businessType, setBusinessType] = useState([]);
	const [storesize, setStoreSize] = useState([]);
	const [inventory, setInventory] = useState([]);
	const [customFields, setCustomFields] = useState([])
	const [level, setLevel] = useState(1);
	const [customFieldModel, setCustomFieldModel] = useState(false)
	const [customFieldEditModel, setCustomFieldEditModel] = useState({ status: false, index: null })

	const getBusinessType = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}pharmacy-business-type/list`)
			.then((res) => {
				setBusinessType(res.data.rows);
			});
	};

	const getStoreSize = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}pharmacy-store-size/list`)
			.then((res) => {
				setStoreSize(res.data.rows);
			});
	};

	const getInventory = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}inventory-value/list`)
			.then((res) => {
				setInventory(res.data.rows);
			});
	};

	useEffect(() => {
		// getPrimaryspl();
		// getExperience();
		// getInClinic();
		getPharmacyRegistraionForm();
		// getGender();
	}, []);

	const retail_pharmacy_store = watch('poc_retail_pharmacy_store');

	const getPharmacyRegistraionForm = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}pharmacy-registration/get/${id}`)
			.then((response) => {
				setValue("first_name", response?.data?.first_name);
				setValue("last_name", response?.data?.last_name);
				setValue("email", response?.data?.email);
				setValue("mobile", response?.data?.mobile);
				setValue("pharmacy_town", response?.data?.pharmacy_town);
				setLocation(response?.data?.pharmacy_town);
				setValue("pharmacy_address", response?.data?.pharmacy_address);
				setValue("pharmacy_owner_name", response?.data?.pharmacy_owner_name);
				setValue("pincode", response?.data?.pincode);
				setValue("business_type", response?.data?.business_type);
				setValue("gst_no", response?.data?.gst_no);
				setValue("pharmacy_registration_number", response?.data?.pharmacy_registration_number);
				setValue("size", response?.data?.size);
				setValue("value_of_inventory", response?.data?.value_of_inventory);
				setValue("pharmacists_count", response?.data?.pharmacists_count);
				setValue("pharmacist_name", response?.data?.pharmacist_name);
				setValue("pharmacist_license_number", response?.data?.pharmacist_license_number);
				setValue("discounts_on_products", response?.data?.discounts_on_products);
				setValue("have_wholesale_license", response?.data?.have_wholesale_license);
				setValue("have_delivery_person", response?.data?.have_delivery_person);
				setValue("online_pharmacy", response?.data?.online_pharmacy);
				setValue("billing_software", response?.data?.billing_software);
				setValue("discount_on_OTC", response?.data?.discount_on_OTC);
				setValue("fssai_license_no", response?.data?.fssai_license_no);
				setValue("company_name", response?.data?.company_name);
				setValue("state", response?.data?.state);
				setValue("pharmacy_location", response?.data?.pharmacy_location);
				setValue("monthly_average_turnover", response?.data?.monthly_average_turnover);
				setValue("poc_retail_pharmacy_store", response?.data?.poc_retail_pharmacy_store);
				setValue("poc_name", response?.data?.poc_name);
				setValue("poc_number", response?.data?.poc_number);
				setValue("pharmacy_available", response?.data?.pharmacy_available);
				if (response?.data?.customFields?.length) {
					setCustomFields(response.data.customFields)
				}

				["pharmacy_start_timing", "pharmacy_end_timing"].forEach((keyName) => {
					const value = response?.data[keyName]
					if (value === "Invalid date") {
						setValue(keyName, "")
					} else if (value?.length <= 8) {
						setValue(keyName, value)
					} else {
						const formattedTime = moment(value).format("h:mm A");
						setValue(keyName, formattedTime)
					}
				})
			})
			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	};

	const onSubmit = (fields) => {
		fields.pharmacy_town = location;
		fields.languages_known = fields.languages_known?.length && fields.languages_known?.map((data) => data?.value)

		if (retail_pharmacy_store !== "Yes") {
			fields.poc_name = "";
			fields.poc_number = "";
		}

		if (!location) {
			setValue("location", "");
			trigger()
		} else {
			if (customFields) {
				fields.customFields = customFields;
			}
			axios
				.patch(
					`${process.env.REACT_APP_API_URL}pharmacy-registration/update/${id}`, fields)
				.then((response) => {
					setSuccess(true);
					reset()
				});
		}
	}

	return (
		<>
			<div className="healtharticle healthcareEditFormWrapper">
				<div className="healtharticle-container">
					<div className="px-3 d-flex justify-content-between">

						<Breadcrumbs
							breadcrumbText="HealthSynergy"
							breadcrumbLink="/doctor-registration"
							nestedText2="HealthSy Partnered Pharmacy Network Programme"
							nestedLink2="/pharmacy-registration"
							nestedText3="Edit"
							nestedLink="#"
							className="w-auto"
						/>
						<div className="doctorAddNewField">
							<AddButton
								onClick={() => setCustomFieldModel(true)}
								btnClassName="fw-bold w-100"
								btnName="Add New Field"
							/>
						</div>
					</div>
					{customFields?.length ? <CustomFieldsLevel
						page={level}
						customFieldLength={customFields?.length}
					/> : <></>}
					<form className="addEditInsiders" onSubmit={handleSubmit(onSubmit)}>
						{(level === 1 || !customFields?.length) ? <div className="doctor-page1">
							{/* one */}
							<div className="addnew-form1-autor-detail mt-3 d-flex">
								<div className="col-md-4">
									<label for="inputAddress" className="form-label title">
										First Name <span className="star">*</span>
									</label>
									<input
										type="text"
										{...register("first_name", { required: true })}
										className="form-control addnew-input"
										// id="authordetails"
										style={{ paddingLeft: "10px" }}
									/>
									{errors.first_name && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Name
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label for="inputAddress" className="form-label title">
										Last Name <span className="star">*</span>
									</label>
									<input
										type="text"
										{...register("last_name", { required: false })}
										className="form-control addnew-input"
										// id="authordetails"
										style={{ paddingLeft: "10px" }}
									/>
									{errors.last_name && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Name
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label title">
										Pharmacy Name <span className="star">*</span>
									</label>
									<input
										className="form-control addnew-input"
										type="text"
										{...register("pharmacy_owner_name", { required: true })}
									/>
									{errors.pharmacy_owner_name && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Pharmacy Name
										</div>
									)}
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-4">
									<label for="inputAddress" className="form-label title">
										Mobile Number <span className="star">*</span>
									</label>
									<input
										type="text"
										{...register("mobile", { required: true })}
										className="form-control addnew-input"
										// id="mobile"
										// placeholder="Type here"
										style={{ paddingLeft: "10px" }}
									/>
									{errors.mobile && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Mobile Number
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label for="inputAddress" className="form-label title">
										Email <span className="star">*</span>
									</label>
									<input
										type="text"
										{...register("email", { required: true })}
										className="form-control addnew-input"
										// id="authordetails"
										style={{ paddingLeft: "10px" }}
									/>
									{errors.email && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Email
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label title">
										Pharmacy Business Type <span className="star">*</span>
									</label>
									<select
										{...register("business_type", { required: true })}
										className="form-control addnew-input"
										defaultValue={""}
									>
										<option value="" disabled pri-specialization="true">
											Select Your business type
										</option>
										{businessType.map((option, idx) => {
											return (
												<option key={idx}>
													{option.type}
												</option>
											)
										})}
									</select>
									{errors.business_type && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Pharmacy Business Type
										</div>
									)}
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-4">
									<label className="form-label title">
										Company Name{" "}
										<span className="fw-light">
											{" "}
										</span>
									</label>
									<input
										className="form-control addnew-input"
										type="text"
										{...register("company_name")}
									/>
								</div>
								<div className="col-md-4">
									<label className="form-label title">
										Pharmacy GST Number <span className="star">*</span>
									</label>
									<input
										className="form-control addnew-input"
										type="text"
										{...register("gst_no", { required: true })}
									/>
									{errors.gst_no && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Pharmacy GST Number
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label title">
										Drug License Number <span className="star">*</span>
									</label>
									<input
										className="form-control addnew-input"
										type="text"
										{...register("pharmacy_registration_number", { required: true })}
									/>
									{errors.pharmacy_registration_number && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Pharmacy Drug License Number
										</div>
									)}
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-4">
									<label className="form-label">
										FSSAI Number{" "}
										<span className="star">*</span>
									</label>{" "}
									<input
										className="form-control addnew-input"
										type="text"
										{...register("fssai_license_no", { required: true })}
									/>
									{errors.fssai_license_no && (
										<div className={`invalid-feedback d-block`}>
											Please provide a FSSAI number
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label title">
										Pharmacist Name <span className="star">*</span>
									</label>
									<input
										className="form-control addnew-input"
										type="text"
										{...register("pharmacist_name", { required: true })}
									/>
									{errors.pharmacist_name && (
										<div className={`invalid-feedback d-block`}>
											Please provide pharmacist name *
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label title">
										Your pharmacist's license number <span className="star">*</span>
									</label>
									<input
										className="form-control addnew-input"
										type="text"
										{...register("pharmacist_license_number", { required: true })}
									/>
									{errors.pharmacist_license_number && (
										<div className={`invalid-feedback d-block`}>
											Please provide Your pharmacist's license number *
										</div>
									)}
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-4">
									<label className="form-label title">
										State <span className="star">*</span>
									</label>
									<select
										{...register("state", { required: true })}
										className="form-control addnew-input"
										defaultValue={""}
									>
										<option value="" disabled pri-specialization="true">
											Select State
										</option>
										{states.map((option, inx) => {
											return (
												<option key={inx} value={option}>
													{option}
												</option>
											);
										})}
									</select>
									{errors.state && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Location
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label for="inputAddress" className="form-label title">
										City{" "}
										<span className="star">*</span>
									</label>
									<Controller
										control={control}
										name="pharmacy_town"
										rules={{ required: "City is required" }}
										render={({ field }) => (
											<Autocomplete
												apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
												value={field.value}
												onChange={(event) => {
													field.onChange(event.target.value)
													if (location) {
														setLocation("")
													}
												}
												}
												onPlaceSelected={(place) => {
													field.onChange(place ? place.formatted_address : "")
													setLocation(place.formatted_address)
												}}
												types={["address"]}
												className={`form-control  addnew-input`}
												componentrestrictions={{ country: "us" }}
												preventdefault="true"
											/>
										)}
									/>
									{errors.pharmacy_town && (
										<div className={`invalid-feedback d-block`}>
											Please provide a Location
										</div>
									)}
								</div>
								<div className="col-md-4">
									<InputTextField
										labelName="Pharmacy Location"
										name="pharmacy_location"
										register={register}
										errors={errors}
										type="text"
										className="w-25 grayColor"
										errorText={"Please provide a pharmacy location"}
									/>
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-4">
									<label className="form-label title">
										Pincode Location of your Pharmacy <span className="star">*</span>
									</label>
									<input
										className="form-control addnew-input"
										type="text"
										{...register("pincode", {
											required: true,
											min: 110000,
											pattern: /[0-9]{6}/,
										})}
										maxLength="6"
										onKeyPress={(e) => {
											if (!/[0-9]/.test(e.key)) {
												e.preventDefault();
											}
										}}
									/>
									{errors.pincode && (
										<div className={`invalid-feedback d-block`}>
											Please provide a valid Pincode Location of your Pharmacy
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label">
										Medicine Discounts (%){" "}
										<span className="star">*</span>
									</label>{" "}
									<br />
									<select
										{...register("discounts_on_products", { required: true })}
										className="form-control addnew-input"
										defaultValue={""}
									>
										<option value="" disabled pri-specialization="true">
											Select Your business type
										</option>
										{pharmacyDiscount.map((option, idx) => {
											return (
												<option key={idx}>
													{option}
												</option>
											)
										})}
									</select>
									{errors.discounts_on_products && (
										<div className={`invalid-feedback d-block`}>
											Please select an option
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label">
										Healthcare Product Discounts (%){" "}
										<span className="star">*</span>
									</label>{" "}
									<select
										{...register("discount_on_OTC", { required: true })}
										className="form-control addnew-input mt-3"
										defaultValue={""}
									>
										<option value="" disabled pri-specialization="true">
											Select Your business type
										</option>
										{discountOtc.map((option, idx) => {
											return (
												<option key={idx}>
													{option}
												</option>
											)
										})}
									</select>
									{errors.discount_on_OTC && (
										<div className={`invalid-feedback d-block`}>
											Please select an option
										</div>
									)}
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-11">
									<label className="form-label title">
										Pharmacy Address <span className="star">*</span>
									</label>
									<textarea
										className="form-control addnew-textArea"
										type="textArea"
										placeholder="Enter Address"
										{...register("pharmacy_address", { required: true })}
									/>
								</div>
								{errors.pharmacy_address && (
									<div className={`invalid-feedback d-block`}>
										Please Enter the Address
									</div>
								)}
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">

								<div className="col-md-4">
									<label className="form-label">
										What is the approximate size of your pharmacy store?{" "}
										<span className="star">*</span>
									</label>{" "}
									<br />
									<div className="d-flex gap-3">
										<select
											{...register("size", { required: true })}
											className="form-control addnew-input"
											// defaultValue={""}
											value={getValues().size}
											onChange={(e) => (setValue("size", e.target.value), trigger())}>
											<option value="" disabled pri-specialization="true">
												Select the your pharmacy store
											</option>
											{storesize.map((el, idx) => {
												return (
													<option key={idx}>{el.type}</option>
												)
											})
											}
										</select>
										{errors.size && (
											<div className={`invalid-feedback d-block`}>
												Please select an option
											</div>
										)}
									</div>
								</div>
								<div className="col-md-4">
									<label className="form-label title">
										What is the value of inventory you have in your pharmacy store?{" "}
										<span className="star">*</span>
									</label>{" "}
									<br />
									<div className="d-flex gap-3 mt-1">
										<select
											{...register("value_of_inventory", { required: true })}
											className="form-control addnew-input"
											// defaultValue={""}
											value={getValues().value_of_inventory}
											onChange={(e) => (setValue("value_of_inventory", e.target.value), trigger())}>
											<option value="" disabled pri-specialization="true">
												Select the inventory in your pharmacy store
											</option>
											{inventory.map((el, inx) => {
												return (
													<option key={inx}>{el.type}</option>
												)
											})}
										</select>
									</div>
									{errors.value_of_inventory && (
										<div className={`invalid-feedback d-block`}>
											Please select an option
										</div>
									)}
								</div>
								<div className="col-md-4">
									<SelectField
										id="monthlyAverageTurnover"
										labelName="Monthly Average Turnover"
										name="monthly_average_turnover"
										options={average}
										className="form-control addnew-input mt-2"
										register={register}
										errors={errors}
										errorText="Please choose a State"
										defaultOption="State"
									/>
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-4">
									<label className="form-label title">
										How many pharmacists are working in your pharmacy?{" "}
										<span className="star">*</span>
									</label>
									<input
										className="form-control addnew-input mt-2"
										type="number"
										{...register("pharmacists_count", { required: true })}
									/>

									{errors.pharmacists_count && (
										<div className={`invalid-feedback d-block`}>
											Please provide How many pharmacists are working in your
											pharmacy?
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label">
										Do you have a delivery person / staff to deliver orders to your
										customers? <span className="star">*</span>
									</label>{" "}
									<br />
									<select
										{...register("have_delivery_person", { required: true })}
										className="form-control addnew-input mt-2"
										defaultValue={""}
									>
										<option value="" disabled pri-specialization="true">
											Select Your delivery person
										</option>
										{deliverOrder.map((option, idx) => {
											return (
												<option key={idx}>
													{option}
												</option>
											)
										})}
									</select>
									{errors.have_delivery_person && (
										<div className={`invalid-feedback d-block`}>
											Please select an option
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label">
										Are you currently part of any online pharmacy / health-tech
										platforms ? <span className="star">*</span>
									</label>{" "}
									<select
										{...register("online_pharmacy", { required: true })}
										className="form-control addnew-input mt-2"
										defaultValue={""}
									>
										<option value="" disabled pri-specialization="true">
											Select Your pharmacy
										</option>
										{healthTech.map((option, idx) => {
											return (
												<option key={idx}>
													{option}
												</option>
											)
										})}
									</select>
									{errors.online_pharmacy && (
										<div className={`invalid-feedback d-block`}>
											Please select an option
										</div>
									)}
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-4">
									<label className="form-label">
										Do you have a wholesale license? <span className="star">*</span>
									</label>{" "}
									<select
										{...register("have_wholesale_license", { required: true })}
										className="form-control addnew-input mt-4"
										defaultValue={""}
									>
										<option value="" disabled pri-specialization="true">
											Select Your business type
										</option>
										{wholesaleLicense.map((option, idx) => {
											return (
												<option key={idx}>
													{option}
												</option>
											)
										})}
									</select>
									{errors.have_wholesale_license && (
										<div className={`invalid-feedback d-block`}>
											Please select an option
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label">
										Do you currently use any billing / sales / ERP software?{" "}
										<span className="star">*</span>
									</label>{" "}
									<select
										{...register("billing_software", { required: true })}
										className="form-control addnew-input"
										defaultValue={""}
									>
										<option value="" disabled pri-specialization="true">
											Select Your business type
										</option>
										{sales.map((option, idx) => {
											return (
												<option key={idx}>
													{option}
												</option>
											)
										})}
									</select>
									{errors.billing_software && (
										<div className={`invalid-feedback d-block`}>
											Please select an option
										</div>
									)}
								</div>
								<div className="col-md-4">
									<label className="form-label">
										Is your pharmacy available on Sundays <span className="star">*</span>
									</label>{" "}
									<br />
									<select
										{...register("pharmacy_available", { required: true })}
										className="form-control addnew-input mt-2"
										defaultValue={""}>
										<option value="" disabled pri-specialization="true">Select</option>
										{deliverOrder.map((option, idx) => {
											return (
												<option key={idx} value={option}>{option}</option>
											)
										})}
									</select>
									{errors.pharmacy_available && (
										<div className={`invalid-feedback d-block`}>
											Please select an option
										</div>
									)}
								</div>
							</div>
							<div className="addnew-form1-autor-detail mt-5 d-flex">
								<div className="col-md-4">
									<SelectField
										id="retailPharmacyStore"
										labelName="Do you have a PoC for your retail pharmacy store?"
										name="poc_retail_pharmacy_store"
										options={yesOrNo}
										className="form-control addnew-input mt-2"
										register={register}
										errors={errors}
										errorText="Please choose a PoC for Retail Pharmacy Store"
										defaultOption="an option"
									/>
								</div>
								<div className="col-md-4">
									<label className="form-label">What is your pharmacy work timings <span className="star">*</span></label>
									<div className="d-flex pharmacyTimingsWrapper">
										<Controller
											// name="time"
											name={`pharmacy_start_timing`}
											className="me-3"
											control={control}
											rules={{
												required: 'Please choose a time or choose closed'
											}}
											render={({ field }) => (
												<>
													<div className="me-3">
														<div className="timePickerIcon">
															{/* <BsClock size={22} /> */}
														</div>
														{/* <input type="time" placeholder="HH:MM" /> */}
														<TimePicker
															{...field}
															showSecond={false}
															className="pharmacyTimings"
															allowEmpty={true}
															use12Hours={true}
															format="h:mm a"
															onChange={(value) => {
																field.onChange(value);
															}}
															value={field.value ? moment(field.value, 'HH:mm A') : null}
															placeholder="Start Time"
														/>
													</div>
												</>
											)}
										/>
										<Controller
											// name="time"
											name={`pharmacy_end_timing`}
											control={control}
											rules={{
												required: 'Please choose a time or choose closed'
											}}
											render={({ field }) => (
												<>
													<div className="">
														<div className="timePickerIcon">
															{/* <BsClock size={22} /> */}
														</div>
														{/* <input type="time" placeholder="HH:MM" /> */}
														<TimePicker
															{...field}
															showSecond={false}
															className="pharmacyTimings"
															allowEmpty={true}
															use12Hours={true}
															format="h:mm a"
															onChange={(value) => {
																field.onChange(value);
															}}
															value={field.value ? moment(field.value, 'HH:mm A') : null}
															placeholder="End Time"
														/>
													</div>
												</>
											)}
										/>
									</div>
									{errors[`pharmacy_start_timing`] || errors[`pharmacy_end_timing`] ? (
										<div className="invalid-feedback d-block">
											{/* {errors[`${option}_start_timing`].message} */}
											Please provide a {errors[`pharmacy_start_timing`] && errors[`pharmacy_end_timing`] ? "Start and End Timings" : errors[`pharmacy_start_timing`] ? "Start Timing" : "End Timing"}
										</div>
									) : ""}
								</div>
							</div>
							<div>
								{retail_pharmacy_store === 'Yes' && (
									<div className="d-flex mt-4">
										<div className="col-md-4">
											<InputTextField
												labelName="Poc Name"
												name="poc_name"
												register={register}
												errors={errors}
												errorText="Please provide a Name"
												placeholder="Enter the Poc Name"
											/>
										</div>
										<div className="col-md-4">
											<InputTextField
												labelName="Poc Number"
												name="poc_number"
												type="number"
												register={register}
												errors={errors}
												errorText="Please provide a Poc Number"
												placeholder="Enter the Poc Number"
											/>
										</div>
									</div>
								)}
							</div>
							<NextBackBtns
								back={false}
								next={customFields?.length ? true : false}
								handleNext={() => setLevel(2)}
								submitBtn={true}
								submitBtnText={"Update"}
							/>
						</div> : (
							<CustomFieldsComp
								customFields={customFields}
								setCustomFields={setCustomFields}
								setCustomFieldEditModel={setCustomFieldEditModel}
								register={register}
								customFieldsLength={customFields?.length}
								errors={errors}
								setLevel={setLevel}
								level={level}
							/>
						)}
					</form>
				</div>
			</div>
			{success && (
				<SucessModal
					page={"/pharmacy-registration"}
					name="Retail Pharmacy"
					post="updated"
				/>
			)}
			{customFieldModel && (
				<DoctorFormCustomField
					setCustomFieldModel={setCustomFieldModel}
					setCustomFields={setCustomFields}
					setLevel={setLevel}
					level={level}
					customFieldsLength={customFields?.length}
				/>
			)}
			{customFieldEditModel?.status && (
				<DoctorFormCustomField
					setCustomFieldModel={setCustomFieldModel}
					setCustomFieldEditModel={setCustomFieldEditModel}
					setCustomFields={setCustomFields}
					setLevel={setLevel}
					customFieldIndex={customFieldEditModel?.index}
					customFieldValue={customFields[customFieldEditModel?.index]}
					edit={true}
					level={level}
					customFieldsLength={customFields?.length}
				/>
			)}
		</>
	)
}

export default PharmacyRegistrationEdit;