const InputTextField = ({
	labelName = "",
	name = "",
	register,
	id = "",
	type = "text",
	errors,
	errorText = "",
	placeholder = "",
	isRequired = true,
	className = "",
	disable = false,
	onInput = () => { }
}) => {

	return (
		<>
			<label
				className="form-label title mb-2"
				htmlFor={id}
			>
				{labelName} {isRequired ? <span className="star">*</span> : null}
			</label>
			<input
				className={`form-control w-75 ${className}`}
				type={type}
				name={name}
				id={id}
				{...register(name, { required: isRequired })}
				placeholder={placeholder}
				disabled={disable}
				onInput={onInput}
			/>
			{errors[name] && (
				<div className={`invalid-feedback d-block`}>
					{errorText}
				</div>
			)}
		</>
	)
}

export default InputTextField;