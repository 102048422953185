import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Home from "../../Home/Home";
import backRed from "../../../assets/svg/back-rose-icon.svg";
import { useForm } from "react-hook-form";
import { useState } from "react";
import "./index.scss";
import greenround from "../../../assets/images/greenround.PNG";
import greentick from "../../../assets/images/greentick.PNG";
import graytick from "../../../assets/images/graytick.PNG";
import { ImageUploadAndPreview } from "../../Pages/HealthArticle/AddNew/AddNew";
import next from "../../../assets/svg/next-icon.svg";
import back from "../../../assets/svg/back-icon.svg";
import SucessModal from "../../modal/SucessModal";

const AddNewPharmacyForm = () => {
  
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [success, setSuccess] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleNext = () => {
    setPage(2);
  };

  const handleNext1 = () => {
    setPage(3);
  };

  const handleBack = () => {
    setPage(1);
  };

  const handleBack1=()=>{
    setPage(2)
  }

  return (
    <>
      {/* <Home> */}

        {page === 1 ? (
          <div
            onClick={() => navigate("/add-new-retail-pharmacy")}
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        ) : page === 2 ? (
          <div onClick={handleBack} className="rose back-button">
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        ) : page === 3 ? (
          <div onClick={handleBack1} className="rose back-button">
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        ) : null}

        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ fontSize: "15px", paddingLeft: "1%" }}>{`>`}</span>
              <Link
                to="/add-new-retail-pharmacy"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  paddingLeft: "0.4%",
                }}
              >
                <span>Partnered Retail Pharmacies</span>
              </Link>
              <span style={{ paddingLeft: "1%", fontSize: "15px" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
                Add New Partnered Retail Pharmacy
              </span>
            </div>

            {page === 1 ? (
              <div className="doctor-page-tick">
                <div>
                  <img src={greenround} alt="" />
                  <div>Page 1</div>
                </div>
                <div>
                  <img src={graytick} alt="" />
                  <div>Page 2</div>
                </div>
                <div>
                  <img src={graytick} alt="" />
                  <div>Page 3</div>
                </div>
              </div>
            ) : page === 2 ? (
              <div className="doctor-page2-tick">
                <div>
                  <img src={greentick} alt="" />
                  <div>Page 1</div>
                </div>
                <div>
                  <img src={greenround} alt="" />
                  <div>Page 2</div>
                </div>
                <div>
                  <img src={graytick} alt="" />
                  <div>Page 3</div>
                </div>
              </div>
            ) : page === 3 ? (
              <div className="doctor-page3-tick">
                <div>
                  <img src={greentick} alt="" />
                  <div>Page 1</div>
                </div>
                <div>
                  <img src={greentick} alt="" />
                  <div>Page 2</div>
                </div>
                <div>
                  <img src={greentick} alt="" />
                  <div>Page 3</div>
                </div>
              </div>
            ) : null}

            <form className="career-jobpost-form">
              {page === 1 ? (
                <div className="doctor-page1">
                  {/* one */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Name of Pharmacy / Chemist Store{" "}
                        <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-8">
                      <label for="inputAddress" className="form-label title">
                        Pharmacy Address <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* two */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-2">
                      <label for="inputAddress" className="form-label title">
                        Locality <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-2 ms-5">
                      <label for="inputAddress" className="form-label title">
                        City / Town <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-2 ms-5">
                      <label for="inputAddress" className="form-label title">
                        State<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Pincode of Pharmacy Location
                        <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* three */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Pharmacy Business Type <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Company Name <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Pharmacy Owner / Manager Name <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* four */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Mobile Number (For all Communication){" "}
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Email ID (For all Communication){" "}
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Telephone Number (opt){" "}
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* five */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Pharmacist Name{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Pharmacist License No{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Pharmacist Mobile No{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="addnew-form1-next">
                    <div onClick={handleNext}>
                      <div>
                        <button>NEXT</button>
                      </div>
                      <div>
                        <img src={next} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : page === 2 ? (
                <div className="doctor-page2">
                  {/* one */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Pharmacy GST Number{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Pharmacy License Number{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Upload Image GST Document{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* two */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        FSSAI License No{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Upload Image of License No{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Upload Image of FSSAI License{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* three */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        PAN Details{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Upload Pan Card Image <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Upload Store Images (Indoor & Outdoor){" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Four */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="row mt-3 col-md-6">
                      <div className="col">
                        <label className=" small mb-2" htmlFor="">
                          ERP Software Available <span className="star">*</span>
                        </label>
                        <div className="d-flex">
                          <div className="form-check form-check-inline me-3 mt-1">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              name="erp"
                              id="erp"
                              value="Active"
                              checked
                            />
                            <label
                              htmlFor="option-1"
                              className="text-dark mt-1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="erp"
                              id="erp"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors.status && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select an Option
                        </div>
                      )}
                    </div>

                    <div className="row mt-3" style={{ marginLeft: "-25%" }}>
                      <div className="col">
                        <label className=" small mb-2" htmlFor="">
                          Internet Available <span className="star">*</span>
                        </label>
                        <div className="d-flex">
                          <div className="form-check form-check-inline me-3 mt-1">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              name="internet"
                              id="internet"
                              value="Active"
                              checked
                            />
                            <label
                              htmlFor="option-1"
                              className="text-dark mt-1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="internet"
                              id="internet"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors.status && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select an Option
                        </div>
                      )}
                    </div>

                    <div className="row mt-3 ms-5">
                      <div className="col">
                        <label className=" small mb-2" htmlFor="">
                          Smartphone / Desktop / Laptop Available{" "}
                          <span className="star">*</span>
                        </label>
                        <div className="d-flex">
                          <div className="form-check form-check-inline me-3 mt-1">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              name="desktop"
                              id="desktop"
                              value="Active"
                              checked
                            />
                            <label
                              htmlFor="option-1"
                              className="text-dark mt-1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="desktop"
                              id="desktop"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors.status && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select an Option
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Five */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="row mt-3">
                      <div className="col">
                        <label className=" small mb-2" htmlFor="">
                          Do you have Delivery Facility{" "}
                          <span className="star">*</span>
                        </label>
                        <div className="d-flex">
                          <div className="form-check form-check-inline me-3 mt-1">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              name="facility"
                              id="facility"
                              value="Active"
                              checked
                            />
                            <label
                              htmlFor="option-1"
                              className="text-dark mt-1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="facility"
                              id="facility"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors.status && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select an Option
                        </div>
                      )}
                    </div>

                    <div className="col-md-2 ms-5 mt-3">
                      <label for="inputAddress" className="form-label title">
                        Medicines %
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-2 ms-5 mt-3">
                      <label for="inputAddress" className="form-label title">
                        OTC's % 
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-2 ms-5 mt-3">
                      <label for="inputAddress" className="form-label title">
                        Wellness & Healthcare
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Six */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="col-md-2">
                      <label for="inputAddress" className="form-label title">
                        Business Start Day <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-2 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Working Days <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-2 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Working Hours <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="addnew1-form1-next">
                    <div className="addnew1-back-next">
                      <div className="addnew1-back">
                        <div onClick={handleBack}>
                          <div>
                            <img src={back} alt="" />
                          </div>
                          <div>BACK</div>
                        </div>
                      </div>
                      <div className="pharm-nxt1">
                        <div onClick={handleNext1}>
                          <div>
                            <button>NEXT</button>
                          </div>
                          <div>
                            <img src={next} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : page === 3 ? (
                <div className="addnewpharm3">

                  <div className="addnewpharm3-title">Inventory Details ——</div>

                  {/* one */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">

                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Total Inventory Value (₹INR) App{" "}
                        <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Total Medicine (Rx) in Stock{" "}
                        <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Medicines Inventory{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>

                  </div>

                  {/* Two */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">

                  <div className="col-md-2">
                      <label for="inputAddress" className="form-label title">
                        Total OTCs in Stock <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        OTCs Inventory{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-2 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Wellness & Healthcare Products in Stock <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="authordetails"
                        placeholder="Type here"
                        // style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Wellness & Healthcare Products in Stock{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>

                  </div>

                  <div className="addnewpharm3-title">Bank Details ——</div>

                  {/* three */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Account Holder Name{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Account number{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        IFSC{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Four */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Account Type{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Bank Name{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Branch Name{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Five */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">

                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        UPI ID{" "}<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="addnew1-form1-next">
                <div className="addnew1-back-next">
                  <div className="addnew1-back">
                    <div 
                      onClick={handleBack1}
                    >
                      <div>
                        <img src={back} alt="" />
                      </div>
                      <div>BACK</div>
                    </div>
                  </div>
                  <div className="addnew1-next">
                  <div>
                    <button
                       type="button"
                       onClick={()=>{
                        setSuccess(true)
                       }}
                       className="bg-transparent border-0 text-white"
                       data-bs-toggle="modal"
                       data-bs-target="#exampleModal2"
                    >ADD</button>
                  </div>
                  </div>
                </div>
              </div>

                </div>
              ) : null}
            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal 
          page={"/home-healthcare-hhsp"} 
          name="Home Healthcare HHSP" 
          post='posted'
        />
      )}
    </>
  );
};

export default AddNewPharmacyForm;
