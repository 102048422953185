import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../../assets/images/searchIcon.png";
import buttonadd from "../../../assets/images/plus.png";
import "./index.scss";
import { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import Autocomplete from "react-google-autocomplete";

const DoctorInclinicAppointment = () => {
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [filter, setFilter] = useState({});
  const [records, setRecords] = useState([]);

  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);
  const [filtershow, setFilterShow] = useState(false);
  const [submitfilter, setSubmitFilter] = useState(false);
  const [expdata, setExpData] = useState([]);
  const [languagedata, setLanguageData] = useState([]);
  const [spldata, setSplData] = useState([]);
  const [location, setLocation] = useState("");
  const [isChecked, setIsChecked] = useState(true);

  const experience = [
    {
      text: "Less than 1 Years",
    },
    {
      text: "1 - 2 Years",
    },
    {
      text: "2 - 3 Years",
    },
    {
      text: "3 - 5 Years",
    },
    {
      text: "5+ Years",
    },
  ];

  const language = [
    {
      text: "Tamil",
    },
    {
      text: "Telugu",
    },
    {
      text: "Hindi",
    },
    {
      text: "English",
    },
    {
      text: "Malayalam",
    },
    {
      text: "Kannada",
    },
    {
      text: "Bengali",
    },
    {
      text: "Marathi",
    },
    {
      text: "Punjabi",
    },
    {
      text: "Others",
    },
  ];

  const primaryspl = [
    {
      text: "Andrology",
    },
    {
      text: "Ayurveda",
    },
    {
      text: "Allergist",
    },
    {
      text: "Bariatrics",
    },
    {
      text: "Cardiology",
    },
    {
      text: "Cosmetology",
    },
    {
      text: "Dentistry",
    },
  ];

  const navigate = useNavigate();

  const handleNewClinicForm = () => {
    navigate("/add-new-inclinic-form");
  };

  const handleEdit = () => {
    navigate("/edit-inclinic-form");
  };

  const data = [
    {
      sno: "1",
      name: "Anuj K",
      spl: "Andrology",
      clinic: "careline Fertility Clinic, Wonderkids Healthcare Hospitals,",
      fee: "399",
      languages: ["English", "Hindi", "Kannada"],
      exp: "6",
      profile: "",
      mobile: "+91 98654 23856",
      email: "anuj.k@gmail.com",
      time: "",
      mrn: "KA242196421",
      location: "Bangalore",
      state: "Karnataka",
    },
    {
      sno: "2",
      name: "Ankil G",
      spl: "Genereal Physician",
      clinic: "Medspa Clinic, Goodwell Hospital",
      fee: "299",
      languages: ["English", "Marathi"],
      exp: "3",
      profile: "",
      mobile: "+91 98654 23856",
      email: "ankil.g@gmail.com",
      time: "",
      mrn: "MH942R2414",
      location: "Mumbai",
      state: "Maharastra",
    },
  ];

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Link
              to="/add-new-doctors"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>{`>`}</span>
            <Link
              to=""
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span>Clinic & Hospital</span>
            </Link>
            <Link
              to=""
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "0%", paddingRight: "0%" }}>{`>`}</span>
              <span>List of Doctors in In-Clinic Appointment</span>
            </Link>
          </div>

          <div className="healtharticle-addnew">
            <div
              id="addnewpost"
              onClick={handleNewClinicForm}
              className="addnewhealth"
              style={{ width: "310px" }}
            >
              <div>
                {/* <BsFillPlusCircleFill /> */}
                <img src={buttonadd} alt="" />
              </div>
              <div>
                <button>Add New Clinic / Hospital Profile</button>
              </div>
            </div>
          </div>
          <div className="careers-tab" style={{ marginLeft: "2%" }}>
            <div className="" onClick={() => navigate("/add-new-clinic")}>
              Active Clinic & Hospitals
            </div>
            <div
              className="master-doctor"
              onClick={() => navigate("/doctor-inclinic-appointment")}
            >
              Doctors in In-clinic Appointments
            </div>
          </div>

          <div className="healtharticle-show mt-5">
            <div>Show</div>
            <div>
              <select
                // value={limit}
                // onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                {/* <FaSearch /> */}
                <img src={search} alt="" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  // onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
            <div className="sortandfilter-new">
              <div className="sortByPosition">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setSortShow(!sortshow);
                      }}
                    >
                      {sortName}
                      {/* Sort by */}
                    </div>
                  </div>
                  <div>
                    <img src={sort1} alt="" />
                  </div>
                </div>
              </div>
              <div className="sortByDepartment">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setFilterShow(true);
                      }}
                    >
                      Filter
                    </div>
                  </div>
                  <div>
                    <img src={filter1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="healtharticle-table add-new-doctors-table">
            <table>
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Name of Doctors</td>
                  <td>Specialisation</td>
                  <td>Clinic / Hospital</td>
                  <td>In-Clinic Appointment Fees (₹INR)</td>
                  <td>Langauges Known</td>
                  <td>Experience in Yrs</td>
                  <td>Profile Picture</td>
                  <td>Registered Mobile Number</td>
                  <td>Registered Email</td>
                  <td>Timings</td>
                  <td>Medical Registration Number</td>
                  <td>City / Town</td>
                  <td>State</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {data.map((x, i) => {
                  return (
                    <tr>
                      <td>{x.sno}</td>
                      <td>{x.name}</td>
                      <td>{x.spl}</td>
                      <td>{x.clinic}</td>
                      <td>{x.fee}</td>
                      <td>{x.languages}</td>
                      <td>{x.exp}</td>
                      <td>{x.profile}</td>
                      <td>{x.mobile}</td>
                      <td>{x.email}</td>
                      <td>
                        <span className="doctor-view">View</span>
                        <span className="doctor-view-logo"></span>
                      </td>
                      <td>{x.mrn}</td>
                      <td>{x.location}</td>
                      <td>{x.state}</td>
                      <td>
                        <span
                          className="doctor-edit"
                          onClick={() => {
                            handleEdit();
                          }}
                        >
                          Edit
                        </span>
                        <span className="doctor-edit-logo"></span>
                        <span className="doctor-delete">Delete</span>
                        <span className="doctor-delete-logo"></span>
                        <span
                          className="doctor-view"
                          onClick={() => {
                            navigate("/view-inclinic-form");
                          }}
                        >
                          View
                        </span>
                        <span className="doctor-view-logo"></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </Home> */}
      {sortshow ? (
        <div className="sortby-Common-career-main sortby-master-common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <div className="filter-container-career">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(2);
              }}
            >
              <div
                style={{
                  color: textcolor === 2 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 2 ? "bold" : "",
                }}
              >
                Experience
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(4);
              }}
            >
              <div
                style={{
                  color: textcolor === 4 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 4 ? "bold" : "",
                }}
              >
                Language
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(5);
              }}
            >
              <div
                style={{
                  color: textcolor === 5 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 5 ? "bold" : "",
                }}
              >
                City / Town
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(6);
              }}
            >
              <div
                style={{
                  color: textcolor === 6 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 6 ? "bold" : "",
                }}
              >
                Primary Specialisation
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  setExpData([]);
                  setLanguageData([]);
                  setSplData([]);
                  setLocation("");
                }}
              >
                Clear
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  setSubmitFilter(true);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Experience--------*/}
      {textcolor === 2 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Experience</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {experience.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setExpData([...expdata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Language--------*/}
      {textcolor === 4 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Language</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {language.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setLanguageData([...languagedata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------City / Town--------*/}
      {textcolor === 5 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">City / Town</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div>
                  <img src={search} alt="" />
                </div>
                <div>
                  {/* <input 
                    type="text" 
                    name="" 
                    id="filter-search" 
                    placeholder="Search"
                    onChange={(e)=>setLocation(e.target.value)}
                  /> */}
                  <Autocomplete
                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                    onPlaceSelected={(place) => {
                      setLocation(place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Primary Specialisation--------*/}
      {textcolor === 6 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Primary Specialisation</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {primaryspl.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setSplData([...spldata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DoctorInclinicAppointment;
