import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../assets/svg/back-rose-icon.svg";
import "./index.scss";
import { useState } from "react";
import redirectlogo from "../../../assets/images/redirect.PNG";
import RedirectPopup from "../../modal/RedirectPopup";

const RetailPharmacyTrackRedirect = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const [allcheck, setAllcheck] = useState(null);
  const [success, setSuccess] = useState(false);

  const data = [
    {
      oid: "622412",
      name: "Prakash. R",
      received: "23 Min Ago",
      status: "Accepted",
      order: "",
      pincode: "110 011",
      patient: "096263",
      value: "716.16",
    },
  ];

  const orderData = [
    {
      logo: redirectlogo,
      name: "Dolo 650 Mg Strip of 15 Tablet(s)",
      desc: "15 Tablet(s) in strip",
      mrp1: "₹25.91",
      mrp2: "₹21.5",
      percentage: "5%",
      qty: "1",
    },
    {
      logo: redirectlogo,
      name: "Volini Pain Relied Spray",
      desc: "15 G Spray in Bottle",
      mrp1: "₹65.00",
      mrp2: "₹53",
      percentage: "5%",
      qty: "2",
    },
    {
      logo: redirectlogo,
      name: "AltPharma All in One Vaporizer",
      desc: "1 Nebuliser(s) in Box",
      mrp1: "₹610.00",
      mrp2: "₹582.91",
      percentage: "5%",
      qty: "1",
    },
  ];

  return (
    <>
      {/* <Home> */}
      <div
        onClick={() => navigate("/retail-pharmacy-track")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>

      <div className="healtharticle-title ms-3 mb-5">
        <Link
          to="/dashboard"
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "15px",
          }}
        >
          <span style={{ paddingLeft: "5%" }}>Dashboard</span>
        </Link>
        <span style={{ paddingLeft: "1%" }}>></span>
        <span style={{ paddingLeft: "0.4%" }}>
          <Link
            to="/add-new-retail-pharmacy"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            Partnered Retail Pharmacies
          </Link>
        </span>
        {/* <span style={{ paddingLeft: "0.4%" }}>></span>
                <span style={{ paddingLeft: "0.4%" }}>
                  List of Doctors for Online Consultation
                </span> */}
        <span style={{ paddingLeft: "0.4%" }}>></span>
        <span style={{ paddingLeft: "0.4%" }}>Track Retail Pharmacy</span>
        <span style={{ paddingLeft: "0.4%" }}>></span>
        <span style={{ paddingLeft: "0.4%" }}>Redirect Order(s)</span>
      </div>

      {/*----------table portion-----------*/}
      <div className="doctor-track-second-portion">
        <div className="healtharticle-table retail-pharmacy-track-table">
          <table>
            <thead>
              <tr>
                <td>Order ID</td>
                <td>Customer / Patient Name</td>
                <td>Received</td>
                <td>Order Status</td>
                <td>Order Reject / Cancelled / Partnered Retail Pharmacy</td>
                <td>Delivery Pincode</td>
                <td>Custom / Patient Mobile Number</td>
                <td>Order Value (INR)</td>
              </tr>
            </thead>
            <tbody>
              {data.map((x, i) => {
                return (
                  <tr>
                    <td>{x.oid}</td>
                    <td>{x.name}</td>
                    <td>{x.received}</td>
                    <td>{x.status}</td>
                    <td>{x.order}</td>
                    <td>{x.pincode}</td>
                    <td>{x.patient}</td>
                    <td>{x.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="redirectorder">
        <div className="redirectorder-one">
          <div>Order Details ──</div>
          <div>
            <div htmlFor="" className="me-3">
              Select All
            </div>
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                className={isChecked ? "checked" : ""}
                onClick={() => {
                  setAllcheck(!allcheck);
                }}
                id=""
              />
            </div>
          </div>
        </div>
        <div className="redirectorder-two">
          {orderData.map((el, i) => {
            return (
              <div className="redirectorder-two-container">
                <div>
                  <img src={el.logo} alt="" />
                </div>
                <div>
                  <div>{el.name}</div>
                  <div>
                    <div>{el.desc}</div>
                    <div className="me-3">x{el.qty}</div>
                  </div>
                  <div>View Alternative ❯</div>
                </div>
                <div>
                  MRP <span style={{ paddingLeft: "3%" }}>{el.mrp1}</span>
                </div>
                <div>
                  <div>{el.percentage}</div>
                </div>
                <div>
                  MRP <span style={{ paddingLeft: "3%" }}>{el.mrp2}</span>
                </div>
                <div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      id=""
                      checked={allcheck ? "checked" : "" || null}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            fontSize: "15px",
            fontWeight: "700",
            marginTop: "2%",
            marginBottom: "2%",
            marginLeft: "2%",
          }}
        >
          Redirect Order to ──
        </div>
        {/* One */}
        <div className="addnew-form1-autor-detail mt-5 d-flex ms-3">
          <div className="col-md-4">
            <label for="inputAddress" className="form-label title">
              Select State{" "}
            </label>
            <select className="form-select w-75 car-job-select">
              <option value="" hidden selected>
                Select
              </option>
              <option value="">Tamil Nadu</option>
              <option value="">Karnataka</option>
              <option value="">Kerala</option>
              <option value="">Telangana</option>
            </select>
          </div>

          <div className="col-md-4">
            <label for="inputAddress" className="form-label title">
              Select District
            </label>
            <select className="form-select w-75 car-job-select">
              <option value="" hidden selected>
                Select
              </option>
              <option value="">Dharmapuri</option>
              <option value="">Coimbatore</option>
              <option value="">Chennai</option>
            </select>
          </div>

          <div className="col-md-4">
            <label for="inputAddress" className="form-label title">
              Select Pincode
            </label>
            <select className="form-select w-75 car-job-select">
              <option value="" hidden selected>
                Select
              </option>
              <option value="">635301</option>
              <option value="">641659</option>
              <option value="">600001</option>
            </select>
          </div>
        </div>
        {/* Two */}
        <div className="addnew-form1-autor-detail mt-5 d-flex ms-3">
          <div className="col-md-4">
            <label for="inputAddress" className="form-label title">
              Choose Partnered Retail Pharmacy
            </label>
            <select className="form-select w-75 car-job-select">
              <option value="" hidden selected>
                Select
              </option>
              <option value="">checking</option>
              <option value="">checking</option>
              <option value="">checking</option>
            </select>
          </div>
        </div>
      </div>
      <div className="redirect-button">
        <button
          onClick={() => {
            setSuccess(true);
          }}
        >
          REDIRECT ORDER
        </button>
      </div>
      {/* </Home> */}
      {success && (
        <RedirectPopup page={"/retail-pharmacy-track"} name="retail pharmacy" />
      )}
    </>
  );
};

export default RetailPharmacyTrackRedirect;
