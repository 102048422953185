import axios from "axios";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "react-multiple-select-dropdown-lite/dist/index.css";

import back from "../../../../assets/svg/back-icon.svg";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import InputRadioDynamic from "../../../Common/inputRadioDynamic";
import InputTextField from "../../../Common/inputTextField";

import apiCall from "../../../../api";
import graytick from "../../../../assets/images/graytick.PNG";
import greentick from "../../../../assets/images/greentick.PNG";
import next from "../../../../assets/svg/next-icon.svg";
import { medicalCouncilYears, states } from "../../../../constants";
import LanguageField from "../../../Common/LanguageFieldNew";
import MultipleAddAwards from "../../../Common/MultipleAddAwards";
import MultipleAddInputs from "../../../Common/MultipleAddInputs";
import MultiSelectField from "../../../Common/multiSelectField";
import MultipleAddMembership from "../../../Common/multipleAddMembership";
import MultipleAddTag from "../../../Common/multipleAddTag";
import NextBackBtns from "../../../Common/nextBackBtns";
import SelectField from "../../../Common/selectField";
import SucessModal from "../../../modal/SucessModal";
import '../index.scss';
import FAQContent from "../../../Common/faqContent";

const StepOne = ({
	register,
	errors,
	watch,
	setValue,
	control,
	trigger,
	isValid,
	getValues,
	setStep,
	doctorSpecialization,
	inClinicSpecialization,
	clinicProfiles,
	docSpecialization,
	incSpecialization
}) => {
	const clinic_profile = watch("clinic_profiles")
	const profile_mode = watch("profile_mode")
	const doctor_primary_specialization = watch("doctor_primary_specialization")
	const inclinic_primary_specialization = watch("inclinic_primary_specialization")
	const [doctorHealthConcers, setDoctorHealthConcers] = useState([])
	const [inClinicHealthConcers, setInClinicHealthConcers] = useState([])


	const getDoctorHealthConcers = async () => {
		const result = await apiCall(`doctor-health-concern/get-by-spl/${doctor_primary_specialization}`, "POST", { active: true });
		if (result?.data) {
			setDoctorHealthConcers(result.data?.map(hc => ({
				value: hc?.health_concern,
				label: hc?.health_concern,
				id: hc?._id
			})));
			const hcIdList = result.data.map((ele) => ele._id);
			if (!(getValues()?.online_health_concern?.length && getValues().online_health_concern.map(hc => hc?.id).every(value => hcIdList.includes(value)))) {
				setValue("online_health_concern", "")
			}
		}
	}

	useEffect(() => {
		if (doctorSpecialization?.length && getValues()?.doctor_primary_specialization) {
			getDoctorHealthConcers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doctor_primary_specialization, doctorSpecialization])

	useEffect(() => {
		if (getValues()?.doctor_primary_specialization) {
			setValue("doctor_primary_specialization", getValues()?.doctor_primary_specialization);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doctorSpecialization])

	useEffect(() => {
		if (getValues()?.online_health_concern) {
			setValue("online_health_concern", getValues()?.online_health_concern)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doctorHealthConcers])


	const getInclinicHealthConcers = async () => {

		const result = await apiCall(`inclinic-health-concern/get-by-spl/${inclinic_primary_specialization}`, "POST", { active: true });
		if (result?.data) {
			setInClinicHealthConcers(result.data?.map(hc => ({
				value: hc?.health_concern,
				label: hc?.health_concern,
				id: hc?._id
			})));
			const hcIdList = result.data.map((ele) => ele._id);
			if (!(getValues()?.inclinic_health_concern?.length && getValues()?.inclinic_health_concern?.map(hc => hc?.id).every(value => hcIdList.includes(value)))) {
				setValue("inclinic_health_concern", "")
			}
		}
	}

	useEffect(() => {
		if (inClinicSpecialization?.length && getValues()?.inclinic_primary_specialization) {
			getInclinicHealthConcers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inclinic_primary_specialization, inClinicSpecialization]);

	useEffect(() => {
		if (getValues()?.inclinic_primary_specialization) {
			setValue("inclinic_primary_specialization", getValues()?.inclinic_primary_specialization);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inClinicSpecialization])

	useEffect(() => {
		if (getValues()?.inclinic_health_concern) {
			setValue("inclinic_health_concern", getValues()?.inclinic_health_concern)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inClinicHealthConcers])
	const handleNext = async () => {
		await trigger();
		if (isValid) {
			setStep(2)
		}
	};

	const removeClinicProfile = (clinicProfile) => {
		const filteredClinics = getValues()?.clinic_profiles?.filter((data) => data?.id !== clinicProfile?.id)
		setValue("clinic_profiles", filteredClinics)
	}
	const CheckText = (profile_mode === "Both" || profile_mode === "Online") ? "Online Doctor" : profile_mode === "InClinic" ? "InClinic" : "";

	return (
		<>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<InputTextField
						labelName="Name of the Doctor"
						name="doctor_name"
						register={register}
						errors={errors}
						errorText={"Please provide a Doctor Name"}
					/>
				</div>
				<div className="col-md-6">
					<InputRadioDynamic
						register={register}
						errors={errors}
						errorMessage="Please choose the Profile Mode"
						watch={watch}
						setValue={setValue}
						trigger={trigger}
						label="Profile Mode"
						name="profile_mode"
						options={[
							{ name: "Online", },
							{ name: "InClinic", },
							{ name: "Both", }
						]}
					/>
				</div>
			</div>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="primarySpecialization"
						labelName={`${CheckText} Specialization `}
						name={profile_mode !== "InClinic" ? "online_specialization" : "inclinic_specialization"}
						options={profile_mode !== "InClinic" ? docSpecialization : incSpecialization}
						isOptionObject={true}
						register={register}
						errors={errors}
						className="w-75"
						errorText={`Please choose a ${CheckText} specialization`}
						defaultOption=""
					/>
				</div>
			</div>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="primarySpecialist"
						labelName={`${CheckText} Specialist`}
						name={profile_mode !== "InClinic" ? "doctor_primary_specialization" : "inclinic_primary_specialization"}
						options={profile_mode !== "InClinic" ? doctorSpecialization : inClinicSpecialization}
						isOptionObject={true}
						register={register}
						errors={errors}
						className="w-75"
						errorText={`Please choose a ${CheckText} Specialist`}
						defaultOption=""
					/>
				</div>
				<div className="col-md-6">
					<InputRadioDynamic
						register={register}
						errors={errors}
						errorMessage="Please choose the Verified"
						watch={watch}
						setValue={setValue}
						trigger={trigger}
						label="Verified"
						name="verified"
						options={[
							{ name: "Yes", },
							{ name: "No", },
						]}
					/>
				</div>
			</div>
			{
				(profile_mode && profile_mode !== "InClinic") ? <div className="addnew-form1-autor-detail mt-5 d-flex">
					<div className="col-md-6">
						<InputTextField
							labelName="Online Consultation Listing Fee"
							name="online_consultation_fee"
							register={register}
							type="number"
							errors={errors}
							errorText={"Please provide a Online Consultation Listing Fee"}
						/>
					</div>
					<div className="col-md-6 partneredDoctorLanuages">
						<div className="col-md-8">
							<MultiSelectField
								name="online_health_concern"
								id="online_health_concern"
								labelName="Online Health Concern "
								control={control}
								errors={errors}
								options={doctorHealthConcers}
								// maxLength={3}
								placeholder="Select"
								requiredText="Please choose a Online Health Concern"
							// errorText = "Please choose a Online Health Concern"
							/>
							{/* <label className="form-label title mb-2">Online Health Concern <span>*</span></label>
						<Controller
							name="online_health_concern"
							control={control}
							rules={{ required: "This field is required" }} // Add required rule
							defaultValue={[]}
							render={({ field }) => (
								<Select
									{...field}
									options={doctorHealthConcers?.map((data) => ({ value: data?.health_concern, label: data?.health_concern }))}
									isMulti
								/>
							)}
						/>
						{errors.online_health_concern && (
							<div className="invalid-feedback d-block">
								Please Provide a Online Health Concern
							</div>
						)} */}
						</div>
					</div>
				</div> : null
			}
			{
				(profile_mode === "Both") ? (
					<div className="addnew-form1-autor-detail mt-5 d-flex">
						<div className="col-md-6">
							<SelectField
								id="Specialization"
								labelName="InClinic Specialization"
								name="inclinic_specialization"
								options={incSpecialization}
								isOptionObject={true}
								register={register}
								errors={errors}
								className="w-75"
								errorText="Please choose a InClinic Specialization"
								defaultOption=""
							/>
						</div>
					</div>) : null
			}
			{
				(profile_mode === "Both") ? (
					<div className="addnew-form1-autor-detail mt-5 d-flex">
						<div className="col-md-6">
							<SelectField
								id="primarySpecialization"
								labelName="InClinic Specialist "
								name="inclinic_primary_specialization"
								options={inClinicSpecialization}
								isOptionObject={true}
								register={register}
								errors={errors}
								className="w-75"
								errorText="Please choose a InClinic Specialist"
								defaultOption=""
							/>
						</div>
					</div>) : null
			}
			{
				(profile_mode && profile_mode !== "Online") ? <div className="addnew-form1-autor-detail mt-5 d-flex">
					<div className="col-md-6">
						<InputTextField
							labelName="Inclinic Consultation Listing Fee"
							name="inclinic_consultation_fee"
							register={register}
							errors={errors}
							type="number"
							errorText={"Please provide a Inclinic Consultation Listing Fee"}
						/>
					</div>
					<div className="col-md-4">
						<MultiSelectField
							name="inclinic_health_concern"
							id="inclinic_health_concern"
							labelName="Inclinic Health Concerns"
							control={control}
							errors={errors}
							options={inClinicHealthConcers}
							placeholder="Select"
							requiredText="Please choose a Inclinic Health Concern"
						/>
					</div>
				</div> : null
			}

			{
				profile_mode && profile_mode !== 'Online' && (
					<div className="addnew-form1-autor-detail mt-5">
						<div className="col-md-6 mt-3">
							<b>Clinic Related Details</b>
						</div>
						<div className="col-md-6 mt-4">
							<MultiSelectField
								name="clinic_profiles"
								id="clinic_profiles"
								labelName="Clinic Name"
								control={control}
								errors={errors}
								options={clinicProfiles}
								maxLength={3}
								placeholder="Select"
								requiredText="Please choose a Clinic Name"
							/>
						</div>

						{getValues()?.clinic_profiles?.length > 0 ? (
							<div className="row mt-3">{
								getValues()?.clinic_profiles?.map(clinicDetail => (
									<div className="col-6">
										<div key={clinicDetail.value} className="clinicNameCard">
											<div className="d-flex clinicDetails">
												<img
													src={`${process.env.REACT_APP_API_URL}clinic-profile/${clinicDetail.logo}`}
													width={50}
													height={50}
													className="rounded"
													alt="Clinic Logo"
												/>
												<div className="ms-2">
													<b>{clinicDetail?.value}</b>
													<div>{clinicDetail.address}</div>
												</div>

											</div>
											<b className="removeClinicBtn"
												onClick={() => removeClinicProfile(clinicDetail)}
											>
												Remove
											</b>
										</div>
									</div>
								))}
							</div>
						) : null}

					</div>
				)
			}

			<div className="addnew-form1-next">
				<div onClick={handleNext}>
					<div>
						<button type="button">NEXT</button>
					</div>
					<div>
						<img src={next} alt="" />
					</div>
				</div>
			</div>
		</>
	)
}

const StepTwo = ({
	register,
	errors,
	watch,
	setValue,
	trigger,
	isValid,
	getValues,
	setStep,
	Memberships,
	setMemberships,
	tags,
	setTags,
	about,
	setAbout,
	incliniAbout,
	setInclinicAbout,
	experiences,
	setExperiences,
	medicalCouncilList,
	degree,
	pgDegree,
	awards,
	setAwards,
}) => {
	const editor = useRef(null);
	const [genderList, setGenderList] = useState([]);
	const [languageList, setLanguage] = useState([]);
	const [selectedLang, setSelectedLang] = useState([]);
	const profileMode = getValues()?.profile_mode;

	useEffect(() => {
		if (getValues()?.languages_known) {
			setValue("languages_known", getValues()?.languages_known?.map((data) => ({ value: data?._id, label: data?.type })))
		}
	}, [languageList])

	const getGenderList = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}doctors-gender/list`, {
				active: true
			})
			.then((response) => {
				setGenderList(response.data?.rows?.map(data => ({ id: data._id, name: data?.type })));
			})
			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	};


	const getLanguageList = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}doctors-languages/list`, {
				active: true
			})
			.then((response) => {
				setLanguage(response.data?.rows);
			})
			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	};

	useEffect(() => {
		getGenderList()
		getLanguageList()
	}, [])

	const handleNext = async () => {
		await trigger();
		if (isValid && (profileMode === "Online" ? about?.length : profileMode === "InClinic" ? incliniAbout?.length: (incliniAbout?.length && about?.length))) {
			setStep(3)
		}
	};

	const handleBack = () => {
		setStep(1)
	}

	return (
		<>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="degree"
						labelName="UG Degree"
						name="degree"
						options={degree}
						register={register}
						errors={errors}
						errorText="Please select the degree"
						defaultOption="Degree"
						isOptionObject={true}
					/>
				</div>
				<div className="col-md-6">
					<InputTextField
						id="education"
						labelName="Education"
						name="education"
						isRequired={false}
						type="text"
						register={register}
						errors={errors}
						placeholder="Type college / university attended"
						errorText={"Please provide a Education"}
					/>
				</div>
			</div>
			<div className="addnew-form1-autor-detail mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="pgDegree"
						labelName="PG Degree"
						name="pg_degree"
						options={pgDegree.map((data) => ({ value: data?._id, label: data?.pg_degree }))}
						register={register}
						errors={errors}
						errorText="Please select the PG Degree"
						defaultOption="PG Degree"
						isOptionObject={true}
						isRequired={false}
					/>
				</div>
				<div className="col-md-6">
					<InputTextField
						id="education"
						labelName="Education"
						name="pg_education"
						type="text"
						register={register}
						errors={errors}
						placeholder="Type college / university attended"
						errorText={"Please provide a Education"}
						isRequired={false}
					/>
				</div>
			</div>
			<div className="mt-5 d-flex">
				<div className="col-md-6">
					<InputRadioDynamic
						register={register}
						errors={errors}
						errorMessage="Please choose the Gender"
						watch={watch}
						setValue={setValue}
						trigger={trigger}
						label="Gender"
						name="gender"
						isOptionObject={true}
						options={genderList}
					/>
				</div>
				<div className="col-md-6">
					<InputTextField
						labelName="MCI/SMC/DCI number"
						name="mci_no"
						register={register}
						errors={errors}
						errorText={"Please provide a MCI/SMC/DCI number"}
						placeholder="Enter MCI/SMC/DCI number"
					/>
				</div>
			</div>
			<div className="mt-5 d-flex">
				<div className="col-md-6">
					<SelectField
						id="medicalCouncilList"
						labelName="Select your Medical Council"
						name="medical_council_status"
						options={medicalCouncilList.map((data) => ({ value: data?._id, label: data?.medical_council }))}
						register={register}
						errors={errors}
						className="w-75"
						errorText="Please choose Medical Council"
						defaultOption=""
						isOptionObject={true}
					/>
				</div>
				<div className="col-md-6">
					<SelectField
						id="medical_council"
						labelName="Year of Registration with your Medical Council"
						name="medical_council"
						options={medicalCouncilYears}
						register={register}
						errors={errors}
						className="w-75"
						errorText="Please choose a Experience"
						defaultOption=""
					/>
				</div>
			</div>
			<div className="mt-5 d-flex">
				<div className="col-md-6">
					<MultipleAddAwards
						register={register}
						errors={errors}
						Awards={awards}
						setAwards={setAwards}
					/>
				</div>
				<div className="col-md-6">
					<MultipleAddInputs
						register={register}
						errors={errors}
						inputValues={experiences}
						setInputValues={setExperiences}
						name={"org_experience"}
						isRequired={false}
						labelName={"Experiences (Organisation Worked in) "}
					/>
				</div>
			</div>
			{(profileMode === "Online" || profileMode === "Both") ? <div className=" d-flex">
				<div className="addnew-form1-desc">
					<label className="title mt-4">
						Online Doctor About <span className="star">*</span>
					</label>
					<JoditEditor
						value={about}
						onChange={(value) => {
							setAbout(value);
							setValue("about", value);
						}}
					/>
						{!about?.length && (
						<div className={`invalid-feedback d-block`}>
							Please provide a About
						</div>
					)}
				</div>
			</div> : null}
			{(profileMode === "InClinic" || profileMode === "Both") ? <div className=" d-flex">
				<div className="addnew-form1-desc">
					<label className="title mt-4">
						Inclinic Doctor About <span className="star">*</span>
					</label>
					<JoditEditor
						value={incliniAbout}
						onChange={(value) => {
							setInclinicAbout(value);
							setValue("inclinic_about", value);
						}}
					/>
					{!incliniAbout?.length && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Inclinic About
						</div>
					)}
				</div>
			</div> : null}
			<div className="mt-5 d-flex">
				<div className="col-md-6">
					<MultipleAddMembership
						register={register}
						errors={errors}
						Memberships={Memberships}
						setMemberships={setMemberships}
					/>
				</div>
				<div className="col-md-6">
					<label
						className="car-dep-form-txt mb-2"
						style={{ color: "#4B4853" }}
						htmlFor=""
					>
						Mobile Number{" "}
						<span className="star">*</span>
					</label>
					<div className="position-relative">
						<input
							className="form-control w-75 mobileInput"
							type="text"
							name=""
							maxLength={10}
							id="mobile"
							{...register("mobile", { required: true })}
							placeholder="Enter mobile number..."
							onKeyPress={(e) => {
								if (!/[0-9]/.test(e.key)) {
									e.preventDefault();
								}
							}}
						/>
						<span className="c-input-group-prefix">+91</span>

					</div>
					{errors.mobile && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Mobile Number
						</div>
					)}
				</div>
			</div>
			<div className="mt-5 d-flex">
				<div className=" col-md-6 partneredDoctorLanuages">
					<LanguageField
						errors={errors}
						language={languageList}
						register={register}
						setValue={setValue}
						trigger={trigger}
						getValues={getValues}
						multiSelectWrapperClassName={'col-md-9'}
						multiSelectClassName={"form-control addnew-input "}
						selectedLang={selectedLang}
					/>
				</div>
				<div className="col-md-6">
					<InputTextField
						id="fellowship"
						labelName="Fellowship"
						name="fellowship"
						type="text"
						className="w-50"
						register={register}
						errors={errors}
						isRequired={false}
						errorText={"Please provide a Fellowship"}
					/>
				</div>
			</div>
			<div className="mt-5 d-flex">
				<div className="col-md-6">
					<InputTextField
						id="other_certified_courses"
						labelName="Other Certified Courses "
						name="other_certified_courses "
						type="text"
						className="w-50"
						register={register}
						errors={errors}
						isRequired={false}
						errorText={"Please provide a Other Certified Courses "}
					/>
				</div>
				{/* <div className="col-md-6">
					<label
						className="car-dep-form-txt mb-2"
						style={{ color: "#4B4853" }}
						htmlFor=""
					>
						Registration Date{" "} <span className="star">*</span>
					</label>
					<div className="position-relative">

						<input
							{...register("registration_date", { required: true })}
							type="date"
							className={`form-control w-75 ${getValues()?.registration_date ? "hasDate" : ""}`}
							placeholder="Choose Date"
							min={new Date().toISOString().split("T")[0]}
						/>

					</div>
					{errors.registration_date && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Registration Date
						</div>
					)}
				</div> */}
				<div className="col-md-6">
					<MultipleAddTag
						register={register}
						errors={errors}
						tags={tags}
						setTags={setTags}
					/>
				</div>
			</div>
			<NextBackBtns
				handleNext={handleNext}
				handleBack={handleBack}
			/>
		</>
	)
}

const StepThree = ({
	register,
	errors,
	watch,
	setValue,
	trigger,
	isValid,
	getValues,
	setStep,
	profileImage,
	setProfileImage,
	cityList,
}) => {

	const selectedCity = watch('city');
	const [localitiesList, setLocalitiesList] = useState([]);
	const profileMode = getValues()?.profile_mode;

	const getLocalityList = async () => {
		const result = await apiCall(`locality/list/${selectedCity}`, "GET", { active: true });
		if (result?.data) {
			setLocalitiesList(result.data.map((ele) => ({ label: ele.locality, value: ele._id })))
			if (!result.data.map((ele) => ele._id).includes(getValues()?.clinic_location)) {
				setValue("clinic_location", "")
			}
		}
	}

	useEffect(() => {
		if (cityList?.length && getValues()?.city) {
			getLocalityList()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cityList, selectedCity])

	useEffect(() => {
		if (getValues()?.city) {
			setValue("city", getValues()?.city)
		}
	}, [cityList])

	useEffect(() => {
		if (getValues()?.clinic_location) {
			setValue("clinic_location", getValues()?.clinic_location)
		}
	}, [localitiesList])


	const removeImage = async () => {
		setProfileImage("");

	}

	const handleNext = async () => {
		await trigger();

		if (isValid) {
			setStep(4)
		}
	};

	const handleBack = () => {
		setStep(2)
	}

	const handleInputChange = (e) => {
		setProfileImage(e.target.files[0]);
	};

	const getsrcValue = (value) => {
		if (typeof value === "string" && value.length)
			return process.env.REACT_APP_API_URL + "profile-images/" + value;
		return typeof value === "object" ? URL.createObjectURL(value) : " ";
	};

	return (
		<>
			{
				profileMode !== "Online" ? <>
					<div className="mt-5 d-flex">
						<div className="col-md-6 pe-3">
							<SelectField
								id="clinicProfileState"
								labelName="State"
								name="state"
								options={states}
								register={register}
								errors={errors}
								errorText="Please choose a State"
								defaultOption="State"
							/>
						</div>
						<div className="col-md-6 ps-3">
							<SelectField
								id="clinicCity"
								labelName="City / Town "
								name="city"
								isOptionObject={true}
								options={cityList.map((data) => ({ value: data._id, label: data?.city }))}
								register={register}
								errors={errors}
								errorText="Please choose a City"
								defaultOption="City"
							/>
						</div>
					</div>
					<div className="addnew-form1-autor-detail mt-5 d-flex">
						<div className="col-md-6 pe-3">
							<SelectField
								id="clinicLocality"
								labelName="Clinic Location "
								name="clinic_location"
								isOptionObject={true}
								options={localitiesList}
								register={register}
								errors={errors}
								errorText="Please choose a Locality"
								defaultOption="Locality"
								isRequired={false}
							/>
						</div>
						<div className="col-md-6 ps-3">
							<label className="form-label title">
								Pincode <span className="star">*</span>
							</label>
							<input
								className="form-control w-75"
								type="text"
								{...register("pincode", {
									required: true,
									min: 110000,
									pattern: /[0-9]{6}/,
								})}
								placeholder="Enter pincode"
								maxLength="6"
								onKeyPress={(e) => {
									if (!/[0-9]/.test(e.key)) {
										e.preventDefault();
									}
								}}
							/>
							{errors.pincode && (
								<div className={`invalid-feedback d-block`}>
									Please provide a Pincode
								</div>
							)}
						</div>
					</div></> : null}
			<div className="mt-5 d-flex">
				<div className="col-md-6 pe-3">
					<label for="inputAddress" className="form-label title">
						Email <span className="star">*</span>
					</label>
					<input
						type="text"
						{...register("email", { required: true })}
						className="form-control w-75"
						placeholder="Enter email id"
						style={{ paddingLeft: "10px" }}
					/>
					{errors.email && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Email
						</div>
					)}
				</div>
				<div className="col-md-6 ps-3">
					<SelectField
						id="plusMembership"
						labelName="Plus Membership"
						name="plus_membership"
						options={["Included", "Not Included"]}
						register={register}
						errors={errors}
						defaultOption=""
						isRequired={false}
					/>
				</div>

				{/* <div className="col-md-6 ps-3">
					<label
						className="car-dep-form-txt mb-2"
						style={{ color: "#4B4853" }}
						htmlFor=""
					>
						Date of LOU Signed{" "} <span className="star">*</span>
					</label>
					<div className="position-relative">
						<input
							{...register("lou_signed_date", { required: true })}
							type="date"
							className={`form-control ${getValues()?.registration_date ? "hasDate" : ""}`}
							placeholder="Choose Date"
						/>
					</div>
					{errors.lou_signed_date && (
						<div className={`invalid-feedback d-block`}>
							Please choose the LOU Signed Date
						</div>
					)}
				</div> */}

			</div>
			{/* <div className="mt-5 d-flex">
				<div className="col-md-6 pe-3">
					<SelectField
						id="signatureType"
						labelName="Signature Type"
						name="signature_type"
						options={["Signdesk ", "Go-Live"]}
						register={register}
						errors={errors}
						errorText="Please choose a Signature Type"
						defaultOption=""
					/>
				</div>
				<div className="col-md-6 ps-3">
					<InputTextField
						labelName="Bank Account Details"
						name="bank_account_details"
						className="w-100"
						register={register}
						errors={errors}
						errorText={"Please provide a Bank Account Details"}
						placeholder="Enter Bank Account"
					/>
				</div>

			</div> */}
			{/* <div className="mt-5 d-flex">
				<div className="col-md-6 pe-3">
					<InputTextField
						labelName="Partner Id"
						name="partner_id"
						className="w-100"
						register={register}
						isRequired={false}
						errors={errors}
						placeholder="Enter Partner ID"
					/>
				</div>
				<div className="col-md-6 ps-3">
					<label
						className="car-dep-form-txt mb-2"
						style={{ color: "#4B4853" }}
						htmlFor=""
					>
						Date of Profile Activated{" "}
					</label>
					<div className="position-relative">
						<input
							{...register("profile_activated_date", { required: false })}
							type="date"
							className={`form-control ${getValues()?.registration_date ? "hasDate" : ""}`}
							placeholder="Choose Date"
						/>
					</div>
				</div>
			</div> */}
			{/* <div className="mt-5 d-flex">
				<div className="col-md-6 pe-3">
					<SelectField
						id="appInstallationStatus"
						labelName="App Installation Status"
						name="app_installation_status"
						options={["Installed", "Yet to Install"]}
						register={register}
						errors={errors}
						defaultOption=""
						isRequired={false}
					/>
				</div>
				 {profileMode !== "InClinic" ? <div className="col-md-6 ps-3">
					<SelectField
						id="groundBreakingStatus"
						labelName="Ground Breaking Status"
						name="ground_breaking_status"
						options={["Yet to Ground-break", "Ground-break Completed"]}
						register={register}
						errors={errors}
						defaultOption=""
						isRequired={false}

					/>
				</div> : null} 
			</div> */}
			<div className="mt-5 d-flex">
				<div className="authorUpload ms-0">
					<div className="title fo-tit-top">
						Upload Profile Image{" "}
						<span className="star">*</span>
					</div>
					{profileImage ? (
						<div> <img
							src={getsrcValue(profileImage)}
							style={{
								width: "auto",
								height: "200px",
								marginTop: "2%",
								marginBottom: "5%",
							}}
							alt="image showing"
						/></div>
					) : null}
					<input
						className="form-control-file custom-file-input"
						type="file"
						{...(profileImage ? {} : register("profileImage", { required: true, onChange: handleInputChange }))}
						style={{
							width: "120px",
						}}
					/>
					{profileImage &&
						<button className="btn btn-danger ms-2"
							onClick={() => removeImage()}
						>Remove</button>}
					{errors.profileImage && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Profile Image
						</div>
					)}
				</div>
			</div>
			<NextBackBtns
				handleNext={handleNext}
				handleBack={handleBack}
			/>
		</>
	)
}

const StepFour = ({
	faqs,
	setFaqs,
	inClinicFaqs,
	setInClinicFaqs,
	getValues,
	setStep,
}) => {
	const profileMode = getValues()?.profile_mode;
	const [isOnlineTab, setIsOnlineTab] = useState(profileMode !== "InClinic");
	const handleBack = () => {
		setStep(3)
	}

	return (
		<>
			{profileMode === "Both" ? <div className="careers-tab" style={{ marginLeft: "2%" }}>
				{["Online Doctor FAQ", "InClinic Doctor FAQ"].map((value, inx) => (
					<div
						key={inx}
						className={`${(("Online Doctor FAQ" === value && isOnlineTab) || ("Online Doctor FAQ" !== value && !isOnlineTab)) ? "career-language" : ""} ${inx === 0 ? "" : "ms-5"} career-tab-jobpost  me-4`}
						onClick={() => setIsOnlineTab(value === "Online Doctor FAQ")}
					>{value}
					</div>
				))}
			</div> : null}
			<FAQContent
				setFaqs={isOnlineTab ? setFaqs : setInClinicFaqs}
				faqs={isOnlineTab ? faqs : inClinicFaqs}
			/>
			<div className="addnew1-form1-next">
				<div className="addnew1-back-next">
					<div className="addnew1-back">
						<div onClick={handleBack}>
							<div>
								<img src={back} alt="" />
							</div>
							<div>BACK</div>
						</div>
					</div>
					<div className="nextBtnStyle ms-4">
						<div>
							<div>
								<button>Submit</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const Level = ({
	page,
}) => {
	return (
		<>
			<Breadcrumbs
				breadcrumbText="Doctor"
				breadcrumbLink=""
				nestedText="Add Doctors"
			/>
			{
				<div className={page === 1 ? "doctor-page-tick" : page === 2 ? "doctor-page2-tick" : page === 3 ? "doctor-page3-tick" : page === 4 ? "doctor-page4-tick" : ""}>
					<div>
						<img src={greentick} alt="" />
						<div>Page 1</div>
					</div>
					<div>
						<img src={page === 1 ? graytick : greentick} alt="" />
						<div>Page 2</div>
					</div>
					<div>
						<img src={(page === 3 || page === 4) ? greentick : graytick} alt="" />
						<div>Page 3</div>
					</div>
					<div>
						<img src={page === 4 ? greentick : graytick} alt="" />
						<div>Page 4</div>
					</div>
				</div>
			}
		</>
	)
}

const PartneredDoctorForm = () => {

	const [step, setStep] = useState(1);
	const [success, setSuccess] = useState(false);

	const {
		register,
		formState: { errors, isValid },
		watch,
		setValue,
		control,
		getValues,
		trigger,
		setError,
		clearErrors,
		handleSubmit,
	} = useForm({
		mode: "onChange",
	});

	const [practices, setPractise] = useState([
		{
			[`practise${0}`]: "",
			id: 0,
		},
	]);
	const [tags, setTags] = useState([
		{
			[`tag${0}`]: "",
			id: 0,
		},
	]);
	const [Memberships, setMemberships] = useState([
		{
			[`membership${0}`]: "",
			id: 0,
		},
	]);

	const [awards, setAwards] = useState([
		{
			[`awards${0}`]: "",
			id: 0,
		},
	]);

	const [experiences, setExperiences] = useState([
		{
			[`org_experience${0}`]: "",
			id: 0,
		},
	]);

	const [faqs, setFaqs] = useState([]);
	const [inClinicFaqs, setInClinicFaqs] = useState([]);
	const [about, setAbout] = useState("")
	const [incliniAbout, setInclinicAbout] = useState("")
	const [profileImage, setProfileImage] = useState("");
	const [doctorSpecialization, setDoctorSpecialization] = useState([])
	const [inClinicSpecialization, setInClinicSpecialization] = useState([]);
	const [clinicProfiles, setClinicProfiles] = useState([]);
	const [medicalCouncilList, setMedicalCouncilList] = useState([]);
	const [degree, setDegree] = useState([]);
	const [pgDegree, setPGDegree] = useState([]);
	const [pgEnable, setPGEnable] = useState(false);
	const [docSpecialization, setDocSpecialization] = useState([])
	const [incSpecialization, setIncSpecialization] = useState([])

	useEffect(() => {
		getDoctorSpecialisation()
		getInClinicSpecialisation()
		getClinicProfiles()
		getCitiesList()
		getMedicalCouncilList()
		getDegree()
		getPGDegree()
		getSpecialization()
		getInclinicSpecialization()
	}, [])

	const getSpecialization = async () => {
		try {
			const result = await apiCall(`specialization/list`, "POST", { active: true });
			if (result.data?.rows) {
				setDocSpecialization(result.data?.rows?.map((data) => ({ value: data?._id, label: data?.online_doc_specialization })));
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getInclinicSpecialization = async () => {
		try {
			const result = await apiCall(`inclinic-specialization/list`, "POST", { active: true });
			if (result.data?.rows) {
				setIncSpecialization(result.data?.rows?.map((data) => ({ value: data?._id, label: data?.inc_specialization })));
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getDegree = async () => {
		try {
			const result = await apiCall(`ug-degree/list`, "POST", { active: true });
			if (result.data?.rows) {
				setDegree(result.data?.rows?.map((data) => ({ value: data?._id, label: data?.ug_degree })));
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getPGDegree = async () => {
		try {
			const result = await apiCall(`pg-degree/list`, "POST", { active: true });
			if (result.data?.rows) {
				setPGDegree(result.data?.rows);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const [cityList, setCityList] = useState([]);

	const getCitiesList = async () => {
		try {
			const result = await apiCall(`doctor-cities/city-list`, "POST", { active: true });
			if (result.data) {
				setCityList(result.data)
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getClinicProfiles = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}doctors-profile/list`, {
				noLimit: true,
			})
			.then((res) => {
				// todo optimize query
				if (res.data?.rows?.length) {
					setClinicProfiles(res.data.rows.map((data) => ({ value: data?.clinic_name, label: data?.clinic_name, id: data?._id, logo: data?.logo, address: data?.address })));
				}
			});
	}
	const getInClinicSpecialisation = async () => {
		try {
			const result = await apiCall(`inclinc-doctor-specialization/list`, "POST", { active: true });
			if (result.data?.rows) {
				setInClinicSpecialization(result.data?.rows?.map((spl) => ({ value: spl._id, label: spl?.inclinic_doctor_specialization })))
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getDoctorSpecialisation = async () => {
		try {
			const result = await apiCall(`doctor-specialisation/list`, "POST", { active: true });
			if (result.data?.rows) {
				setDoctorSpecialization(result.data?.rows?.map((spl) => ({ value: spl._id, label: spl?.online_doctor_specialization })))
			}
		} catch (err) {
			console.error(err);
		}
	}

	const getMedicalCouncilList = async () => {
		try {
			const result = await apiCall(`doctors-medical-council/list`, "POST", { active: true });
			if (result.data?.rows) {
				setMedicalCouncilList(result.data.rows);
			}
		} catch (err) {
			console.error(err);
		}
	}

	const onSubmit = (fields) => {
		const tempFields = {};
		const fieldKeys = Object.keys(fields);
		["education", "org_experience", "practise", "membership", "tag", "awards"].forEach((value) => {
			fieldKeys.forEach((key) => {
				if (!key.includes(value)) {
					tempFields[key] = fields[key];
				}
			});
			tempFields[value] = fieldKeys.map(key => {
				if (!key.includes(value)) {
					return
				}
			})
		})
		const languages_known = fields?.languages_known.reduce((acc, data) => {
			if (data) {
				if (typeof (data?._id) === 'string') {
					acc.push(data?._id)
				}
			}
			return acc
		}, [])
		const healthConcerns = fields?.online_health_concern ? fields?.online_health_concern?.map((option) => option.id) : [];
		const inclinicHealthConcerns = fields?.inclinic_health_concern?.map((option) => option.id) ?? [];
		if (!fields?.pg_degree) {
			delete fields.pg_degree;
		}
		if (!fields?.clinic_location) {
			delete fields.clinic_location;
		}
		if (!fields?.doctor_primary_specialization) {
			delete fields.doctor_primary_specialization;
		}
		if (!fields?.inclinic_primary_specialization) {
			delete fields?.inclinic_primary_specialization;
		}
		if (!fields?.online_specialization) {
			delete fields.online_specialization;
		}
		if (!fields?.inclinic_specialization) {
			delete fields?.inclinic_specialization;
		}

		const updatedFields = {
			...fields,
			faq: faqs,
			inclinic_faq: inClinicFaqs,
			practise: practices,
			membership: Memberships,
			awards: awards,
			// education: educations,
			org_experience: experiences,
			tag: tags,
			profileImage: fields.profileImage && fields.profileImage[0],
			verified: fields?.verified === "Yes",
			languages_known,
			online_health_concern: healthConcerns,
			inclinic_health_concern: inclinicHealthConcerns,
			clinicProfiles: fields?.clinicProfiles?.map(data => data?.id)
		}

		// selectedClinic: selectedClinics
		axios
			.postForm(`${process.env.REACT_APP_API_URL}partnered-doctor/create`, updatedFields)
			.then((response) => {
				if (response.data?._id) {
					setSuccess(true);
				} else {
					alert("Fail");
					console.log("failed...");
				}
			})
			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	}

	return (
		<>
			{/* <BackBtn
				navigatePath="/partnered-doctor"
			/> */}
			<div className="healtharticle mt-5 pt-4 ps-3 pb-5 ps-4">
				<div>
					<Level
						page={step}
					/>

					<form className="career-jobpost-form clinicProfile mt-2 ms-2" onSubmit={handleSubmit(onSubmit)}>
						{step === 1 ? (
							<StepOne
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								trigger={trigger}
								page={step}
								getValues={getValues}
								isValid={isValid}
								setStep={setStep}
								control={control}
								inClinicSpecialization={inClinicSpecialization}
								doctorSpecialization={doctorSpecialization}
								clinicProfiles={clinicProfiles}
								docSpecialization={docSpecialization}
								incSpecialization={incSpecialization}
							/>
						) : ""}

						{step === 2 ? (
							<StepTwo
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								trigger={trigger}
								page={step}
								getValues={getValues}
								isValid={isValid}
								setStep={setStep}
								Memberships={Memberships}
								setMemberships={setMemberships}
								experiences={experiences}
								setExperiences={setExperiences}
								awards={awards}
								setAwards={setAwards}
								tags={tags}
								setTags={setTags}
								practices={practices}
								setPractise={setPractise}
								about={about}
								setAbout={setAbout}
								incliniAbout={incliniAbout}
								setInclinicAbout={setInclinicAbout}
								medicalCouncilList={medicalCouncilList}
								degree={degree}
								pgEnable={pgEnable}
								setPGEnable={setPGEnable}
								pgDegree={pgDegree}
							/>
						) : ""}

						{step === 3 ? (
							<StepThree
								register={register}
								errors={errors}
								watch={watch}
								setValue={setValue}
								trigger={trigger}
								page={step}
								getValues={getValues}
								isValid={isValid}
								setStep={setStep}
								profileImage={profileImage}
								setProfileImage={setProfileImage}
								cityList={cityList}
							/>
						) : ""}

						{step === 4 ? (
							<StepFour
								faqs={faqs}
								setFaqs={setFaqs}
								inClinicFaqs={inClinicFaqs}
								setInClinicFaqs={setInClinicFaqs}
								getValues={getValues}
								setStep={setStep}
							/>
						) : ""}
					</form>
				</div>
			</div>
			{
				success && (
					<SucessModal
						page={"/partnered-doctor"}
						name="Doctor Profile"
						post='Created'
					/>
				)
			}
		</>
	)
}

export default PartneredDoctorForm