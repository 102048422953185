import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import filter1 from "../../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../../../assets/images/searchIcon.png";
import { BsFillCaretDownFill } from "react-icons/bs";
import { BsFillCaretUpFill } from "react-icons/bs";
import { useState } from "react";
import { FaAngleRight } from "react-icons/fa";

const HealthcareTrack = () => {

  const navigate = useNavigate();
  const [arrow1, setArrow1]=useState(false)
  const [arrow2, setArrow2]=useState(false)

  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [filter, setFilter] = useState({});
  const [records, setRecords] = useState([]);

  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);
  const [filtershow, setFilterShow] = useState(false);
  const [submitfilter, setSubmitFilter] = useState(false);
  const [genderdata, setGenderData]=useState([])
  const [feedata, setFeeData]=useState([])
  const [statusdata, setStatusData] = useState([])
  const [isChecked, setIsChecked] = useState(true);

  const gender = [
    {
      text:"Male"
    },
    {
      text:"Female"
    },
    {
      text:"Others"
    }
  ]

  const fee = [
    {
      text:"Less than INR 99"
    },
    {
      text:"INR 100 to INR 299"
    },
    {
      text:"INR 300 to INR 499"
    },
    {
      text:"INR 500 to INR 799"
    },
    {
      text:"INR 800 to INR 999"
    },
    {
      text:"More than INR 1000"
    }
  ]

  const status=[
    {
      text:"Active"
    },
    {
      text:"Inactive"
    }
  ]

  const data = [
    {
      sno: "1",
      name: "Vignesh P",
      age: "28",
      gender: "Male",
      mobile: "+91 72964 45263",
      date: "02-02-2022",
      slot: "12:30 PM",
      duration:"2 Hours",
      health:"-",
      status:"Upcoming",
      bookingid:"R62591",
      fee:"500",
      mode:"Direct to Service Provider",
      payment:"-",
      id:"-",
      pincode:"421241",
      additional:"-",
      final:"-",
      actions:"one",
      complete:"-"
    },
    {
      sno: "1",
      name: "Sidhi Jain",
      age: "41",
      gender: "Female",
      mobile: "+91 72964 45263",
      date: "02-02-2022",
      slot: "09:00 PM",
      duration:"3 Hours",
      health:"Mom & Baby Care Support",
      status:"Completed",
      bookingid:"R26611",
      fee:"750",
      mode:"Online",
      payment:"Paid",
      id:"RP20R62116",
      pincode:"421243",
      additional:"One hour Extension",
      final:"1,000",
      actions:"two",
      complete:"02-02-2022 at 01:02 PM"
    },
  ];

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/home-healthcare-service-provider")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container ps-3">
            <div className="healtharticle-title ms-0">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.4%" }}>
                <Link
                  to="/add-new-doctors"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  Home Healthcare Service Providers
                </Link>
              </span>
              {/* <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>
                List of Doctors for Online Consultation
              </span> */}
              <span style={{ paddingLeft: "0.4%" }}>{`>`}</span>
              <span style={{ paddingLeft: "0.4%" }}>Track HHSP</span>
            </div>

            <div className="doctor-track-header">
              <div>
                <img src="" alt="" />
              </div>
              <div>
                <div>Julie. R</div>
                <div>Nursing Support Service</div>
              </div>
              <div>Resend Login Credentials</div>
            </div>

            <div className="doctor-track-data">
              <div>
                <div>HealthSy ID</div>
                <div>KOSL95612DF</div>
              </div>
              <div>
                <div>Registered Mobile Number</div>
                <div>+91 92226 25629</div>
              </div>
              <div>
                <div>Registered Email ID</div>
                <div>ananth@gmail.com</div>
              </div>
            </div>

            <div className="doctor-track-data">
              <div>
                <div>Password</div>
                <div>Xjrxxxxxxxxxxx92</div>
              </div>
              <div>
                <div>Active Since</div>
                <div>27-01-2022</div>
              </div>
              <div>
                <div>Total Online Consultation Completed</div>
                <div>29</div>
              </div>
            </div>

            <div className="doctor-track-data">
              <div>
                <div>Total Revenue Clocked (₹INR)</div>
                <div>₹ 5,771</div>
              </div>
            </div>
          </div>
        </div>

        {/*----------table portion-----------*/}
        <div className="doctor-track-second-portion">
          <div className="healtharticle-show mt-5">
            <div>Show</div>
            <div>
              <select
                // value={limit}
                // onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                <img src={search} alt="" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  // onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
            <div className="sortandfilter-new">
              <div className="sortByPosition">
                <div>
                  <div>
                    <div
                      onClick={() => {
                          setSortShow(!sortshow)
                      }}
                    >
                      {sortName}
                      {/* Sort by */}
                    </div>
                  </div>
                  <div>
                    <img src={sort1} alt="" />
                  </div>
                </div>
              </div>
              <div className="sortByDepartment">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setFilterShow(true)
                      }}
                    >
                      Filter
                    </div>
                  </div>
                  <div>
                    <img src={filter1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="healtharticle-table add-new-doctors-table">
            <table>
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Name of Patient</td>
                  <td>Age</td>
                  <td>Gender</td>
                  <td>Patient's Mobile Number</td>
                  <td>Booking Date</td>
                  <td>Slot</td>
                  <td>Duration & Service</td>
                  <td>Health Concern</td>
                  <td>Status</td>
                  <td>HH Booking ID</td>
                  <td>Fees (₹INR)</td>
                  <td>Payment Mode</td>
                  <td>Payment Status</td>
                  <td>Transaction ID</td>
                  <td>Patient's Pincode</td>
                  <td>Additional Service ( if any )</td>
                  <td>Final Invoice (₹INR )</td>
                  <td>Actions</td>
                  <td>Booking Completed at</td>
                </tr>
              </thead>
              <tbody>
                {data.map((x, i) => {
                  return (
                    <tr>
                      <td>{x.sno}</td>
                      <td>{x.name}</td>
                      <td>{x.age}</td>
                      <td>{x.gender}</td>
                      <td>{x.mobile}</td>
                      <td>{x.date}</td>
                      <td>{x.slot}</td>
                      <td>{x.duration}</td>
                      <td>{x.health}</td>
                      <td>{x.status}</td>
                      <td>{x.bookingid}</td>
                      <td>{x.fee}</td>
                      <td>{x.mode}</td>
                      <td>{x.payment}</td>
                      <td>{x.id}</td>
                      <td>{x.pincode}</td>
                      <td>{x.additional}</td>
                      <td>{x.final}</td>
                      <td>
                        <span 
                          className="doctor-track-view"
                          onClick={()=>{
                            navigate("/healthcare-track-view")
                          }}
                        >View</span>
                        <span className="doctor-track-view-logo"></span>
                        {x.actions === "one" ? (
                          <>
                            <span className="doctor-track-cancel">Cancel</span>
                            <span className="doctor-track-cancel-logo"></span>
                            <span className="doctor-track-reschedule">
                              Reshedule
                            </span>
                            <span className="doctor-track-reschedule-logo"></span>
                            <span 
                              className="doctor-track-redirect"
                              onClick={()=>{
                                navigate("/healthcare-track-redirect")
                              }}
                            >
                              Redirect
                            </span>
                            <span className="doctor-track-redirect-logo"></span>
                          </>
                        ) : (
                          <>
                            <span className="doctor-track-invoice">
                              Invoice
                            </span>
                            <span className="doctor-track-invoice-logo"></span>
                          </>
                        )}
                      </td>
                      <td>{x.complete}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="doctor-revenue-section">
            <div>
              <img src="" alt="" />
              <div>Total Revenue (INR)</div>
              <div>₹ 20,600</div>
              {
                arrow1 === true
                ? <BsFillCaretUpFill onClick={()=>setArrow1(!arrow1)}/>
                : <BsFillCaretDownFill onClick={()=>setArrow1(!arrow2)}/>
              }
              {
                arrow1 === true
                ? 
                <div className="doctor-arrow1">
                  <div>Daily</div>
                  <div>Weekly</div>
                  <div>Monthly</div>
                  <div>Quaterly</div>
                  <div>Custom Date</div>
                </div>
                : null
              }
            </div>
            <div>
            <img src="" alt="" />
              <div>Total HealthSy % (INR)</div>
              <div>₹ 3,090</div>
              {
                arrow2 === true
                ? <BsFillCaretUpFill onClick={()=>setArrow2(!arrow2)}/>
                : <BsFillCaretDownFill onClick={()=>setArrow2(!arrow2)}/>
              }
              {
                arrow2 === true
                ? 
                <div className="doctor-arrow1">
                  <div>Daily</div>
                  <div>Weekly</div>
                  <div>Monthly</div>
                  <div>Quaterly</div>
                  <div>Custom Date</div>
                </div>
                : null
              }
            </div>
          </div>

        </div>
      {/* </Home> */}

      {sortshow ? (
        <div className="sortby-Common-career-main sortby-doctor-track">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}


      {/*---------Filter Portion--------*/}
      {
        filtershow
        ?
        <div className="filter-container-career">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(1)
              }}
            >
              <div style={{color:textcolor==1?"#CB1B5B":"", fontWeight:textcolor==1?"bold":""}}>Fee</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(2)
              }}
            >
              <div style={{color:textcolor==2?"#CB1B5B":"", fontWeight:textcolor==2?"bold":""}}>Gender</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(3)
              }}
            >
              <div style={{color:textcolor==3?"#CB1B5B":"", fontWeight:textcolor==3?"bold":""}}>Status</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button
                onClick={()=>{
                  setFilterShow(false)
                  setFeeData([])
                  setGenderData([])
                  setStatusData([])
                }}
              >Clear</button>
            </div>
            <div>
              <button 
                onClick={()=>{
                  setFilterShow(false)
                  setSubmitFilter(true)
                }}
              >Apply</button>
            </div>
          </div>
        </div>
        : null
      }


      {/*-------Fee---------*/}
      {
        textcolor === 1 && filtershow === true
        ? 
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Fee</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              fee.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                      <input 
                        type="checkbox" 
                        className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setFeeData([...feedata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }


      {/*-------Gender--------*/}
      {
        textcolor === 2 && filtershow === true
        ? 
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Gender</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              gender.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                      <input 
                        type="checkbox" 
                        className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setGenderData([...genderdata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }

      {/*-------Status--------*/}
      {
        textcolor === 3 && filtershow === true
        ? 
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Status</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              status.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div className="checkbox-wrapper">
                      <input 
                        type="checkbox" 
                        className={isChecked ? "checked" : ""}
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setStatusData([...statusdata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }

    </>
  );
};

export default HealthcareTrack