
import React, { Fragment, useState, useEffect } from "react";
// import buttonadd from "../../assets/images/plus.png";
import addIcon from "../../assets/images/add-icon.svg";

const MultipleAddMembership = ({
  register,
  errors,
  Memberships,
  setMemberships,

}) => {
  const [emailerror, setEmailError] = useState(false);
  // const [Memberships, setMemberships] = useState([
  //   {
  //     [`membership${0}`]: "",
  //     id: 0,
  //   },
  // ]);
  const addmembership = () => {
    setMemberships([
      ...Memberships,
      {
        [`membership${Memberships.length}`]: "",
        id: Memberships.length,
      },
    ]);
  };

  const onUpdateEducation = (evt, id) => {
    const list = [...Memberships];
    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return false;

    list[index][evt.target.name] = evt.target.value;
    setMemberships(list);
  };

  const removeEducation = (id) => {
    const list = [...Memberships];
    if (list.length === 1) return false;

    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return false;

    list.splice(index, 1);
    setMemberships(list);
  };

  return (
    <div className="mb-32 col-9">
      <div className="d-flex justify-content-between">
        <label>
          Membership{" "}
          {/* <span className="color-mandatory">*</span> */}
        </label>{" "}
        <span
          onClick={() => addmembership()}
          className="addPlusIcon text-end text-white"
          style={{
            marginLeft: "0px",
            cursor: "pointer",
          }}
        >
          <img src={addIcon} className='mob-plus-icon' alt="Add"></img>
          Add
        </span>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
        }}
      >
        {Memberships.map((item, idx) => (
          <div
            key={`input-membership-${item.id}`}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              columnGap: "24px",
            }}
          >
            <div
              style={{ flexGrow: "1", flexShrink: "0" }}
            >
              <input
                type="text"
                style={{ width: "100%" }}
                {...register(`membership${item.id}`, {
                  required: false,
                })}
                placeholder="Add Membership"
                defaultValue={item.value || null}
                onInput={(evt) =>
                  onUpdateEducation(evt, item.id)
                }
                className="form-control"
              // autoFocus
              />
              {errors[`membership${item.id}`] && (
                <div
                  className={`invalid-feedback d-block`}
                >
                  Please provide a Membership
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              {Memberships.length > 1 ? (
                <span
                  onClick={() => removeEducation(item.id)}
                  className="plus-icon"
                  style={{
                    marginLeft: "0px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      height: "2px",
                      width: "50%",
                      backgroundColor: "#fff",
                    }}
                  ></span>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultipleAddMembership