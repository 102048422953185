import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import BackBtn from "../../../Common/backBtn";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import InputRadioField from "../../../Common/inputRadioField";

const OnlineSpecializationView = () => {

  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}specialization/get/${id}`)
      .then((res) => {
        setData(res.data);
      });
  };

  return (
    <>
      <BackBtn
        navigatePath="/online-specialization"
      />
      <div className="healtharticle mt-5">
        <div className="pt-3 ms-3">
          <Breadcrumbs
            breadcrumbText="Doctors"
            breadcrumbLink="/add-new-doctors"
            nestedText="Common"
            nestedLink=""
            nestedText2="View Online Specialization"
            nestedLink2=""
          />
        </div>
        <div className="row mt-5 ms-2 new-menu ">
          <div className="menu-field col-md-3 me-0">
            <p className="form-label fw-bold" htmlFor="">
              Online Specialisation
            </p>
            <div className="blog-view-text-half-bor">{data.online_doc_specialization}</div>
          </div>
          <div className="col">
            <InputRadioField
              viewOnly={true}
              checked={data.active === true ? "checked" : null}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default OnlineSpecializationView