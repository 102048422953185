import axios from "axios"
import { useEffect, useState } from "react"
import { FaAngleRight } from "react-icons/fa"
import { useNavigate } from "react-router"

import contact from "../../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../../assets/images/HealthSy/delete.png";
import inactive from "../../../../assets/svg/inactive.svg";
import search from "../../../../assets/images/searchIcon.png"
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs"
import AddButton from "../../../Common/addButton"
import FilterOptions from "../../../Common/filterOptions"
import Pagination from "../../../Common/pagination"
import Deletepopup from "../../../modal/Deletepopup"
import DoctorCommonNavTitles from "../../../Common/doctorCommonNavTitles";
import apiCall from "../../../../api";
import FilterRootModal from "../../../Common/filterRootModal";
import FilterModal from "../../../Common/filterModal";

const filterOptions = ["Clinic Speciality", "Status"]

const DoctorClinicSpeciality = () => {

    const navigate = useNavigate()

    const handleClinicSpecialityForm = () => {
        navigate("/doctors-clinic-speciality-create")
    }

    const handleEdit = (id) => {
        navigate(`/doctors-clinic-speciality-edit/${id}`)
    }

    const handleView = (id) => {
        navigate(`/doctors-clinic-speciality-view/${id}`)
    }

    const [del, setDel] = useState(false)
    const [id, setId] = useState("")

    const handleDelete = async (fields) => {
        const result = await apiCall(`clinic-speciality/delete/${id}`, "DELETE", fields)
        if (result.data?._id) {
            list_api_call();
            setDel(false)
        }
    };

    const [filtered_records, setFilteredRecords] = useState([]);
    const [rows_count, setRowCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [current_page, setCurrentPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState("newest");
    const [sortName, setSortName] = useState("Sort by");
    const [filter, setFilter] = useState({});
    const [records, setRecords] = useState([]);

    const [sortshow, setSortShow] = useState(false);
    const [textcolor, setTextColor] = useState(0);
    const [filtershow, setFilterShow] = useState(false);
    const [submitfilter, setSubmitFilter] = useState(false);
    const [clinicSpeciality, setClinicSpeciality] = useState([]);
    const [clinicSplData, setClinicSplData] = useState([]);
    const [statusdata, setStatusData] = useState([]);
    const [isChecked, setIsChecked] = useState(true);

    const experience = [
        {
            text: "Less than 2 Years"
        },
        {
            text: "1-2 Years"
        },
        {
            text: "2-3 Years"
        },
        {
            text: "3-5 Years"
        },
        {
            text: "5+ Years"
        }
    ]

    const status = [
        {
            text: "Active",
        },
        {
            text: "Inactive",
        },
    ];

    useEffect(() => {
        list_api_call();
        getClinicSpeciality()
    }, [filter, limit, sort, skip]);

    useEffect(() => {
        if (current_page > 1) {
            setSkip((current_page - 1) * limit);
        } else {
            setSkip(0);
        }
    }, [current_page]);

    const list_api_call = async () => {
        const result = await apiCall(`clinic-speciality/list`, "POST",
            {
                ...filter,
                skip: skip,
                limit: limit,
                sort: sort,
            })
        setFilteredRecords([]);
        setRecords(result.data.rows);
        setRowCount(result.data.count);
        setPages(Math.ceil(result.data.count / limit));
    };

    const filter_records = (filter) => {
        let rows = records.filter((x) => {
            return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
        setFilteredRecords(rows);
    };

    const getClinicSpeciality = async () => {
        const result = await apiCall(`clinic-speciality/list`, "POST")
        setClinicSpeciality(result.data.rows.map((data) => data?.clinic_speciality));
    };

    const filter_call = async () => {
        const result = await apiCall(`filters/healthsy-filter`, "POST", {
            key: "doctors",
            values: {
                clinic_speciality: clinicSplData,
                status: statusdata,
            },
        })
        if (result.data?._id) {
            setRecords(result.data);
        }
    };

    return (
        <>
            <div className="healtharticle">
                <div className="healtharticle-container">
                    <div className="healtharticle-title carrer-job-title ms-4">
                        <Breadcrumbs
                            breadcrumbText="Doctor"
                            breadcrumbLink="/add-new-doctors"
                            nestedText="Common Heads"
                            nestedLink=""
                            nestedText2="Clinic Speciality"
                            nestedLink2=""
                        />
                    </div>

                    <div className="healtharticle-addnew">
                        <AddButton
                            onClick={handleClinicSpecialityForm}
                            btnName="Add New Clinic Speciality"
                        />
                    </div>
                    <DoctorCommonNavTitles />
                    <FilterOptions
                        limit={limit}
                        sortshow={sortshow}
                        sortName={sortName}
                        search={search}
                        setFilterShow={setFilterShow}
                        setSortShow={setSortShow}
                        filter_records={filter_records}
                        setLimit={setLimit}
                    />

                    <div className="healtharticle-table master-doctor-table">
                        <table>
                            <thead>
                                <tr>
                                    <td className="career-jobpost-td1">S.No</td>
                                    <td className="career-jobpost-td2">Clinic Speciality</td>
                                    <td className="career-jobpost-td3">Status</td>
                                    <td className="career-jobpost-td12">Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {(filtered_records.length > 0
                                    ? filtered_records
                                    : records
                                ).map((el, i) => {
                                    return (
                                        <tr key={el._id}>
                                            <td>{(current_page - 1) * limit + (i + 1)}</td>
                                            <td>{el.clinic_speciality}</td>
                                            <td>{el.active == true ? "Verified" : "Not Verified"}</td>
                                            <td>
                                                <span className="master-edit" onClick={() => { handleEdit(el._id) }}>Edit</span>
                                                <span className="contact-logo">
                                                    <img src={contact} alt="" />
                                                </span>
                                                <span className="master-delete" onClick={() => {
                                                    setId(el._id)
                                                    setDel(true);
                                                }}>Delete</span>
                                                <span className="newdelete-logo">
                                                    <img src={newdelete} alt="" />
                                                </span>
                                                <span className="master-view" onClick={() => { handleView(el._id) }}>View</span>
                                                <span className="inactive-icon">
                                                    <img src={inactive} alt="" />
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                setCurrentPage={setCurrentPage}
                current_page={current_page}
                pages={pages}
            />
            {
                del === true
                &&
                <Deletepopup
                    handleDelete={handleDelete}
                    id={id}
                />
            }

            {sortshow ? (
                <div className="sortby-Common-career-main sortby-master-common">
                    <div
                        onClick={() => {
                            setTextColor(11);
                            setSortShow(false);
                            setSort("oldest");
                            setSortName("Oldest");
                        }}
                        style={{
                            color: textcolor == 11 ? "#CB1B5B" : "",
                            fontWeight: textcolor == 11 ? "bold" : "",
                        }}
                    >
                        Oldest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(12);
                            setSortShow(false);
                            setSort("newest");
                            setSortName("Newest");
                        }}
                        style={{
                            color: textcolor == 12 ? "#CB1B5B" : "",
                            fontWeight: textcolor == 12 ? "bold" : "",
                        }}
                    >
                        Newest
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(13);
                            setSortShow(false);
                            setSort("ascending");
                            setSortName("Ascending");
                        }}
                        style={{
                            color: textcolor == 13 ? "#CB1B5B" : "",
                            fontWeight: textcolor == 13 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↑
                    </div>
                    <div
                        onClick={() => {
                            setTextColor(14);
                            setSortShow(false);
                            setSort("descending");
                            setSortName("Descending");
                        }}
                        style={{
                            color: textcolor == 14 ? "#CB1B5B" : "",
                            fontWeight: textcolor == 14 ? "bold" : "",
                        }}
                    >
                        Alphabetical ↓
                    </div>
                </div>
            ) : null}

            {/*---------Filter Portion--------*/}
            {filtershow ? (
                <FilterRootModal
                    filterOptions={filterOptions}
                    onClickClear={() => {
                        setFilterShow(false);
                        setClinicSplData([]);
                        setStatusData([]);
                    }}
                    onClickApply={() => {
                        setFilterShow(false);
                        filter_call()
                    }}
                    selectOption={textcolor}
                    setSelectOption={setTextColor}
                />
            ) : null}

            {/*-------Experience---------*/}
            {textcolor == 1 && filtershow == true ? (
                <FilterModal
                    title={"Clinic Speciality"}
                    data={clinicSpeciality}
                    className="filterModelNestedLevel"
                    activeFilters={clinicSplData}
                    onChange={(e) => {
                        if (clinicSplData.includes(e.target.name)) {
                            setClinicSplData(clinicSplData.filter((ele) => ele !== e.target.name))
                        } else {
                            setClinicSplData((pre) => [...pre, e.target.name])
                        }
                    }}
                />
            ) : null}

            {/*-------Status---------*/}
            {textcolor == 2 && filtershow == true ? (
                <FilterModal
                    title={"Status"}
                    data={status}
                    className="filterModelNestedLevel"
                    activeFilters={statusdata}
                    onChange={(e) => {
                        if (statusdata.includes(e.target.name)) {
                            setStatusData(statusdata.filter((ele) => ele !== e.target.name))
                        } else {
                            setStatusData((pre) => [...pre, e.target.name])
                        }
                    }}
                />
            ) : null}
        </>
    )
}

export default DoctorClinicSpeciality