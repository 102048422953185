import { useState, useMemo } from "react";
import "react-multiple-select-dropdown-lite/dist/index.css";

import arrow from "../../assets/svg/arrow_lang.svg";

const LanguageFieldNew = ({
  language,
  register,
  errors,
  setValue,
  getValues,
  trigger,
  labelTxt = "Languages Known",
  registerContent = "languages_known",
  placeholder = "Select your known languages",
  multiSelectClassName = "dynamic-languages",
  multiSelectWrapperClassName = "col-md-6",
  labelClassName = "",
  validation = "known Languages",
  selectedLang = [],
  selectedDataIdList=[]
}) => {

  const [langtext, setLangText] = useState(getValues()?.[registerContent]);
  const [AppliedLang, setAppliedLang] = useState(getValues()?.[registerContent]);
  const [lang, setLang] = useState(false);
  const [langtextcolor, setLangTextColor] = useState(false);

  // useEffect(() => {
  //   if (getValues()?.[registerContent]?.length && typeof (getValues()?.[registerContent][0]) === 'string') {
  //     setAppliedLang( getValues()?.[registerContent])
  //   }
  // }, [getValues().languages_known])

  const selectLanguage = (type, id) => {
    let langSelected = langtext;
    if (!Array.isArray(langSelected)) {
      langSelected = [{ [`${registerContent}.${id}`]: type, type, _id: id }];
      setLangText(langSelected);
      return;
    }

    const index = langSelected.findIndex(
      (item) => item._id === id
    );

    index !== -1
      ? langSelected.splice(index, 1)
      : langSelected.push({ [`${registerContent}.${id}`]: type, _id: id, type });
    if (langSelected?.length) {
      setLangText([...langSelected]);
    }
  };

  const ApplyLang = () => {
    setLang(false);
    setAppliedLang([...langtext]);

    setValue(registerContent, langtext?.length ? [...langtext] : null)
    setLangTextColor(false)
    trigger()
  }

  const memoizedOptions = useMemo(() => {
		return selectedDataIdList?.length ? language.filter(({ active = null, _id }) => active === null || active || selectedDataIdList.includes(_id)) : language			
	}, [language])

  return (
    <div className={`col-12 ${multiSelectWrapperClassName} mb-40 position-relative`}>
      <label className={`form-label ${labelClassName}`}>
        {labelTxt} <span className="star">*</span>
      </label>
      <div
        className="position-relative"
        onClick={() => {
          setLang(!lang);
        }}
      >
        <input
          className={`${multiSelectClassName} form-control`}
          type=""
          // disabled={true}
          onChange={() => { }}
          placeholder={placeholder}
          value={AppliedLang ? `${AppliedLang?.map((i) => i.type).slice(0, 4)
            .join(", ")}${AppliedLang.length - 4 >= 1
              ? ` and ${AppliedLang.length - 4} more` : ""}` : selectedLang.length ? `${selectedLang?.map((data) => data?.type)}` : ""}
        />
        <div className="position-absolute inlineArrow">
          <img src={arrow} alt="arrow" />
        </div>
      </div>

      <div
        className="dynamic-languages-dropdown"
        style={{ display: lang ? "block" : "none" }}
      >
        {lang &&
          memoizedOptions?.map((el) => {
            if (el.active === true || selectedDataIdList?.length) {
              return (
                <div
                  key={el._id}
                  className="dynamic-languages-dropdown-container"
                >
                  <div className="dynamic-languages-dropdown-text">
                    {el.type}
                  </div>
                  <div className="dynamic-languages-dropdown-input checkbox-wrapper">
                    <input
                      className="dynamic-languages-dropdown-radio"
                      type="checkbox"
                      value={el._id}

                      checked={langtext && langtext?.map((data) => data._id).includes(el._id)}
                      onChange={(e) => selectLanguage(el.type, el._id)}
                      onClick={() => setLangTextColor(true)}
                    />
                  </div>
                </div>
              );
            }
          })}
        <div
          className="dynamic-languages-apply"
          style={{
            color: langtextcolor ? "#CB1B5B" : "",
          }}
        >
          <div
            onClick={() => ApplyLang()}
          >
            Apply
          </div>
        </div>
      </div>
      {!AppliedLang?.length && (
        // {!AppliedLang?.length && errors[registerContent] && (
        <div className={`invalid-feedback d-block`}>
          Please provide a {validation}
        </div>
      )}
    </div>
  )
}

export default LanguageFieldNew;