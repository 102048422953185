import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../modal/SucessModal";
import backRed from "../../assets/svg/back-rose-icon.svg";

const EditFaqQuestionAndAnswer = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const [success, setSuccess] = useState(false);
    const [editFaq, setEditFaq] = useState([])

    useEffect(() => {
        getCurrentFaq()
    }, [id])

    const getCurrentFaq = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}faq/get/${id}`)
            .then((response) => {
                setEditFaq(response?.data)
                setValue('answer', response?.data?.answer)
                setValue('question', response?.data?.question)
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    }

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = (fields) => {

        fetch(`${process.env.REACT_APP_API_URL}faq/update/${id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fields)
        })
            .then((result) => {
                result.json()
                    .then((resp) => {
                        setSuccess(true);
                    })
            });
    };

    return (
        <div className="mt-5 faqContainer p-4">
            <div
                onClick={() => navigate("/faq")}
                className="rose back-button"
            >
                <img src={backRed} alt="" />
                <span className="rose-back">Back</span>
            </div>
            <div className="faq-title ms-0">
                <span>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to="/dashboard"
                    >
                        Dashboard
                    </Link>
                </span>
                <span>&#62;</span>
                <span>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to="/faq"
                    >
                        FAQ's
                    </Link>
                </span>
                <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
                <span style={{ paddingLeft: "0.4%" }}>{editFaq?.section}</span>
                <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
                <span style={{ paddingLeft: "0.4%" }}>Edit FAQs</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex justify-content-between align-items-center">
                    <div> Edit a FAQ</div>
                    <div> <button className="btn btn-success createFaqBtn" type="submit">Save</button></div>
                </div>
                <div className="mt-3">
                    Question
                    <div className="questionWrapper mt-4 mb-4">
                        <input type="text"
                            className="w-100 questionWrapper border-0"
                            name=""
                            id="type"
                            // defaultValue={editFaq.question}
                            {...register("question", { required: true })}
                        />
                        {errors.question && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a question
                            </div>
                        )}
                    </div>
                    Answer
                    <div className="mt-4">
                        <textarea type="text"
                            className="w-100 answerWrapper border-0"
                            name=""
                            id="type"
                            // defaultValue={editFaq.answer}
                            {...register("answer", { required: true })}
                        />
                        {errors.answer && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a answer
                            </div>
                        )}
                    </div>
                </div>
            </form>
            {success && (
                <SucessModal
                    page={"/faq"}
                    name="New FAQ's"
                    post="updated"
                />
            )}
        </div>
    )
}
export default EditFaqQuestionAndAnswer