import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SucessModal from "../../modal/SucessModal";
import backRed from "../../../assets/svg/back-rose-icon.svg";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import { useForm } from "react-hook-form";

const EditMetaTags = () => {

    const { id } = useParams();
    const navigate = useNavigate()
    const [success, setSuccess] = useState(false);

    const {
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm()

    const onSubmit = (fields) => {
        axios
            .patch(
                `${process.env.REACT_APP_API_URL}doctors-meta-tags/update/${id}`, fields)
            .then((response) => {
                setSuccess(true);
                reset()
            });
    }

    useEffect(() => {
        getMetaTagsForm();
    }, []);

    const getMetaTagsForm = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}doctors-meta-tags/get/${id}`)
            .then((response) => {
                setValue("meta_title", response?.data?.meta_title);
                setValue("meta_keyword", response?.data?.meta_keyword);
                setValue("meta_description", response?.data?.meta_description);
                setValue("others", response?.data?.others);
            })
            .catch((err) => {
                console.error(err);
                alert("Some error");
            });
    };

    return (
        <>
            {/* <Home> */}
            <div
                onClick={() => navigate("/doctors-gender")}
                className="rose back-button"
            >
                <img src={backRed} alt="" />
                <span className="rose-back">Back</span>
            </div>
            <div className="healtharticle">
                <Breadcrumbs
                    breadcrumbText="Clinic Profile"
                    breadcrumbLink=""
                    nestedText="Edit Meta Tags"
                    nestedLink=""
                />
                <form onClick={handleSubmit(onSubmit)}>
                    {/* One */}
                    <div className="mt-4">
                        <label for="inputAddress" className="form-label title">
                            Meta Title <span className="star">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control addnew-input w-100"
                            placeholder="Enter meta title"
                            id="meta_title"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.meta_title && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Author Name
                            </div>
                        )}
                    </div>
                    {/* Two */}
                    <div className="mt-4">
                        <label for="inputAddress" className="form-label title">
                            Meta Keyword <span className="star">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control addnew-input w-100"
                            placeholder="Enter meta keyword"
                            id="meta_keyword"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.meta_keyword && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Author Name
                            </div>
                        )}
                    </div>
                    {/* Three */}
                    <div className="mt-4">
                        <label for="inputAddress" className="form-label title">
                            Meta Description <span className="star">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control addnew-input w-100"
                            placeholder="Enter meta description"
                            id="meta_description"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.meta_description && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Author Name
                            </div>
                        )}
                    </div>
                    {/* Four */}
                    <div className="mt-4">
                        <label for="inputAddress" className="form-label title">
                            Others <span className="star">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control addnew-input w-100"
                            placeholder="Enter meta description"
                            id="others"
                            style={{ paddingLeft: "10px" }}
                        />
                        {errors.others && (
                            <div className={`invalid-feedback d-block`}>
                                Please provide a Author Name
                            </div>
                        )}
                    </div>
                </form>
                <div className="addnew1-next mt-4 mb-4">
                    <div>
                        <button
                            type="submit"
                            className="bg-transparent border-0 text-white"
                        >
                            UPDATE
                        </button>
                    </div>
                </div>
            </div>
            {/* </Home> */}
            {success && (
                <SucessModal
                    page={"/doctors-gender"}
                    name="Gender"
                    post='updated'
                />
            )}
        </>
    )
}

export default EditMetaTags