import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { CTACategoryList } from "../../constants";
import buttonadd from "../../assets/images/plus.png";

const CTABanner = () => {
  const navigate = useNavigate();
  const pathName = useLocation()

  const faqBredcrumbs = () => {
    return CTACategoryList?.filter((data) => data?.slugurl === pathName.pathname.split('/')[2]).map((val) => val.name)
  }

  const handleOrder = (pathName) => {
    navigate(`/cta-banner/${pathName}`);

  };

  return (
    <div className="mt-5 faqContainer">
      <div className="faq-title ms-0">
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/dashboard"
          >
            Dashboard
          </Link>
        </span>
        <span>&#62;</span>
        <span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/cta-banner/retail-pharmacies"
          >
            CTA Banner
          </Link>
        </span>
        <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
        <span style={{ paddingLeft: "0.4%" }}>{faqBredcrumbs()}</span>
      </div>
      <div className="faq-addnew ms-2">
        <div className="addNewfaq">
          <div>
            <img src={buttonadd} alt="" />
          </div>
          <div>
            <button onClick={() => navigate(`/cta-banner/${pathName.pathname.split('/')[2]}/add-new-cta-banner`)}>Add New CTA Banner</button>
          </div>
        </div>

      </div>
      <div className="faq-tab">
        {
          CTACategoryList.map((data, idx) => {
            return (
              <div key={idx} className={`${pathName.pathname.split('/')[2] === data?.slugurl ? 'rose-line' : ''} ${idx !== 0 ? 'ms-5' : ''}`} onClick={() => handleOrder(data?.slugurl)}>
                {data?.name}
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default CTABanner;
