import axios from "axios";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

import apiCall from "../../../../api";
import BackBtn from "../../../Common/backBtn";
import SucessModal from "../../../modal/SucessModal";
import InputTextField from "../../../Common/inputTextField";
import InputRadioField from "../../../Common/inputRadioField";
import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";

const HealthArticleBlogSearchForm = ({
    edit = false
}) => {
    const { id } = useParams();

    const [success, setSuccess] = useState(false);

    const {
        register,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
        trigger
    } = useForm();


    useEffect(() => {
        edit && getMenuData();
    }, [edit]);

    const getMenuData = async () => {
        try {
            const response = await apiCall(`blog-search-keyword/get/${id}`, "GET", {})
            setValue('keyword', response.data.keyword || "");
            setValue("active", response.data.active)
            trigger()
        } catch (err) {
            console.error(err);
        }
    }

    const onSubmit = async (fields) => {
        try {
            const url = edit
                ? `${process.env.REACT_APP_API_URL}blog-search-keyword/update/${id}`
                : `${process.env.REACT_APP_API_URL}blog-search-keyword/create`;

            const response = edit
                ? await axios.patch(url, fields)
                : await axios.post(url, fields);

            if (response.data?._id) {
                setSuccess(true);
            } else {
                alert("Fail");
                console.log("failed...");
            }
        } catch (error) {
            console.error("Submit failed", error);
        }
    };

    return (
        <>
            <BackBtn
                navigatePath="/blog-search-keyword"
            />
            <div className="healtharticle mt-5">
                <div className="pt-3 ms-3">
                    <Breadcrumbs
                        breadcrumbText="Blogs"
                        breadcrumbLink="/healtharticle"
                        nestedText="Blog Search Keyword"
                        nestedLink="/blog-search-keyword"
                        nestedText3={edit ? "Edit Keyword" : "Add new Keyword"}
                        nestedLink3=""
                    />
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="carr-dep-form"
                >
                    <div className="row car-dep-form">
                        <div className="col-5">
                            <InputTextField
                                register={register}
                                errors={errors}
                                labelName="Blog Search Keyword"
                                errorText="Please enter the Keyword type."
                                name="keyword"
                                placeholder="Type here..."
                            />
                        </div>

                        <div className="col-3">
                            <InputRadioField
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                watch={watch}
                            />
                        </div>
                    </div>

                    <div className="car-dep-form-button">
                        <button type="submit" style={{ marginTop: "5%" }}>
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {/* </Home> */}
            {success && (
                <SucessModal
                    page={"/blog-search-keyword"}
                    name="new keyword"
                    post={edit ? 'updated' : 'added'}
                />
            )}
        </>
    )
}

export default HealthArticleBlogSearchForm