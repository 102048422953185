import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import backRed from "../../assets/svg/back-rose-icon.svg";
import SucessModal from "../modal/SucessModal";

const AddEditInsider = ({ edit = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [insiderImage, setInsiderImage] = useState("")
  const {
    handleSubmit,
    getValues,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const getEmployee = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}insiders/get/${id}`)
      .then((response) => {
        setValue("name", response?.data?.name);
        setValue("designation", response?.data?.designation);
        setValue("linkedin", response?.data?.linkedin);
        setInsiderImage(response?.data?.insiderImage);
        setValue("insiderImage", { name: response?.data?.insiderImage })
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  useEffect(() => {
    if (edit) {
      getEmployee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleInputChange = (e) => {
    setInsiderImage(e.target.files[0]);
  };

  const getsrcValue = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "insider-images/" + value;
    return typeof value === "object" ? URL.createObjectURL(value) : " ";
  };

  const removeImage = async () => {
    setInsiderImage("");
  }

  const onSubmit = () => {
    const fields = getValues()
    fields["insiderImage"] = fields?.insiderImage[0]
    fields["active"] = fields?.active === "Active" ? true : false
    if (!edit) {
      axios
        .postForm(
          `${process.env.REACT_APP_API_URL}insiders/create`,
          fields
        )
        .then((response) => {
          setSuccess(true);
          reset()
        });
    } else {
      if (insiderImage) {
        axios
          .postForm(
            `${process.env.REACT_APP_API_URL}insiders/update/${id}`,
            { ...fields, insiderImage: typeof (insiderImage) === 'string' ? insiderImage : fields["insiderImage"] }
          )
          .then((response) => {
            setSuccess(true);
            reset()
          });
      }
    }
  }

  return (
    <>
      <div
        onClick={() => navigate("/insiders")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="faq-title ms-0">
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/dashboard"
              >
                Dashboard
              </Link>
            </span>
            <span>&#62;</span>
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/settings-social-media-form"
              >
                Setings
              </Link>
            </span>
            <span>&#62;</span>
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/insiders"
              >
                Insiders Image
              </Link>
            </span>
            <span className="ps-2">&#62;</span>
            <span className="ps-2">{edit ? 'Edit': 'Add New'}  Insiders</span>
          </div>

          <form className="addEditInsiders" onSubmit={handleSubmit(onSubmit)}>
            <div className="addnew-form1-autor-detail mt-3 row">
              <div className="col-6 col-lg-5">
                <label for="inputAddress" className="form-label title">
                  Name
                  <span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control addnew-input"
                  id="authordetails"
                  {...register("name", { required: true })}
                  style={{ paddingLeft: "10px" }}
                  placeholder="Enter name"
                />
                {errors.name && (
                  <div className={`invalid-feedback d-block`}>
                    Please provide a Name
                  </div>
                )}
              </div>
              <div className="col-6 col-lg-5">
                <label for="inputAddress" className="form-label title">
                  Designation
                  <span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control addnew-input"
                  id="authordetails"
                  {...register("designation", { required: true })}
                  style={{ paddingLeft: "10px" }}
                  placeholder="Enter designation"
                />
                {errors.designation && (
                  <div className={`invalid-feedback d-block`}>
                    Please provide a Designation
                  </div>
                )}
              </div>

            </div>
            <div className="col-12 mt-4">
              <label for="inputAddress" className="form-label">
                Linked In Url <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control addnew-input"
                style={{ paddingLeft: "10px" }}
                id="title"
                {...register("linkedin", { required: true })}

                placeholder="Enter Linked in url"
              />
              {errors.linkedin && (
                <div className={`invalid-feedback d-block`}>
                  Please provide a Url
                </div>
              )}
            </div>
            <div className="addnew-form1-autor-detail mt-4 row">
              <div className="col-6 col-lg-5">
                <div className="title mb-2">
                  Upload Image{" "}
                  <span className="star">*</span>
                </div>
                {insiderImage ? (
                  <div> <img
                    src={getsrcValue(insiderImage)}
                    style={{
                      width: "auto",
                      height: "200px",
                      marginTop: "2%",
                      marginBottom: "5%",
                    }}
                    alt="image showing"
                  /></div>
                ) : null}
                <input
                  className="form-control-file custom-file-input"
                  type="file"
                  {...register("insiderImage", { required: !edit })}
                  style={{
                    width: "120px",
                  }}
                  onChange={handleInputChange}
                />
                {insiderImage &&
                  <button className="btn btn-danger ms-2"
                    onClick={() => removeImage()}
                  >Remove</button>}
                {errors.insiderImage && (
                  <div className={`invalid-feedback d-block`}>
                    Please provide a Image
                  </div>
                )}
                {edit && !insiderImage && <div className={`invalid-feedback d-block`}>
                  Please provide a Image
                </div>}
              </div>
              <div className="   col-6 col-lg-5">
                <div className="">
                  <label className=" small mb-2" htmlFor="">
                    Active <span className="star">*</span>
                  </label>
                  <div className="d-flex">
                    <div className="form-check form-check-inline me-3 mt-1">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        name="gender"
                        id="gender"
                        value="Active"
                        checked
                        {...register("active", { required: true })}
                      />
                      <label htmlFor="option-1" className="text-dark mt-1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-1">
                      <input
                        className="form-check-input  me-3"
                        type="radio"
                        name="gender"
                        id="gender"
                        value="Inactive"
                        {...register("active", { required: true })}
                      />
                      <label htmlFor="option-2" className="text-dark mt-1">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {errors.active && (
                  <div className={`invalid-feedback d-block`}>
                    Please select an Option
                  </div>
                )}
              </div>
            </div>
            <div className="car-pos-form-button mb-5 mt-5 pb-5">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      {success && (
        <SucessModal
          page={`/insiders`}
          name="Insider"
          post={edit ? 'updated' : 'added'}
          onClick={() => navigate('/insiders')}
        />
      )}
    </>
  );
};

export default AddEditInsider;
