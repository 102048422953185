import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import Breadcrumbs from "../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../Common/backBtn";
import InputRadioField from "../../../Common/inputRadioField";
import InputTextField from "../../../Common/inputTextField";
import SucessModal from "../../../modal/SucessModal";

const OnlineSpecializationForm = ({
  edit = false
}) => {
  const { id } = useParams()

  const {
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
    trigger
  } = useForm();

  const [success, setSuccess] = useState(false);

  const getMenuData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}specialization/get/${id}`).then(response => {
      setValue('active', response.data.active);
      setValue('online_doc_specialization', response.data.online_doc_specialization);
      trigger()
    }).catch(err => {
      console.error(err);
      alert("Some error");
    })
  }

  useEffect(() => {
    edit && getMenuData();
  }, [edit]);

  const onSubmit = (fields) => {
    console.log("fields", fields)
    if (edit) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}specialization/update/${id}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });

    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}specialization/create`,
          fields
        )
        .then((response) => {
          console.log("response...");

          if (response?.data?._id) {
            setSuccess(true);
          } else {
            console.log("failed...");
            alert("Fail");
          }
        });
    }
  };

  return (
    <>
      <BackBtn
        navigatePath="/online-specialization"
      />
      <div className="healtharticle mt-5">
        <div className="pt-3 ms-3">
          <Breadcrumbs
            breadcrumbText="Doctor"
            breadcrumbLink="/add-new-doctors"
            nestedText="Common Heads"
            nestedLink=""
            nestedText2="Online Specialization"
            nestedLink2=""
            nestedText3={edit ? "Edit Online Specialization" : "Add new Online Specialization"}
            nestedLink3=""
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="carr-dep-form"
        >
          <div className="row car-dep-form">
            <div className="col-5">
              <InputTextField
                register={register}
                errors={errors}
                labelName="Online Specialization"
                errorText="Please enter the Online Specialization type."
                name="online_doc_specialization"
                placeholder="Type here..."
              />
            </div>

            <div className="col-3">
              <InputRadioField
                register={register}
                errors={errors}
                setValue={setValue}
                watch={watch}
              />
            </div>
          </div>

          <div className="car-dep-form-button">
            <button type="submit" style={{ marginTop: "5%" }}>
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/online-specialization"}
          name="new online specialization"
          post={edit ? 'updated' : 'added'}
        />
      )}
    </>
  )
}

export default OnlineSpecializationForm