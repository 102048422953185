import axios from "axios";
import JoditEditor from "jodit-react";
import moment from "moment";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

import { states } from "../../../../src/constants";
import apiCall from "../../../api";
import graytick from "../../../assets/images/graytick.PNG";
import greentick from "../../../assets/images/greentick.PNG";
import buttonadd from "../../../assets/images/plus.png";
import trash from "../../../assets/images/trash.svg";
import next from "../../../assets/svg/next-icon.svg";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import FaqForm from "../../Common/faqForm";
import InputRadioDynamic from "../../Common/inputRadioDynamic";
import InputTextField from "../../Common/inputTextField";
import MultiSelectField from "../../Common/multiSelectField";
import NextBackBtns from "../../Common/nextBackBtns";
import SelectField from "../../Common/selectField";
import SucessModal from "../../modal/SucessModal";
import "./index.scss";

const days = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday"
]

const ImageUploader = ({ images, setImages }) => {

	const [fileInputKey, setFileInputKey] = useState(0);
	const fileInputRef = useRef(null);

	const handleImageChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		setImages((prevImages) => [...prevImages, ...selectedFiles]);
		setFileInputKey((prevKey) => prevKey + 1);
	};

	const handleRemoveImage = (index) => {
		const updatedImages = images.filter((_, i) => i !== index);
		setImages(updatedImages);
	};

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const getsrcValue = (value) => {
		if (typeof value === "string" && value.length)
			return process.env.REACT_APP_API_URL + "clinic-profile/" + value;
		return typeof value === "object" ? URL.createObjectURL(value) : " ";
	};

	return (
		<div>
			<div className="d-flex gap-4 clinicProfilePhotos">
				{images.map((image, index) => (
					<div key={index} className="uploaded-image position-relative mb-2">
						<img
							// src={URL.createObjectURL(image)}
							src={getsrcValue(image)}
							width={100}
							height={133}
							alt={`uploaded-${index}`}
						/>
						<img
							src={trash}
							width="24"
							height="24"
							onClick={() => handleRemoveImage(index)}
							className="rpQrUploadImage position-absolute top-0 end-0"
							alt="Remove"
						/>
					</div>
				))}
			</div>

			<label htmlFor="imageInput">
				<button onClick={handleButtonClick} className="border-0" type="button">
					{/* <img src={addPhotos} width={24} height={24} className="me-2" alt="add-photo" /> */}
					<div className="multiImageUploader">Choose File</div>
				</button>
				<input
					ref={fileInputRef}
					key={fileInputKey}
					type="file"
					// {...register('photos', { required: true })} // Include validation for required images
					id="photos"
					accept="image/*"
					className="visually-hidden"
					onChange={handleImageChange}
					multiple
				/>
			</label>
		</div>
	);
};

const StepOne = ({
	register,
	errors,
	handleNext,
	setValue,
	watch,
	control,
	trigger,
	images,
	setImages,
	about,
	setAbout,
	logo,
	setLogo,
	getValues,
	cityList,
	clinicSpeciality,
	citySelected,
	doctorSpecializationList

}) => {
	const selectedCity = watch('city');
	const selectedSpecializations = watch('specialization');
	const [localitiesList, setLocalitiesList] = useState([]);
	const [services, setServices] = useState([]);

	const getLocalityList = async () => {
		const result = await apiCall(`locality/list/${selectedCity}`, "GET");
		if (result?.data) {
			setLocalitiesList(result.data.map((ele) => ({ label: ele.locality, value: ele._id })))
			if (!result.data.map((ele) => ele._id).includes(getValues()?.clinic_location)) {
				setValue("clinic_location", "")
			}
		}
	}

	const getServicesList = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}doctors-clinic/list`, {
				specialization: selectedSpecializations.map((data) => data.value),
			})
			.then((res) => {
				if (res.data.rows?.length) {
					setServices(res.data.rows.map((data) => ({
						value: data?.service_name,
						label: data?.service_name,
						id: data?._id,
						specializationId: data?.specialization?._id
					})));
				}
			});
	}

	useEffect(() => {
		if (selectedSpecializations?.length) {
			getServicesList();
		} else {
			setServices([])
			setValue("services", [])
		}

		if (selectedSpecializations?.length && getValues()?.services?.length) {
			const selectedSpecializationsIds = selectedSpecializations?.map(data => data?.value)
			setValue("services", getValues()?.services.filter((service) => selectedSpecializationsIds.includes(service.specializationId)))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [doctorSpecializationList, selectedSpecializations])

	useEffect(() => {
		if (getValues()?.services) {
			setValue("services", getValues()?.services)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [services])

	useEffect(() => {
		if (cityList?.length && getValues()?.city) {
			getLocalityList()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cityList, selectedCity])

	useEffect(() => {
		if (getValues()?.city) {
			setValue("city", getValues()?.city);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cityList])


	useEffect(() => {
		if (getValues()?.clinic_location) {
			setValue("clinic_location", getValues()?.clinic_location)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localitiesList])

	useEffect(() => {
		if (getValues()?.clinic_specialty_status) {
			setValue("clinic_specialty_status", getValues()?.clinic_specialty_status)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clinicSpeciality])

	const handleInputChange = (e) => {
		setLogo(e.target.files[0]);
	};

	const getsrcValue = (value) => {
		if (typeof value === "string" && value.length)
			return process.env.REACT_APP_API_URL + "clinic-profile/" + value;
		return typeof value === "object" ? URL.createObjectURL(value) : " ";
	};
	const removeImage = async () => {
		setLogo("");
		setValue(logo, "")
	}

	return (
		<div className="">
			<div className="mt-3">
				<div className="col-12">
					<InputTextField
						labelName="Name of Clinic / Hospital"
						name="clinic_name"
						className="w-100"
						register={register}
						errors={errors}
						errorText={"Please provide a Clinic Name"}
					/>
				</div>
			</div>
			{/* two */}
			<div className="mt-2 col-12">
				<InputRadioDynamic
					register={register}
					errors={errors}
					errorMessage="Please choose the Organisation Type"
					watch={watch}
					setValue={setValue}
					// onChange={handleSelection}
					trigger={trigger}
					label="Type of Organisation"
					name="organisationType"
					options={[
						{ name: "Clinic", },
						{ name: "Hospital", }
					]}
				/>
			</div>
			<div className="col-12 mt-4">
				<SelectField
					id="clinicSpeciality"
					labelName="Clinic Specialty Status"
					name="clinic_specialty_status"
					className="w-100"
					isOptionObject={true}
					options={clinicSpeciality}
					register={register}
					errors={errors}
					errorText="Please choose a Clinic Speciality"
					defaultOption="Clinic Speciality"
				/>
			</div>
			<div className="col-12 mt-4">
				<MultiSelectField
					name="specialization"
					id="specialization"
					labelName="Doctor Specializations in the In-Clinic"
					control={control}
					errors={errors}
					options={doctorSpecializationList}
					// maxLength={3}
					placeholder="Select"
					className="w-50"
					requiredText="Please select the specialization"
				/>
			</div>
			<div className="mt-4 row">
				<div className="col-12 col-md-6 pe-3">
					<SelectField
						id="clinicCity"
						labelName="City / Town "
						name="city"
						className="w-100"
						isOptionObject={true}
						options={cityList}
						register={register}
						errors={errors}
						errorText="Please choose a City"
						defaultOption="City"
						selectedDataId={citySelected}
					/>
				</div>
				<div className="col-12 col-md-6 ps-3">
					<SelectField
						id="clinicProfileState"
						labelName="State"
						name="state"
						options={states}
						className="w-100"
						register={register}
						errors={errors}
						errorText="Please choose a State"
						defaultOption="State"
					/>
				</div>
			</div>
			{/* three */}
			<div className="mt-3 row">
				<div className="col-12 col-md-6 pe-3">
					<label htmlFor="inputAddress" className="form-label title">
						Pincode of Clinic / Hospital <span className="star">*</span>
					</label>
					<input
						className="form-control addnew-input w-100"
						type="text"
						{...register("pincode", {
							required: true,
							min: 110000,
							pattern: /[0-9]{6}/,
						})}
						placeholder="Enter pincode"
						maxLength="6"
						onKeyPress={(e) => {
							if (!/[0-9]/.test(e.key)) {
								e.preventDefault();
							}
						}}
					/>
					{errors.pincode && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Pincode
						</div>
					)}
				</div>
				<div className="col-12 col-md-6 ps-3">
					<SelectField
						id="clinicLocality"
						labelName="Location of Clinic / Hospital "
						name="clinic_location"
						isOptionObject={true}
						options={localitiesList}
						register={register}
						errors={errors}
						className="w-100"
						errorText="Please choose a Locality"
						defaultOption="Locality"
					/>
				</div>
			</div>
			<div className="mt-3 row">
				<div className="col-12 col-md-6">
					<InputTextField
						id="clinicContact"
						labelName="Clinic / Hospital Contact"
						name="contact"
						type="number"
						className="w-100"
						register={register}
						errors={errors}
						errorText={"Please provide a Contact Number"}
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputTextField
						id="licenseNo"
						className="w-100"
						labelName="Clinic / Hospital License or Registration Number"
						name="license_no"
						register={register}
						errors={errors}
						errorText={"Please provide a License Number"}
					/>
				</div>
			</div>
			<div className="mt-3 row">
				<div className="col-12 col-md-6">
					<div className="authorUpload ms-0">
						<div className=" form-label title">
							Upload Logo of Clinic / Hospital{" "}
							<span className="star">*</span>
						</div>
						{logo ? (
							<div> <img
								src={getsrcValue(logo)}
								style={{
									width: "100px",
									height: "100px",
									marginTop: "2%",
									marginBottom: "5%",
								}}
								alt="logo"
							/></div>
						) : null}
						{(logo === "" || typeof (logo) !== 'string') ? <input
							className="form-control-file custom-file-input"
							type="file"
							{...register("logo", { required: true })}
							style={{
								width: "120px",
							}}
							onChange={handleInputChange}
						/> : <></>}
						{logo &&
							<button className="btn btn-danger ms-2"
								onClick={() => removeImage()}
							>Remove</button>}
					</div>
					{errors?.logo && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Logo
						</div>
					)}
				</div>
				<div className="col-12 col-md-6">
					<label htmlFor="inputAddress" className="form-label title">
						Upload Photos of Clinic / Hospital<span className="star">*</span><br />
						<span className="star">(interior / Exterior) max 8 photos</span>
					</label>
					<Controller
						name="photos"
						{...register("photos")}
						control={control}
						defaultValue={[]}
						render={({ field: { value } }) => (
							<ImageUploader images={images} setImages={setImages} />
						)}
					/>
					{!images?.length ? (
						<div className={`invalid-feedback d-block`}>
							Please choose the images of Clinic / Hospital
						</div>
					) : <></>}
				</div>
			</div>
			<div className=" mt-3">
				<div className="col-12">
					<InputTextField
						id="clinicAddress"
						labelName="Address of the clinic"
						name="address"
						className="w-100"
						register={register}
						errors={errors}
						errorText={"Please provide a clinic address"}
					/>
				</div>
			</div>
			{/* Six */}
			<div className=" mt-3">
				<div className="col-12">
					<label htmlFor="inputAddress" className="form-label title mb-2">
						Google Map Location Link<span className="star">*</span>
					</label>
					<input
						type="text"
						{...register("map_location", { required: true })}
						className="form-control addnew-input w-100"
						id="map_location"
						style={{ paddingLeft: "10px" }}
					/>
					{errors.map_location && (
						<div className={`invalid-feedback d-block`}>
							Please provide a Map Location
						</div>
					)}
				</div>
			</div>
			<div className=" mt-3 z-2 position-relative">
				<MultiSelectField
					name="services"
					id="services"
					// value={selectedOptions}
					labelName="Clinic Services"
					control={control}
					errors={errors}
					options={services}
					placeholder="Select"
					errorText="Please choose a Services"
					requiredText="This field is required"
				/>
			</div>
			<div className="addnew-form1-desc mt-3">
				<label className="title mt-4">
					About
					<span className="star ms-1">*</span>
				</label>
				<JoditEditor
					value={about}
					onChange={(value) => {
						setAbout(value);
						setValue("about", value);
					}}
				/>
				{!about?.length && (
					<div className="invalid-feedback d-block"> About field is required</div>
				)}
			</div>
			<div className="formNextBtn">
				<div onClick={handleNext}>
					<button type="button">NEXT</button>
					<img src={next} alt="" />
				</div>
			</div>
		</div>
	)
}

const StepTwo = ({
	Controller,
	setPage,
	// handleBack,
	errors,
	control,
	setValue,
	getValues,
	register,
	trigger,
	watch
}) => {

	const watchCloseStatus = useWatch({
		control,
		name: days.map(day => `${day}Closed`),
		defaultValue: []
	});

	useEffect(() => {
		const keyValues = Object.keys(getValues());
		days.forEach((keyName) => {
			if (keyValues?.includes(`${keyName}Closed`) && getValues()[`${keyName}Closed`] === false) {
				setValue(`${keyName}_start_timing`, getValues()[`${keyName}_start_timing`])
				setValue(`${keyName}_end_timing`, getValues()[`${keyName}_end_timing`])
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const keyValues = Object.keys(getValues());
		days.forEach((data, inx) => {
			if (!keyValues?.includes(`${data}Closed`) && data) {
				if (data) {
					setValue(`${data}_start_timing`, "")
					setValue(`${data}_end_timing`, "")
				}
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchCloseStatus])

	const handleBack = () => {
		setPage(1);
	}

	const handleNext = async () => {
		await trigger()
		if (!Object.keys(errors)?.length) {
			setPage(3)
		}
	}

	return (
		<div className="doctor-page2">
			<div className=" mt-3">
				{days.map((option, idx) => {
					return (
						<Fragment key={idx}>
							<div className="doctorSpecialisation mt-5 ps-4 pt-3 pb-4">
								<div key={idx}><b>{option}</b></div>
								{((watchCloseStatus?.length && watchCloseStatus[idx]) || getValues()[`${option}Closed`]) ? <></> : <>
									<div className="d-flex mt-3">
										<div>Start Time</div>
										<div className="doctorEndTime">End Time</div>
									</div>
									<div className="d-flex">
										<div className="d-flex mt-3">
											<Controller
												// name="time"
												name={`${option}_start_timing`}
												control={control}
												rules={{
													required: 'Please choose a time or choose closed'
												}}
												render={({ field }) => (
													<>
														<div className="">
															<div className="timePickerIcon">
																{/* <BsClock size={22} /> */}
															</div>
															{/* <input type="time" placeholder="HH:MM" /> */}
															<TimePicker
																{...field}
																showSecond={false}
																allowEmpty={true}
																format="h:mm a"
																use12Hours={true}
																onChange={(value) => {
																	field.onChange(value);
																}}
																value={field.value ? moment(field.value, 'HH:mm A') : null}
																placeholder="Choose Time"
															/>
															{errors[`${option}_start_timing`] && (
																<div className="invalid-feedback d-block">
																	{errors[`${option}_start_timing`].message}
																</div>
															)}
														</div>
													</>
												)}
											/>
										</div>
										<div className="d-flex mt-3 registrationEndTime">
											<Controller
												name={`${option}_end_timing`}
												control={control}
												rules={{
													required: 'Please choose a time or choose closed'
												}}
												render={({ field }) => {
													return (
														<>
															<div>
																<div className="timePickerIcon">
																</div>
																<TimePicker
																	{...field}
																	showSecond={false}
																	allowEmpty={true}
																	format="h:mm a"
																	onChange={(value) => {
																		field.onChange(value);
																	}}
																	use12Hours={true}
																	value={field.value ? moment(field.value, 'HH:mm A') : null}
																	placeholder="Choose Time"
																/>
																{errors[`${option}_end_timing`] && (
																	<div className="invalid-feedback d-block">
																		{errors[`${option}_end_timing`].message}
																	</div>
																)}
															</div>
														</>
													)
												}}
											/>
											{/* <div className="doctorsTiming ms-4 d-flex align-item-center justify-content-center">
											<select className="bg-white">
												{mode.map((option, idx) => {
													return (
														<option key={idx}>{option}</option>
													)
												})}
											</select>
										</div> */}
										</div>
									</div></>}
								<div className="mt-4 d-flex">
									<input
										type="checkbox"
										{...register(`${option}Closed`, { required: false })}
										checked={watch(`${option}Closed`)}
									// onChange={() => handleCheckboxChange(option)}
									/>
									<div className="ps-3">Closed</div>
								</div>
							</div>
						</Fragment>
					)
				})}
			</div>
			<NextBackBtns
				handleBack={handleBack}
				handleNext={handleNext}
				nextBtnType={"submit"}
			/>
		</div>
	)
}

const EditClinicProfile = () => {

	const navigate = useNavigate();
	const [logo, setLogo] = useState("");
	const [page, setPage] = useState(1);
	const [success, setSuccess] = useState(false);
	const [images, setImages] = useState([]);
	const [faqs, setFaqs] = useState([]);
	const [about, setAbout] = useState("")
	const { id } = useParams();

	const {
		register,
		getValues,
		formState: { errors, isValid },
		handleSubmit,
		trigger,
		watch,
		setError,
		control,
		setValue
	} = useForm({
		mode: "onChange",
	});

	const [cityList, setCityList] = useState([]);
	const [clinicProfileDetail, setClinicProfileDetail] = useState([]);
	const [clinicSpeciality, setClinicSpeciality] = useState([]);
	const [doctorSpecializationList, setDoctorSpecialization] = useState([]);

	useEffect(() => {
		getCitiesList();
		getClinicProfileDatail()
		getDoctorSpecialization();
		getclinicSpeciality();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getDoctorSpecialization = async () => {
		try {
			const result = await apiCall(`inclinc-doctor-specialization/list`, "POST", { active: true });
			if (result.data?.rows) {
				setDoctorSpecialization(result.data.rows?.map((data) => ({ value: data?._id, label: data?.inclinic_doctor_specialization })));
			}
		} catch (err) {
			console.error(err);
		}
	};

	const getclinicSpeciality = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}clinic-speciality/list`, {
				noLimit: true,
				active: true
			})
			.then((res) => {
				setClinicSpeciality(res.data.rows.map((data) => ({ value: data?._id, label: data?.clinic_speciality })));
			});
	};


	const getClinicProfileDatail = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}doctors-profile/get/${id}`)
			.then((response) => {
				setClinicProfileDetail(response?.data);
				setValue("clinic_name", response?.data?.clinic_name);
				setValue("clinic_specialty_status", response?.data?.clinic_specialty_status);
				setValue("organisationType", response?.data?.organisationType);
				// setValue("verified", response?.data?.verified ? "Yes" : "No")
				setValue("city", response?.data?.city)
				setValue("state", response?.data?.state)
				setValue("pincode", response?.data?.pincode)
				setValue("clinic_location", response?.data?.clinic_location)
				setValue("contact", response?.data?.contact)
				setValue("license_no", response?.data?.license_no)
				setValue("address", response?.data?.address)
				setValue("map_location", response?.data?.map_location)
				setImages(response?.data?.photos)
				setLogo(response?.data?.logo)
				setValue("logo", response?.data?.logo)
				if (response?.data?.specialization?.length) {
					setValue("specialization", response?.data?.specialization.map((data) => ({ value: data?._id, label: data?.inclinic_doctor_specialization, })));
				}
				if (response?.data?.services?.length) {
					setValue("services", response?.data?.services.map((data) => ({ value: data?.service_name, label: data?.service_name, id: data?._id, specializationId: data?.specialization })));
				}
				const timings = response?.data?.timings;
				if (timings) {
					Object.keys(timings).forEach((keyName) => {
						if (keyName.includes("Closed")) {
							setValue(keyName, timings[keyName])
						} else {
							if (timings[keyName] === "Invalid date") {
								setValue(keyName, "")
							} else if (timings[keyName]?.length <= 8) {
								setValue(keyName, timings[keyName])
							} else {
								const formattedTime = moment(timings[keyName]).format("h:mm A");
								setValue(keyName, formattedTime)
							}
						}
					})
				}
				setValue("about", response?.data?.about)

				setAbout(response?.data?.about)
				setFaqs(response?.data?.faq);
			})
			.catch((err) => {
				console.error(err);
				alert("Some error");
			});
	};

	const getCitiesList = async () => {
		try {
			const result = await apiCall(`doctor-cities/city-list`, "POST");
			if (result.data) {
				setCityList(result.data?.map((data) => ({ value: data._id, label: data?.city, active: data?.active })))
			}
		} catch (err) {
			console.error(err);
		}
	}

	const handleNext = async () => {
		await trigger();
		if (isValid && images?.length && about?.length) {
			setPage(2);
		}
	};

	const handlenewClinicProfile = () => {
		navigate(`/doctor-add-new-faq`)
	}

	const onSubmit = (fields) => {
		const formData = new FormData();
		images.forEach(file => {
			formData.append("photos", file);
		});
		let timings = {}
		Object.keys(fields).forEach((key) => {
			const hasDayInKey = days.some(day => key.startsWith(day));

			if (hasDayInKey) {
				timings[key] = fields[key]

			} else if (!["logo", "photos", "services", "specialization"].includes(key)) {
				formData.append(key, fields[key])
			}
		})

		const logo = fields.logo && (typeof (fields.logo) === 'string' ? fields.logo : fields.logo[0]);

		formData.append("logo", logo)
		formData.append("faq", JSON.stringify(faqs))
		formData.append("timings", JSON.stringify(timings))
		formData.append("services", fields?.services?.map((data) => data?.id))
		formData.append("specialization", fields?.specialization?.map((data) => data?.value))
		axios
			.postForm(
				`${process.env.REACT_APP_API_URL}doctors-profile/update/${clinicProfileDetail?._id}`,
				formData
			)
			.then((response) => {
				if (response.data?._id) {
					setSuccess(true);
				} else {
					alert("Fail");
					console.log("failed...");
				}
			});
	};

	return (
		<>
			<div className="healtharticle">
				<div className="healtharticle-container">
					<div className="pt-3 ms-3">
						<Breadcrumbs
							breadcrumbText="Clinic Profile"
							breadcrumbLink="/doctor-clinic-profile"
							nestedText="Add New Clinic & Hospital"
							nestedLink=""
						/>
					</div>
					{page === 3 ?
						<div className="healtharticle-addnew">
							<div
								id="addnewpost"
								onClick={handlenewClinicProfile}
								className="addnewhealth"
								style={{ width: "190px" }}
							>
								<div>
									{/* <BsFillPlusCircleFill /> */}
									<img src={buttonadd} alt="" />
								</div>
								<div>
									<button>Add New FAQ's</button>
								</div>
							</div>
						</div> : ""
					}
					{
						<div className={page === 1 ? "doctor-page-tick" : page === 2 ? "doctor-page2-tick" : page === 3 ? "doctor-page3-tick" : ""}>
							<div>
								<img src={greentick} alt="" />
								<div>Page 1</div>
							</div>
							<div>
								<img src={page === 1 ? graytick : greentick} alt="" />
								<div>Page 2</div>
							</div>
							<div>
								<img src={page === 3 ? greentick : graytick} alt="" />
								<div>Page 3</div>
							</div>
						</div>
					}
					<form className="career-jobpost-form clinicProfile" onSubmit={handleSubmit(onSubmit)}>
						{page === 1 ? (
							<StepOne
								register={register}
								errors={errors}
								handleNext={handleNext}
								setLogo={setLogo}
								logo={logo}
								setValue={setValue}
								getValues={getValues}
								control={control}
								isValid={isValid}
								trigger={trigger}
								watch={watch}
								setError={setError}
								images={images}
								setImages={setImages}
								setAbout={setAbout}
								about={about}
								cityList={cityList}
								clinicSpeciality={clinicSpeciality}
								citySelected={clinicProfileDetail?.city}
								doctorSpecializationList={doctorSpecializationList}
							/>
						) : page === 2 ? (
							<StepTwo
								register={register}
								setPage={setPage}
								watch={watch}
								Controller={Controller}
								errors={errors}
								control={control}
								getValues={getValues}
								setValue={setValue}
								isValid={isValid}
								trigger={trigger}
								setError={setError}
							/>
						) : page === 3 ? (
							<FaqForm
								faqs={faqs}
								setFaqs={setFaqs}
								handleBack={() => setPage(2)}
							/>
						) : null
						}
					</form>
				</div>
			</div>
			{
				success && (
					<SucessModal
						page={"/doctor-clinic-profile"}
						name="Doctor Clinic Profile"
						post='updated'
					/>
				)
			}
		</>
	);
}

export default EditClinicProfile