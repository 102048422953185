import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import dashboardd from "../../assets/svg/dashboard-icon.svg";
import submenu from "../../assets/svg/submenu-icon.svg";
import { CTACategoryList, LegalPageAppDownloadLinks } from "../../constants";
import Logout from "../Account/Logout/Logout";
import Header from "../Layout/Header/Header";
import "./home.scss";

const Home = ({ children }) => {
  const [healthsy, setHealthsy] = useState(null);
  const [masters, setMasters] = useState(null);
  const [popupdata, setPopupData] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [homehealth, setHomeHealth] = useState(null);
  const [retail, setRetail] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [setting, setSetting] = useState(null);
  const [count, setCount] = useState(null);
  const [brocher, setBrocher] = useState(null);
  const [sitemap, setSitemap] = useState(null);
  const [textcolor, setTextColor] = useState(1);
  const [faq, setFaq] = useState(null);
  const [faqSections, setFaqsections] = useState([]);
  const [cta, setCta] = useState(null);
  const [ctaForm, setCtaForm] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null);
  const [rpQrRarings, setRpQrRarings] = useState(null);
  const [doctorConsultation, setDoctorConsultation] = useState();
  const [blogSearch, setBlogSearch] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getFaqSections();
  }, []);

  const getFaqSections = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}faq-section/list`)
      .then((response) => {
        if (response?.data?.rows?.length) {
          setFaqsections(response?.data?.rows);
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  return (
    <div className="home">
      <Header />
      <div className="home-container ">
        <div style={{ display: "flex" }}>
          <div className="sidebar">
            <div>
              {/*--------Dashboard----------*/}
              <div className="side-n-dashboard common-dash-card">
                <div
                  style={{
                    color: textcolor === 1 ? "#fff" : "",
                    background: textcolor === 1 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={dashboardd} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      navigate("/dashboard");
                      setTextColor(1);
                    }}
                    style={{
                      color: textcolor === 1 ? "#fff" : "",
                    }}
                  >
                    Dashboard
                  </div>
                </div>
              </div>

              {/*--------Healthsy----------*/}
              <div className="side-n-healthsy common-dash-select">
                <div
                  onClick={() => {
                    setHealthsy(!healthsy);
                    setTextColor(2);
                  }}
                  style={{
                    color: textcolor === 2 ? "#fff" : "",
                    background: textcolor === 2 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/doctor-registration")}
                    style={{
                      color: healthsy ? "#fff" : "",
                    }}
                  >
                    HealthSynergy
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {healthsy ? (
                <div className="common-show-dash healthsy-show-dash">
                  <div
                    style={{ color: textcolor === 2 ? "#fff" : "" }}
                    onClick={() => {
                      navigate("/doctor-registration");
                      setTextColor(2);
                    }}
                  >
                    HealthSy Partnered Doctor..
                  </div>
                  <div
                    onClick={() => {
                      navigate("/pharmacy-registration");
                      setTextColor(201);
                    }}
                    style={{ color: textcolor === 201 ? "#fff" : "" }}
                  >
                    HealthSy Partnered Pharma..
                  </div>
                  <div
                    onClick={() => {
                      navigate("/healthcare-registration");
                      setTextColor(202);
                    }}
                    style={{ color: textcolor === 202 ? "#fff" : "" }}
                  >
                    HealthSy Partnered Home H..
                  </div>
                  <div
                    onClick={() => {
                      navigate("/insta-doc");
                      setTextColor(203);
                    }}
                    style={{ color: textcolor === 203 ? "#fff" : "" }}
                  >
                    InstaDoc
                  </div>
                  <div
                    onClick={() => {
                      navigate("/other-registration");
                      setTextColor(204);
                    }}
                    style={{ color: textcolor === 204 ? "#fff" : "" }}
                  >
                    Others
                  </div>
                  <div
                    onClick={() => {
                      navigate("/doctor-demo");
                      setTextColor(205);
                    }}
                    style={{ color: textcolor === 205 ? "#fff" : "" }}
                  >
                    Book a Demo
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Masters----------*/}
              <div className="side-n-masters common-dash-select">
                <div
                  onClick={() => {
                    setMasters(!masters);
                    setTextColor(3);
                  }}
                  style={{
                    color: textcolor === 3 ? "#fff" : "",
                    background: textcolor === 3 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/master-primary-specialisation")}
                    style={{
                      color: masters ? "#fff" : "",
                    }}
                  >
                    Masters
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {masters ? (
                <div className="common-show-dash masters-show-dash">
                  <div
                    onClick={() => {
                      navigate("/master-primary-specialisation");
                      setTextColor(3);
                    }}
                    style={{ color: textcolor === 3 ? "#fff" : "" }}
                  >
                    Doctors
                  </div>
                  <div
                    onClick={() => {
                      navigate("/master-health-care");
                      setTextColor(301);
                    }}
                    style={{ color: textcolor === 301 ? "#fff" : "" }}
                  >
                    Home Healthcare Servoice Pr..
                  </div>
                  <div
                    onClick={() => {
                      navigate("/master-partnered-pharmacy");
                      setTextColor(302);
                    }}
                    style={{ color: textcolor === 302 ? "#fff" : "" }}
                  >
                    Partnered Pharamcies
                  </div>
                  <div
                    onClick={() => {
                      navigate("/master-others");
                      setTextColor(303);
                    }}
                    style={{ color: textcolor === 303 ? "#fff" : "" }}
                  >
                    Others
                  </div>
                  <div
                    onClick={() => {
                      navigate("/master-gender");
                      setTextColor(304);
                    }}
                    style={{ color: textcolor === 304 ? "#fff" : "" }}
                  >
                    Common
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Popup Data----------*/}
              <div className="side-n-popupdata common-dash-select">
                <div
                  onClick={() => {
                    setPopupData(!popupdata);
                    setTextColor(4);
                  }}
                  style={{
                    color: textcolor === 4 ? "#fff" : "",
                    background: textcolor === 4 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/comment")}
                    style={{
                      color: popupdata ? "#fff" : "",
                    }}
                  >
                    Popup Data
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {popupdata ? (
                <div className="common-show-dash popupdata-show-dash">
                  {/* <div
                    onClick={() => {
                      navigate("/getnotified");
                      setTextColor(4);
                    }}
                    style={{ color: textcolor === 4 ? "#fff" : "" }}
                  >
                    Get Notified
                  </div>
                  <div
                    onClick={() => {
                      navigate("/notifyme");
                      setTextColor(401);
                    }}
                    style={{ color: textcolor === 401 ? "#fff" : "" }}
                  >
                    Notify Me
                  </div> */}
                  <div
                    onClick={() => {
                      navigate("/comment");
                      setTextColor(402);
                    }}
                    style={{ color: textcolor === 402 ? "#fff" : "" }}
                  >
                    Post a Comment
                  </div>
                  <div
                    onClick={() => {
                      navigate("/blog-subscribe");
                      setTextColor(403);
                    }}
                    style={{ color: textcolor === 403 ? "#fff" : "" }}
                  >
                    News letter Subscription
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Health Articles----------*/}
              <div className="side-n-popupdata common-dash-select">
                <div
                  onClick={() => {
                    setBlogSearch(!blogSearch);
                    setTextColor(5);
                  }}
                  style={{
                    color: textcolor === 5 ? "#fff" : "",
                    background: textcolor === 5 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/healtharticle")}
                    style={{
                      color: blogSearch ? "#fff" : "",
                    }}
                  >
                    Blogs
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {blogSearch ? (
                <div className="common-show-dash masters-show-dash">
                  <div
                    onClick={() => {
                      navigate("/healtharticle");
                      setTextColor(5);
                    }}
                    style={{ color: textcolor === 5 ? "#fff" : "" }}
                  >
                    Blogs
                  </div>
                  <div
                    onClick={() => {
                      navigate("/blog-search-keyword");
                      setTextColor(501);
                    }}
                    style={{ color: textcolor === 501 ? "#fff" : "" }}
                  >
                    Blog Search Keyword
                  </div>
                  <div
                    onClick={() => {
                      navigate("/blog-banner-image");
                      setTextColor(502);
                    }}
                    style={{ color: textcolor === 502 ? "#fff" : "" }}
                  >
                    Blog Banner Image
                  </div>
                </div>
              ) : null}

              {/*--------Careers----------*/}
              <div className="side-n-career common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(6);
                  }}
                  style={{
                    color: textcolor === 6 ? "#fff" : "",
                    background: textcolor === 6 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/careers")}
                    style={{
                      color: textcolor === 6 ? "#fff" : "",
                    }}
                  >
                    Careers
                  </div>
                </div>
              </div>

              {/* --------Doctors---------- */}
              <div className="side-n-doctor common-dash-select">
                <div
                  onClick={() => {
                    setDoctor(!doctor);
                    setTextColor(7);
                  }}
                  style={{
                    color: textcolor === 7 ? "#fff" : "",
                    background: textcolor === 7 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/partnered-doctor")}
                    style={{
                      color: doctor ? "#fff" : "",
                    }}
                  >
                    Doctors
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {doctor ? (
                <div className="common-show-dash doctor-show-dash">
                  <div
                    onClick={() => {
                      navigate("/partnered-doctor");
                      setTextColor(7);
                    }}
                    style={{ color: textcolor === 7 ? "#fff" : "" }}
                  >
                    Partnered Doctor
                  </div>
                  <div
                    onClick={() => {
                      navigate("/add-new-doctors");
                      setTextColor(701);
                    }}
                    style={{ color: textcolor === 701 ? "#fff" : "" }}
                  >
                    Specialisation Listing Meta
                  </div>
                  <div
                    onClick={() => {
                      navigate("/online-doctor-specialisation");
                      setTextColor(702);
                    }}
                    style={{ color: textcolor === 702 ? "#fff" : "" }}
                  >
                    Doctor Specialisation
                  </div>
                  <div
                    onClick={() => {
                      navigate("/doctors-location");
                      setTextColor(703);
                    }}
                    style={{ color: textcolor === 703 ? "#fff" : "" }}
                  >
                    City & Localities List
                  </div>
                  <div
                    onClick={() => {
                      navigate("/doctor-clinic-profile");
                      setTextColor(704);
                    }}
                    style={{ color: textcolor === 704 ? "#fff" : "" }}
                  >
                    Clinic Profile
                  </div>
                  <div
                    onClick={() => {
                      navigate("/doctor-clinic-service");
                      setTextColor(705);
                    }}
                    style={{ color: textcolor === 705 ? "#fff" : "" }}
                  >
                    Clinic Services
                  </div>
                  <div
                    onClick={() => {
                      navigate("/doctors-gender");
                      setTextColor(706);
                    }}
                    style={{ color: textcolor === 706 ? "#fff" : "" }}
                  >
                    Common Heads
                  </div>
                  <div
                    onClick={() => {
                      navigate("/doctors-report");
                      setTextColor(707);
                    }}
                    style={{ color: textcolor === 707 ? "#fff" : "" }}
                  >
                    Report a Error
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Home Healthcare Service Provider----------*/}
              <div className="side-n-homehealth common-dash-select">
                <div
                  onClick={() => {
                    setHomeHealth(!homehealth);
                    setTextColor(8);
                  }}
                  style={{
                    color: textcolor === 8 ? "#fff" : "",
                    background: textcolor === 8 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() =>
                      navigate("/home-healthcare-service-provider")
                    }
                    style={{
                      color: homehealth ? "#fff" : "",
                    }}
                  >
                    Home Healthcare Se..
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {homehealth ? (
                <div className="common-show-dash homehealth-show-dash">
                  <div
                    onClick={() => {
                      navigate("/home-healthcare-service-provider");
                      setTextColor(8);
                    }}
                    style={{ color: textcolor === 8 ? "#fff" : "" }}
                  >
                    Add New Service Provider
                  </div>
                  <div
                    onClick={() => {
                      navigate("/home-healthcare-hhsp");
                      setTextColor(801);
                    }}
                    style={{ color: textcolor === 801 ? "#fff" : "" }}
                  >
                    HHSP Specialisations
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Partnered Pharmacies----------*/}
              <div className="side-n-retail common-dash-select">
                <div
                  onClick={() => {
                    setRetail(!retail);
                    setTextColor(9);
                  }}
                  style={{
                    color: textcolor === 9 ? "#fff" : "",
                    background: textcolor === 9 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/add-new-retail-pharmacy")}
                    style={{
                      color: retail ? "#fff" : "",
                    }}
                  >
                    Partnered Pharmacies
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {retail ? (
                <div className="common-show-dash retail-show-dash">
                  <div
                    onClick={() => {
                      navigate("/add-new-retail-pharmacy");
                      setTextColor(9);
                    }}
                    style={{ color: textcolor === 9 ? "#fff" : "" }}
                  >
                    Add New Retail Pharmacies
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Clinic / Hospital----------*/}
              <div className="side-n-clinic common-dash-select">
                <div
                  onClick={() => {
                    setClinic(!clinic);
                    setTextColor(10);
                  }}
                  style={{
                    color: textcolor === 10 ? "#fff" : "",
                    background: textcolor === 10 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/add-new-clinic")}
                    style={{
                      color: clinic ? "#fff" : "",
                    }}
                  >
                    Clinic / Hospital
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {clinic ? (
                <div className="common-show-dash clinic-show-dash">
                  <div
                    onClick={() => {
                      navigate("/add-new-clinic");
                      setTextColor(10);
                    }}
                    style={{ color: textcolor === 10 ? "#fff" : "" }}
                  >
                    Add New Clinic / Hospital
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Settings----------*/}
              <div className="side-n-setting common-dash-select">
                <div
                  onClick={() => {
                    setSetting(!setting);
                    setTextColor(11);
                  }}
                  style={{
                    color: textcolor === 11 ? "#fff" : "",
                    background: textcolor === 11 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/settings-social-media-form")}
                    style={{
                      color: setting ? "#fff" : "",
                    }}
                  >
                    Settings
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {setting ? (
                <div className="common-show-dash setting-show-dash">
                  <div
                    onClick={() => {
                      navigate("/settings-social-media-form");
                      setTextColor(11);
                    }}
                    style={{ color: textcolor === 11 ? "#fff" : "" }}
                  >
                    Social Media Links
                  </div>
                  <div
                    onClick={() => {
                      navigate("/add-banner-images");
                      setTextColor(1001);
                    }}
                    style={{ color: textcolor === 1001 ? "#fff" : "" }}
                  >
                    Banner Images
                  </div>
                  <div
                    onClick={() => {
                      navigate("/store-links");
                      setTextColor(1002);
                    }}
                    style={{ color: textcolor === 1002 ? "#fff" : "" }}
                  >
                    Play Store / App Store Links
                  </div>
                  <div
                    onClick={() => {
                      navigate("/business-settings");
                      setTextColor(1003);
                    }}
                    style={{ color: textcolor === 1003 ? "#fff" : "" }}
                  >
                    Business Settings
                  </div>
                  <div
                    onClick={() => {
                      navigate("/meta-tags");
                      setTextColor(1004);
                    }}
                    style={{ color: textcolor === 1004 ? "#fff" : "" }}
                  >
                    Meta Tags
                  </div>
                  <div
                    onClick={() => {
                      navigate("/email-setting");
                      setTextColor(1006);
                    }}
                    style={{ color: textcolor === 1006 ? "#fff" : "" }}
                  >
                    Email Settings
                  </div>
                  <div
                    onClick={() => {
                      navigate("/insiders");
                      setTextColor(1007);
                    }}
                    style={{ color: textcolor === 1007 ? "#fff" : "" }}
                  >
                    Insiders
                  </div>
                  <div
                    onClick={() => {
                      navigate("/company-reels");
                      setTextColor(1008);
                    }}
                    style={{ color: textcolor === 1008 ? "#fff" : "" }}
                  >
                    Company Reels
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Sitemap----------*/}
              <div className="side-n-setting common-dash-select">
                <div
                  onClick={() => {
                    setSitemap(!sitemap);
                    setTextColor(27);
                  }}
                  style={{
                    color: textcolor === 27 ? "#fff" : "",
                    background: textcolor === 27 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/sitemap/parent-sitemap")}
                    style={{
                      color: setting ? "#fff" : "",
                    }}
                  >
                    Sitemap
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {sitemap ? (
                <div className="common-show-dash setting-show-dash">
                  <div
                    onClick={() => {
                      navigate("/sitemap/parent-sitemap");
                      setTextColor(27);
                    }}
                    style={{ color: textcolor === 27 ? "#fff" : "" }}
                  >
                    Sitemap Analysis
                  </div>
                  <div
                    onClick={() => {
                      navigate("/site-map-setting");
                      setTextColor(1005);
                    }}
                    style={{ color: textcolor === 1005 ? "#fff" : "" }}
                  >
                    Other Codes / Pixel
                  </div>
                  <div
                    onClick={() => {
                      navigate("/sitemap-category");
                      setTextColor(1009);
                    }}
                    style={{ color: textcolor === 1009 ? "#fff" : "" }}
                  >
                    Common Masters
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------Email Brochures----------*/}
              <div className="side-n-broucher common-dash-select">
                <div
                  onClick={() => {
                    setBrocher(!brocher);
                    setTextColor(12);
                  }}
                  style={{
                    color: textcolor === 12 ? "#fff" : "",
                    background: textcolor === 12 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/doctor-registration-brochures")}
                    style={{
                      color: brocher ? "#fff" : "",
                    }}
                  >
                    Email Brochures
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {brocher ? (
                <div className="common-show-dash broucher-show-dash">
                  <div
                    onClick={() => {
                      navigate("/doctor-registration-brochures");
                      setTextColor(12);
                    }}
                    style={{ color: textcolor === 12 ? "#fff" : "" }}
                  >
                    Doctor Registration
                  </div>
                  <div
                    onClick={() => {
                      navigate("/doctor-demo-registration-brochures");
                      setTextColor(2001);
                    }}
                    style={{ color: textcolor === 2001 ? "#fff" : "" }}
                  >
                    Doctor Demo
                  </div>
                  <div
                    onClick={() => {
                      navigate("/pharmacy-registration-brochures");
                      setTextColor(2002);
                    }}
                    style={{ color: textcolor === 2002 ? "#fff" : "" }}
                  >
                    Pharmacy Registration
                  </div>
                  <div
                    onClick={() => {
                      navigate("/healthcare-registration-brochures");
                      setTextColor(2003);
                    }}
                    style={{ color: textcolor === 2003 ? "#fff" : "" }}
                  >
                    Healthcare Registration
                  </div>
                  <div
                    onClick={() => {
                      navigate("/others-registration-brochures");
                      setTextColor(2004);
                    }}
                    style={{ color: textcolor === 2004 ? "#fff" : "" }}
                  >
                    Other Registartion
                  </div>
                  <hr />
                </div>
              ) : null}

              {/*--------FAQ----------*/}
              <div className="common-dash-select">
                <div
                  onClick={() => {
                    setFaq(!faq);
                    setTextColor(13);
                  }}
                  style={{
                    color: textcolor === 13 ? "#fff" : "",
                    background: textcolor === 13 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/faq")}
                    style={{ color: faq ? "fff" : "" }}
                  >
                    FAQ's
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {faq ? (
                <div className="common-show-dash">
                  {faqSections &&
                    faqSections?.map((data, idx) => {
                      return (
                        <>
                          <div
                            style={{ color: textcolor === idx ? "#fff" : "" }}
                            onClick={() => {
                              navigate(`/faq/${data.slugurl}`);
                              setTextColor(idx);
                            }}
                          >
                            {data.name}
                          </div>
                        </>
                      );
                    })}
                </div>
              ) : null}
              {/*--------CTA Banner----------*/}
              <div className="common-dash-select">
                <div
                  onClick={() => {
                    setCta(!cta);
                    setTextColor(14);
                  }}
                  style={{
                    color: textcolor === 14 ? "#fff" : "",
                    background: textcolor === 14 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/cta-banner/retail-pharmacies")}
                    style={{ color: cta ? "fff" : "" }}
                  >
                    CTA Banner
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {cta ? (
                <div className="common-show-dash">
                  {CTACategoryList.map((data, idx) => {
                    return (
                      <>
                        <div
                          style={{ color: textcolor === 61 + idx ? "#fff" : "" }}
                          onClick={() => {
                            navigate(`/cta-banner/${data.slugurl}`);
                            setTextColor(61 + idx);
                          }}
                        >
                          {data.name}
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : null}
              <div className="common-dash-select">
                <div
                  onClick={() => {
                    setCtaForm(!ctaForm);
                    setTextColor(15);
                  }}
                  style={{
                    color: textcolor === 15 ? "#fff" : "",
                    background: textcolor === 15 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/cta-form/retail-pharmacies")}
                    style={{ color: cta ? "fff" : "" }}
                  >
                    CTA Form
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {ctaForm ? (
                <div className="common-show-dash">
                  {CTACategoryList.map((data, idx) => {
                    return (
                      <>
                        <div
                          style={{ color: textcolor === 51 + idx ? "#fff" : "" }}
                          onClick={() => {
                            navigate(`/cta-form/${data.slugurl}`);
                            setTextColor(51 + idx);
                          }}
                        >
                          {data.name}
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : null}
              {/*--------Contact Form----------*/}
              <div className="common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(16);
                  }}
                  style={{
                    color: textcolor === 16 ? "#fff" : "",
                    background: textcolor === 16 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/contact-us")}
                    style={{
                      color: textcolor === 16 ? "#fff" : "",
                    }}
                  >
                    Contact Form
                  </div>
                </div>
              </div>
              {/*--------App Download Links----------*/}
              <div className="common-dash-select">
                <div
                  onClick={() => {
                    setDownloadLink(!downloadLink);
                    setTextColor(17);
                  }}
                  style={{
                    color: textcolor === 17 ? "#fff" : "",
                    background: textcolor === 17 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/app-download-link/user-app")}
                    style={{ color: cta ? "fff" : "" }}
                  >
                    {/* App Download Links */}
                    Legal Pages
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {downloadLink ? (
                <div className="common-show-dash">
                  {LegalPageAppDownloadLinks.map((data, idx) => {
                    return (
                      <>
                        <div
                          style={{ color: textcolor === 91 + idx ? "#fff" : "" }}
                          onClick={() => {
                            navigate(`/app-download-link/${data.slugurl}`);
                            setTextColor(91 + idx);
                          }}
                        >
                          {data.name}
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : null}
              {/*--------Web Bookings, Consultations & Appointments----------*/}
              <div className="common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(18);
                  }}
                  style={{
                    color: textcolor === 18 ? "#fff" : "",
                    background: textcolor === 18 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/web-bookings/home-healthcare-bookings")}
                    style={{
                      color: textcolor === 18 ? "#fff" : "",
                    }}
                  >
                    <div className="webBookings-menu">
                      Web Bookings, Consultations & Appointments
                    </div>
                  </div>
                </div>
              </div>
              {/*--------Ad Landing Forms----------*/}
              <div className="common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(19);
                  }}
                  style={{
                    color: textcolor === 19 ? "#fff" : "",
                    background: textcolor === 19 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("ad-landing-forms/partnered-doctors")}
                    style={{
                      color: textcolor === 19 ? "#fff" : "",
                    }}
                  >
                    <div className="webBookings-menu">
                      Ad Landing Forms
                    </div>
                  </div>
                </div>
              </div>
              {/*--------Ad Landing Meta Tags----------*/}
              <div className="common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(20);
                  }}
                  style={{
                    color: textcolor === 20 ? "#fff" : "",
                    background: textcolor === 20 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/ad-landing-meta-tags")}
                    style={{
                      color: textcolor === 20 ? "#fff" : "",
                    }}
                  >
                    <div className="webBookings-menu">
                      Ad Landing Meta Tags
                    </div>
                  </div>
                </div>
              </div>
              {/*--------Insta Doc QR Code----------*/}
              <div className="common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(21);
                  }}
                  style={{
                    color: textcolor === 21 ? "#fff" : "",
                    background: textcolor === 21 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/insta-doc-qr-code")}
                    style={{
                      color: textcolor === 21 ? "#fff" : "",
                    }}
                  >
                    <div className="webBookings-menu">
                      Insta Doc QR Code
                    </div>
                  </div>
                </div>
              </div>

              {/* ----Counter---- */}
              <div className="side-n-setting common-dash-select">
                <div
                  onClick={() => {
                    setCount(!count);
                    setTextColor(22);
                  }}
                  style={{
                    color: textcolor === 22 ? "#fff" : "",
                    background: textcolor === 22 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/counter-home")}
                    style={{
                      color: count ? "#fff" : "",
                    }}
                  >
                    Counter
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {count ? (
                <div className="common-show-dash setting-show-dash">
                  <div
                    onClick={() => {
                      navigate("/counter-home");
                      setTextColor(22);
                    }}
                    style={{ color: textcolor === 22 ? "#fff" : "" }}
                  >
                    Home Page
                  </div>
                  <div
                    onClick={() => {
                      navigate("/ad-user-counter");
                      setTextColor(22);
                    }}
                    style={{ color: textcolor === 22 ? "#fff" : "" }}
                  >
                    Ad Landing User General
                  </div>
                  <div
                    onClick={() => {
                      navigate("/sustainability-policy-counter");
                      setTextColor(22);
                    }}
                    style={{ color: textcolor === 22 ? "#fff" : "" }}
                  >
                    Sustainability Policy
                  </div>
                  <hr />
                </div>

              ) : null}
              {/*--------Sustainability Policy----------*/}
              <div className="common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(23);
                  }}
                  style={{
                    color: textcolor === 23 ? "#fff" : "",
                    background: textcolor === 23 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/sustainability-policy")}
                    style={{
                      color: textcolor === 23 ? "#fff" : "",
                    }}
                  >
                    <div className="webBookings-menu">
                      Sustainability Policy
                    </div>
                  </div>
                </div>
              </div>
              {/*--------FRP----------*/}
              <div className="common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(25);
                  }}
                  style={{
                    color: textcolor === 25 ? "#fff" : "",
                    background: textcolor === 25 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/frp-states")}
                    style={{
                      color: textcolor === 25 ? "#fff" : "",
                    }}
                  >
                    <div className="webBookings-menu">
                      FRP
                    </div>
                  </div>
                </div>
              </div>
              {/*--------Franchise Programme----------*/}
              <div className="common-dash-card">
                <div
                  onClick={() => {
                    setTextColor(24);
                  }}
                  style={{
                    color: textcolor === 24 ? "#fff" : "",
                    background: textcolor === 24 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/franchise-programme")}
                    style={{
                      color: textcolor === 24 ? "#fff" : "",
                    }}
                  >
                    <div className="webBookings-menu">
                      Franchise Programme
                    </div>
                  </div>
                </div>
              </div>
              {/*--------RP QR Ratings----------*/}
              <div className="side-n-setting common-dash-select">
                <div
                  onClick={() => {
                    setRpQrRarings(!count);
                    setTextColor(26);
                  }}
                  style={{
                    color: textcolor === 26 ? "#fff" : "",
                    background: textcolor === 26 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/rp-qr-ratings/pharmacies-partner-id")}
                    style={{
                      color: count ? "#fff" : "",
                    }}
                  >
                    RP QR Ratings
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {rpQrRarings ? (
                <div className="common-show-dash setting-show-dash">
                  <div
                    onClick={() => {
                      navigate("/rp-qr-ratings/pharmacies-partner-id");
                      setTextColor(26);
                    }}
                    style={{ color: textcolor === 26 ? "#fff" : "" }}
                  >
                    Pharmacies Partner ID
                  </div>
                  <div
                    onClick={() => {
                      navigate("/rp-qr-ratings/pharmacy-rating");
                      setTextColor(26);
                    }}
                    style={{ color: textcolor === 26 ? "#fff" : "" }}
                  >
                    Rp-Qr-Rating Form
                  </div>
                  <hr />
                </div>

              ) : null}
              {/* ------Doctor Consultaion--------*/}
              {/* <div className="side-n-setting common-dash-select">
                <div
                  onClick={() => {
                    setDoctorConsultation(!count);
                    setTextColor(27);
                  }}
                  style={{
                    color: textcolor === 27 ? "#fff" : "",
                    background: textcolor === 27 ? "#464151" : "",
                  }}
                >
                  <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div
                    onClick={() => navigate("/booking-details/doctor-consultation")}
                    style={{
                      color: count ? "#fff" : "",
                    }}
                  >
                    Doctor Consultation
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {doctorConsultation ? (
                <div className="common-show-dash setting-show-dash">
                  <div
                    onClick={() => {
                      navigate("/booking-details/doctor-consultation");
                      setTextColor(27);
                    }}
                    style={{ color: textcolor === 27 ? "#fff" : "" }}
                  >
                    Doctor Consultation Form
                  </div>
                  <hr />
                </div>
              ) : null} */}
            </div>
          </div>
          <main className="main-container">
            <div>{children}</div>
          </main>
        </div>
      </div>
      <Logout />
    </div>
  );
};

export default Home;
