import React from "react";

import success from "../../assets/images/animation_300_lb53ccn4 (1).gif";
import "./SucessModel.scss";

export default function BannerSuccessModal({ name, post, setSuccess }) {
  return (
    <div
      onClick={() => {
        setSuccess({ isActive: false, action: '' })
      }}
    >
      <div className="modal-backdrop fade show success-model"></div>
      <div
        className="modal fade show d-block modal-sm"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className=" modal-content ">
            <div><img className="w-100 " src={success} alt="" /></div>
            <p className="text-center pb-3">A {name} has been {post} succesfully</p>
          </div>
        </div>
      </div>
    </div>
  );
}
