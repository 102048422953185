import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import apiCall from "../../api";
import BackBtn from "../Common/backBtn";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import InputRadioField from "../Common/inputRadioField";
import InputTextField from "../Common/inputTextField";
import SucessModal from "../modal/SucessModal";

const AddLegalDraftedBy = ({
    edit = "",
}) => {

    const { id } = useParams();

    const [success, setSuccess] = useState(false);

    const {
        register,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
        trigger
    } = useForm();

    useEffect(() => {
        edit && getMenuData();
    }, [edit]);

    const getMenuData = async () => {
        try {
            const response = await apiCall(`drafted-by/get/${id}`, "GET",
                {})
            setValue('name', response.data.name);
            setValue("active", response.data.active)
            trigger()
        } catch (err) {
            console.error(err);
        }
    }

    const onSubmit = async (fields) => {
        try {
            const url = edit
                ? `${process.env.REACT_APP_API_URL}drafted-by/update/${id}`
                : `${process.env.REACT_APP_API_URL}drafted-by/create`;

            const response = edit
                ? await axios.patch(url, fields)
                : await axios.post(url, fields);

            if (response.data?._id) {
                setSuccess(true);
            } else {
                alert("Fail");
                console.log("failed...");
            }
        } catch (error) {
            console.error("Submit failed", error);
        }
    };

    return (
        <>
            <BackBtn
                navigatePath="/master-legal-drafted-by"
            />
            <div className="healtharticle mt-5">
                <div className="pt-5 ms-3">
                    <Breadcrumbs
                        breadcrumbText="Master"
                        breadcrumbLink=""
                        nestedText="Common"
                        nestedLink=""
                        nestedText2="Drafted By"
                        nestedLink2=""
                        nestedText3={edit ? "Edit Drafted By" : "Add New Drafted By"}
                    />
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="carr-dep-form"
                >
                    <div className="row car-dep-form">
                        <div className="col-5">
                            <InputTextField
                                register={register}
                                errors={errors}
                                labelName="Drafted By"
                                errorText={`Please enter the Drafted By`}
                                name="name"
                                placeholder="Type here..."
                            />
                        </div>

                        <div className="col-3">
                            <InputRadioField
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                watch={watch}
                            />
                        </div>
                    </div>

                    <div className="car-dep-form-button">
                        <button type="submit" style={{ marginTop: "5%" }}>
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            {/* </Home> */}
            {success && (
                <SucessModal
                    page="/master-legal-drafted-by"
                    name={`new drafted-by`}
                    post={edit ? 'updated' : 'added'}
                />
            )}
        </>
    )
}

export default AddLegalDraftedBy