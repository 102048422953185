import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import Breadcrumbs from "../../../../Common/Breadcrumbs/Breadcrumbs";
import BackBtn from "../../../../Common/backBtn";
import InputRadioField from "../../../../Common/inputRadioField";
import InputTextField from "../../../../Common/inputTextField";
import SucessModal from "../../../../modal/SucessModal";

const BDForm = ({
  edit = false
}) => {
  const { id } = useParams()

  const {
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
    trigger
  } = useForm();

  const [success, setSuccess] = useState(false);

  const getMenuData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}bd/get/${id}`).then(response => {
      setValue('active', response.data.active);
      setValue('bd_name', response.data.bd_name);
      trigger()
    }).catch(err => {
      console.error(err);
      alert("Some error");
    })
  }

  useEffect(() => {
    edit && getMenuData();
  }, [edit]);

  const onSubmit = (fields) => {
    if (edit) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}bd/update/${id}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}bd/create`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    }
  };

  return (
    <>
      <BackBtn
        navigatePath="/master-bd"
      />
      <div className="healtharticle mt-5">
        <div className="pt-3 ms-3">
          <Breadcrumbs
            breadcrumbText="Master"
            breadcrumbLink=""
            nestedText="Common"
            nestedLink=""
            nestedText2="BD"
            nestedLink2=""
            nestedText3={edit ? "Edit BD" : "Add New BD"}
            nestedLink3=""
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="carr-dep-form"
        >
          <div className="row car-dep-form">
            <div className="col-5">
              <InputTextField
                register={register}
                errors={errors}
                labelName="BD Name"
                errorText="Please enter the BD Name"
                name="bd_name"
                placeholder="Type here..."
              />
            </div>
            <div className="col-3">
              <InputRadioField
                register={register}
                errors={errors}
                setValue={setValue}
                watch={watch}
              />
            </div>
          </div>

          <div className="car-dep-form-button">
            <button type="submit" style={{ marginTop: "5%" }}>
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/master-bd"}
          name="new BD"
          post={edit ? 'updated' : 'added'}
        />
      )}
    </>
  )
}

export default BDForm