import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";

import "./SucessModel.scss";

const PharmacyRatingCount = ({
  id,
  setCountModal = () => { },
}) => {

  const [ratingList, setRatingList] = useState([])

  useEffect(() => {
    getRatingList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const queryParams = {
    pharmacy_id: id,
  };

  const getRatingList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}rating-form/rating-form-list`, {
        params: queryParams
      })
      .then((response) => {
        setRatingList(response?.data?.rows);
      });
  }

  return (
    <>
      <div className="assignModal">
        <div className="modal-backdrop fade show success-model"></div>
        <div
          style={{ display: "block" }}
          className="modal fade show modal-sm"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className=" modal-content p-4">
              <div className="d-flex justify-content-between w-100">
                <p className="assignTitle">Feedbacks Count: {ratingList?.length}</p>
                <IoCloseCircleSharp size={30} color={"gray"} className="cursorPointer" onClick={() => setCountModal({ id: "", status: false })} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PharmacyRatingCount; 