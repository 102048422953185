import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import BackBtn from "../../../../Common/backBtn";
import InputRadioField from "../../../../Common/inputRadioField";
import Breadcrumbs from "../../../../Common/Breadcrumbs/Breadcrumbs";

const BDView = () => {

  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}bd/get/${id}`)
      .then((res) => {
        setData(res.data);
      });
  };

  return (
    <>
      {/* <Home> */}
      <BackBtn
        navigatePath="/master-bd
        "
      />
      <div className="healtharticle mt-5 ps-4">
        <div className="healtharticle-title ms-2">
          <Breadcrumbs
            breadcrumbText="Master"
            breadcrumbLink=""
            nestedText="Common"
            nestedLink=""
            nestedText2="View BD"
            nestedLink2=""
          />
        </div>
        <div className="row mt-5 ms-2 new-menu">
          <div className="menu-field col-md-3 me-0">
            <p className="form-label " htmlFor="">
              BD
            </p>
            <div className="blog-view-text-half-bor">{data.bd_name}</div>
          </div>
          <div className="col-3">
            <InputRadioField
              viewOnly={true}
              checked={data.active === true ? "checked" : null}
            />
          </div>
        </div>
      </div>
      {/* </Home> */}
    </>
  )
}

export default BDView