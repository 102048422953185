
import buttonadd from "../../assets/images/plus.png";

const AddButton = ({
  btnName = "",
  btnClassName = "",
  onClick = () => { },
}) => {

  return (
    <div
      onClick={onClick}
      className="addNewbtn px-3"
    >
      <div>
        <img src={buttonadd} alt="" />
      </div>
      <div className="ms-1">
        <button className={btnClassName}>{btnName}</button>
      </div>
    </div>
  )
}

export default AddButton