import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import backRed from "../../assets/svg/back-rose-icon.svg";
import axios from "axios";
import SucessModal from "../modal/SucessModal";

const NewFaqSection = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (fields) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}faq-section/create`, {...fields, name: fields?.name?.trim() })
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true);
        } else {
          errors.submit_fail = response.data;
        }
      })
      .catch((err) => {
        errors.submit_fail = err.response.data.error;
      });
  };

  return (
    <>
      <div className="mt-5">
        <div
          onClick={() => navigate("/faq")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="faq-title ms-0">
          <span>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/dashboard"
            >
              Dashboard
            </Link>
          </span>
          <span>&#62;</span>
          <span>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/faq"
            >
              FAQ's
            </Link>
          </span>
          <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
          <span style={{ paddingLeft: "0.4%" }}>Add New FAQ Section</span>
        </div>
        <hr />
      </div>
      <form className="faq-section-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row new-faq-section-form">
          <div className="col-5">
            <label
              className="new-faq-section-form-txt mb-2"
              style={{ color: "#4B4853" }}
              htmlFor=""
            >
              FAQ's Section <span className="star">*</span>
            </label>
            <input
              className="form-control w-75"
              type="text"
              name=""
              id="type"
              placeholder="Type"
              {...register("name", { required: true })}

            />
            {errors.name && (
              <div className={`invalid-feedback d-block`}>
                Please provide a valid name
              </div>
            )}
          </div>

          <div className="col-3">
            <label
              className="new-faq-section-form-txt mb-2"
              style={{ color: "#4B4853" }}
              htmlFor=""
            >
              Active <span className="star">*</span>
            </label>
            <div className="d-flex">
              <div className="form-check form-check-inline me-3 mt-1">
                <input
                  className="form-check-input me-3"
                  type="radio"
                  name="active"
                  id="active"
                  value={true}
                  checked
                  {...register("active", { required: true })}

                />
                <label htmlFor="option-1" className="text-dark mt-1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline mt-1">
                <input
                  className="form-check-input  me-3"
                  type="radio"
                  name="active"
                  id="active"
                  value={false}
                  {...register("active", { required: true })}

                />
                <label htmlFor="option-2" className="text-dark mt-1">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="new-faq-section-form-button">
          <button type="submit" style={{ marginTop: "5%" }}>
            SUBMIT
          </button>
        </div>
      </form>
      {success && (
        <SucessModal
          page={"/faq"}
          name="New FAQ section"
          post="created"
        />
      )}
    </>
  );
};

export default NewFaqSection;
