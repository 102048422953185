import axios from "axios";
import { useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { Fragment, useEffect, useState } from "react";
import { medicalCouncilOptions, states, yesOrNo } from "../../../constants";

import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import LanguageField from "../../Common/LanguageField";
import AddButton from "../../Common/addButton";
import CustomFieldsComp from "../../Common/customFields";
import CustomFieldsLevel from "../../Common/customFieldsLevel";
import InputTextField from "../../Common/inputTextField";
import MultipleAddOrg from "../../Common/multipleAddOrg";
import NextBackBtns from "../../Common/nextBackBtns";
import SelectField from "../../Common/selectField";
import DoctorFormCustomField from "../../modal/DoctorFormCustomField";
import SucessModal from "../../modal/SucessModal";

import addIcon from "../../../assets/svg/add_image.svg";
import clinicPlusImg from "../../../assets/svg/plus_icon.svg"

const consultationModes = [
  "Online Only",
  "In-Clinic Only",
  "Both",
]

const details = [
  "I am an establishment owner only",
  "I am both a doctor and an establishment owner",
  "I am a doctor only"
]

const CouncilType = [
  "Allopathy",
  "Dentistry",
  "Ayurveda",
  "Homeopathy"
]

const StepOne = ({
  register,
  errors,
  getValues,
  setValue,
  gender,
  primaryspl,
  inclinic,
  language,
  trigger,
  control,
  city,
  selectedLang,
  setCity,
  customFields,
  pgEnable,
  setPGEnable,
  degree,
  pgDegree,
  setOrganisation,
  organisation,
  setLevel,
  watch,
  clinics,
  selectedOption,
  showAddClinicBtn,
  setClinics,
  clinicsSelectedOptions
}) => {
  watch()
  const selectedCouncilType = watch('council_type');

  useEffect(() => {
    if (!selectedCouncilType || (!medicalCouncilOptions[selectedCouncilType]?.includes(getValues()?.medical_council))) {
      setValue("medical_council", "")
    } else if (getValues()?.medical_council) {
      setValue("medical_council", getValues()?.medical_council)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCouncilType]);

  useEffect(() => {
    if (getValues()?.clinics) {
      setValue("clinic_details", getValues()?.clinic_details)
    }
  }, [clinics])

  useEffect(() => {
    const clinicDetails = getValues()?.clinic_details;
    if (clinics.length > 0 && clinicDetails) {
      setValue("clinic_details", clinicDetails);
    }
  }, [clinics, getValues]);


  useEffect(() => {
    if (getValues()?.degree) {
      setValue("degree", getValues()?.degree)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degree])

  useEffect(() => {
    if (getValues()?.online_consultation_fee) {
      setValue("online_consultation_fee", getValues()?.online_consultation_fee)
    }
    if (getValues()?.in_clinic_fee) {
      setValue("in_clinic_fee", getValues()?.in_clinic_fee)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inclinic])

  const handleAddClinic = () => {
    if (clinics.length) {
      setClinics([...clinics, clinics[clinics.length - 1] + 1])
    } else {
      setClinics([2])
    }
  };

  const primaryLanguage = watch("primary_language")
  const [filteredLanguage, setFilteredLanguage] = useState(language);

  useEffect(() => {
    // { primaryLanguage ? filteredLanguage = language.filter((data) => data.type !== primaryLanguage) : null }
    if (primaryLanguage) {
      setFilteredLanguage(language.filter((data) => data.type !== primaryLanguage))
    }
    const selectedOtherLanguages = getValues('languages_known')
    if (selectedOtherLanguages) {
      setValue('languages_known', selectedOtherLanguages.filter((data) => data.value !== primaryLanguage))
    }
  }, [primaryLanguage])

  return (
    <div className="doctor-page1">
      {/* one */}
      <div className="addnew-form1-autor-detail mt-3 d-flex">
        <div className="col-md-4">
          <label htmlFor="inputAddress" className="form-label title">
            Name of the Doctor <span className="star">*</span>
          </label>
          <input
            type="text"
            {...register("name", { required: true })}
            className="form-control addnew-input"
            // id="authordetails"
            style={{ paddingLeft: "10px" }}
          />
          {errors.name && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Name
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label htmlFor="inputAddress" className="form-label title">
            Contact Number <span className="star">*</span>
          </label>
          <input
            type="text"
            {...register("mobile", { required: true })}
            className="form-control addnew-input"
            // id="mobile"
            // placeholder="Type here"
            style={{ paddingLeft: "10px" }}
          />
          {errors.mobile && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Mobile Number
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label htmlFor="inputAddress" className="form-label title">
            Email ID<span className="star">*</span>
          </label>
          <input
            type="text"
            {...register("email", { required: true })}
            className="form-control addnew-input"
            // id="authordetails"
            style={{ paddingLeft: "10px" }}
          />
          {errors.email && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Email
            </div>
          )}
        </div>
      </div>

      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="row w-100">
          <div className="col-12 mb-1">
            <div className="d-flex justify-content-between degreeFieldLabel">
              <label className="form-label">
                Degree & Education{" "}
                <span className="star">*</span>
              </label>
              <div style={{ cursor: !pgEnable ? "pointer" : "not-allowed" }} className="" onClick={() => setPGEnable(true)}>
                <img src={addIcon} width={16} height={16} alt={"addIcon"} className="addDoctorPlusImg me-2" />
                <span className={`addDoctorDegree fs16fw600 `}>Add PG Degree</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-40">
            <select
              {...register("degree", { required: true })}
              className="form-control healthsynergyFormInput"
              defaultValue={""}
            >
              <option value="" disabled pri-specialization="true">
                Select your UG degree
              </option>
              {degree.map((data, idx) => {
                return (
                  <option key={idx}>
                    {data.ug_degree}
                  </option>
                );
              })}
            </select>
            {errors.degree && (
              <div className={`invalid-feedback d-block`}>
                Please provide a UG degree
              </div>
            )}
          </div>
          <div className="col-12 col-md-6 mb-40">
            <input
              className="form-control healthsynergyFormInput"
              type="text"
              placeholder="Enter your UG education"
              {...register("education", { required: true })}
            />
            {errors.education && (
              <div className={`invalid-feedback d-block`}>
                Please provide a UG Education
              </div>
            )}
          </div>
          {pgEnable ? <>
            <div className="col-12  mb-1 mt-3">
              <div className="d-flex justify-content-between degreeFieldLabel">
                <label className="form-label">
                  Degree & Education{" "}
                  <span className="star">*</span>
                </label>
                <div className="cursor-pointer" onClick={() => setPGEnable(false)}>
                  {/* <img src={minusIcon} width={16} height={16} alt={"minus"} className=" me-2" /> */}
                  <span className="addDoctorDegree fs16fw600">Delete</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-40">
              <select
                {...register("pg_degree", { required: true })}
                className="form-control healthsynergyFormInput"
                defaultValue={""}
              >
                <option value="" disabled pri-specialization="true">
                  Select your PG degree
                </option>
                {pgDegree.map((data, idx) => {
                  return (
                    <option key={idx}>
                      {data?.pg_degree}
                    </option>
                  );
                })}
              </select>
              {errors?.pg_degree && (
                <div className={`invalid-feedback d-block`}>
                  Please provide a PG degree
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 mb-40">
              <input
                className="form-control healthsynergyFormInput"
                type="text"
                placeholder="Enter your PG education"
                {...register("pg_education", { required: true })}
              />
              {errors.pg_education && (
                <div className={`invalid-feedback d-block`}>
                  Please provide a PG Education
                </div>
              )}
            </div>
          </> : <></>}
        </div>
      </div>
      <div>
        <div className="col-12 col-md-6 mb-40 mt-4">
          <MultipleAddOrg
            register={register}
            errors={errors}
            organisation={organisation}
            setOrganisation={setOrganisation}
            isRequired={false}
          />
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-4">
          <InputTextField
            labelName="Fellowship"
            name="fellowship"
            register={register}
            isRequired={false}
            errors={errors}
            placeholder="Type here"
          />
        </div>
        <div className="col-md-4">
          <InputTextField
            labelName="Awards & Recognition"
            name="awards_recognition"
            register={register}
            isRequired={false}
            errors={errors}
            placeholder="Type here"
          />
        </div>
        <div className="col-md-4">
          <label className="form-label title">
            Other Certified Education
          </label>
          <input
            className="form-control addnew-input"
            type="text"
            placeholder="Type here"
            {...register("qualification")}
          />
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-4">
          <label className="form-label title">
            Gender <span className="star">*</span>
          </label>{" "}
          <br />
          <div className="d-flex">
            {gender.map((el, index) => {
              if (el.active === true) {
                return (
                  <div
                    key={index}
                    className="form-check form-check-inline d-flex align-items-center"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      {...register("gender", { required: true })}
                      value={el.type}
                    />
                    <label className="ps-2">
                      {el.type}
                    </label>
                  </div>
                );
              }
            })}
          </div>
          {errors.gender && (
            <div className={`invalid-feedback d-block`}>
              Please select an option
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label title">
            Primary Specialisation <span className="star">*</span>
          </label>
          <select
            {...register("specialization", { required: true })}
            className="form-control addnew-input"
            defaultValue={""}
          >
            <option value="" disabled pri-specialization="true">
              Select Your specialisation
            </option>
            {primaryspl.map((option, inx) => {
              if (option.active === true) {
                return (
                  <option key={inx} value={option.type}>
                    {option.type}
                  </option>
                );
              }
            })}
          </select>
          {errors.specialization && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Primary Specialisation
            </div>
          )}
        </div>
        <div className="col-md-4">
          <SelectField
            id="councilType"
            labelName="Choose Council Type"
            name="council_type"
            options={CouncilType}
            register={register}
            className=""
            errors={errors}
            errorText="Please select the Council Type"
            defaultOption="your council type"
          />
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-4">
          <SelectField
            id="medicalCouncil"
            labelName="State Medical Council"
            name="medical_council"
            options={medicalCouncilOptions[selectedCouncilType] ?? []}
            register={register}
            className="healthsynergyFormInput"
            errors={errors}
            errorText="Please choose a State Medical Council"
            defaultOption="your state medical council"
          />
        </div>
        <div className="col-md-4">
          <label className="form-label title">
            Medical Registration Number (SMC / DCI / Other Council Number)
            <span className="star"> * </span>
          </label>
          <input
            className="form-control addnew-input"
            type="text"
            {...register("mci", { required: true })}
          />
          {errors.mci && (
            <div className={`invalid-feedback d-block`}>
              Please provide a MCI
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label title">
            Year of Registration with Medical Council <span className="star">*</span>
          </label>
          <input
            className="form-control addnew-input"
            type="number"
            {...register("registered_year", { required: true })}
            placeholder="Type Your Registered Year"
          />
          {errors.registered_year && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Year of Registration with Medical Council
            </div>
          )}
        </div>
        {/* <div className="col-md-4">
          <label className="form-label">State Medical Council<span className="star">*</span></label>
          <select
            {...register("medical_council", { required: true })}
            className="form-control addnew-input"
            defaultValue={""}
          >
            <option value="" disabled pri-specialization="true">
              Select
            </option>
            {medicalCouncil.map((datas, idx) => {
              return (
                <option key={idx}>
                  {datas}
                </option>
              );
            }
            )}
          </select>
          {errors.medical_council && (
            <div className={`invalid-feedback d-block`}>
              Please choose a Medical Council
            </div>
          )}
        </div> */}
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-4">
          <label className="form-label">Mode of Consultation<span className="star">*</span></label>
          <select
            {...register("intrested_mode", { required: true })}
            className="form-control addnew-input"
            defaultValue={""}
          >
            <option value="" disabled pri-specialization="true">
              Select Consultaion Mode
            </option>
            {consultationModes.map((datas, idx) => {
              return (
                <option key={idx}>
                  {datas}
                </option>
              );
            }
            )}
          </select>
          {errors.intrested_mode && (
            <div className={`invalid-feedback d-block`}>
              Please choose a Medical Council
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label title">Memberships </label>
          <input
            className="form-control addnew-input"
            type="text"
            {...register("memberships", { required: false })}
          />
          {errors.memberships && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Memberships
            </div>
          )}
        </div>
        {clinicsSelectedOptions !== "In-Clinic Only" &&
          <div className="col-md-4">
            <label className="form-label title">
              Online Consultation Fee
            </label>
            <select
              {...register("online_consultation_fee", { required: false })}
              className="form-control addnew-input"
              defaultValue={""}
            >
              <option value="" disabled pri-specialization="true">
                Select fee
              </option>
              {inclinic.map((option) => {
                if (option.active === true) {
                  return (
                    <option key={option.type} value={option.type}>
                      {option.type}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        }
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-11">
          <label className="form-label title">About Yourself <span className="star">*</span></label>
          <textarea
            className="form-control addnew-textArea"
            type="textArea"
            {...register("about", { required: false })}
          />
          {errors.about && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Memberships
            </div>
          )}
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        {clinicsSelectedOptions !== "Online Only" &&
          <div className="col-md-4">
            <label className="form-label title">
              In-Clinic Fee
            </label>
            <select
              {...register("in_clinic_fee", { required: false })}
              className="form-control addnew-input"
              defaultValue={""}
            >
              <option value="" disabled pri-specialization="true">
                Select fee
              </option>
              {inclinic.map((option) => {
                if (option.active === true) {
                  return (
                    <option key={option.type} value={option.type}>
                      {option.type}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        }
        <div className="col-md-4">
          <SelectField
            id="primaryLanguage"
            labelName="Primary Language"
            name="primary_language"
            options={language.map((data) => data.type)}
            className="form-control addnew-input mt-2"
            register={register}
            errors={errors}
            isOptionObject={false}
            errorText="Please choose a Primary Language"
            defaultOption="Select an option"
          />
        </div>
        <div className="col-md-4">
          <LanguageField
            errors={errors}
            labelTxt="Other Language Known"
            language={filteredLanguage}
            register={register}
            setValue={setValue}
            trigger={trigger}
            getValues={getValues}
            multiSelectClassName={"form-control addnew-input"}
            selectedLang={selectedLang}
          />
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-4">
          <label className="form-label title">
            State <span className="star">*</span>
          </label>
          <select
            {...register("state", { required: true })}
            className="form-control addnew-input"
            defaultValue={""}
          >
            <option value="" disabled pri-specialization="true">
              Select State
            </option>
            {states.map((option, inx) => {
              return (
                <option key={inx} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
          {errors.state && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Experience
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label htmlFor="inputAddress" className="form-label title">
            City{" "}
            <span className="star">*</span>
          </label>
          <Controller
            control={control}
            name="city"
            // rules={{ required: "City is required" }}
            render={({ field }) => (
              <Autocomplete
                apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value)
                  if (city) {
                    setCity("")
                  }
                }
                }
                onPlaceSelected={(place) => {
                  field.onChange(place ? place.formatted_address : "")
                  setCity(place.formatted_address)
                }}
                types={["address"]}
                className={`form-control  addnew-input`}
                componentrestrictions={{ country: "us" }}
                preventdefault="true"
              />
            )}
          />
          {errors.city && (
            <div className={`invalid-feedback d-block`}>
              Please provide a City
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label htmlFor="inputAddress" className="form-label title">
            Location <span className="star">*</span>
          </label>
          <input
            type="text"
            {...register("location", { required: true })}
            className="form-control addnew-input"
            // id="authordetails"
            style={{ paddingLeft: "10px" }}
          />
          {errors.location && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Location
            </div>
          )}
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-4">
          <label className="form-label title">
            Pincode <span className="star">*</span>
          </label>
          <input
            className="form-control addnew-input"
            type="text"
            {...register("pincode", {
              required: true,
              min: 110000,
              pattern: /[0-9]{6}/,
            })}
            placeholder="Enter pincode"
            maxLength="6"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          {errors.pincode && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Pincode
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label htmlFor="inputAddress" className="form-label title">
            Clinic Name <span className="star">*</span>
          </label>
          <input
            type="text"
            {...register("clinic_name", { required: true })}
            className="form-control addnew-input"
            id="authordetails"
            style={{ paddingLeft: "10px" }}
          />
          {errors.clinic_name && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Clinic Name
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label htmlFor="inputAddress" className="form-label title">
            Clinic Contact Number <span className="star">*</span>
          </label>
          <input
            type="text"
            {...register("clinic_contact_number", { required: true })}
            className="form-control addnew-input"
            id="authordetails"
            style={{ paddingLeft: "10px" }}
          />
          {errors.clinic_contact_number && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Clinic Name
            </div>
          )}
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-11">
          <label className="form-label title">
            Clinic Address <span className="star">*</span>
          </label>
          <textarea
            className="form-control addnew-textArea"
            type="textArea"
            placeholder="Enter Address"
            {...register("clinic_address", { required: true })}
          />
          {errors.clinic_address && (
            <div className={`invalid-feedback d-block`}>
              Please provide a Clinic Address
            </div>
          )}
        </div>
      </div>
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-4">
          <SelectField
            id="pocClinic"
            labelName="Do you have a PoC for your Clinic?"
            name="poc_clinic"
            options={yesOrNo}
            className="form-control addnew-input mt-2"
            register={register}
            errors={errors}
            errorText="Please choose a PoC for Clinic"
            defaultOption="Select an option"
          />
        </div>
        {selectedOption === 'Yes' && (
          <>
            <div className={`col-4 ${showAddClinicBtn && !clinics?.length ? 'mb-3' : 'mb-4'}`}>
              {/* <div className={`col-4 ${showAddClinicBtn && !clinics?.length ? 'mb-5' : 'mb-5'}`}> */}
              <InputTextField
                labelName="Poc Name"
                name="poc_clinic_name"
                register={register}
                errors={errors}
                errorText="Please provide a Name"
                placeholder="Enter the Poc Name"
              />
            </div>
            <div className={`col-4 ${showAddClinicBtn && !clinics?.length ? 'mb-3' : 'mb-4'}`}>
              <InputTextField
                labelName="Poc Number"
                name="poc_clinic_contact_no"
                type="number"
                register={register}
                errors={errors}
                errorText="Please provide a Poc Number"
                placeholder="Enter the Poc Number"
              />
            </div>
          </>
        )}
      </div>
      {clinics.map((data, inx) => (
        <Fragment key={inx}>
          <div className="d-flex">
            <div className="col-4 mb-40">
              <InputTextField
                labelName={`Clinic Name-${data}`}
                name={`clinic_name_${data}`}
                register={register}
                errors={errors}
                // isRequired={false}
                placeholder="Enter your your clinic name"
              />
            </div>
            <div className="col-4 mb-40">
              <InputTextField
                labelName={`Clinic Contact Number-${data}`}
                name={`clinic_contact_number_${data}`}
                register={register}
                errors={errors}
                type="number"
                maxLength={12}
                placeholder="Enter your contact number"
              // isRequired={false}
              />
            </div>
          </div>
          <div className="col-12 mb-16 mt-4">
            <label className="form-label">
              Clinic Address-{data}
            </label>
            <textarea
              className="form-control textAreaControl w-100 h-100"
              placeholder="Clinic Address"
              {...register(`clinic_address_${data}`)}
            />
          </div>
          <div className={`col-4 ${showAddClinicBtn && getValues()?.[`poc_clinic_${data}`] !== 'Yes' ? 'mb-4' : 'mb-5'}`}>
            <SelectField
              labelName={`Do you have a PoC for your Clinic ${data}?`}
              name={`poc_clinic_${data}`}
              options={yesOrNo}
              className="healthsynergyFormInput"
              register={register}
              errors={errors}
              errorText="Please provide a PoC for Clinic"
              defaultOption="an option"
            />
          </div>
          {getValues()?.[`poc_clinic_${data}`] === 'Yes' && (
            <div className="d-flex">
              <div className={`col-4  ${showAddClinicBtn && (clinics?.length === inx + 1) ? 'mb-4' : 'mb-5'}`}>
                <InputTextField
                  labelName="Poc Name"
                  // name="poc_clinic_name"
                  name={`poc_clinic_name_${data}`}
                  className="healthsynergyFormInput"
                  register={register}
                  errors={errors}
                  errorText="Please provide a Name"
                  placeholder="Enter the Poc Name"
                />
              </div>
              <div className={`col-4 ${showAddClinicBtn && (clinics?.length === inx + 1) ? 'mb-4' : 'mb-5'}`}>
                <InputTextField
                  labelName="Poc Number"
                  // name="poc_clinic_contact_no"
                  name={`poc_clinic_contact_no_${data}`}
                  type="number"
                  className="healthsynergyFormInput"
                  register={register}
                  errors={errors}
                  errorText="Please provide a Poc Number"
                  placeholder="Enter the Poc Number"
                />
              </div>
            </div>
          )}
        </Fragment>)
      )}
      {showAddClinicBtn && (
        <button type="button" className="d-flex gap-2 border-0 bg-white mb-40" onClick={handleAddClinic}>
          <img src={clinicPlusImg} alt="plusIcon" />
          <div className="fs16fw600">Add Clinic</div>
        </button>
      )}
      <div className="addnew-form1-autor-detail mt-5 d-flex">
        <div className="col-md-4">
          <label className="form-label title">
            Are you currently using telemedicine platform{" "}
            <span className="star">*</span>
          </label>{" "}
          <div className="d-flex align-items-center">
            <input
              className="form-check-input"
              type="radio"
              {...register("telemedicine", { required: true })}
              value="Yes"
            />
            <label className="ps-2 pe-4">
              Yes
            </label>
            <input
              className="form-check-input"
              type="radio"
              {...register("telemedicine", { required: true })}
              value="No"
            />
            <label className="ps-2">
              No
            </label>
          </div>
          {errors.telemedicine && (
            <div className={`invalid-feedback d-block`}>
              Please select an option
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">
            Establishment Details{" "}
            <span
              className=""
              style={{
                color: "rgba(24, 20, 35, 1)",
                fontWeight: "600",
              }}
            >
              (Only when Applicable)
            </span>
          </label>{" "}
          <select
            {...register("establishment")}
            className="form-control addnew-input"
            defaultValue={""}
          >
            <option value="" disabled pri-specialization="true">
              Select the delivery person
            </option>
            {details.map((data, idx) => {
              return (
                <option key={idx}>{data}</option>
              )
            })}
          </select>
        </div>
      </div>
      <NextBackBtns
        back={false}
        next={customFields}
        handleNext={() => setLevel(2)}
        submitBtn={true}
        submitBtnText={"Update"}
      />
    </div>
  )
}

const DoctorRegistrationEdit = () => {

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { id } = useParams();
  const [level, setLevel] = useState(1);
  const [gender, setGender] = useState([]);
  const [primaryspl, setPrimaryspl] = useState([]);
  const [city, setCity] = useState("");
  const [inclinic, setInclinic] = useState([]);
  const [success, setSuccess] = useState(false);
  const [language, setLanguage] = useState([]);
  const [selectedLang, setSelectedLang] = useState([])
  const [customFields, setCustomFields] = useState([])
  const [customFieldModel, setCustomFieldModel] = useState(false)
  const [customFieldEditModel, setCustomFieldEditModel] = useState({ status: false, index: null })
  const [degree, setDegree] = useState([]);
  const [pgDegree, setPGDegree] = useState([]);
  const [pgEnable, setPGEnable] = useState(false)
  const [clinics, setClinics] = useState([]);

  const showAddClinicBtn = clinics.length < 2;
  const selectedOption = watch('poc_clinic');
  const clinicsSelectedOptions = watch("intrested_mode");

  useEffect(() => {
    if (clinicsSelectedOptions?.clinics?.length) {
      setClinics(clinicsSelectedOptions?.clinics)
    }
  }, [clinicsSelectedOptions?.clinics])

  const [organisation, setOrganisation] = useState([
    {
      [`organisation${0}`]: "",
      id: 0,
    },
  ]);

  useEffect(() => {
    getPrimaryspl();
    getInClinic();
    getDoctorsRegistraionForm();
    getGender();
    getLangauges();
    getDegree();
    getPGDegree();
  }, []);

  const getLangauges = () => {
    axios.post(`${process.env.REACT_APP_API_URL}languages/list`).then((res) => {
      setLanguage(res.data.rows);
    });
  };

  const getGender = () => {
    axios.post(`${process.env.REACT_APP_API_URL}gender/list`).then((res) => {
      setGender(res.data.rows);
    });
  };

  const getPrimaryspl = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}primary-specialisation/list`, {
        noLimit: true,
      })
      .then((res) => {
        setPrimaryspl(res.data.rows);
      });
  };

  const getInClinic = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}in-clinic-fee/list`)
      .then((res) => {
        setInclinic(res.data.rows);
      });
  };

  const getDegree = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}ug-degree/list`)
      .then((res) => {
        setDegree(res.data?.rows);
      });
  };

  const getPGDegree = () => {
    axios.post(`${process.env.REACT_APP_API_URL}pg-degree/list`).then((res) => {
      setPGDegree(res.data.rows);
    });
  };

  const getDoctorsRegistraionForm = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}doctor-registration/get/${id}`)
      .then((response) => {
        setValue("name", response?.data?.name);
        setValue("email", response?.data?.email);
        setValue("mobile", response?.data?.mobile);
        setValue("gender", response?.data?.gender);
        setValue("specialization", response?.data?.specialization);
        setValue("mci", response?.data?.mci);
        setValue("registered_year", response?.data?.registered_year);
        setValue("medical_council", response?.data?.medical_council);
        setValue("experience", response?.data?.experience);
        setValue("location", response?.data?.location);
        setValue("city", response?.data?.city);
        setCity(response?.data?.city)
        setValue("pincode", response?.data?.pincode);
        setValue("qualification", response?.data?.qualification);
        setValue("in_clinic_fee", response?.data?.in_clinic_fee);
        setValue("online_consultation_fee", response?.data?.online_consultation_fee);
        setValue("clinic_name", response?.data?.clinic_name);
        setValue("clinic_address", response?.data?.clinic_address);
        setValue("telemedicine", response?.data?.telemedicine);
        setValue("memberships", response?.data?.memberships);
        setValue("establishment", response?.data?.establishment);
        setValue("languages_known", response?.data?.languages_known);
        setValue("intrested_mode", response?.data?.intrested_mode);
        setValue("about", response?.data?.about);
        setValue("state", response?.data?.state);
        setValue("degree", response?.data?.degree);
        setValue("pg_degree", response?.data?.pg_degree);
        setValue("poc_clinic", response?.data?.poc_clinic);
        setValue("poc_clinic_name", response?.data?.poc_clinic_name);
        setValue("poc_clinic_contact_no", response?.data?.poc_clinic_contact_no);
        setValue("primary_language", response?.data?.primary_language);
        setValue("clinic_name", response.data.clinic_name)

        if (response?.data?.pg_degree) {
          setPGEnable(true)
        }

        if (response?.data?.clinic_details) {
          const initialClinics = response.data.clinic_details.map((_, index) => index + 2);
          setClinics(initialClinics);
        }

        if (response?.data?.clinic_details) {
          response.data.clinic_details.forEach((clinic, index) => {
            setValue(`clinic_name_${index + 2}`, clinic.clinic_name);
            setValue(`clinic_contact_number_${index + 2}`, clinic.clinic_contact_number);
            setValue(`clinic_address_${index + 2}`, clinic.clinic_address);
            setValue(`poc_clinic_${index + 2}`, clinic.poc_clinic);
            if (clinic.poc_clinic === "Yes") {
              setValue(`poc_clinic_name_${index + 2}`, clinic.poc_clinic_name);
              setValue(`poc_clinic_contact_no_${index + 2}`, clinic.poc_clinic_contact_no);
            }
          });
        }
        setValue("education", response?.data?.education);
        setValue("pg_education", response?.data?.pg_education);
        setValue("fellowship", response?.data?.fellowship);
        setValue("council_type", response?.data?.council_type);
        setValue("awards_recognition", response?.data?.awards_recognition);
        response?.data?.organisation?.forEach((org) => {
          const key = Object.keys(org)[0];
          const index = key.slice(-1); // Extract the index from the key (e.g., 'orgcation0' -> '0')
          setValue(`organisation${index}`, org[key]);
        });
        setValue("organisation", response?.data?.organisation);
        setOrganisation(response?.data?.organisation);
        setValue("clinic_contact_number", response?.data?.clinic_contact_number);
        if (response?.data?.customFields?.length) {
          setCustomFields(response.data.customFields)
        }
        setSelectedLang(response?.data?.languages_known?.map((data) => ({ value: data })))
        trigger()
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const onSubmit = (fields) => {
    // fields.city = city;
    fields.languages_known = fields.languages_known?.length && fields.languages_known?.map((data) => data?.value)

    const tempFields = {};
    const fieldKeys = Object.keys(fields);
    ["organisation"].forEach((value) => {
      fieldKeys.forEach((key) => {
        if (!key.includes(value)) {
          tempFields[key] = fields[key];
        }
      });
      tempFields[value] = fieldKeys.map(key => {
        if (!key.includes(value)) {
          return
        }
      })
    })

    let combinedData = { ...fields, organisation: organisation }
    if (customFields) {
      combinedData.customFields = customFields;
    }

    if (combinedData?.intrested_mode === "Online Only") {
      combinedData.pincode = "";
      combinedData.location = "";
      combinedData.poc_clinic = "";
      combinedData.poc_clinic_name = "";
      combinedData.poc_clinic_contact_no = "";
    }
    let clinic_details = []
    if (clinics?.length) {
      clinics.forEach(data => {
        clinic_details.push({
          clinic_name: combinedData?.[`clinic_name_${data}`],
          clinic_address: combinedData?.[`clinic_address_${data}`],
          clinic_contact_number: combinedData?.[`clinic_contact_number_${data}`],
          poc_clinic: combinedData?.[`poc_clinic_${data}`],
          poc_clinic_name: combinedData?.[`poc_clinic_name_${data}`],
          poc_clinic_contact_no: combinedData?.[`poc_clinic_contact_no_${data}`],
        })
        for (let key in combinedData) {
          if (key.endsWith(`_${data}`)) {
            delete combinedData[key];
          }
        }
      })
    }

    combinedData = { ...combinedData, clinic_details }

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-registration/update/${id}`, combinedData)
      .then((response) => {
        setSuccess(true);
        reset()
      });
  }

  return (
    <>
      <div className="healtharticle p pb-4 my-5">
        <div className="healtharticle doctorEditFormWrapper pb-4">
          <div className="healtharticle-container">
            <div className="px-3 d-flex justify-content-between">
              <Breadcrumbs
                breadcrumbText="HealthSynergy"
                breadcrumbLink="/doctor-registration"
                nestedText2="Healthsy Patnered Doctors Network Programme"
                nestedLink2="/doctor-registration"
                nestedText3="Edit"
                nestedLink="#"
              />
              <div className="doctorAddNewField">
                <AddButton
                  onClick={() => setCustomFieldModel(true)}
                  btnClassName="fw-bold"
                  btnName="Add New Field"
                />
              </div>
            </div>
            {customFields?.length ? <CustomFieldsLevel
              page={level}
              customFieldLength={customFields?.length}
            /> : <></>}
            <form className="addEditInsiders editDoctorsForm" onSubmit={handleSubmit(onSubmit)}>
              {(level === 1 || !customFields?.length) ? (
                <StepOne
                  register={register}
                  errors={errors}
                  setLevel={setLevel}
                  getValues={getValues}
                  setValue={setValue}
                  gender={gender}
                  primaryspl={primaryspl}
                  inclinic={inclinic}
                  language={language}
                  watch={watch}
                  degree={degree}
                  pgEnable={pgEnable}
                  setPGEnable={setPGEnable}
                  pgDegree={pgDegree}
                  trigger={trigger}
                  control={control}
                  city={city}
                  selectedLang={selectedLang}
                  setCity={setCity}
                  customFields={customFields?.length}
                  organisation={organisation}
                  setOrganisation={setOrganisation}
                  selectedOption={selectedOption}
                  clinics={clinics}
                  showAddClinicBtn={showAddClinicBtn}
                  clinicsSelectedOptions={clinicsSelectedOptions}
                  setClinics={setClinics}
                />
              ) : (
                <CustomFieldsComp
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  setCustomFieldEditModel={setCustomFieldEditModel}
                  register={register}
                  customFieldsLength={customFields?.length}
                  errors={errors}
                  setLevel={setLevel}
                  level={level}
                />
              )}
            </form>
          </div>
        </div>
      </div>
      {success && (
        <SucessModal
          page={"/doctor-registration"}
          name="Doctor Details"
          post="updated"
        />
      )}
      {customFieldModel && (
        <DoctorFormCustomField
          setCustomFieldModel={setCustomFieldModel}
          setCustomFields={setCustomFields}
          setLevel={setLevel}
          level={level}
          customFieldsLength={customFields?.length}
        />
      )}
      {customFieldEditModel?.status && (
        <DoctorFormCustomField
          setCustomFieldModel={setCustomFieldModel}
          setCustomFieldEditModel={setCustomFieldEditModel}
          setCustomFields={setCustomFields}
          setLevel={setLevel}
          customFieldIndex={customFieldEditModel?.index}
          customFieldValue={customFields[customFieldEditModel?.index]}
          edit={true}
          level={level}
          customFieldsLength={customFields?.length}
        />
      )}
    </>
  )
}

export default DoctorRegistrationEdit;