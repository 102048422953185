import React from "react";
import "./job.scss";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import edit from "../../../../assets/svg/edit.svg";
import inactive from "../../../../assets/svg/inactive.svg";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import buttonadd from "../../../../assets/images/plus.png";
import filter1 from "../../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../../assets/svg/Health Article/sortbyicon.svg";
import Deletepopup from "../../../modal/Deletepopup";
import search from "../../../../assets/images/searchIcon.png";
import { FaAngleRight } from "react-icons/fa";
import Autocomplete from "react-google-autocomplete";

const JobPost = () => {
  const [filtered_records, setFilteredRecords] = useState([]);
  const [rows_count, setRowCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [filter, setFilter] = useState({});
  const [records, setRecords] = useState([]);
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [submitfilter, setSubmitFilter] = useState(false);
  const [departmentdata, setDepartmentData] = useState([]);
  const [designationdata, setDesignationData] = useState([]);
  const [typeofjobdata, setTypeofJobData] = useState([]);
  const [statusdata, setStatusData] = useState([]);
  const [location, setLocation] = useState("");

  const department = [
    {
      text: "Engineering",
    },
    {
      text: "Sales",
    },
    {
      text: "Content",
    },
  ];

  const designation = [
    {
      text: "Web Developer",
    },
    {
      text: "Content Market",
    },
    {
      text: "Web Designer",
    },
  ];

  const typeofjob = [
    {
      text: "Full Time",
    },
    {
      text: "Part Time",
    },
    {
      text: "Freelancer",
    },
  ];

  const status = [
    {
      text: "Active",
    },
    {
      text: "Inactive",
    },
  ];

  const handleActive = (id, status) => {
    // debugger;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}career-add-job-post/update/${id}`,
        {
          active: !status,
        }
      )
      .then((result) => {
        console.log(result, "result...");
      });
    list_api_call();
  };

  useEffect(() => {
    list_api_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, limit, sort, skip]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page]);

  // useEffect(()=>{
  //   filter_call()
  // }, [departmentdata, submitfilter])

  const filter_call = () => {
    // if(submitfilter === true){
    axios
      .post(`${process.env.REACT_APP_API_URL}filters/jobpost`, {
        key: "job_post",
        values: {
          department: departmentdata,
        },
      })
      .then((res) => {
        setRecords(res.data);
      });
    // }
  };

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}filters/job_sortlist`, {
        key: "job_post",
        value: sort,
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data);
        // setRowCount(response.data.count);
        // setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows);
  };

  const handleJobPostForm = () => {
    navigate("/careerjobpostform");
  };

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}career-add-job-post/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  const handleEdit = (id) => {
    navigate(`/jobpostedit/${id}`);
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>
              &#62;
            </span>
            <Link
              to="/careers"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{}}>Careers</span>
            </Link>
            <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
            <span style={{ paddingLeft: "0.4%", fontSize: "15px" }}>
              Job Post
            </span>
          </div>
          <div className="healtharticle-addnew">
            <div
              id="addnewpost"
              onClick={handleJobPostForm}
              className="addnewhealth"
              style={{
                width: "202px",
              }}
            >
              <div>
                {/* <BsFillPlusCircleFill /> */}
                <img src={buttonadd} alt="" />
              </div>
              <div>
                <button>Add New Job Post</button>
              </div>
            </div>
          </div>
          <div className="careers-tab" style={{ marginLeft: "2%" }}>
            <div
              className="career-tab-career"
              onClick={() => navigate("/careers")}
            >
              Careers
            </div>
            <div className="career-tab-jobpost job-post">Job Post</div>
            <div
              className="career-post-department"
              onClick={() => navigate("/careerdepartment")}
            >
              Department / Function
            </div>
            <div
              className="career-post-position"
              onClick={() => navigate("/careerposition")}
            >
              Position / Designation
            </div>
          </div>

          <div className="healtharticle-show mt-5">
            <div>Show</div>
            <div>
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                {/* <FaSearch /> */}
                <img src={search} alt="" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
            <div className="sortandfilter-new">
              <div className="sortByPositionNew">
                <div
                  onClick={() => {
                    setSortShow(!sortshow);
                  }}
                >
                  <div>{sortName}</div>
                  <div>
                    <img src={sort1} alt="" />
                  </div>
                </div>
                {sortshow ? (
                  <div className="sortby-Common-career-2">
                    <div>
                      <div
                        onClick={() => {
                          setTextColor(11);
                          setSortShow(false);
                          setSort("oldest");
                          setSortName("Oldest");
                        }}
                        style={{
                          color: textcolor === 11 ? "#CB1B5B" : "",
                          fontWeight: textcolor === 11 ? "bold" : "",
                        }}
                      >
                        Oldest
                      </div>
                      <div
                        onClick={() => {
                          setTextColor(12);
                          setSortShow(false);
                          setSort("newest");
                          setSortName("Newest");
                        }}
                        style={{
                          color: textcolor === 12 ? "#CB1B5B" : "",
                          fontWeight: textcolor === 12 ? "bold" : "",
                        }}
                      >
                        Newest
                      </div>
                      <div
                        onClick={() => {
                          setTextColor(13);
                          setSortShow(false);
                          setSort("ascending");
                          setSortName("Ascending");
                        }}
                        style={{
                          color: textcolor === 13 ? "#CB1B5B" : "",
                          fontWeight: textcolor === 13 ? "bold" : "",
                        }}
                      >
                        Alphabetical ↑
                      </div>
                      <div
                        onClick={() => {
                          setTextColor(14);
                          setSortShow(false);
                          setSort("descending");
                          setSortName("Descending");
                        }}
                        style={{
                          color: textcolor === 14 ? "#CB1B5B" : "",
                          fontWeight: textcolor === 14 ? "bold" : "",
                        }}
                      >
                        Alphabetical ↓
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="sortByDepartment">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setFilterShow(true);
                      }}
                    >
                      Filter
                    </div>
                  </div>
                  <div>
                    <img src={filter1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="healtharticle-table career-jobpost-table">
            <table>
              <thead>
                <tr>
                  <td className="career-jobpost-td1">S.No</td>
                  <td className="career-jobpost-td2">
                    Department / Function Name
                  </td>
                  <td className="career-jobpost-td3">Location</td>
                  <td className="career-jobpost-td4">Job Type</td>
                  <td className="career-jobpost-td5">Open Positions</td>
                  <td className="career-jobpost-td6">Position / Designation</td>
                  <td className="career-jobpost-td7">Job Description</td>
                  <td className="career-jobpost-td8">Organisation Name</td>
                  <td className="career-jobpost-td9">Employment Start Date</td>
                  <td className="career-jobpost-td10">Total Working Days</td>
                  <td className="career-jobpost-td11">Post Expiry Date</td>
                  <td className="career-jobpost-td12">Actions</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0 ? filtered_records : records).map(
                  (el, i) => {
                    if (el.show === undefined || el.show === true)
                      return (
                        <tr key={el._id}>
                          <td>{(current_page - 1) * limit + (i + 1)}</td>
                          <td>{el.department}</td>
                          <td>{el.job_Location}</td>
                          <td>{el.type_of_job}</td>
                          <td>{el.total_Open_Position}</td>
                          <td>{el.designation}</td>
                          <td
                            className="jobPostDescription"
                            dangerouslySetInnerHTML={{
                              __html: el.job_Description,
                            }}
                          ></td>
                          <td>{el.organisation_Name}</td>
                          <td>{el.employment_Startdate}</td>
                          <td>{el.total_working_days}</td>
                          <td>{el.jobpost_expiry}</td>
                          <td>
                            <span
                              className="edit"
                              onClick={() => handleEdit(el._id)}
                            >
                              Edit
                            </span>
                            <span className="edit-icon">
                              <img src={edit} alt="" />
                            </span>
                            <span
                              onClick={() => {
                                // handleDelete(el._id)
                                setId(el._id);
                                setDel(true);
                              }}
                              className="delete"
                              type="submit"
                            >
                              Delete
                            </span>
                            <span className="delete-icon"></span>
                            <span
                              className="inactive"
                              onClick={() => {
                                handleActive(el._id, el.active);
                              }}
                            >
                              {el.active ? "Inactive" : "Active"}
                            </span>
                            <span className="inactive-icon">
                              <img src={inactive} alt="" />
                            </span>
                          </td>
                        </tr>
                      );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col d-flex pagination admin-pagination justify-content-end mt-5 ">
        <input
          type="button"
          className="page-item page-link"
          value="<<"
          // onClick={(e) => setCurrentPage(1)}
          disabled={current_page === 1}
        />
        <input
          type="button"
          className="page-item page-link"
          value="<"
          // onClick={(e) => setCurrentPage(current_page - 1)}
          disabled={current_page === 1}
        />

        {current_page > 1 && (
          <input
            type="button"
            value={current_page - 1}
            onClick={(e) => {
              // setCurrentPage(current_page - 1);
            }}
          />
        )}
        <input
          className="page-item page-link"
          type="button"
          value={current_page}
        />
        {current_page < pages && (
          <input
            type="button"
            value={current_page + 1}
            onClick={(e) => {
              // setCurrentPage(current_page + 1);
            }}
          />
        )}
        <input
          type="button"
          value=">"
          className="page-item page-link"
          // onClick={(e) => setCurrentPage(current_page + 1)}
          disabled={current_page === pages}
        />
        <input
          type="button"
          value=">>"
          className="page-link"
          // onClick={(e) => setCurrentPage(pages)}
          disabled={current_page === pages}
        />
      </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <div className="filter-container-career">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(1);
              }}
            >
              <div
                style={{
                  color: textcolor === 1 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 1 ? "bold" : "",
                }}
              >
                Department
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(2);
              }}
            >
              <div
                style={{
                  color: textcolor === 2 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 2 ? "bold" : "",
                }}
              >
                Designation
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(3);
              }}
            >
              <div
                style={{
                  color: textcolor === 3 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 3 ? "bold" : "",
                }}
              >
                Type of Job
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(4);
              }}
            >
              <div
                style={{
                  color: textcolor === 4 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 4 ? "bold" : "",
                }}
              >
                Status
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(5);
              }}
            >
              <div
                style={{
                  color: textcolor === 5 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 5 ? "bold" : "",
                }}
              >
                Location
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                }}
              >
                Clear
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  // setSubmitFilter(true)
                  filter_call();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Department---------*/}
      {textcolor === 1 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Department</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {department.map((el) => {
              return (
                <div className="filter-doctor-fee-box" key={el.text}>
                  <div>{el.text}</div>
                  <div>
                    <input
                      type="radio"
                      name={el.text}
                      id=""
                      checked={departmentdata?.includes(el.text)}
                      onClick={(e) => {
                        if (departmentdata?.includes(e.target.name)) {
                          let filteredData = departmentdata.filter(
                            (val) => val !== e.target.name
                          );
                          setDepartmentData(filteredData);
                        } else {
                          setDepartmentData([...departmentdata, e.target.name]);
                        }
                      }}
                      readOnly
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Designation---------*/}
      {textcolor === 2 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Designation</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {designation.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div>
                    <input
                      type="radio"
                      name={el.text}
                      readOnly
                      checked={designationdata?.includes(el.text)}
                      id=""
                      onClick={(e) => {
                        if (designationdata?.includes(e.target.name)) {
                          let filteredData = designationdata.filter(
                            (val) => val !== e.target.name
                          );
                          setDesignationData(filteredData);
                        } else {
                          setDesignationData([
                            ...designationdata,
                            e.target.name,
                          ]);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Type of Job---------*/}
      {textcolor === 3 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Type of Job</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {typeofjob.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div>
                    <input
                      type="radio"
                      name={el.text}
                      readOnly
                      id=""
                      checked={typeofjobdata?.includes(el.text)}
                      onClick={(e) => {
                        if (typeofjobdata?.includes(e.target.name)) {
                          let filteredData = typeofjobdata.filter(
                            (val) => val !== e.target.name
                          );
                          setTypeofJobData(filteredData);
                        } else {
                          setTypeofJobData([...typeofjobdata, e.target.name]);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Status---------*/}
      {textcolor === 4 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Type of Job</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {status.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div>
                    <input
                      type="radio"
                      name={el.text}
                      id=""
                      checked={statusdata?.includes(el.text)}
                      onClick={(e) => {
                        if (statusdata?.includes(e.target.name)) {
                          let filteredData = statusdata.filter(
                            (val) => val !== e.target.name
                          );
                          setStatusData(filteredData);
                        } else {
                          setStatusData([...statusdata, e.target.name]);
                        }
                      }}
                      // onChange={(e)=>{
                      //   setStatusData([...statusdata, e.target.name])
                      // }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Location--------*/}
      {textcolor === 5 && filtershow === true ? (
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Location</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div>
                  <img src={search} alt="" />
                </div>
                <div>
                  {/* <input 
                    type="text" 
                    name="" 
                    id="filter-search" 
                    placeholder="Search"
                    onChange={(e)=>setLocation(e.target.value)}
                  /> */}
                  <Autocomplete
                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                    onPlaceSelected={(place) => {
                      setLocation(place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {sortshow ? (
        <div className="sortby-Common-career">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
    </>
  );
};

export default JobPost;
